import React, {useEffect} from "react";
import {
    Box,
    Typography,
} from "@material-ui/core";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {FormattedMessage} from "react-intl";

import ChartCard from "../chart-card";
import MessageTitle from "../../../common/components/message-title";
import {useChartProperties} from "../chart";
import BrandRatingTable, {RatingTableSortBlock} from "../../../common/components/brand-rating-table";
import {formatTimeRangeString} from "../../../utils/localeUtils";
import {selectors} from "../../../../_metronic/ducks/i18n";
import {parseTimeRange} from "../../../utils/timeUtils";

const ClosestCompetitorsTable = ({ brandId, metricId, alertText, toggleHelpDrawer }) => {
    const dispatch = useDispatch();

    const language = useSelector(selectors.getSelectedLanguage);

    const {
        metricFilters,
        isChartLoaded,
        data,
        dataFeatures,
        title,
        subTitle,
        description,
        releaseNotes,
        chartHeight,
        setMetricActiveFiltersAction,
    } = useChartProperties(brandId, null, null, null, metricId, 400);

    const reportTimeRange = parseTimeRange(_.get(data, "reportTimeRange"));
    const baseTimeRange = parseTimeRange(_.get(data, "baseTimeRange"));
    const brandRatings = _.get(data, "brandRatings", []);

    const orderInfo = ((metricFilters || {}).orderInfo || {});

    const onBrandRatingTableSortChange = (newOrderInfo) => {
        const updatedOrderInfo = {
            ...orderInfo,
            ...newOrderInfo
        };

        dispatch(setMetricActiveFiltersAction({
            orderInfo: updatedOrderInfo
        }));
    };

    useEffect(() => {
        if (brandRatings.length > 0) {
            if (!orderInfo.sortKey) {
                onBrandRatingTableSortChange({
                    sortKey: _.head(_.head(brandRatings).properties).key,
                    sortProperty: "rawValue",
                    sortOrder: "desc",
                });
            }
        }
        // eslint-disable-next-line
    }, [brandRatings.length]);

    return (
        <ChartCard
            title={title}
            subTitle={subTitle}
            description={description}
            releaseNotes={releaseNotes}
            dataFeatures={dataFeatures}
            toggleHelpDrawer={toggleHelpDrawer}
            isLoading={!isChartLoaded}
        >
            <Box>
                {brandRatings.length > 0 ? (
                    <Box>
                        <Box ml={1} mb={1}>
                            <Typography variant="subtitle1" color="textSecondary">
                                {`${formatTimeRangeString(reportTimeRange, true, language)} vs ${formatTimeRangeString(baseTimeRange, true, language)}`}
                            </Typography>
                        </Box>

                        <Box mb={1}>
                            <RatingTableSortBlock
                                orderInfo={orderInfo}
                                shouldAllowByValueSort={true}
                                shouldAllowByRawValueSort={true}
                                onChange={onBrandRatingTableSortChange}
                            />
                        </Box>

                        <BrandRatingTable
                            brandRatings={brandRatings}
                            shouldShowRankColumn={false}
                            orderInfo={orderInfo}
                            onSortChange={onBrandRatingTableSortChange}
                        />
                    </Box>
                ) : (
                    <Box style={{display:"flex", flexDirection: "column", height: chartHeight}}>
                        <MessageTitle>
                            {!isChartLoaded ? (
                                <FormattedMessage id="DATA_IS_BEING_LOADED"/>
                            ) : (alertText ? alertText : <FormattedMessage id="NOT_ENOUGH_DATA_PROMPT"/>)}
                        </MessageTitle>
                    </Box>
                )}
            </Box>
        </ChartCard>
    );
};

export default ClosestCompetitorsTable;
