import React from "react";
import { Formik } from "formik";
import {connect, useDispatch, useSelector} from "react-redux";
import { TextField } from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";

import * as alert from "../../store/ducks/alert.duck";

import logoImage from "../../../assets/images/logo.png";
import * as auth from "../../store/ducks/auth.duck";
import { selectors } from "../../../_metronic/ducks/i18n";
import { recoverPassword } from "../../crud/auth.crud";

import ProgressableButton from "../../common/components/progressable-button";
import GoogleAnalytics from "../../common/components/google-analytics";
import { getLandingUrl } from "../../crud/constants";

const RecoverPassword = (props) => {
  const { intl } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const language = useSelector(selectors.getSelectedLanguage);

  return (
    <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__logo">
              <a
                  href={getLandingUrl("HOME", language)}
                  target="_blank"
                  rel="noopener noreferrer"
              >
              <img alt="Logo" src={logoImage} />
            </a>
          </div>
          <div className="kt-login__title">
            <h3>
              <FormattedMessage id="AUTH.FORGOT.TITLE" />
            </h3>
          </div>

          <Formik
            initialValues={{ email: "" }}
            validate={(values) => {
              const errors = {};

              if (!values.email) {
                errors.email = intl.formatMessage({
                  id: "VALIDATION.REQUIRED_FIELD",
                });
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = intl.formatMessage({
                  id: "VALIDATION.INVALID_FIELD",
                });
              }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              GoogleAnalytics.reportEvent({
                category: 'Auth',
                action: 'RecoverPassword',
                label: values.email
              });

              recoverPassword(values.email)
                .then(({data: { message } }) => {
                  setSubmitting(false);

                  dispatch(alert.actions.showAlert({
                    severity: "success",
                    message: message
                  }));

                  history.push("/auth/login");
                })
                .catch(() => {
                  setSubmitting(false);

                  setStatus(
                    intl.formatMessage(
                      { id: "VALIDATION.NOT_FOUND" },
                      { name: values.email }
                    )
                  );
                });
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} className="kt-form">
                {status && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                )}

                <div className="form-group">
                  <TextField
                    type="email"
                    label={<FormattedMessage id="INPUT.EMAIL" />}
                    margin="normal"
                    fullWidth={true}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                  />
                </div>

                <div className="kt-login__actions">
                  <Link to="/auth">
                    <button
                      type="button"
                      className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                    >
                      <FormattedMessage id="AUTH.GENERAL.BACK_BUTTON" />
                    </button>
                  </Link>

                  <ProgressableButton
                    type="submit"
                    disabled={isSubmitting}
                    isProgressing={isSubmitting}
                  >
                    <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
                  </ProgressableButton>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(connect(null, auth.actions)(RecoverPassword));
