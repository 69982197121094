import React from "react";
import {
    Box,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography
} from "@material-ui/core";
import {rightBorderedCellStyle} from "../../../utils/tableStyles";
import css from "./styles.module.scss";
import {FormattedMessage, injectIntl} from "react-intl";
import BrandTooltip from "../brand-tooltip";
import {
    convertValueToLocaleString,
    generateCatalogUrl,
    generateSalesAnalysisUrl,
    getLogoImageUrl
} from "../../../utils/utils";
import _ from "lodash";
import {Link} from "react-router-dom";
import * as popups from "../../../store/ducks/popups.duck";
import StarOutlinedIcon from "@material-ui/icons/StarOutlined";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowDownShortWide,
    faArrowUpWideShort,
    faLock,
    faSort
} from "@fortawesome/free-solid-svg-icons";
import Badge from "../badge";
import {useDispatch, useSelector} from "react-redux";
import {selectors} from "../../../../_metronic/ducks/i18n";
import {useTheme} from "@material-ui/styles";
import Tooltip from "../tooltip";
import {Dropdown} from "react-bootstrap";

export const createRatingPropertyValueElement = (property, settings, intl, language) => {
    let rawValueSpanElement = null;
    if (property.rawValue !== null && property.rawValue !== undefined) {
        let formattedRawValue = convertValueToLocaleString(property.rawValue, intl, language, settings.rawValueFormat)
        let rawValueClassName = css.rawValue;
        let rawValueTooltipTitle;

        if (property.rawValuePolicy) {
            if (property.rawValuePolicy === "blur") {
                formattedRawValue = formattedRawValue.replace(/\d/g, "*");
                rawValueClassName = css.rawValueBlur;
                rawValueTooltipTitle = intl.formatMessage({ id: "BLURRY.VALUE.TOOLTIP" });
            }
            else {
                console.assert(false, "Unsupported rawValuePolicy!");
            }
        }

        rawValueSpanElement = (
            <Tooltip arrow title={rawValueTooltipTitle}>
                <span className={rawValueClassName}>
                    {`${formattedRawValue}`}
                </span>
            </Tooltip>
        );
    }

    let valueSpanElement = null;
    if (property.value !== null && property.value !== undefined) {
        let formattedValue = convertValueToLocaleString(property.value, intl, language, {
            ...settings.valueFormat,
            signDisplay: "exceptZero"
        });
        let valueClassName;
        let valueTooltipTitle;

        if (formattedValue !== "-") {
            const formattedValueSign = (_.includes(['+', '-'], formattedValue[0]) ? formattedValue[0] : '');
            if (formattedValueSign) {
                formattedValue = formattedValue.slice(1);
            }

            const signToClassNames = {
                '': 'ratingZeroValue',
                '+': "ratingPositiveValue",
                '-': "ratingNegativeValue",
            };
            valueClassName = signToClassNames[formattedValueSign];
        }
        else {
            formattedValue = "N/A";
            valueClassName = "ratingUnknownValue";
            valueTooltipTitle = intl.formatMessage({ id: "UNKNOWN.VALUE.TOOLTIP" });
        }

        if (property.valuePolicy) {
            if (property.valuePolicy === "hide") {
                formattedValue = "";
                valueTooltipTitle = intl.formatMessage({ id: "BLURRY.VALUE.TOOLTIP" });
            }
            else {
                console.assert(false, "Unsupported valuePolicy!");
            }
        }

        valueSpanElement = (
            <Tooltip arrow title={valueTooltipTitle}>
                <span className={css[valueClassName]}>
                    {formattedValue}
                </span>
            </Tooltip>
        );
    }

    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            {rawValueSpanElement}
            {valueSpanElement}
        </Box>
    );
};

export const RatingTableSortLabel = injectIntl(({sortKey, orderInfo, onSortChange, intl, options}) => {
    if (!options) {
        options = {};
    }

    const defaultSortOrder = (options.defaultSortOrder || "asc");
    const negativeDefaultSortOrder = (defaultSortOrder === "asc" ? "desc" : "asc");
    const sortOrder = (orderInfo.sortOrder || defaultSortOrder);

    const SortIcon = () => {
        const icon = (orderInfo.sortKey !== sortKey ? faSort : (sortOrder === "asc" ? faArrowDownShortWide : faArrowUpWideShort));
        const color = (orderInfo.sortKey === sortKey ? "#16a2e9" : "#e3e3e3");

        return (
            <Box ml={0.5}>
                <FontAwesomeIcon icon={icon} size={"lg"} color={color} />
            </Box>
        );
    };

    return (
        <TableSortLabel
            IconComponent={SortIcon}
            onClick={() => {
                const newOrderInfo = {
                    ...orderInfo,
                    sortKey: sortKey,
                    sortOrder: (orderInfo.sortKey !== sortKey ? defaultSortOrder : (sortOrder === defaultSortOrder) ? negativeDefaultSortOrder : defaultSortOrder),
                };

                onSortChange(newOrderInfo);
            }}
        >
        </TableSortLabel>
    );
});

const useStyles = makeStyles(() => ({
    dropdownMenu: {
        maxHeight: "500px",
        overflow: "auto",
    },
}));

export const RatingTableSortBlock = injectIntl(({
    orderInfo,
    shouldAllowByValueSort,
    shouldAllowByRawValueSort,
    onChange,
    intl
}) => {
    const classes = useStyles();

    const supportedSortProperties = [...(shouldAllowByValueSort ? ["value"] : []), ...(shouldAllowByRawValueSort ? ["rawValue"] : [])];
    const formattedSortProperties = {
        value: intl.formatMessage({ id: "SORT.PROPERTY.VALUE.TITLE" }),
        rawValue: intl.formatMessage({ id: "SORT.PROPERTY.RAW.VALUE.TITLE" }),
    };

    return (
        <Box display="flex" alignItems="center">
            <Box mr={1}>
                <Typography>
                    <FormattedMessage id="RATING.TABLE.SORT.PROPERTY.PROMPT" />
                </Typography>
            </Box>

            <Dropdown>
                <Dropdown.Toggle>
                    {formattedSortProperties[orderInfo.sortProperty]}
                </Dropdown.Toggle>

                <Dropdown.Menu flip={false} className={classes.dropdownMenu}>
                    {supportedSortProperties.map((supportedSortProperty) => (
                        <Dropdown.Item
                            key={supportedSortProperty}
                            active={supportedSortProperty === orderInfo.sortProperty}
                            onClick={() => {
                                onChange({
                                    sortProperty: supportedSortProperty
                                });
                            }}>
                            {formattedSortProperties[supportedSortProperty]}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </Box>
    );
});

const BrandRatingTable = ({
    brandRatings,
    shouldShowRankColumn,
    orderInfo,
    onSortChange,
    intl
}) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const language = useSelector(selectors.getSelectedLanguage);

    return (
        <Table stickyHeader>
            <TableHead>
                <TableRow>
                    {shouldShowRankColumn && (
                        <TableCell align="center" style={rightBorderedCellStyle}>
                            <span className={css.columnTitle}>
                                <FormattedMessage id="BRAND.RATING.RANK.COLUMN.TITLE" />
                            </span>
                        </TableCell>
                    )}

                    <TableCell align="center" style={rightBorderedCellStyle}>
                        <span className={css.columnTitle}>
                            <FormattedMessage id="BRAND.RATING.NAME.COLUMN.TITLE" />
                        </span>
                    </TableCell>

                    {brandRatings[0].properties.map((property, index) => (
                        <TableCell key={index} align="center" style={index < (brandRatings[0].properties.length - 1) ? rightBorderedCellStyle : {}}>
                            <span className={css.columnTitle}>
                                {property.title}
                            </span>
                            <RatingTableSortLabel
                                sortKey={property.key}
                                orderInfo={orderInfo}
                                onSortChange={onSortChange}
                                options={{
                                    defaultSortOrder: "desc"
                                }}
                            />
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {brandRatings.map((brandRating, rowIndex) => (
                    <TableRow key={rowIndex}>
                        {shouldShowRankColumn && (
                            <TableCell align="center" style={rightBorderedCellStyle}>
                                <Typography className={css.brandRank}>
                                    {_.get(brandRating, "rank", "")}
                                </Typography>
                            </TableCell>
                        )}

                        {(brandRating.brand && brandRating.brand.id !== "0") ? (
                            <BrandTooltip
                                key={rowIndex}
                                brandInfo={brandRating.brand}
                                enableSalesAnalysisLink={true}
                                useBrandLockStatus={false}
                                showAddToCartButton={false}
                                showAddToComparisonButton={true}
                            >
                                <TableCell style={rightBorderedCellStyle}>
                                    <Box className={css.brandWrapper}>
                                        <img
                                            className={css.brandImage}
                                            src={getLogoImageUrl(_.get(brandRating, "brand.logo", ""))}
                                            alt={_.get(brandRating, "brand.name", "")}
                                        />
                                        <Box>
                                            <Link
                                                to={!_.get(brandRating, "brand.isLocked") ? generateSalesAnalysisUrl({
                                                    brandId: _.get(brandRating, "brand.id", "")
                                                }) : window.location.href.replace(window.location.origin, "")}
                                                onClick={(event) => {
                                                    if (_.get(brandRating, "brand.isLocked")) {
                                                        event.preventDefault();
                                                        dispatch(popups.actions.showSendMessagePopup(
                                                            "Brand access request (closest competitors)",
                                                            intl.formatMessage({ id: "REQUEST.ACCESS.POPUP.TITLE" }),
                                                            intl.formatMessage({ id: "REQUEST.BRAND.ACCESS.MESSAGE.TEMPLATE"}, { brandName: _.get(brandRating, "brand.name", "") }),
                                                        ));
                                                    }
                                                }}
                                            >
                                                <Box display="flex" flexDirection="row" alignItems="center">
                                                    {_.get(brandRating, "brand.isAccented") && (
                                                        <Box mr={0.5}>
                                                            <StarOutlinedIcon />
                                                        </Box>
                                                    )}

                                                    <Typography className={css.brandName}>
                                                        {_.get(brandRating, "brand.name", "")}

                                                        {_.get(brandRating, "brand.isLocked") && (
                                                            <span style={{marginLeft: "8px"}}>
                                                                <FontAwesomeIcon icon={faLock} color={theme.palette.primary.dark} />
                                                            </span>
                                                        )}
                                                    </Typography>

                                                    {_.get(brandRating, "brand.isDemo") && (
                                                        <Box ml={1}>
                                                            <Badge color={"green"} text={<FormattedMessage id="BRAND.DEMO.BADGE.TEXT" />} />
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Link>

                                            <Link
                                                to={generateCatalogUrl({
                                                    industry: _.get(brandRating, "brand.industry.id", "")
                                                })}
                                            >
                                                <p className={css.catalogLink}>
                                                    {_.get(brandRating, "brand.industry.name", "")}
                                                </p>
                                            </Link>
                                        </Box>
                                    </Box>
                                </TableCell>
                            </BrandTooltip>
                        ) : (
                            <TableCell style={rightBorderedCellStyle}>
                                <Box className={css.brandWrapper} display="flex" justifyContent="center" alignItems="center">
                                    <Typography className={css.brandName}>
                                        {!brandRating.brand ? (
                                            <FormattedMessage id="INDUSTRY.TOTAL.VALUES.PROMPT"/>
                                        ) : (
                                            <FormattedMessage id="OTHER.BRANDS.PROMPT"/>
                                        )}
                                    </Typography>
                                </Box>
                            </TableCell>
                        )}

                        {brandRating.properties.map((property, index) => (
                            <TableCell key={index} style={index < (brandRating.properties.length - 1) ? rightBorderedCellStyle : {}}>
                                {createRatingPropertyValueElement(property, brandRatings[0].properties[index].settings, intl, language)}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default injectIntl(BrandRatingTable);
