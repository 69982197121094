import React, { useState } from "react";
import {connect, useDispatch} from "react-redux";
import {Snackbar, makeStyles, Portal, Box} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import Tooltip from "../tooltip";
import classnames from "classnames";
import * as cart from "../../../store/ducks/cart.duck";
import {FormattedMessage, injectIntl} from "react-intl";

import GoogleAnalytics from "../google-analytics";

const useStyles = makeStyles((theme) => ({
    button: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        height: "35px",
        width: "auto",

        border: "none",
        borderRadius: "3px",

        background: "#f4f4f8",
        color: "#2c77f4",
        transition: "background 0.3s ease",

        "&:hover": {
            background: "#2c77f4",
            color: "#fff",
            transition: "background 0.3s ease",
        },

        "& > i": {
            fontSize: "23px",
        },
    },
    buttonActive: {
        background: "#2c77f4",
        color: "#fff",
    },
    buttonSimple: {
        height: "44px",
        width: "44px",
    },
}));

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const AddToCartButton = ({ brandInfo, items, useButtonSimpleMode, text, ...props }) => {
    const { intl } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    const isBrandInCart = !!items.find((item) => item.id === brandInfo.id);

    // TODO: Migrate to alert!
    const [alertState, setAlertState] = useState({});

    const handleClick = () => {
        if (!isBrandInCart) {
            GoogleAnalytics.reportEvent({
                category: 'Cart',
                action: 'AddItemToCart',
                label: `${brandInfo.id}/${brandInfo.name}`
            });

            dispatch(cart.actions.addItemToCart(brandInfo));

            setAlertState({
                severity: "success",
                text: intl.formatMessage({id: "ADD_TO_CART_BUTTON.ADDED_TO_CART"}),
                isOpen: true
            });
        }
        else {
            setAlertState({
                severity: "info",
                text: intl.formatMessage({id: "ADD_TO_CART_BUTTON.IN_CART"}),
                isOpen: true
            });
        }
    };

    const handleCloseAlert = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setAlertState({
            ...alertState,
            isOpen: false
        });
    };

    return (
        <>
            <Tooltip
                arrow
                title={
                    !isBrandInCart ? (
                        <FormattedMessage id="ADD_TO_CART_BUTTON.ADD_TO_CART" />
                    ) : (
                        <FormattedMessage id="ADD_TO_CART_BUTTON.IN_CART" />
                    )
                }
            >
                <button
                    className={classnames({
                        [classes.button]: true,
                        [classes.buttonActive]: isBrandInCart,
                        [classes.buttonSimple]: useButtonSimpleMode,
                    })}
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();

                        handleClick();
                    }}
                >
                    <Box display="flex" flexWrap="wrap" justifyContent="center" alignItems="center">
                        <Box mr={useButtonSimpleMode ? 0 : 1}>
                            <ShoppingCartOutlinedIcon />
                        </Box>
                        {useButtonSimpleMode ? (<></>) : (
                            text
                        )}
                    </Box>
                </button>
            </Tooltip>
            <Portal>
                <Snackbar
                    open={alertState.isOpen}
                    autoHideDuration={3000}
                    onClose={handleCloseAlert}
                >
                    <Alert onClose={handleCloseAlert} severity={alertState.severity}>
                        {alertState.text}
                    </Alert>
                </Snackbar>
            </Portal>
        </>
    );
};

const mapStateToProps = (state) => ({
    items: cart.selectors.getAddedItems(state),
});

export default injectIntl(connect(mapStateToProps, null)(AddToCartButton));
