import React, {useEffect, useState} from "react";
import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,
    makeStyles, Typography, IconButton, withStyles,
} from "@material-ui/core";
import { Dropdown } from "react-bootstrap";
import {connect, useDispatch, useSelector} from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";

import { selectors } from "../../../../_metronic/ducks/i18n";
import {
    createTimeRange,
    createMonthTimeRange,
    formatTimeRangeString,
    formatMonthYearString,
    utcDateToLocalDate,
    localDateToUtcDate,
    generateMonthList,
    isTimeRangeWithinMonths,
    alignTimeRangeToMonth,
    TimeRangeCreator,
    getMonthTimeRangeIncludeness,
    MonthTimeRangeIncludeness,
} from "../../../utils/utils";

import LocationSelect from "../location-select";
import PosSelect from "../pos-select";
import Tooltip from "../tooltip";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import * as alert from "../../../store/ducks/alert.duck";
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import DateRangeIcon from "@material-ui/icons/DateRange";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import CloseIcon from "@material-ui/icons/Close";
import {
    faArrowsToDot,
    faCalendar,
    faCube,
    faCubes,
    faGroupArrowsRotate,
    faD,
    faW,
    faM,
    faCalendarDays,
    faLock,
    faUnlock,
} from "@fortawesome/free-solid-svg-icons";

import _ from "lodash";
import moment from "moment";
import * as auth from "../../../store/ducks/auth.duck";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTheme} from "@material-ui/styles";
import RefreshProgressableButton from "../refresh-progressable-button";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
    filters: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "right",
        "@media (max-width: 700px)": {
            flexDirection: "column",
            flexWrap: "wrap",
            justifyContent: "center",
        }
    },
    timeRangeFilters: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        "@media (max-width: 700px)": {
            marginTop: 10,
        }
    },
    timeRangeDropdownMenuToggle: {
        display: "flex",
        maxWidth: "150px",
    },
    dropdownMenu: {
        maxHeight: "500px",
        overflow: "auto",
    },
    timeRangesUnlockDropdownItem: {
        width: "auto",
        height: "auto",
        margin: "5px",
        borderRadius: "5px",
        color: "#6d8de7",
        backgroundColor: "#edf2fc",
        whiteSpace: "pre-wrap",
    },
    swapTimeRangesButton: {
        width: "15px",
        height: "15px",
    },
    swapTimeRangesButtonIcon: {
        width: "15px",
        height: "15px",
    },
    fullyActiveMonthMenuItem: {
        color: "#ffffff !important",
        textDecoration: "none !important",
        backgroundColor: "#2c77f4 !important",
    },
    partiallyActiveMonthMenuItem: {
        color: "#ffffff !important",
        textDecoration: "none !important",
        backgroundColor: "#7daefa !important",
    }
}));

const DialogActionsPadded = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    }
}))(DialogActions);

const TimeRangeSelectionPopup = ({ isOpen, onChange, onApply, onClose, timeRangeKey, timeRange, value, allowedMonths, locale, ...props }) => {
    const { intl } = props;
    const dispatch = useDispatch();

    const timeRangeToValue = (timeRange) => {
        let value = null;

        if (timeRange) {
            // TimeRange is in Local time but Calendar is in UTC time.
            value = [localDateToUtcDate(timeRange.startDate), localDateToUtcDate(timeRange.endDate)];
        }

        return value;
    };

    const valueToTimeRange = (value) => {
        let timeRange = null;

        if (value) {
            // TimeRange is in Local time but Calendar is in UTC time.
            timeRange = createTimeRange(utcDateToLocalDate(value[0]), utcDateToLocalDate(value[1]));
        }

        return timeRange;
    };

    // Sort them in asc order.
    allowedMonths = _.orderBy(allowedMonths, null, "asc");

    if (!timeRange || (!timeRange.startDate || !timeRange.endDate)) {
        timeRange = createMonthTimeRange(allowedMonths[allowedMonths.length - 1]);
    }

    if (!value) {
        value = timeRangeToValue(timeRange);
    }

    const minAllowedDate = localDateToUtcDate(createMonthTimeRange(allowedMonths[0]).startDate);
    const maxAllowedDate = localDateToUtcDate(createMonthTimeRange(allowedMonths[allowedMonths.length - 1]).endDate);

    return (
        <Dialog
            open={!!isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box display={"flex"} flexDirection={"row"}>
                        <Box mr={1}>
                            <DateRangeIcon color="primary"/>
                        </Box>
                        <Typography variant="h6">
                            <FormattedMessage id="TIME_RANGE_SELECTION_POPUP_TITLE" />
                        </Typography>
                    </Box>
                    <IconButton aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent dividers>
                {value && (
                    <>
                        <Box display={"flex"} flexDirection={"row"} mb={1}>
                            <Box>
                                <Typography variant="subtitle1">
                                    <FormattedMessage id="CHOSEN_TIME_RANGE_PROMPT" />
                                </Typography>
                            </Box>

                            <Box ml={1}>
                                <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                                    {formatTimeRangeString(valueToTimeRange(value), true, locale)}
                                </Typography>
                            </Box>
                        </Box>

                        <Calendar
                            minDate={minAllowedDate}
                            maxDate={maxAllowedDate}
                            value={value}
                            onChange={onChange}
                            selectRange={true}
                            showNeighboringMonth={true}
                            showWeekNumbers={true}
                            locale={locale}
                        />
                    </>
                )}
            </DialogContent>
            <DialogActionsPadded>
                <Button
                    onClick={onClose}
                    color="secondary"
                    variant="contained"
                >
                    <FormattedMessage id="DISCARD_BUTTON_TITLE" />
                </Button>
                <Button
                    onClick={() => {
                        const timeRange = valueToTimeRange(value);
                        if (isTimeRangeWithinMonths(timeRange, allowedMonths)) {
                            onApply(timeRangeKey, timeRange);
                            onClose();
                        }
                        else
                        {
                            dispatch(alert.actions.showAlert({
                                severity: "error",
                                message: intl.formatMessage({id: "UNPAID_MONTH_IN_CHOSEN_TIME_RANGE_ERROR"})
                            }));
                        }
                    }}
                    color="primary"
                    variant="contained"
                >
                    <FormattedMessage id="APPLY_BUTTON_TITLE" />
                </Button>
            </DialogActionsPadded>
        </Dialog>
    );
};

const MetricNakedFilters = ({ locations, periods, months, lockedMonths, onMonthUnlockRequest, options, filters, onChange, isChanging, user, ...props }) => {
    const { intl } = props;
    const theme = useTheme();
    const classes = useStyles();

    if (!options) {
        options = {};
    }

    if (_.get(options, "locationFilter.visible") === undefined) {
        _.set(options, "locationFilter.visible", user && user.capabilities["location_filter"]);
    }
    if (_.get(options, "locationFilter.enabled") === undefined) {
        _.set(options, "locationFilter.enabled", user && user.capabilities["location_filter"]);
    }
    if (_.get(options, "posFilter.visible") === undefined) {
        _.set(options, "posFilter.visible", user && user.capabilities["pos_filter"]);
    }
    if (_.get(options, "posFilter.enabled") === undefined) {
        _.set(options, "posFilter.enabled", user && user.capabilities["pos_filter"]);
    }

    const language = useSelector(selectors.getSelectedLanguage);

    const fasIconColor = theme.palette.primary.light;

    const { salesChannel, locationId, posId, timeRanges, period } = filters;
    let { reportTimeRange, baseTimeRange } = filters;
    if ((!reportTimeRange || !baseTimeRange) && timeRanges) {
        reportTimeRange = _.head(timeRanges);
        baseTimeRange = _.last(timeRanges);
    }

    console.assert(reportTimeRange);

    const salesChannels = _.keys(locations);

    const location = (locations[salesChannel] || []).find((location) => location.id === locationId);

    const poses = ((location || {}).poses || []);
    const pos = poses.find((pos) => pos.id === posId);

    const formattedPeriods = {
        day: intl.formatMessage({ id: "PERIOD.DAY" }),
        week: intl.formatMessage({ id: "PERIOD.WEEK" }),
        month: intl.formatMessage({ id: "PERIOD.MONTH" }),
    };
    const periodIcons = {
        day: faD,
        week: faW,
        month: faM,
    };
    let filteredPeriods = _.keys(formattedPeriods);
    if (periods && periods.length) {
        filteredPeriods = _.filter(filteredPeriods, (period) => {
            return _.includes(periods, period);
        });
    }

    // Sort them in desc order (they are in asc by default).
    months = _.orderBy(months, null, "desc");

    lockedMonths = (lockedMonths || []);
    const unlockedMonths = _.difference(months, lockedMonths);

    const swapTimeRanges = () => {
        if (reportTimeRange && baseTimeRange) {
            onChange({
                reportTimeRange: baseTimeRange,
                baseTimeRange: reportTimeRange
            });
        }
    };

    const isTimeRangeAvailable = (timeRange) => {
        let result = false;

        if (timeRange) {
            result = isTimeRangeWithinMonths(timeRange, unlockedMonths);
        }

        return result;
    };

    const applyAvailableMonthsToTimeRange = (timeRange) => {
        let result = null;

        if (timeRange) {
            if (isTimeRangeWithinMonths(timeRange, months)) {
                result = timeRange;
            }
            else {
                const timeRangeMonths = generateMonthList(timeRange.startDate, timeRange.endDate);
                if (timeRangeMonths.length > 0) {
                    const allowedTimeRangeMonths = _.filter(timeRangeMonths, (timeRangeMonth) => {
                        return _.includes(months, timeRangeMonth);
                    });
                    if (allowedTimeRangeMonths.length > 0) {
                        const allowedTimeRangeMonthSequences = [];
                        let allowedTimeRangeMonthSequence = [allowedTimeRangeMonths[0]];
                        for (let i = 1; i < allowedTimeRangeMonths.length; ++i) {
                            const monthDifference = Math.abs(moment.utc(allowedTimeRangeMonths[i - 1]).diff(moment.utc(allowedTimeRangeMonths[i]), "months"));
                            if (monthDifference === 1) {
                                allowedTimeRangeMonthSequence.push(allowedTimeRangeMonths[i]);
                            }
                            else {
                                if (allowedTimeRangeMonthSequence) {
                                    allowedTimeRangeMonthSequences.push(allowedTimeRangeMonthSequence);
                                    allowedTimeRangeMonthSequence = [allowedTimeRangeMonths[i]];
                                }
                            }
                        }
                        if (allowedTimeRangeMonthSequence) {
                            allowedTimeRangeMonthSequences.push(allowedTimeRangeMonthSequence);
                            allowedTimeRangeMonthSequence = null;
                        }

                        const longestAllowedTimeRangeMonthSequence = _.maxBy(allowedTimeRangeMonthSequences, (el) => el.length);

                        result = alignTimeRangeToMonth(createTimeRange(moment.utc(_.head(longestAllowedTimeRangeMonthSequence)).toDate(), moment.utc(_.last(longestAllowedTimeRangeMonthSequence)).toDate()));
                    }
                }
            }
        }

        return result;
    };

    const timeRangeCreator = new TimeRangeCreator("month", applyAvailableMonthsToTimeRange);

    const autoSelectBaseTimeRangeIfNeeded = (newReportTimeRange) => {
        let newBaseTimeRange = baseTimeRange;

        if (user && user.preferences.metricBasePeriodAutoSelectionStrategy) {
            if (user.preferences.metricBasePeriodAutoSelectionStrategy === "minus1Year") {
                const possibleNewBaseTimeRange = timeRangeCreator.createSimilarTimeRangeMinusNYears(newReportTimeRange, 1);
                if (isTimeRangeAvailable(possibleNewBaseTimeRange)) {
                    newBaseTimeRange = possibleNewBaseTimeRange;
                }
            }
            else {
                console.assert(false, "Unsupported metricBasePeriodAutoSelectionStrategy!");
            }
        }

        return newBaseTimeRange;
    };

    const [timeRangeSelectionPopupState, setTimeRangeSelectionPopupState] = useState({
        isOpen: false,
        timeRangeKey: null,
        timeRange: null,
        value: null
    });

    const isFormatTimeRangeStringSmartMode = true;

    useEffect(() => {
        if (!_.includes(filteredPeriods, period)) {
            onChange({
                period: filteredPeriods[0]
            });
        }
    });

    return (
        <Box className={classes.filters}>
            <TimeRangeSelectionPopup
                isOpen={timeRangeSelectionPopupState.isOpen}
                onChange={(value) => {
                    setTimeRangeSelectionPopupState({
                        ...timeRangeSelectionPopupState,
                        value: value
                    });
                }}
                onApply={(timeRangeKey, timeRange) => {
                    onChange({
                        [timeRangeKey]: timeRange
                    });
                }}
                onClose={() => {
                    setTimeRangeSelectionPopupState({
                        ...timeRangeSelectionPopupState,
                        isOpen: false
                    });
                }}
                timeRangeKey={timeRangeSelectionPopupState.timeRangeKey}
                timeRange={timeRangeSelectionPopupState.timeRange}
                value={timeRangeSelectionPopupState.value}
                allowedMonths={unlockedMonths}
                locale={language}
                intl={intl}
            />

            <Box display="flex" alignItems="center" justifyContent="center">
                {_.get(options, "locationFilter.visible") && (
                    <Box mr={1}>
                        <LocationSelect
                            intl={intl}
                            salesChannels={salesChannels}
                            locations={locations}
                            salesChannel={salesChannel}
                            locationId={(location || {}).id}
                            onChange={({ salesChannel, locationId }) => {
                                onChange({
                                    salesChannel: salesChannel,
                                    locationId: locationId,
                                    posId: null
                                });
                            }}
                            isEnabled={_.get(options, "locationFilter.enabled")}
                        />
                    </Box>
                )}

                {_.get(options, "posFilter.visible") && (
                    <Box mr={1}>
                        <PosSelect
                            intl={intl}
                            poses={poses}
                            posId={(pos || {}).id}
                            onChange={({ posId }) => {
                                onChange({
                                    posId: posId
                                });
                            }}
                            isEnabled={_.get(options, "posFilter.enabled")}
                        />
                    </Box>
                )}

                {_.get(options, "periodFilter.visible") && (
                    <Box ml={(_.get(options, "locationFilter.visible") || _.get(options, "posFilter.visible")) ? 1 : 0}>
                        <Dropdown>
                            <Tooltip arrow title={`${intl.formatMessage({id: "PERIOD.TOOLTIP.PROMPT"})}${formattedPeriods[period]}`}>
                                <Dropdown.Toggle>
                                    <span className="fa-layers fa-fw">
                                        <FontAwesomeIcon icon={faCalendar} size={"lg"} />
                                        <FontAwesomeIcon icon={periodIcons[period]} size={"xs"} transform={"down-5 shrink-5"} color={fasIconColor} />
                                    </span>
                                </Dropdown.Toggle>
                            </Tooltip>
                            <Dropdown.Menu flip={false} className={classes.dropdownMenu}>
                                {filteredPeriods.map((filteredPeriod) => (
                                    <Dropdown.Item
                                        key={filteredPeriod}
                                        active={period === filteredPeriod}
                                        onClick={() => onChange({
                                            period: filteredPeriod
                                        })}
                                    >
                                        {formattedPeriods[filteredPeriod]}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Box>
                )}
            </Box>

            <Box className={classes.timeRangeFilters} ml={2}>
                {_.get(options, "timeRanges.reportTimeRange.visible") && (
                    <Box>
                        <Dropdown>
                            <Tooltip arrow title={`${intl.formatMessage({id: "REPORT.TIME_RANGE.TOOLTIP.PROMPT"})}${formatTimeRangeString(reportTimeRange, isFormatTimeRangeStringSmartMode, language)}`}>
                                <Dropdown.Toggle className={classes.timeRangeDropdownMenuToggle}>
                                    <FontAwesomeIcon icon={faCalendarDays} />
                                    &nbsp;
                                    <span className="text-truncate">
                                        {formatTimeRangeString(reportTimeRange, isFormatTimeRangeStringSmartMode, language)}
                                    </span>
                                </Dropdown.Toggle>
                            </Tooltip>

                            <Dropdown.Menu flip={false} className={classes.dropdownMenu}>
                                {_.get(options, "timeRanges.reportTimeRange.presetListVisible") && (
                                    <>
                                        {(isTimeRangeAvailable(timeRangeCreator.createTimeRangeCurrentPeriod())) && (
                                            <Dropdown.Item
                                                onClick={() => onChange({
                                                    reportTimeRange: timeRangeCreator.createTimeRangeCurrentPeriod(),
                                                    baseTimeRange: autoSelectBaseTimeRangeIfNeeded(timeRangeCreator.createTimeRangeCurrentPeriod()),
                                                })}
                                            >
                                                <FormattedMessage id="TIME_RANGE.CURRENT_MONTH" />
                                            </Dropdown.Item>
                                        )}

                                        {(isTimeRangeAvailable(timeRangeCreator.createTimeRangeLast1Period())) && (
                                            <Dropdown.Item
                                                onClick={() => onChange({
                                                    reportTimeRange: timeRangeCreator.createTimeRangeLast1Period(),
                                                    baseTimeRange: autoSelectBaseTimeRangeIfNeeded(timeRangeCreator.createTimeRangeLast1Period()),
                                                })}
                                            >
                                                <FormattedMessage id="TIME_RANGE.LAST_1_MONTH" />
                                            </Dropdown.Item>
                                        )}

                                        {(isTimeRangeAvailable(timeRangeCreator.createTimeRangeLast3Periods())) && (
                                            <Dropdown.Item
                                                onClick={() => onChange({
                                                    reportTimeRange: timeRangeCreator.createTimeRangeLast3Periods(),
                                                    baseTimeRange: autoSelectBaseTimeRangeIfNeeded(timeRangeCreator.createTimeRangeLast3Periods()),
                                                })}
                                            >
                                                <FormattedMessage id="TIME_RANGE.LAST_3_MONTHS" />
                                            </Dropdown.Item>
                                        )}

                                        {(isTimeRangeAvailable(timeRangeCreator.createTimeRangeLast6Periods())) && (
                                            <Dropdown.Item
                                                onClick={() => onChange({
                                                    reportTimeRange: timeRangeCreator.createTimeRangeLast6Periods(),
                                                    baseTimeRange: autoSelectBaseTimeRangeIfNeeded(timeRangeCreator.createTimeRangeLast6Periods()),
                                                })}
                                            >
                                                <FormattedMessage id="TIME_RANGE.LAST_6_MONTHS" />
                                            </Dropdown.Item>
                                        )}

                                        {(isTimeRangeAvailable(timeRangeCreator.createTimeRangeLast12Periods())) && (
                                            <Dropdown.Item
                                                onClick={() => onChange({
                                                    reportTimeRange: timeRangeCreator.createTimeRangeLast12Periods(),
                                                    baseTimeRange: autoSelectBaseTimeRangeIfNeeded(timeRangeCreator.createTimeRangeLast12Periods()),
                                                })}
                                            >
                                                <FormattedMessage id="TIME_RANGE.LAST_12_MONTHS" />
                                            </Dropdown.Item>
                                        )}

                                        {(isTimeRangeAvailable(timeRangeCreator.createTimeRangeCurrentYear())) && (
                                            <Dropdown.Item
                                                onClick={() => onChange({
                                                    reportTimeRange: timeRangeCreator.createTimeRangeCurrentYear(),
                                                    baseTimeRange: autoSelectBaseTimeRangeIfNeeded(timeRangeCreator.createTimeRangeCurrentYear()),
                                                })}
                                            >
                                                <FormattedMessage id="TIME_RANGE.CURRENT_YEAR" />
                                            </Dropdown.Item>
                                        )}

                                        {(isTimeRangeAvailable(timeRangeCreator.createTimeRangeLastYear())) && (
                                            <Dropdown.Item
                                                onClick={() => onChange({
                                                    reportTimeRange: timeRangeCreator.createTimeRangeLastYear(),
                                                    baseTimeRange: autoSelectBaseTimeRangeIfNeeded(timeRangeCreator.createTimeRangeLastYear()),
                                                })}
                                            >
                                                <FormattedMessage id="TIME_RANGE.LAST_YEAR" />
                                            </Dropdown.Item>
                                        )}

                                        {(isTimeRangeAvailable(timeRangeCreator.createSimilarTimeRangeMinusNYears(reportTimeRange, 1))) && (
                                            <Dropdown.Item
                                                onClick={() => onChange({
                                                    reportTimeRange: timeRangeCreator.createSimilarTimeRangeMinusNYears(reportTimeRange, 1),
                                                    baseTimeRange: autoSelectBaseTimeRangeIfNeeded(timeRangeCreator.createSimilarTimeRangeMinusNYears(reportTimeRange, 1)),
                                                })}
                                            >
                                                <FormattedMessage id="TIME_RANGE.SELECTED_RANGE.MINUS_1_YEAR" />
                                            </Dropdown.Item>
                                        )}

                                        {(isTimeRangeAvailable(timeRangeCreator.createSimilarTimeRangeMinusNYears(reportTimeRange, 2))) && (
                                            <Dropdown.Item
                                                onClick={() => onChange({
                                                    reportTimeRange: timeRangeCreator.createSimilarTimeRangeMinusNYears(reportTimeRange, 2),
                                                    baseTimeRange: autoSelectBaseTimeRangeIfNeeded(timeRangeCreator.createSimilarTimeRangeMinusNYears(reportTimeRange, 2)),
                                                })}
                                            >
                                                <FormattedMessage id="TIME_RANGE.SELECTED_RANGE.MINUS_2_YEARS" />
                                            </Dropdown.Item>
                                        )}

                                        {(isTimeRangeAvailable(timeRangeCreator.createSimilarTimeRangePlusNYears(reportTimeRange, 1))) && (
                                            <Dropdown.Item
                                                onClick={() => onChange({
                                                    reportTimeRange: timeRangeCreator.createSimilarTimeRangePlusNYears(reportTimeRange, 1),
                                                    baseTimeRange: autoSelectBaseTimeRangeIfNeeded(timeRangeCreator.createSimilarTimeRangePlusNYears(reportTimeRange, 1)),
                                                })}
                                            >
                                                <FormattedMessage id="TIME_RANGE.SELECTED_RANGE.PLUS_1_YEAR" />
                                            </Dropdown.Item>
                                        )}

                                        {(isTimeRangeAvailable(timeRangeCreator.createSimilarTimeRangePlusNYears(reportTimeRange, 2))) && (
                                            <Dropdown.Item
                                                onClick={() => onChange({
                                                    reportTimeRange: timeRangeCreator.createSimilarTimeRangePlusNYears(reportTimeRange, 2),
                                                    baseTimeRange: autoSelectBaseTimeRangeIfNeeded(timeRangeCreator.createSimilarTimeRangePlusNYears(reportTimeRange, 2)),
                                                })}
                                            >
                                                <FormattedMessage id="TIME_RANGE.SELECTED_RANGE.PLUS_2_YEARS" />
                                            </Dropdown.Item>
                                        )}
                                    </>
                                )}

                                {_.get(options, "timeRanges.reportTimeRange.customizable") && (
                                    <Dropdown.Item
                                        onClick={() => {
                                            setTimeRangeSelectionPopupState({
                                                isOpen: true,
                                                timeRangeKey: "reportTimeRange",
                                                timeRange: reportTimeRange,
                                            });
                                        }}
                                    >
                                        <Box display={"flex"} flexDirection={"row"}>
                                            <Box mr={1}>
                                                <DateRangeIcon color="primary"/>
                                            </Box>
                                            <FormattedMessage id="CHOOSE_TIME_RANGE" />
                                        </Box>
                                    </Dropdown.Item>
                                )}

                                {lockedMonths.length > 0 && (
                                    <>
                                        <Dropdown.Divider />

                                        <Dropdown.Item
                                            className={classes.timeRangesUnlockDropdownItem}
                                            onClick={() => {
                                                if (onMonthUnlockRequest) {
                                                    onMonthUnlockRequest();
                                                }
                                            }}
                                        >
                                            <Box display={"flex"} flexDirection={"row"}>
                                                <Box mr={1}>
                                                    <FontAwesomeIcon icon={faUnlock} />
                                                </Box>
                                                <FormattedMessage id="TIME_RANGE.UNLOCK.LOCKED.DATES" />
                                            </Box>
                                        </Dropdown.Item>
                                    </>
                                )}

                                {(
                                    _.get(options, "timeRanges.reportTimeRange.presetListVisible") ||
                                    _.get(options, "timeRanges.reportTimeRange.customizable")
                                ) && (
                                    <Dropdown.Divider />
                                )}

                                {_.get(options, "timeRanges.reportTimeRange.monthListVisible") && (
                                    <>
                                        {(months || []).map((month) => {
                                            const isItemDisabled = _.includes(lockedMonths, month);
                                            const monthTimeRangeIncludeness = getMonthTimeRangeIncludeness(month, reportTimeRange);

                                            return (
                                                <Dropdown.Item
                                                    key={`${month}-reportTimeRangeMonth`}
                                                    active={monthTimeRangeIncludeness !== MonthTimeRangeIncludeness.None}
                                                    className={clsx(
                                                        {
                                                            [classes.fullyActiveMonthMenuItem]: monthTimeRangeIncludeness === MonthTimeRangeIncludeness.Full,
                                                            [classes.partiallyActiveMonthMenuItem]: monthTimeRangeIncludeness === MonthTimeRangeIncludeness.Partial
                                                        },
                                                    )}
                                                    disabled={isItemDisabled}
                                                    onClick={() => onChange({
                                                        reportTimeRange: createMonthTimeRange(month),
                                                        baseTimeRange: autoSelectBaseTimeRangeIfNeeded(createMonthTimeRange(month)),
                                                    })}
                                                >
                                                    <Box display="flex" flexDirection="row" flexGrow="1" alignItems="center">
                                                        {formatMonthYearString(month, language)}

                                                        {isItemDisabled && (
                                                            <Box display="flex" flexDirection="row" flexGrow="1" justifyContent="flex-end" ml={1}>
                                                                <FontAwesomeIcon icon={faLock} size="sm" />
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </Dropdown.Item>
                                            );
                                        })}
                                    </>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Box>
                )}

                {(_.get(options, "timeRanges.reportTimeRange.visible") && _.get(options, "timeRanges.baseTimeRange.visible")) && (
                    <>
                        {_.get(options, "timeRanges.swappable") ? (
                            <Tooltip arrow title={intl.formatMessage({id: "TIME_RANGES.SWAP.TOOLTIP.TITLE"})}>
                                <IconButton className={classes.swapTimeRangesButton} onClick={() => swapTimeRanges()}>
                                    <SwapHorizIcon className={classes.swapTimeRangesButtonIcon} color="primary" />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Box ml={1}></Box>
                        )}
                    </>
                )}

                {_.get(options, "timeRanges.baseTimeRange.visible") && (
                    <Box>
                        <Dropdown alignRight={true}>
                            <Tooltip arrow title={(!baseTimeRange && _.get(options, "timeRanges.baseTimeRange.clearable")) ? (<FormattedMessage id="COMPARE" />) : (`${intl.formatMessage({id: "BASE.TIME_RANGE.TOOLTIP.PROMPT"})}${formatTimeRangeString(baseTimeRange, isFormatTimeRangeStringSmartMode, language)}`)}>
                                <Dropdown.Toggle className={classes.timeRangeDropdownMenuToggle}>
                                    <FontAwesomeIcon icon={faCalendarDays} />
                                    &nbsp;
                                    <span className="text-truncate">
                                        {(!baseTimeRange && _.get(options, "timeRanges.baseTimeRange.clearable")) ? (<FormattedMessage id="COMPARE" />) : (formatTimeRangeString(baseTimeRange, isFormatTimeRangeStringSmartMode, language))}
                                    </span>
                                </Dropdown.Toggle>
                            </Tooltip>

                            <Dropdown.Menu flip={false} className={classes.dropdownMenu}>
                                {_.get(options, "timeRanges.baseTimeRange.presetListVisible") && (
                                    <>
                                        {(isTimeRangeAvailable(timeRangeCreator.createPrecedingTimeRange(reportTimeRange))) && (
                                            <Dropdown.Item
                                                onClick={() => onChange({
                                                    baseTimeRange: timeRangeCreator.createPrecedingTimeRange(reportTimeRange)
                                                })}
                                            >
                                                <FormattedMessage id="TIME_RANGE.PRECEDING_RANGE" />
                                            </Dropdown.Item>
                                        )}

                                        {(isTimeRangeAvailable(timeRangeCreator.createSimilarTimeRangeMinusNYears(reportTimeRange, 1))) && (
                                            <Dropdown.Item
                                                onClick={() => onChange({
                                                    baseTimeRange: timeRangeCreator.createSimilarTimeRangeMinusNYears(reportTimeRange, 1)
                                                })}
                                            >
                                                <FormattedMessage id="TIME_RANGE.SAME_RANGE.MINUS_1_YEAR" />
                                            </Dropdown.Item>
                                        )}

                                        {(isTimeRangeAvailable(timeRangeCreator.createSimilarTimeRangeMinusNYears(reportTimeRange, 2))) && (
                                            <Dropdown.Item
                                                onClick={() => onChange({
                                                    baseTimeRange: timeRangeCreator.createSimilarTimeRangeMinusNYears(reportTimeRange, 2)
                                                })}
                                            >
                                                <FormattedMessage id="TIME_RANGE.SAME_RANGE.MINUS_2_YEARS" />
                                            </Dropdown.Item>
                                        )}
                                    </>
                                )}

                                {_.get(options, "timeRanges.baseTimeRange.customizable") && (
                                    <Dropdown.Item
                                        onClick={() => {
                                            setTimeRangeSelectionPopupState({
                                                isOpen: true,
                                                timeRangeKey: "baseTimeRange",
                                                timeRange: baseTimeRange,
                                            });
                                        }}
                                    >
                                        <Box display={"flex"} flexDirection={"row"}>
                                            <Box mr={1}>
                                                <DateRangeIcon color="primary"/>
                                            </Box>
                                            <FormattedMessage id="CHOOSE_TIME_RANGE" />
                                        </Box>
                                    </Dropdown.Item>
                                )}

                                {_.get(options, "timeRanges.baseTimeRange.clearable") && (
                                    <Dropdown.Item
                                        onClick={() => onChange({
                                            baseTimeRange: null
                                        })}
                                    >
                                        <Box display={"flex"} flexDirection={"row"}>
                                            <Box mr={1}>
                                                <EventBusyIcon color="primary"/>
                                            </Box>
                                            <FormattedMessage id="CLEAR_TIME_RANGE" />
                                        </Box>
                                    </Dropdown.Item>
                                )}

                                {lockedMonths.length > 0 && (
                                    <>
                                        <Dropdown.Divider />

                                        <Dropdown.Item
                                            className={classes.timeRangesUnlockDropdownItem}
                                            onClick={() => {
                                                if (onMonthUnlockRequest) {
                                                    onMonthUnlockRequest();
                                                }
                                            }}
                                        >
                                            <Box display={"flex"} flexDirection={"row"}>
                                                <Box mr={1}>
                                                    <FontAwesomeIcon icon={faUnlock} />
                                                </Box>
                                                <FormattedMessage id="TIME_RANGE.UNLOCK.LOCKED.DATES" />
                                            </Box>
                                        </Dropdown.Item>
                                    </>
                                )}

                                {(
                                    _.get(options, "timeRanges.baseTimeRange.presetListVisible") ||
                                    _.get(options, "timeRanges.baseTimeRange.customizable") ||
                                    _.get(options, "timeRanges.baseTimeRange.clearable")
                                ) && (
                                    <Dropdown.Divider />
                                )}

                                {_.get(options, "timeRanges.baseTimeRange.monthListVisible") && (
                                    <>
                                        {(months || []).map((month) => {
                                            const isItemDisabled = _.includes(lockedMonths, month);
                                            const monthTimeRangeIncludeness = (baseTimeRange ? getMonthTimeRangeIncludeness(month, baseTimeRange) : null);

                                            return (
                                                <Dropdown.Item
                                                    key={`${month}-baseTimeRangeMonth`}
                                                    active={monthTimeRangeIncludeness && monthTimeRangeIncludeness !== MonthTimeRangeIncludeness.None}
                                                    className={clsx(
                                                        {
                                                            [classes.fullyActiveMonthMenuItem]: monthTimeRangeIncludeness === MonthTimeRangeIncludeness.Full,
                                                            [classes.partiallyActiveMonthMenuItem]: monthTimeRangeIncludeness === MonthTimeRangeIncludeness.Partial
                                                        },
                                                    )}
                                                    disabled={isItemDisabled}
                                                    onClick={() => onChange({
                                                        baseTimeRange: createMonthTimeRange(month)
                                                    })}
                                                >
                                                    <Box display="flex" flexDirection="row" flexGrow="1" alignItems="center">
                                                        {formatMonthYearString(month, language)}

                                                        {isItemDisabled && (
                                                            <Box display="flex" flexDirection="row" flexGrow="1" justifyContent="flex-end" ml={1}>
                                                                <FontAwesomeIcon icon={faLock} size="sm" />
                                                            </Box>
                                                        )}
                                                    </Box>

                                                </Dropdown.Item>
                                            );
                                        })}
                                    </>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Box>
                )}

                {_.get(options, "switches.visible") && (
                    <Box ml={2} display="flex" alignItems="center" justifyContent="center">
                        {_.get(options, "switches.rawDataSwitch.visible") && (
                            <Box>
                                <Tooltip arrow title={!filters.isRawDataMode ? <FormattedMessage id="RAW.DATA.SWITCH.ON.TITLE" /> : <FormattedMessage id="RAW.DATA.SWITCH.OFF.TITLE" />}>
                                    <button
                                        className="btn btn-primary btn-elevate"
                                        onClick={() => {
                                            onChange({
                                                isRawDataMode: !filters.isRawDataMode
                                            });
                                        }}
                                    >
                                        <FontAwesomeIcon icon={!filters.isRawDataMode ? faCube : faCubes} />
                                    </button>
                                </Tooltip>
                            </Box>
                        )}

                        {_.get(options, "switches.indexDataSwitch.visible") && (
                            <Box ml={1}>
                                <Tooltip arrow title={!filters.isIndexDataMode ? <FormattedMessage id="INDEX.DATA.SWITCH.ON.TITLE" /> : <FormattedMessage id="INDEX.DATA.SWITCH.OFF.TITLE" />}>
                                    <button
                                        className="btn btn-primary btn-elevate"
                                        onClick={() => {
                                            onChange({
                                                isIndexDataMode: !filters.isIndexDataMode
                                            });
                                        }}
                                    >
                                        <FontAwesomeIcon icon={!filters.isIndexDataMode ? faGroupArrowsRotate : faArrowsToDot} />
                                    </button>
                                </Tooltip>
                            </Box>
                        )}
                    </Box>
                )}
            </Box>

            {process.env.NODE_ENV === "development" && (
                <Box ml={2}>
                    <RefreshProgressableButton
                        disabled={isChanging}
                        isProgressing={isChanging}
                        onClick={() => onChange({
                            forceUpdate: true
                        })}
                    >
                    </RefreshProgressableButton>
                </Box>
            )}
        </Box>
    );
};

const mapStateToProps = (state) => ({
    user: auth.selectors.getUser(state),
});

export default injectIntl(connect(mapStateToProps, null)(MetricNakedFilters));
