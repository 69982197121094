import React, {forwardRef} from "react";
import { Bar } from "react-chartjs-2";
import {convertValueToLocaleString} from "../../../utils/utils";

const HorizontalBar = forwardRef((props, ref) => {
    const options = {
        ...props.options,
        indexAxis: 'y',
    };

    return (
        <Bar
            {...props}
            ref={ref}
            options={options}
        />
    );
});

export const enableDataLabels = (chartOptions, intl) => {
    chartOptions.plugins.datalabels = {
        display: true,
        color: "black",
        anchor: "end",
        align: "end",
        formatter: function(value, context) {
            const tooltipOptions = context.chart.config.options.plugins.tooltip.options || {};
            const language = context.chart.config.options.plugins.tooltip.language;
            return convertValueToLocaleString(Number(value), intl, language, tooltipOptions);
        }
    };
};

export default HorizontalBar;
