import React  from "react";
import { Link } from "react-router-dom";
import { Box, Paper, makeStyles, Typography, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import Filters from "./filters";
import BrandCard from "../../common/components/brand-card";
import Pagination from "../../common/components/pagination";
import { LayoutSubheader } from "../../../_metronic";
import * as purchases from "../../store/ducks/purchases.duck";
import { selectors } from "../../../_metronic/ducks/i18n";
import {
    parseParams,
    generateCatalogUrl,
    formatMonthYearString,
    replaceParams
} from "../../utils/utils";

const useStyles = makeStyles((theme) => ({
    cardList: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
        gridGap: "1rem",
        marginBottom: "20px",
        "@media (max-width: 370px)": {
            gridTemplateColumns: "repeat(auto-fill, minmax(285px, 1fr))",
        },
    },
    titleMonth: {
        fontSize: "1.2rem",
        fontWeight: "500",
        color: "#434349",
        textTransform: "capitalize",
    },
    wrapPagination: {
        "@media (max-width: 370px)": {
            paddingLeft: 3,
            paddingRight: 3,
        },
    },
}));

const MyCompanies = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const language = useSelector(selectors.getSelectedLanguage);

    const params = parseParams();
    const { isLoading } = useSelector(purchases.selectors.getPurchaseInfosLoadState);
    const items = useSelector(purchases.selectors.getPurchaseInfos);
    const totalPageCount = useSelector(purchases.selectors.getPurchaseTotalPageCount);

    const currentPageMonths = [...new Set(items.map(({ month }) => month))];

    const handleNextPage = (page) => {
        if (!page) {
            return;
        }

        const newParams = {
            ...params,
            page
        };

        dispatch(purchases.actions.requestPurchaseInfos(newParams));
        replaceParams(newParams);
    };

    return (
        <Box>
            <LayoutSubheader
                title={<FormattedMessage id="MY_COMPANIES.CAPTION" />}
                component={
                    <Link to={generateCatalogUrl()}>
                        <Button variant="contained" color="primary" startIcon={<AddIcon />}>
                            <FormattedMessage id="ADD_COMPANY.BUTTON" />
                        </Button>
                    </Link>
                }
            />
            <Filters
                onChange={(filtersPortion) => {
                    const newParams = {
                        ...params,
                        ...filtersPortion,
                    };
                    dispatch(purchases.actions.requestPurchaseInfos(newParams));
                    replaceParams(newParams);
                }}
                isLoading={isLoading}
            />
            <Box mt={3} mb={2}>
                {currentPageMonths.map((month, i) => {
                    const _items = items.filter((item) => item.month === month);
                    if (!_items.length) {
                        return null;
                    }

                    return (
                        <React.Fragment key={i}>
                            <Box pt={2} pb={2}>
                                <Typography className={classes.titleMonth}>
                                    {formatMonthYearString(month, language)}
                                </Typography>
                            </Box>
                            <Box className={classes.cardList}>
                                {_items.map((card) => (
                                    <Box key={card.brand.id}>
                                        <BrandCard
                                            brandInfo={card.brand}
                                            month={month}
                                            enableSalesAnalysisLink={true}
                                            useBrandLockStatus={false}
                                            showAddToCartButton={false}
                                            showAddToComparisonButton={true}
                                        />
                                    </Box>
                                ))}
                            </Box>
                        </React.Fragment>
                    );
                })}
            </Box>
            <Box>
                {!isNaN(totalPageCount) && totalPageCount > 0 && (
                    <Paper>
                        <Box className={classes.wrapPagination} p={2} pb={"3px"}>
                            <Pagination
                                totalPages={totalPageCount}
                                currentPage={Number(params.page) || 1}
                                onChange={handleNextPage}
                            />
                        </Box>
                    </Paper>
                )}
            </Box>
        </Box>
    );
};

export default MyCompanies;
