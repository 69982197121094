import React, {Suspense, useEffect} from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import {LayoutSplashScreen} from "../../../_metronic";
import Catalog from "../catalog";
import MyCompanies from "../my-companies";
import SalesAnalysis from "../sales-analysis";
import Comparison from "../comparison";
import Industries from "../industries";
import IndustryDetails from "../industry-details";
import Profile from "../profile";
import Cart from "../cart";
import DatabaseStatistics from "../database-statistics";
import DataPartnerStatistics from "../data-partner-statistics";

import SendMessagePopup from "../../common/components/send-message-popup";
import TrialRequestPopup from "../../common/components/trial-request-popup";
import NarratorPopup from "../../common/components/narrator-popup";

import {UserRoles, IS_CART_ENABLED} from "../../crud/constants";
import {connect, useDispatch} from "react-redux";

import * as auth from "../../store/ducks/auth.duck";
import _ from "lodash";

const HomePage = ({ user }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(auth.actions.requestUser());
        // eslint-disable-next-line
    }, []);

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {user && user.preferences.startPage && _.includes(["catalog", "my-companies", "comparison", "industries", "profile"], user.preferences.startPage) && (
                    <Redirect exact from="/" to={`/${user.preferences.startPage}`}/>
                )}

                <Route path="/catalog" exact component={Catalog}/>
                <Route path="/my-companies" exact component={MyCompanies}/>
                <Route path="/sales-analysis/:brandId/:requestedTabName?" component={SalesAnalysis}/>
                <Route path="/comparison/:requestedTabName?" exact component={Comparison}/>
                <Route path="/industries" exact component={Industries}/>
                <Route path="/industry" exact component={IndustryDetails}/>
                {IS_CART_ENABLED && (
                    <Route path="/cart" exact component={Cart}/>
                )}
                <Route path="/profile" exact component={Profile}/>
                {user && user.role === UserRoles.Admin && (
                    <Route path="/database-statistics" exact component={DatabaseStatistics}/>
                )}
                {user && (user.role === UserRoles.Admin || user.role === UserRoles.DataPartner) && (
                    <Route path="/data-partner-statistics" exact component={DataPartnerStatistics}/>
                )}

                <Redirect to="/error/error-v1"/>
            </Switch>
            <SendMessagePopup/>
            <TrialRequestPopup/>
            <NarratorPopup/>
        </Suspense>
    );
};

const mapStateToProps = (state) => ({
    user: auth.selectors.getUser(state),
});

export default connect(mapStateToProps, null)(HomePage);
