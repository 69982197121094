import {createAction, handleActions} from "redux-actions";
import {delay, put, takeLatest} from "redux-saga/effects";
import { createActionName, wrapSafely } from "./utils";
import _ from "lodash";
import * as auth from "./auth.duck";

const TAG = "ALERT";

export const actions = {
    showAlert: createAction(createActionName(TAG, "showAlert")),
    hideAlert: createAction(createActionName(TAG, "hideAlert")),
};

const defaultState = {
    severity: "",
    message: "",
};

const initialState = _.clone(defaultState);

export const reducer = handleActions(
    {
        [actions.showAlert]: (state, { payload: { severity, message, timeout } }) => {
            return {
                ...state,
                severity,
                message,
                timeout,
            };
        },

        [actions.hideAlert]: (state) => {
            return {
                ...state,
                severity: "",
                message: "",
            };
        },

        [auth.actionTypes.LogoutUser]: () => {
            console.log(`LogoutUser in ${TAG}`);
            return _.clone(defaultState);
        }
    },
    initialState
);

export function* saga() {
    yield takeLatest(actions.showAlert, wrapSafely(function*({ payload: { timeout } }) {
        yield delay(timeout || 5000);
        yield put(actions.hideAlert());
    }, TAG, "showAlert"));
}

export const getAlert = (state) => state.alert;

export const selectors = {
    getAlert
};
