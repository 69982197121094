import React from "react";
import {Box, Paper, Tabs, Tab} from "@material-ui/core";
import HorizontalBar, {enableDataLabels} from "../horizontal-bar";
import _ from "lodash";
import {useSelector} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";

import {prepareChartData, createChartOptions, useChartProperties} from "../chart";
import ChartCard from "../chart-card";
import TabPanel, {generateTabProps} from "../tab-panel";
import MessageTitle from "../../../common/components/message-title";

import { selectors } from "../../../../_metronic/ducks/i18n";

const CustomerExpensesStructure = ({ brandId, metricId, alertText, toggleHelpDrawer, intl }) => {
    const language = useSelector(selectors.getSelectedLanguage);

    const {
        isChartLoaded,
        scope,
        data,
        dataFeatures,
        title,
        subTitle,
        description,
        releaseNotes,
        chartHeight,
    } = useChartProperties(brandId, null, null, null, metricId, 350);

    const groupMetricDatas = [];
    if (data && data.groupMetricDatas) {
        for (const groupMetricData of data.groupMetricDatas) {
            const options = createChartOptions("bar", _.get(data, "metric.config.groupOptions", {}), intl, language);
            enableDataLabels(options, intl);

            groupMetricDatas.push({
                label: groupMetricData.label,
                options: options,
                preparedData: prepareChartData(groupMetricData, null, scope, options)
            });
        }
    }

    const [activeTabIndex, setActiveTabIndex] = React.useState(0);

    const handleTabChange = (event, tabIndex) => {
        setActiveTabIndex(tabIndex);
    };

    return (
        <ChartCard
            title={title}
            subTitle={subTitle}
            description={description}
            releaseNotes={releaseNotes}
            dataFeatures={dataFeatures}
            toggleHelpDrawer={toggleHelpDrawer}
            isLoading={!isChartLoaded}
        >
            <Box style={{display:"flex", flexDirection: "column", height: chartHeight}}>
                {(groupMetricDatas.length) ? (
                    <>
                        <Paper variant="outlined">
                            <Tabs
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="on"
                                value={activeTabIndex}
                                onChange={handleTabChange}
                            >
                                {groupMetricDatas.map((groupMetricData, index) => (
                                    <Tab key={index} label={groupMetricData.label} {...generateTabProps(index)} />
                                ))}
                            </Tabs>
                        </Paper>

                        <Box mt={1} style={{flexGrow: 1, overflow: "hidden"}}>
                            {groupMetricDatas.map((groupMetricData, index) => (
                                <TabPanel key={index} index={index} isHidden={activeTabIndex !== index}>
                                    {(groupMetricData.preparedData && groupMetricData.preparedData.datasets && groupMetricData.preparedData.datasets.length) ? (
                                        <Box style={{position: "relative"}} ref={(ref) => {
                                            if (ref) {
                                                ref.style.height = groupMetricData.preparedData.datasets[0].data.length * groupMetricData.preparedData.options.barHeight + "px";
                                            }
                                        }}>
                                            <HorizontalBar
                                                data={groupMetricData.preparedData}
                                                options={groupMetricData.options}
                                            />
                                        </Box>
                                    ) : (
                                        <MessageTitle>
                                            {!isChartLoaded ? (
                                                <FormattedMessage id="DATA_IS_BEING_LOADED"/>
                                            ) : (alertText ? alertText : <FormattedMessage id="NOT_ENOUGH_DATA_PROMPT"/>)}
                                        </MessageTitle>
                                    )}
                                </TabPanel>
                            ))}
                        </Box>
                    </>
                ) : (
                    <MessageTitle>
                        {!isChartLoaded ? (
                            <FormattedMessage id="DATA_IS_BEING_LOADED"/>
                        ) : (alertText ? alertText : <FormattedMessage id="NOT_ENOUGH_DATA_PROMPT"/>)}
                    </MessageTitle>
                )}
            </Box>
        </ChartCard>
    );
};

export default injectIntl(CustomerExpensesStructure);
