import {put, takeLatest, select} from "redux-saga/effects";
import {createAction, handleActions} from "redux-actions";
import { createSelector } from "reselect";
import * as narratorCrud from "../../crud/narrator.crud";
import { createActionName, wrapSafely } from "./utils";
import _ from "lodash";
import * as auth from "./auth.duck";
import * as brands from "./brands.duck";
import * as popups from "./popups.duck";

const TAG = "NARRATOR";

export const actions = {
    requestBrandMetricOpinionData: createAction(createActionName(TAG, "requestBrandMetricOpinionData")),
    fillBrandMetricOpinionData: createAction(createActionName(TAG, "fillBrandMetricOpinionData")),
};

const defaultState = {
    isLoading: false,
    isLoaded: false,
};

const initialState = _.clone(defaultState);

export const reducer = handleActions(
    {
        [actions.requestBrandMetricOpinionData]: (state) => {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
            };
        },

        [actions.fillBrandMetricOpinionData]: (state, { payload: { data } }) => {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                data: data,
            };
        },

        [auth.actionTypes.LogoutUser]: () => {
            console.log(`LogoutUser in ${TAG}`);
            return _.clone(defaultState);
        }
    },
    initialState
);

export function* saga() {
    yield takeLatest(actions.requestBrandMetricOpinionData, wrapSafely(function*({ payload: { brandId, metricId } }) {
        yield put(popups.actions.showNarratorPopup());

        const filters = yield select(brands.selectors.getBrandActiveFilters(brandId));
        const metricFilters = yield select(brands.selectors.getBrandMetricActiveFilters(brandId, metricId));

        const data = yield narratorCrud.fetchBrandMetricOpinionData(
            brandId,
            metricId,
            filters.period,
            [
                filters.reportTimeRange,
                filters.baseTimeRange
            ],
            filters.locationId,
            filters.posId,
            metricFilters,
            filters.isRawDataMode,
            filters.isIndexDataMode
        );

        yield put(actions.fillBrandMetricOpinionData({ data }));
    }, TAG, "requestBrandMetricOpinionData"));
}

const getNarrator = (state) => state.narrator;

export const selectors = {
    getOpinionData: createSelector(getNarrator, (narrator) => (narrator.data || {})),
    getOpinionDataLoadState: createSelector(getNarrator, (narrator) => {
        return {
            isLoading: narrator.isLoading,
            isLoaded: narrator.isLoaded
        };
    }),
}