import React, {useRef} from "react";
import _ from "lodash";
import {connect, useDispatch, useSelector} from "react-redux";
import {Box} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";

import * as auth from "../../../store/ducks/auth.duck";
import ChartCard from "../chart-card";
import MessageTitle from "../../../common/components/message-title";

import { selectors } from "../../../../_metronic/ducks/i18n";
import {
    calculateStatsCardProperties,
    createChartOnExportButtonClickHandler,
    createChartOptions, hideTrendLineAnnotations,
    prepareChartData, showTrendLineAnnotations,
    useChartProperties
} from "../chart";
import {useTheme} from "@material-ui/styles";
import {color} from "chart.js/helpers";
import {getSalesChannelIcons} from "../../../common/components/location-select";

const BrandIndustryShare = ({ brandId, countryId, industryId, industryDefinition, metricId, alertText, toggleHelpDrawer, user, intl }) => {
    const dispatch = useDispatch();
    const theme = useTheme();

    const language = useSelector(selectors.getSelectedLanguage);

    const {
        filters,
        metricFilters,
        isChartLoaded,
        scope,
        data,
        unit,
        dataFeatures,
        title,
        subTitle,
        description,
        releaseNotes,
        chartHeight,
        chartType,
        ChartComponent,
        setMetricActiveFiltersAction
    } = useChartProperties(brandId, countryId, industryId, industryDefinition, metricId, 300);

    const options = createChartOptions(chartType, _.get(data, "metric.config.options", {}), intl, language);

    const preparedData = prepareChartData(data, unit, scope, options);

    const shouldUseSelectedSalesChannel = (metricFilters || {}).shouldUseSelectedSalesChannel;
    const salesChannelIcons = getSalesChannelIcons("2xs", shouldUseSelectedSalesChannel ? theme.palette.primary.main : color(theme.palette.primary.main).lighten(0.4).rgbString());

    const chartRef = useRef();

    const { statsCardViewMode } = calculateStatsCardProperties(preparedData, scope);

    const onExportButtonClickHandler = createChartOnExportButtonClickHandler(data, scope, brandId, metricId, user, dispatch);

    return (
        <ChartCard
            title={title}
            subTitle={subTitle}
            description={description}
            releaseNotes={releaseNotes}
            scope={scope}
            statsCardViewMode={statsCardViewMode}
            sums={preparedData ? preparedData.sums : []}
            averages={preparedData ? preparedData.averages : []}
            buttons={preparedData ? [
                {
                    icon: salesChannelIcons[filters.salesChannel],
                    tooltip: (!shouldUseSelectedSalesChannel ? intl.formatMessage({ id: "CONSIDER.SELECTED.SALES.CHANNEL.TOOLTIP" }) : intl.formatMessage({ id: "NOT.CONSIDER.SELECTED.SALES.CHANNEL.TOOLTIP" })),
                    onClick: () => {
                        dispatch(setMetricActiveFiltersAction({
                            shouldUseSelectedSalesChannel: !shouldUseSelectedSalesChannel
                        }));
                    }
                }
            ] : []}
            conclusions={preparedData ? preparedData.conclusions : []}
            onConclusionsTooltipOpen={(conclusions) => {
                showTrendLineAnnotations(chartRef.current, conclusions);
            }}
            onConclusionsTooltipClose={() => {
                hideTrendLineAnnotations(chartRef.current);
            }}
            dataFeatures={dataFeatures}
            onExportButtonClickHandler={onExportButtonClickHandler}
            toggleHelpDrawer={toggleHelpDrawer}
            isLoading={!isChartLoaded}
        >
            <Box style={{display:"flex", flexDirection: "column", height: chartHeight}}>
                {(preparedData && preparedData.datasets && preparedData.datasets.length) ? (
                    <ChartComponent
                        ref={chartRef}
                        data={preparedData}
                        options={options}
                    />
                ) : (
                    <MessageTitle>
                        {!isChartLoaded ? (
                            <FormattedMessage id="DATA_IS_BEING_LOADED"/>
                        ) : (alertText ? alertText : <FormattedMessage id="NOT_ENOUGH_DATA_PROMPT"/>)}
                    </MessageTitle>
                )}
            </Box>
        </ChartCard>
    );
};

const mapStateToProps = (state) => ({
    user: auth.selectors.getUser(state),
});

export default injectIntl(connect(mapStateToProps, null)(BrandIndustryShare));
