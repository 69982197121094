import React, {useRef} from "react";
import {Box, Paper, Tabs, Tab} from "@material-ui/core";
import HorizontalBar, {enableDataLabels} from "../horizontal-bar";
import _ from "lodash";
import {useSelector} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';

import {
    prepareChartData,
    createChartOptions,
    useChartProperties,
    showTrendLineAnnotations,
    hideTrendLineAnnotations
} from "../chart";
import ChartCard, {ConclusionsTooltipButton} from "../chart-card";
import TabPanel, {generateTabProps} from "../tab-panel";
import MessageTitle from "../../../common/components/message-title";

import { selectors } from "../../../../_metronic/ducks/i18n";

const CustomerExpensesDistribution = ({ brandId, metricId, alertText, toggleHelpDrawer, intl }) => {
    const language = useSelector(selectors.getSelectedLanguage);

    const {
        isChartLoaded,
        scope,
        data,
        dataFeatures,
        title,
        subTitle,
        description,
        releaseNotes,
        chartHeight,
        chartType,
        ChartComponent,
    } = useChartProperties(brandId, null, null, null, metricId, 350);

    const options = createChartOptions("bar", _.get(data, "metric.config.options", {}), intl, language);
    enableDataLabels(options, intl);

    const preparedData = prepareChartData(data, null, scope, options);

    const groupMetricDatas = [];
    if (data && data.groupMetricDatas) {
        for (const groupMetricData of data.groupMetricDatas) {
            const options = createChartOptions(chartType, _.get(data, "metric.config.groupOptions", {}), intl, language);

            groupMetricDatas.push({
                options: options,
                preparedData: prepareChartData(groupMetricData, null, scope, options)
            });
        }
    }

    const chartRefs = useRef([].slice(0, 1 + groupMetricDatas.length));

    const [activeTabIndex, setActiveTabIndex] = React.useState(0);

    let conclusions = (preparedData ? preparedData.conclusions : []);
    if (activeTabIndex >= 1 && groupMetricDatas.length > 0) {
        conclusions = (groupMetricDatas[activeTabIndex - 1].preparedData.conclusions || []);
    }

    const handleTabChange = (event, tabIndex) => {
        setActiveTabIndex(tabIndex);
    };

    return (
        <ChartCard
            title={title}
            subTitle={subTitle}
            description={description}
            releaseNotes={releaseNotes}
            dataFeatures={dataFeatures}
            toggleHelpDrawer={toggleHelpDrawer}
            isLoading={!isChartLoaded}
        >
            <Box style={{display:"flex", flexDirection: "column", height: chartHeight}}>
                {(preparedData && preparedData.datasets && preparedData.datasets.length) ? (
                    <>
                        <Paper variant="outlined">
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <Box ml={2}>
                                    <ConclusionsTooltipButton
                                        conclusions={conclusions}
                                        onTooltipOpen={(conclusions) => {
                                            showTrendLineAnnotations(chartRefs.current[activeTabIndex], conclusions);
                                        }}
                                        onTooltipClose={() => {
                                            hideTrendLineAnnotations(chartRefs.current[activeTabIndex]);
                                        }}
                                    />
                                </Box>

                                <Tabs
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="on"
                                    value={activeTabIndex}
                                    onChange={handleTabChange}
                                >
                                    <Tab key={0} icon={<HorizontalSplitIcon />} {...generateTabProps(0)} />

                                    {preparedData.labels.map((label, index) => (
                                        <Tab key={index + 1} label={label} {...generateTabProps(index + 1)} />
                                    ))}
                                </Tabs>
                            </Box>
                        </Paper>

                        <Box mt={1} style={{flexGrow: 1, overflow: "hidden"}}>
                            <TabPanel index={0} isHidden={activeTabIndex !== 0}>
                                <HorizontalBar
                                    ref={el => chartRefs.current[0] = el}
                                    data={preparedData}
                                    options={options}
                                />
                            </TabPanel>

                            {preparedData.labels.map((label, index) => {
                                const groupMetricData = groupMetricDatas[index];
                                
                                return (
                                    <TabPanel key={index + 1} index={index + 1} isHidden={activeTabIndex !== (index + 1)}>
                                        {(groupMetricData && groupMetricData.preparedData && groupMetricData.preparedData.datasets && groupMetricData.preparedData.datasets.length) ? (
                                            <ChartComponent
                                                ref={el => chartRefs.current[index + 1] = el}
                                                data={groupMetricData.preparedData}
                                                options={groupMetricData.options}
                                            />
                                        ) : (
                                            <MessageTitle>
                                                {!isChartLoaded ? (
                                                    <FormattedMessage id="DATA_IS_BEING_LOADED"/>
                                                ) : (alertText ? alertText : <FormattedMessage id="NOT_ENOUGH_DATA_PROMPT"/>)}
                                            </MessageTitle>
                                        )}
                                    </TabPanel>
                                );
                            })}
                        </Box>
                    </>
                ) : (
                    <MessageTitle>
                        {!isChartLoaded ? (
                            <FormattedMessage id="DATA_IS_BEING_LOADED"/>
                        ) : (alertText ? alertText : <FormattedMessage id="NOT_ENOUGH_DATA_PROMPT"/>)}
                    </MessageTitle>
                )}
            </Box>
        </ChartCard>
    );
};

export default injectIntl(CustomerExpensesDistribution);
