import axios from "axios";

import { API_URL } from "./constants"

export const MESSAGE_URL = `${API_URL}/contact/message`;
export const REQUEST_URL = `${API_URL}/contact/request`;

export const sendMessage = (context, phone, message) => {
  return axios.post(MESSAGE_URL, {
    context,
    phone,
    message
  });
}

export const sendTrialRequest = (data) => {
  return axios.post(REQUEST_URL, {
    type: "trial",
    data
  });
}