import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";

const MenuItemText = (props) => {
    const {item, parentItem, state, intl} = props;

    const badgeText = ((item.badge && item.badge.getText) ? item.badge.getText(state, intl) : null);
    const badgeIcon = ((item.badge && item.badge.getIcon) ? item.badge.getIcon(state) : null);
    const badgeStyle = Object.assign({
        backgroundColor: "#ffffff",
        color: "#000000",

        fontSize: "11px",
        width: "22px",
        height: "22px",
        borderRadius: "50%",

        textAlign: "center",
    }, (item.badge || {}).style);

    return (
        <>
            {item.icon && (
                <i className={`kt-menu__link-icon ${item.icon}`}/>
            )}

            {parentItem && parentItem.bullet === "dot" && (
                <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
                    <span/>
                </i>
            )}

            {parentItem && parentItem.bullet === "line" && (
                <i className="kt-menu__link-bullet kt-menu__link-bullet--line">
                    <span/>
                </i>
            )}

            <span className="kt-menu__link-text">
                {!item.translate ? (
                    item.title
                ) : (
                    <FormattedMessage id={item.translate} defaultMessage={item.title}/>
                )}
            </span>

            {badgeText && (
                <div style={badgeStyle}>
                    <span style={{ verticalAlign: "sub" }}>{badgeText}</span>
                </div>
            )}

            {badgeIcon && (
                <div style={badgeStyle}>
                    <span style={{ verticalAlign: "sub" }}>{badgeIcon}</span>
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    state: state,
});

export default injectIntl(connect(mapStateToProps, null)(MenuItemText));
