/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { LastLocationProvider } from "react-router-last-location";

import { Routes } from "./app/router/Routes";
import { I18nProvider, LayoutSplashScreen, ThemeProvider } from "./_metronic";
import Alert from "./app/common/components/alert";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { Chart, registerables } from 'chart.js';
import { color } from 'chart.js/helpers';
import annotationPlugin from 'chartjs-plugin-annotation';
import datalabelsPlugin from 'chartjs-plugin-datalabels';
Chart.register(...registerables);
Chart.register(annotationPlugin);
Chart.register(datalabelsPlugin);

// Disable "datalabels" by default.
Chart.defaults.plugins.datalabels.display = false;

const eventHookPlugin = {
    id: "eventHook",
    beforeUpdate: (chart, args, options) => {
        if (options.beforeUpdate) {
            options.beforeUpdate(chart, args, options);
        }
    },
};
Chart.register(eventHookPlugin);

Chart.defaults.plugins.legend.labels = {
    ...Chart.defaults.plugins.legend.labels,
    usePointStyle: true,
    pointStyleWidth: 30,
    pointStyleHeight: 15,
    borderWidth: 3,
};

const chartLegendDrawerPlugin = {
    id: "chartLegendDrawer",
    beforeDraw: (chart, args, options) => {
        chart.legend.legendItems.forEach(function(legendItem) {
            const dataset = chart.data.datasets[legendItem.datasetIndex];
            const datasetBackgroundColor = (typeof(dataset.backgroundColor) === "string" ? dataset.backgroundColor : dataset.backgroundColor(chart.ctx));

            legendItem.pointStyle = document.createElement("canvas");
            legendItem.pointStyle.width = Chart.defaults.plugins.legend.labels.pointStyleWidth;
            legendItem.pointStyle.height = Chart.defaults.plugins.legend.labels.pointStyleHeight;
            legendItem.pointStyle.borderWidth = Chart.defaults.plugins.legend.labels.borderWidth;

            const context = legendItem.pointStyle.getContext("2d");
            context.fillStyle = color(datasetBackgroundColor).alpha(0.6).rgbString();
            context.fillRect(0, 0, legendItem.pointStyle.width, legendItem.pointStyle.height);
            context.strokeStyle = datasetBackgroundColor;
            context.lineWidth = legendItem.pointStyle.borderWidth;
            context.strokeRect(0, 0, legendItem.pointStyle.width, legendItem.pointStyle.height);

            if (options.beforeLegendItemDraw) {
                options.beforeLegendItemDraw(chart, legendItem);
            }
        });
    }
};
Chart.register(chartLegendDrawerPlugin);

export default function App({ store, persistor, basename }) {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {/* Provide Redux store */}
            <Provider store={store} loading={<LayoutSplashScreen />}>
                {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
                <PersistGate persistor={persistor}>
                    {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
                    <React.Suspense fallback={<LayoutSplashScreen />}>
                        {/* Override `basename` (e.g: `homepage` in `package.json`) */}
                        <BrowserRouter basename={basename}>
                            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
                            <LastLocationProvider>
                                {/* Provide Metronic theme overrides. */}
                                <ThemeProvider>
                                    <Alert />
                                    {/* Provide `react-intl` context synchronized with Redux state.  */}
                                    <I18nProvider>
                                        {/* Render routes with provided `Layout`. */}
                                        <Routes />
                                    </I18nProvider>
                                </ThemeProvider>
                            </LastLocationProvider>
                        </BrowserRouter>
                    </React.Suspense>
                </PersistGate>
            </Provider>
        </MuiPickersUtilsProvider>
    );
}
