import React from "react";
import {Grid, Box} from "@material-ui/core";
import ChartCard from "../charts/chart-card";
import Chart from "../charts/chart";
import GroupedChart from "../charts/grouped-chart";
import CustomerTypeRatio from "../charts/customer-type-ratio";
import CustomerRetention from "../charts/customer-retention";
import CustomerExpensesDistribution from "../charts/customer-expenses-distribution";
import CustomerExpensesStructure from "../charts/customer-expenses-structure";
import BrandIntersection from "../charts/brand-intersection";
import BrandShareDistribution from "../charts/brand-share-distribution";
import SocDemDistribution from "../charts/socdem-distribution";
import BostonMatrix from "../charts/boston-matrix";
import BrandIndustryShare from "../charts/brand-industry-share";
import ClosestCompetitorsTable from "../charts/closest-competitors-table";
import MessageTitle from "../../common/components/message-title";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import * as brands from "../../store/ducks/brands.duck";
import * as comparison from "../../store/ducks/comparison.duck";
import _ from "lodash";

export const createMetricComponent = (metricId, brandId, toggleHelpDrawer) => {
    return {
        // Observed sales
        "1":
            (
                <React.Fragment key={metricId}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Chart
                            brandId={brandId}
                            metricId={metricId}
                            toggleHelpDrawer={toggleHelpDrawer}
                        />
                    </Grid>
                </React.Fragment>
            ),

        // Average transaction value
        "2":
            (
                <React.Fragment key={metricId}>
                    <Grid item xs={12}>
                        <Chart
                            brandId={brandId}
                            metricId={metricId}
                            toggleHelpDrawer={toggleHelpDrawer}
                        />
                    </Grid>
                </React.Fragment>
            ),

        // Observed transactions
        "3":
            (
                <React.Fragment key={metricId}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Chart
                            brandId={brandId}
                            metricId={metricId}
                            toggleHelpDrawer={toggleHelpDrawer}
                        />
                    </Grid>
                </React.Fragment>
            ),

        // DAU-WAU-MAU
        "4":
            (
                <React.Fragment key={metricId}>
                    <Grid item xs={12}>
                        <GroupedChart
                            brandId={brandId}
                            metricId={metricId}
                            toggleHelpDrawer={toggleHelpDrawer}
                        />
                    </Grid>
                </React.Fragment>
            ),

        // Average revenue per client
        "5":
            (
                <React.Fragment key={metricId}>
                    <Grid item xs={12}>
                        <Chart
                            brandId={brandId}
                            metricId={metricId}
                            toggleHelpDrawer={toggleHelpDrawer}
                        />
                    </Grid>
                </React.Fragment>
            ),

        // Average check distribution
        "6":
            (
                <React.Fragment key={metricId}>
                    <Grid item xs={12}>
                        <GroupedChart
                            brandId={brandId}
                            metricId={metricId}
                            toggleHelpDrawer={toggleHelpDrawer}
                        />
                    </Grid>
                </React.Fragment>
            ),

        // The ratio of regular and new customers
        "7":
            (brandId ? (
                <React.Fragment key={metricId}>
                    <Grid item xs={12}>
                        <CustomerTypeRatio
                            brandId={brandId}
                            metricId={metricId}
                            toggleHelpDrawer={toggleHelpDrawer}
                        />
                    </Grid>
                </React.Fragment>
            ) : null),

        // Expense structure
        "8":
            (brandId ? (
                <React.Fragment key={metricId}>
                    <Grid item xs={12} sm={12} md={6}>
                        <CustomerExpensesStructure
                            brandId={brandId}
                            metricId={metricId}
                            toggleHelpDrawer={toggleHelpDrawer}
                        />
                    </Grid>
                </React.Fragment>
            ) : null),

        // Customer expenses
        "9":
            (brandId ? (
                <React.Fragment key={metricId}>
                    <Grid item xs={12} sm={12} md={6}>
                        <CustomerExpensesDistribution
                            brandId={brandId}
                            metricId={metricId}
                            toggleHelpDrawer={toggleHelpDrawer}
                        />
                    </Grid>
                </React.Fragment>
            ) : null),

        // Retention of new customers
        "10":
            (brandId ? (
                <React.Fragment key={metricId}>
                    <Grid item xs={12}>
                        <CustomerRetention
                            brandId={brandId}
                            metricId={metricId}
                            toggleHelpDrawer={toggleHelpDrawer}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ChartCard
                            title={"LTV"}
                            isLoading={false}
                        >
                            <Box style={{display:"flex", flexDirection: "column", height: "300px"}}>
                                <MessageTitle>
                                    <FormattedMessage id="COMING_SOON.TITLE"/>
                                </MessageTitle>
                            </Box>
                        </ChartCard>
                    </Grid>
                </React.Fragment>
            ) : null),

        // TOP stores which clients visited BEFORE
        "11":
            (brandId ? (
                <React.Fragment key={metricId}>
                    <Grid item xs={12} sm={12} md={6}>
                        <BrandIntersection
                            brandId={brandId}
                            metricId={metricId}
                            supportedTimeIntervals={["hour_3", "hour_24", "hour_72"]}
                            toggleHelpDrawer={toggleHelpDrawer}
                        />
                    </Grid>
                </React.Fragment>
            ) : null),

        // TOP stores which clients visited AFTER
        "12":
            (brandId ? (
                <React.Fragment key={metricId}>
                    <Grid item xs={12} sm={12} md={6}>
                        <BrandIntersection
                            brandId={brandId}
                            metricId={metricId}
                            supportedTimeIntervals={["hour_3", "hour_24", "hour_72"]}
                            toggleHelpDrawer={toggleHelpDrawer}
                        />
                    </Grid>
                </React.Fragment>
            ) : null),

        // TOP stores intersection for the MONTH
        "13":
            (brandId ? (
                <React.Fragment key={metricId}>
                    <Grid item xs={12} sm={12} md={6}>
                        <BrandIntersection
                            brandId={brandId}
                            metricId={metricId}
                            supportedTimeIntervals={["month_1"]}
                            shouldSelectBrandIndustryFilter={true}
                            toggleHelpDrawer={toggleHelpDrawer}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <ChartCard
                            title={"Collaborations"}
                            isLoading={false}
                        >
                            <Box style={{display:"flex", flexDirection: "column", height: "450px"}}>
                                <MessageTitle>
                                    <FormattedMessage id="COMING_SOON.TITLE"/>
                                </MessageTitle>
                            </Box>
                        </ChartCard>
                    </Grid>
                </React.Fragment>
            ) : null),

        // Brand share in the industry
        "14":
            (brandId ? (
                <React.Fragment key={metricId}>
                    <Grid item xs={12}>
                        <BrandShareDistribution
                            brandId={brandId}
                            metricId={metricId}
                            toggleHelpDrawer={toggleHelpDrawer}
                        />
                    </Grid>
                </React.Fragment>
            ) : null),

        // Observed sales, age structure
        "15":
            (
                <React.Fragment key={metricId}>
                    <Grid item xs={12} sm={12} md={6}>
                        <SocDemDistribution
                            brandId={brandId}
                            metricId={metricId}
                            toggleHelpDrawer={toggleHelpDrawer}
                        />
                    </Grid>
                </React.Fragment>
            ),

        // Average transaction value, age structure
        "16":
            (
                <React.Fragment key={metricId}>
                    <Grid item xs={12} sm={12} md={6}>
                        <SocDemDistribution
                            brandId={brandId}
                            metricId={metricId}
                            toggleHelpDrawer={toggleHelpDrawer}
                        />
                    </Grid>
                </React.Fragment>
            ),

        // Observed transactions, age structure
        "17":
            (
                <React.Fragment key={metricId}>
                    <Grid item xs={12} sm={12} md={6}>
                        <SocDemDistribution
                            brandId={brandId}
                            metricId={metricId}
                            toggleHelpDrawer={toggleHelpDrawer}
                        />
                    </Grid>
                </React.Fragment>
            ),

        // Observed sales, boston matrix
        "18":
            (
                <React.Fragment key={metricId}>
                    <Grid item xs={12} sm={12} md={6}>
                        <BostonMatrix
                            brandId={brandId}
                            metricId={metricId}
                            toggleHelpDrawer={toggleHelpDrawer}
                        />
                    </Grid>
                </React.Fragment>
            ),

        // Observed transactions, boston matrix
        "19":
            (
                <React.Fragment key={metricId}>
                    <Grid item xs={12} sm={12} md={6}>
                        <BostonMatrix
                            brandId={brandId}
                            metricId={metricId}
                            toggleHelpDrawer={toggleHelpDrawer}
                        />
                    </Grid>
                </React.Fragment>
            ),

        // Observed sales, brand share
        "20":
            (brandId ? null : (
                <React.Fragment key={metricId}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Chart
                            metricId={metricId}
                            toggleHelpDrawer={toggleHelpDrawer}
                        />
                    </Grid>
                </React.Fragment>
            )),

        // Observed transactions, brand share
        "21":
            (brandId ? null : (
                <React.Fragment key={metricId}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Chart
                            metricId={metricId}
                            toggleHelpDrawer={toggleHelpDrawer}
                        />
                    </Grid>
                </React.Fragment>
            )),

        // Observed sales, YoY growth
        "22":
            (brandId ? null : (
                <React.Fragment key={metricId}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Chart
                            metricId={metricId}
                            toggleHelpDrawer={toggleHelpDrawer}
                        />
                    </Grid>
                </React.Fragment>
            )),

        // Observed transactions, YoY growth
        "23":
            (brandId ? null : (
                <React.Fragment key={metricId}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Chart
                            metricId={metricId}
                            toggleHelpDrawer={toggleHelpDrawer}
                        />
                    </Grid>
                </React.Fragment>
            )),

        // Average transaction value, YoY growth
        "24":
            (brandId ? null : (
                <React.Fragment key={metricId}>
                    <Grid item xs={12}>
                        <Chart
                            metricId={metricId}
                            toggleHelpDrawer={toggleHelpDrawer}
                        />
                    </Grid>
                </React.Fragment>
            )),

        // Observed sales, brand share in industry
        "27":
            (
                <React.Fragment key={metricId}>
                    <Grid item xs={12} sm={12} md={6}>
                        <BrandIndustryShare
                            brandId={brandId}
                            metricId={metricId}
                            toggleHelpDrawer={toggleHelpDrawer}
                        />
                    </Grid>
                </React.Fragment>
            ),

        // Observed transactions, brand share in industry
        "28":
            (
                <React.Fragment key={metricId}>
                    <Grid item xs={12} sm={12} md={6}>
                        <BrandIndustryShare
                            brandId={brandId}
                            metricId={metricId}
                            toggleHelpDrawer={toggleHelpDrawer}
                        />
                    </Grid>
                </React.Fragment>
            ),

        // Closest competitors
        "29":
            (brandId ? (
                <React.Fragment key={metricId}>
                    <Grid item xs={12}>
                        <ClosestCompetitorsTable
                            brandId={brandId}
                            metricId={metricId}
                            toggleHelpDrawer={toggleHelpDrawer}
                        />
                    </Grid>
                </React.Fragment>
            ) : null),
    }[metricId];
};

const BrandPackageTab = ({brandId, packageId, toggleHelpDrawer}) => {
    const supportedPackages = useSelector(brands.selectors.getBrandSupportedPackages(brandId));
    const supportedPackage = supportedPackages.find((supportedPackage) => supportedPackage.id === packageId);

    const metricIds = _.map(_.filter(supportedPackage.metrics, (metric) => _.includes(metric.scopes, "brand")), "id");
    const metricComponents = _.sortBy(metricIds, (metricId) => supportedPackage.metricLayout.brand[metricId]).map((metricId) => {
        return createMetricComponent(metricId, brandId, toggleHelpDrawer);
    });

    return (
        <Grid container spacing={2}>
            {metricComponents}
        </Grid>
    );
};

const ComparisonPackageTab = ({packageId, toggleHelpDrawer}) => {
    const comparisonBrandInfos = useSelector(comparison.selectors.getComparisonBrandInfos);
    const brandDatas = useSelector(brands.selectors.getBrandDatas(comparisonBrandInfos.map((comparisonBrandInfo) => comparisonBrandInfo.id)));
    const supportedPackages = comparison.functions.getComparisonBrandSupportedPackages(brandDatas);
    const supportedPackage = supportedPackages.find((supportedPackage) => supportedPackage.id === packageId);

    const metricIds = _.map(_.filter(supportedPackage.metrics, (metric) => _.includes(metric.scopes, "comparison")), "id");
    const metricComponents = _.sortBy(metricIds, (metricId) => supportedPackage.metricLayout.comparison[metricId]).map((metricId) => {
        return createMetricComponent(metricId, undefined, toggleHelpDrawer);
    });

    return (
        <Grid container spacing={2}>
            {metricComponents}
        </Grid>
    );
};

const PackageTab = ({brandId, packageId, toggleHelpDrawer}) => {
    return (
        (brandId ? (
            <BrandPackageTab
                brandId={brandId}
                packageId={packageId}
                toggleHelpDrawer={toggleHelpDrawer}
            />
        ) : (
            <ComparisonPackageTab
                packageId={packageId}
                toggleHelpDrawer={toggleHelpDrawer}
            />
        ))
    );
};

export default PackageTab;
