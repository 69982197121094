import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Checkbox,
  Typography,
  Box,
  FormControlLabel,
  makeStyles,
  Button,
} from "@material-ui/core";
import { selectors } from "../../../../_metronic/ducks/i18n";
import {connect, useDispatch, useSelector} from "react-redux";
import { FormattedMessage } from "react-intl";

import * as cart from "../../../store/ducks/cart.duck";
import { getPurchaseCurrentMonth } from "../../../crud/cart.crud";
import { getLogoImageUrl, formatMonthYearString } from "../../../utils/utils";
import Cover from "../cover";

import GoogleAnalytics from "../../../common/components/google-analytics";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "850px",
  },
  colorSecondary: {
    textTransform: "capitalize",
  },
}));

const getPriceCurrency = (packages) => {
  return (packages && packages.length ? packages[0].price_currency : null);
};

const getPackagePrice = (id, packages) => {
  let result = {
    value: 0,
    currency: getPriceCurrency(packages)
  }

  const filteredPackage = packages.find((packageItem) => packageItem.id === id) || {};
  result.value = Number(filteredPackage.price || 0);
  return result;
};

export const getItemPrice = (item, packages) => {
  let result = {
    value: 0,
    currency: getPriceCurrency(packages)
  }

  if (item.selectedPackageIds) {
    result.value = item.selectedPackageIds.reduce(
      (price, id) => price + getPackagePrice(id, packages).value,
      0
    );
  }

  return result;
};

export const getDiscountPercent = (items, packages) => {
  let result = 0;

  const selectedCompanyCount = items.reduce(
      (acc, item) => acc + ((item.selectedPackageIds && item.selectedPackageIds.length) ? 1 : 0),
      0
  );

  if (selectedCompanyCount >= 3 && selectedCompanyCount < 5) {
    result = 0.15;
  }
  else if (selectedCompanyCount >= 5) {
    result = 0.30;
  }

  return result;
};

export const getDiscountPrice = (items, packages, discountPercent) => {
  let result = {
    value: 0,
    currency: getPriceCurrency(packages)
  }

  result.value = getTotalPrice(items, packages).value * discountPercent;

  return result;
};

export const getTotalPrice = (items, packages, discountPercent) => {
  let result = {
    value: 0,
    currency: getPriceCurrency(packages)
  }

  result.value = items.reduce(
    (totalPrice, item) =>
      totalPrice + getItemPrice(item, packages).value,
    0
  );

  if (discountPercent) {
    result.value *= (1.0 - discountPercent);
  }

  return result;
};

export const formatPrice = (price, language) => {
  let result = "";

  if (price.currency) {
    const formatter = new Intl.NumberFormat(language, {
      style: 'currency',
      currency: price.currency,
    });

    result = formatter.format(price.value);
  }

  return result;
};

const ServicesList = ({
  items,
  packages,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [purchaseCurrentMonth, setPurchaseCurrentMonth] = useState();

  const language = useSelector(selectors.getSelectedLanguage);

  useEffect(() => {
    dispatch(cart.actions.requestSupportedPackages());
    getPurchaseCurrentMonth().then((res) =>
      setPurchaseCurrentMonth(res.data.month)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateItemInCart = (isChecked, brandId, packageId) => {
    dispatch(cart.actions.updateItemInCart({ isChecked, brandId, packageId }))
  };

  const hanldeChangeOnePackage = (isChecked, brandId, packageId) => {
    updateItemInCart(isChecked, brandId, packageId);
  };

  const handleChangeAllPackagesInColumn = (isChecked, packageId) => {
    items.map((item) => updateItemInCart(isChecked, item.id, packageId));
  };

  const handleChangeAllPackagesInRow = (isChecked, itemId, packages) => {
    packages.map((packageItem) =>
      updateItemInCart(isChecked, itemId, packageItem.id)
    );
  };

  const handleChangeAll = (isChecked, items, packages) => {
    items.map((item) =>
      handleChangeAllPackagesInRow(isChecked, item.id, packages)
    );
  };

  const monthName = formatMonthYearString(new Date(new Date().getFullYear(), purchaseCurrentMonth - 1), language);

  return (
    <Cover title={<FormattedMessage id="CART.SERVICES" />}>
      <div style={{ overflowX: "auto" }}>
        {items.length ? (
          <Table className={classes.root}>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={
                      items.filter(
                        (item) =>
                          (item.selectedPackageIds || []).length ===
                          packages.length
                      ).length === items.length
                    }
                    onChange={(e) =>
                      handleChangeAll(e.target.checked, items, packages)
                    }
                  />
                </TableCell>
                <TableCell padding="checkbox">
                  <Typography align="center">
                    <FormattedMessage id="CART.COMPANY" />
                  </Typography>
                </TableCell>
                {packages.map((packageItem) => (
                  <TableCell key={packageItem.id} padding="checkbox">
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={
                            items.filter((item) =>
                              (item.selectedPackageIds || []).includes(
                                packageItem.id
                              )
                            ).length === items.length
                          }
                          onChange={(e) =>
                            handleChangeAllPackagesInColumn(
                              e.target.checked,
                              packageItem.id
                            )
                          }
                        />
                      }
                      label={packageItem.title}
                    />
                    <Box margin="-12px 0 0 27px">
                      <Typography
                        className={classes.colorSecondary}
                        color="textSecondary"
                      >
                        {monthName}
                      </Typography>
                    </Box>
                  </TableCell>
                ))}
                <TableCell padding="checkbox">
                  <FormControlLabel
                    control={<Checkbox disabled />}
                    label={<FormattedMessage id="CART.GEOLOCATION" />}
                  />
                  <Box margin="-12px 0 0 27px">
                    <Typography
                        className={classes.colorSecondary}
                        color="textSecondary"
                    >
                      <FormattedMessage id="COMING_SOON.TEXT" />
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell padding="checkbox">
                  <Typography align="center">
                    <FormattedMessage id="CART.PRICE" />
                  </Typography>
                </TableCell>
                <TableCell padding="checkbox"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(items || []).map((item, index) => (
                <TableRow key={`${index}-${item.id}-row`}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={
                        (item.selectedPackageIds || []).length ===
                        packages.length
                      }
                      onChange={(e) =>
                        handleChangeAllPackagesInRow(
                          e.target.checked,
                          item.id,
                          packages
                        )
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Box>
                        <img
                          src={getLogoImageUrl(item.logo)}
                          alt="logo"
                          style={{ maxWidth: 30, marginRight: 10 }}
                        />
                      </Box>
                      <Box>
                        <Typography>
                          {_.get(item, ["name"], "")}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  {packages.map((packageItem) => (
                    <TableCell key={packageItem.id} padding="checkbox">
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={(item.selectedPackageIds || []).includes(
                              packageItem.id
                            )}
                            onChange={(e) =>
                              hanldeChangeOnePackage(
                                e.target.checked,
                                item.id,
                                packageItem.id
                              )
                            }
                          />
                        }
                        label={packageItem.title}
                      />
                    </TableCell>
                  ))}
                  <TableCell padding="checkbox">
                    <FormControlLabel
                      control={<Checkbox disabled />}
                      label={<FormattedMessage id="CART.GEOLOCATION" />}
                    />
                  </TableCell>
                  <TableCell padding="checkbox" align="center">
                    <Typography>
                      {formatPrice(getItemPrice(item, packages), language)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => {
                      GoogleAnalytics.reportEvent({
                        category: 'Cart',
                        action: 'RemoveCompanyFromCart',
                        label: `${item.id}/${item.name}`
                      });

                      dispatch(cart.actions.removeItemFromCart(item));
                    }}>X</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography color="textSecondary">
            <FormattedMessage id="NOTHING_WAS_FOUND" />
          </Typography>
        )}
      </div>
      {!!items.length && (
        <Box display="flex" justifyContent="flex-end" margin="20px 100px 0 0">
          <Typography variant="h6">
            <FormattedMessage id="CART.TOTAL" />{" "}
          </Typography>
          <Box marginLeft="30px">
            <Typography variant="h6">
              {formatPrice(getTotalPrice(items, packages), language)}
            </Typography>
          </Box>
        </Box>
      )}
    </Cover>
  );
};

const mapStateToProps = (state) => ({
  items: cart.selectors.getAddedItems(state),
  packages: cart.selectors.getSupportedPackages(state)
});

export default connect(mapStateToProps, null)(ServicesList);
