import React from "react";
import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";
import {connect} from "react-redux";

const MenuSubmenu = (props) => {
    const { item, currentUrl, layoutConfig, state } = props;

    return (
        <ul className="kt-menu__subnav">
            {item.submenu.getItems(state).map((child, index) => (
                <React.Fragment key={index}>
                    {child.section && (
                        <MenuSection
                            item={child}
                            parentItem={item}
                            currentUrl={currentUrl}
                        />
                    )}

                    {child.separator && (
                        <MenuItemSeparator
                            item={child}
                            parentItem={item}
                            currentUrl={currentUrl}
                        />
                    )}

                    {child.title && (
                        <MenuItem
                            item={child}
                            parentItem={item}
                            currentUrl={currentUrl}
                            layoutConfig={layoutConfig}
                        />
                    )}
                </React.Fragment>
            ))}
        </ul>
    );
};

const mapStateToProps = (state) => ({
    state: state,
});

export default connect(mapStateToProps, null)(MenuSubmenu);