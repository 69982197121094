import React  from "react";
import {connect, useDispatch} from "react-redux";
import { makeStyles } from "@material-ui/core";
import Tooltip from "../tooltip";
import CompareOutlinedIcon from '@material-ui/icons/CompareOutlined';
import classnames from "classnames";
import {injectIntl} from "react-intl";
import * as comparison from "../../../store/ducks/comparison.duck";
import GoogleAnalytics from "../google-analytics";
import * as alert from "../../../store/ducks/alert.duck";

const useStyles = makeStyles((theme) => ({
    button: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        height: "35px",
        width: "auto",

        border: "none",
        borderRadius: "3px",

        background: "#eeeeee",
        color: "#2c77f4",
        transition: "background 0.3s ease",

        "&:hover": {
            background: "#2c77f4",
            color: "#fff",
            transition: "background 0.3s ease",
        },

        "& > i": {
            fontSize: "23px",
        },
    },
    buttonActive: {
        background: "#2c77f4",
        color: "#fff",
    },
    buttonSimple: {
        height: "44px",
        width: "44px",
    },
    numberIcon: {
        width: "22px",
        height: "22px",
        borderRadius: "50%",

        fontSize: "11px",
        textAlign: "center",

        backgroundColor: "#bb3e53",
        color: "#fab9c5",
    }
}));

const AddToComparisonButton = ({ brandInfo, comparisonBrandInfos, ...props }) => {
    const { intl } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    const isBrandInComparison = !!comparisonBrandInfos.find((el) => el.id === brandInfo.id);

    let tooltipText = (!isBrandInComparison ? intl.formatMessage({id: "ADD_TO_COMPARISON_BUTTON.ADD_TO_COMPARISON"}) : intl.formatMessage({id: "ADD_TO_COMPARISON_BUTTON.REMOVE_FROM_COMPARISON"}));
    if (comparisonBrandInfos.length > 0) {
        tooltipText += "\n\n" + intl.formatMessage({id: "ADD_TO_COMPARISON_BUTTON.IN_COMPARISON_PROMPT"}) + "\n";
        for (let i = 0; i < comparisonBrandInfos.length; ++i) {
            const comparisonBrandInfo = comparisonBrandInfos[i];
            tooltipText += `${i + 1}. "${comparisonBrandInfo.name}"\n`;
        }
        tooltipText = tooltipText.trim();
    }

    const handleClick = () => {
        if (!isBrandInComparison) {
            if (comparisonBrandInfos.length < comparison.comparisonBrandMaxCount) {
                GoogleAnalytics.reportEvent({
                    category: 'AddToComparisonButton',
                    action: 'AddItemToComparison',
                    label: `${brandInfo.id}`
                });

                dispatch(comparison.actions.addBrandToComparison({
                    brandInfo
                }));
                dispatch(alert.actions.showAlert({
                    severity: "success",
                    message: intl.formatMessage({id: "ADD_TO_COMPARISON_BUTTON.ADDED_TO_COMPARISON"}),
                }));
            }
            else {
                dispatch(alert.actions.showAlert({
                    severity: "error",
                    message: intl.formatMessage({id: "TOO_MANY_COMPANIES_IN_COMPARISON_ERROR"}),
                }));
            }
        }
        else {
            GoogleAnalytics.reportEvent({
                category: 'AddToComparisonButton',
                action: 'RemoveItemFromComparison',
                label: `${brandInfo.id}`
            });

            dispatch(comparison.actions.removeBrandFromComparison({
                id: brandInfo.id
            }));
            dispatch(alert.actions.showAlert({
                severity: "info",
                message: intl.formatMessage({id: "ADD_TO_COMPARISON_BUTTON.REMOVED_FROM_COMPARISON"}),
            }));
        }
    };

    return (
        <>
            <Tooltip
                arrow
                title={<span style={{ whiteSpace: 'pre-line' }}>{tooltipText}</span>}
            >
                <button
                    className={classnames({
                        [classes.button]: true,
                        [classes.buttonActive]: isBrandInComparison,
                        [classes.buttonSimple]: true,
                    })}
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();

                        handleClick();
                    }}
                >
                    <CompareOutlinedIcon />
                </button>
            </Tooltip>
        </>
    );
};

const mapStateToProps = (state) => ({
    comparisonBrandInfos: comparison.selectors.getComparisonBrandInfos(state),
});

export default injectIntl(connect(mapStateToProps, null)(AddToComparisonButton));
