import React from "react";
import { makeStyles, Backdrop, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    overlay: {
        position: "absolute",
        height: "100%",
        zIndex: 1000, // Should be less than kt-dropdown-zindex
        backgroundColor: "rgba(0, 0, 0, 0.2) !important",
        borderRadius: theme.shape.borderRadius
    },
}));

const ProgressingOverlay = ({ isOpen }) => {
    const classes = useStyles();

    return (
        <Backdrop className={classes.overlay} open={isOpen}>
            <CircularProgress color="primary" />
        </Backdrop>
    );
};

export default ProgressingOverlay;
