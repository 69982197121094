import React, {useState} from "react";
import {
    Box,
    FormControlLabel,
    IconButton,
    InputAdornment,
    makeStyles,
    Popover,
    Radio,
    RadioGroup,
    TextField
} from "@material-ui/core";
import {Dropdown} from "react-bootstrap";
import Tooltip from "../tooltip";
import SearchIcon from "@material-ui/icons/Search";
import {FormattedMessage} from "react-intl";
import {faShop, faShopLock} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTheme} from "@material-ui/styles";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import {getSalesChannelIcons} from "../location-select";

const useStyles = makeStyles(() => ({
    popover: {
        maxHeight: "500px",
        overflow: "auto",
    }
}));

const PosSelect = ({ poses, posId, onChange, isEnabled, ...props }) => {
    const { intl } = props;
    const classes = useStyles();
    const theme = useTheme();

    const [searchQuery, setSearchQuery] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isOpen = !!anchorEl;
    const id = isOpen ? "simple-popover" : undefined;

    const salesChannelIcons = getSalesChannelIcons("sm", theme.palette.primary.dark);

    const filteredPoses = [
        { id: "0", name: intl.formatMessage({ id: "POS.ALL_POSES" }) },
        ...(!!searchQuery.length ? poses.filter(({ name }) => name.toLowerCase().includes(searchQuery.toLowerCase())) : poses),
    ];

    const getTooltipTitle = (posId) => {
        let result = null;

        if (isEnabled) {
            result = "";

            if (posId) {
                const pos = poses.find(({ id }) => {
                    return (id === posId);
                });
                result = `${intl.formatMessage({ id: "POS.TOOLTIP.PROMPT" })}${pos.name}`;
            }
            else {
                result = intl.formatMessage({ id: "POS.ALL_POSES" });
            }
        }
        else {
            result = intl.formatMessage({ id: "POS.SELECTION.DISABLED.TOOLTIP" });
        }

        return result;
    };

    return (
        <Box>
            <Tooltip arrow title={<span style={{ whiteSpace: "pre-line"}}>{getTooltipTitle(posId)}</span>}>
                <span>
                    <Dropdown.Toggle
                        aria-describedby={id}
                        onClick={handleClick}
                        disabled={!isEnabled}
                        style={!isEnabled ? { pointerEvents: 'none' } : {}}
                    >
                        <FontAwesomeIcon icon={posId ? faShopLock : faShop} size={"lg"} fixedWidth={true} />
                    </Dropdown.Toggle>
                </span>
            </Tooltip>
            <Popover
                id={id}
                open={isOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Box pt={1} pl={2} pr={2} className={classes.popover}>
                    <Box mb={2}>
                        <TextField
                            label={<FormattedMessage id="INPUT.POS" />}
                            value={searchQuery}
                            onChange={({ target: { value } }) => setSearchQuery(value)}
                            fullWidth={true}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <IconButton style={{padding: 0}} onClick={() => {
                                        setSearchQuery("");
                                    }}>
                                        <CancelRoundedIcon fontSize="small" />
                                    </IconButton>
                                )
                            }}
                        />
                    </Box>

                    <RadioGroup
                        value={posId || "0"}
                        onChange={({ target: { value } }) => {
                            onChange({
                                posId: (value === "0" ? undefined : value)
                            });
                            handleClose();
                        }}
                    >
                        {filteredPoses.map(({ id, salesChannel, name, }) => (
                            <FormControlLabel
                                key={`${id}-${name}`}
                                value={id}
                                control={<Radio color="primary" />}
                                label={(
                                    <Box display="flex" flexDirection="row" alignItems="center">
                                        {salesChannelIcons[salesChannel] && (
                                            <Box mr={0.5}>
                                                {salesChannelIcons[salesChannel]}
                                            </Box>
                                        )}

                                        {name}
                                    </Box>
                                )}
                            />
                        ))}
                    </RadioGroup>
                </Box>
            </Popover>
        </Box>
    );
};

export default PosSelect;
