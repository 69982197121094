import React from "react";
import {Checkbox, TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const DropdownAutocomplete = ({
    label,
    options,
    isMultiValue,
    isClearable,
    value,
    onChange,
}) => {
    return (
        <Autocomplete
            options={options}
            multiple={isMultiValue}
            value={value}
            limitTags={1}
            size="small"
            disableCloseOnSelect={isMultiValue}
            disableClearable={!isClearable}
            renderOption={(option, {selected}) => (
                <React.Fragment>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                        checkedIcon={<CheckBoxIcon fontSize="small"/>}
                        style={{marginRight: 8}}
                        checked={selected}
                        color="primary"
                    />
                    {option.name}
                </React.Fragment>
            )}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={label}
                />
            )}
            onChange={(event, value) => onChange(value)}
            style={{width: 220}}
        />
    );
};

export default DropdownAutocomplete;
