import React, { useState } from "react";
import { Formik } from "formik";
import {connect, useDispatch, useSelector} from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  Checkbox,
  FormControlLabel,
  TextField,
  IconButton,
  InputAdornment
} from "@material-ui/core";

import ProgressableButton from "../../common/components/progressable-button";
import GoogleAnalytics from "../../common/components/google-analytics";
import { getLandingUrl } from "../../crud/constants";

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import * as alert from "../../store/ducks/alert.duck";

import logoImage from "../../../assets/images/logo.png";
import * as auth from "../../store/ducks/auth.duck";
import { selectors } from "../../../_metronic/ducks/i18n";
import { registerUser } from "../../crud/auth.crud";

function Register(props) {
  const { intl } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const language = useSelector(selectors.getSelectedLanguage);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const handleClickShowPassword = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const handleClickShowConfirmPassword = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  return (
    <div className="kt-login__body">
      <div className="kt-login__form">
        <div className="kt-login__logo">
          <a
              href={getLandingUrl("HOME", language)}
              target="_blank"
              rel="noopener noreferrer"
          >
            <img alt="Logo" src={logoImage} />
          </a>
        </div>
        <div className="kt-login__title">
          <h3>
            <FormattedMessage id="AUTH.REGISTER.TITLE" />
          </h3>
        </div>

        <Formik
          initialValues={{
            first_name: "",
            email: "",
            company: "",
            password: "",
            confirmPassword: "",
            acceptTerms: true,
            acceptData: true,
          }}
          validate={(values) => {
            const errors = {};

            if (!values.email) {
              errors.email = intl.formatMessage({
                id: "VALIDATION.REQUIRED_FIELD",
              });
            }
            else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = intl.formatMessage({
                id: "VALIDATION.INVALID_FIELD",
              });
            }

            if (
              values.first_name
                ? !/^[-a-zA-Zа-яА-ЯёЁ\s]+$/i.test(values.first_name)
                : false
            ) {
              errors.first_name = intl.formatMessage({
                id: "VALIDATION.INVALID_FIELD",
              });
            }
            else if (!values.first_name) {
              errors.first_name = intl.formatMessage({
                id: "VALIDATION.REQUIRED_FIELD",
              });
            }

            if (!values.password) {
              errors.password = intl.formatMessage({
                id: "VALIDATION.REQUIRED_FIELD",
              });
            }

            if (!values.confirmPassword) {
              errors.confirmPassword = intl.formatMessage({
                id: "VALIDATION.REQUIRED_FIELD",
              });
            }
            else if (values.password !== values.confirmPassword) {
              errors.confirmPassword = intl.formatMessage({
                id: "VALIDATION.PASSWORDS_SHOULD_MATCH",
              });
            }

            if (!values.acceptTerms) {
              errors.acceptTerms = intl.formatMessage({
                id: "VALIDATION.ACCEPT.TERMS",
              });
            }

            if (!values.acceptData) {
              errors.acceptData = intl.formatMessage({
                id: "VALIDATION.ACCEPT.TERMS",
              });
            }

            return errors;
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            GoogleAnalytics.reportEvent({
              category: 'Auth',
              action: 'Register',
              label: values.email
            });

            registerUser(
              values.email,
              values.password,
              values.first_name,
              null,
              null,
              values.company,
              null
            )
              .then(({ data: { accessToken, message } }) => {
                setSubmitting(false);

                dispatch(alert.actions.showAlert({
                  severity: "success",
                  message: message
                }));

                props.registerUser(accessToken);
                history.push("/auth/login");
              })
              .catch(() => {
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              {status && (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{status}</div>
                </div>
              )}

              <div className="form-group mb-0">
                <TextField
                  margin="normal"
                  label={`${intl.formatMessage({id: "INPUT.FIRST.NAME"})} *`}
                  className="kt-width-full"
                  name="first_name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.first_name}
                  helperText={touched.first_name && errors.first_name}
                  error={Boolean(touched.first_name && errors.first_name)}
                />
              </div>

              <div className="form-group mb-0">
                <TextField
                  label={`${intl.formatMessage({id: "INPUT.EMAIL"})} *`}
                  margin="normal"
                  className="kt-width-full"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  helperText={touched.email && errors.email}
                  error={Boolean(touched.email && errors.email)}
                />
              </div>

              <div className="form-group mb-0">
                <TextField
                  margin="normal"
                  label={<FormattedMessage id="INPUT.BRAND" />}
                  className="kt-width-full"
                  name="company"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.company}
                />
              </div>

              <div className="form-group mb-0">
                <TextField
                  type={isPasswordVisible ? 'text' : 'password'}
                  margin="normal"
                  label={`${intl.formatMessage({id: "INPUT.PASSWORD"})} *`}
                  className="kt-width-full"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  helperText={touched.password && errors.password}
                  error={Boolean(touched.password && errors.password)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                        >
                          {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </div>

              <div className="form-group">
                <TextField
                  type={isConfirmPasswordVisible ? 'text' : 'password'}
                  margin="normal"
                  label={`${intl.formatMessage({id: "INPUT.CONFIRM.PASSWORD"})} *`}
                  className="kt-width-full"
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.confirmPassword}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                  InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                          >
                            {isConfirmPasswordVisible ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                    )
                  }}
                />
              </div>

              <div className="form-group mb-0">
                <FormControlLabel
                  label={
                    <>
                      <FormattedMessage id="AUTH.REGISTER.AGREE_WITH_PROMPT" />
                      <a
                          href={getLandingUrl("TERMS", language)}
                          target="_blank"
                          rel="noopener noreferrer"
                      >
                        <FormattedMessage id="TERMS.CONDITIONS" />
                      </a>
                    </>
                  }
                  control={
                    <Checkbox
                      color="primary"
                      name="acceptTerms"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      checked={values.acceptTerms}
                    />
                  }
                />
              </div>

              <div className="form-group mb-0">
                <FormControlLabel
                    label={
                      <>
                        <FormattedMessage id="AUTH.REGISTER.AGREE_WITH_PROMPT" />
                        <a
                            href={getLandingUrl("DATA.PROCESSING", language)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                          <FormattedMessage id="DATA.PROCESSING" />
                        </a>
                      </>
                    }
                    control={
                      <Checkbox
                          color="primary"
                          name="acceptData"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          checked={values.acceptData}
                      />
                    }
                />
              </div>

              <div className="kt-login__actions">
                <Link
                  to="/auth/password/recover"
                  className="kt-link kt-login__link-forgot"
                >
                  <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                </Link>

                <Link to="/auth">
                  <button
                    type="button"
                    className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                  >
                    <FormattedMessage id="AUTH.GENERAL.BACK_BUTTON" />
                  </button>
                </Link>

                <ProgressableButton
                  type="submit"
                  disabled={isSubmitting || !values.acceptTerms  || !values.acceptData}
                  isProgressing={isSubmitting}
                >
                  <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
                </ProgressableButton>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Register));
