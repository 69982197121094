import React, {createRef, useEffect, useState} from "react";
import {
    Box,
    Button,
    makeStyles,
    Paper,
    Typography
} from "@material-ui/core";
import {connect, useDispatch, useSelector} from "react-redux";
import { LayoutSubheader } from "../../../_metronic";
import Header from "./header";
import * as industries from "../../store/ducks/industries.duck";
import MetricHelpDrawer from "../../common/components/metric-help-drawer";
import {FormattedMessage, injectIntl} from "react-intl";
import {
    parseParams,
    replaceParams,
    parseTimeRange,
    booleanizeString,
    formatTimeRangeString,
} from "../../utils/utils";
import MessageTitle from "../../common/components/message-title";
import ProgressingOverlay from "../../common/components/progressing-overlay";
import _ from "lodash";
import IndustryMetricFilters from "../../common/components/industry-metric-filters";
import GroupedChart from "../../partials/charts/grouped-chart";
import * as auth from "../../store/ducks/auth.duck";
import BrandRatingTable, {RatingTableSortBlock} from "../../common/components/brand-rating-table";
import {assignTimeRanges, prepareParamsForDispatch, prepareParamsForReplacement} from "../industries";
import {selectors} from "../../../_metronic/ducks/i18n";

const useStyles = makeStyles(() => ({
    root: {
        position: "relative",
    },
    ratingTablePaper: {
        position: "relative",
    },
    dropdownMenu: {
        maxHeight: "500px",
        overflow: "auto",
    },
}));

const IndustryDetails = ({user, intl}) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const language = useSelector(selectors.getSelectedLanguage);

    const { countryId, industryId, industryDefinition } = parseParams();

    const industryInfo = useSelector(industries.selectors.getIndustryInfo(countryId, industryId, industryDefinition));
    const { isLoaded: isIndustryInfoLoaded } = useSelector(industries.selectors.getIndustryInfoLoadState(countryId, industryId, industryDefinition));

    const getFilters = () => {
        const params = {
            ...industryInfo.recommendedFilters,
            ...parseParams(),
        };

        const { countryId, industryId, industryDefinition, salesChannel, locationId, reportTimeRange, baseTimeRange, period, isRawDataMode } = params;

        const result = {
            countryId,
            industryId,
            industryDefinition,
            salesChannel,
            locationId,
            period,
            isRawDataMode: booleanizeString(isRawDataMode)
        };

        if (reportTimeRange && baseTimeRange) {
            assignTimeRanges(result, parseTimeRange(reportTimeRange), parseTimeRange(baseTimeRange));
        }

        return result;
    };
    const filters = getFilters();

    const industryBrandRatingInfo = useSelector(industries.selectors.getIndustryBrandRatingInfo(filters.countryId, filters.industryId, filters.industryDefinition, filters.timeRanges, filters.period));
    const { isLoaded: isIndustryBrandRatingInfoLoaded } = useSelector(industries.selectors.getIndustryBrandRatingInfoLoadState(filters.countryId, filters.industryId, filters.industryDefinition, filters.timeRanges, filters.period));

    const brandRatings = industryBrandRatingInfo.brandRatings || [];

    const industryBrandRatingTableCollapsedRowCount = 6;
    const industryBrandRatingTableExpandRowCount = (brandRatings.length - industryBrandRatingTableCollapsedRowCount);
    const [isIndustryBrandRatingTableExpanded, setIsIndustryBrandRatingTableExpanded] = useState(false);

    const getIndustryBrandRatingOrderInfo = () => {
        const { sortKey, sortProperty, sortOrder } = parseParams();
        return {
            sortKey,
            sortProperty,
            sortOrder
        };
    };
    const industryBrandRatingOrderInfo = getIndustryBrandRatingOrderInfo();

    const metricHelpDrawer = createRef();

    const requestFilteredIndustryMetricData = (filters) => {
        if (filters && filters.period) {
            dispatch(industries.actions.requestIndustryMetricData({
                ...filters,
                metricId: "26",
            }));
        }
    };

    const requestFilteredIndustryBrandRatingInfo = (filters) => {
        if (filters) {
            dispatch(industries.actions.requestIndustryBrandRatingInfo({
                ...filters,
            }));
        }
    };

    const onIndustryBrandRatingTableSortChange = (newOrderInfo) => {
        const newParams = {
            ...filters,
            ...industryBrandRatingOrderInfo,
            ...newOrderInfo,
        };

        requestFilteredIndustryBrandRatingInfo(prepareParamsForDispatch(newParams));
        replaceParams(prepareParamsForReplacement(newParams));
    };

    useEffect(() => {
        if (!isIndustryInfoLoaded) {
            dispatch(industries.actions.requestIndustryInfo({
                countryId: filters.countryId,
                industryId: filters.industryId,
                industryDefinition: filters.industryDefinition,
            }));
        }

        requestFilteredIndustryMetricData({
            ...filters,
        });

        if (brandRatings.length === 0) {
            requestFilteredIndustryBrandRatingInfo({
                ...filters,
                ...industryBrandRatingOrderInfo,
            });
        }
        else {
            if (!industryBrandRatingOrderInfo.sortKey) {
                onIndustryBrandRatingTableSortChange({
                    sortKey: _.head(_.head(brandRatings).properties).key,
                    sortProperty: "rawValue",
                    sortOrder: "desc",
                });
            }
        }
        // eslint-disable-next-line
    }, [isIndustryInfoLoaded, brandRatings.length]);

    return (
        <Box className={classes.root}>
            <LayoutSubheader
                component={
                    <Header
                        industryInfo={industryInfo}
                        isIndustryInfoLoaded={isIndustryInfoLoaded}
                    />
                }
            />

            <IndustryMetricFilters
                industryDefinition={industryDefinition}
                locations={_.get(industryInfo, "availableLocations")}
                periods={["month", "week"]}
                months={_.get(industryInfo, "availableMonths")}
                options={{
                    locationFilter: {
                        visible: true,
                    },
                    posFilter: {
                        visible: false,
                    },
                    periodFilter: {
                        visible: true,
                    },
                    timeRanges: {
                        reportTimeRange: {
                            visible: true,
                            presetListVisible: true,
                            customizable: true,
                            monthListVisible: true,
                        },
                        baseTimeRange: {
                            visible: true,
                            presetListVisible: true,
                            customizable: true,
                            monthListVisible: true,
                        },
                        swappable: true,
                    },
                    switches: {
                        visible: (user && user.capabilities["raw_data_mode"]),
                        rawDataSwitch: {
                            visible: (user && user.capabilities["raw_data_mode"]),
                        },
                        indexDataSwitch: {
                            visible: false
                        },
                    },
                }}
                filters={filters}
                onChange={(filtersPortion) => {
                    requestFilteredIndustryMetricData(prepareParamsForDispatch({
                        ...filters,
                        ...filtersPortion,
                    }));
                    requestFilteredIndustryBrandRatingInfo(prepareParamsForDispatch({
                        ...filters,
                        ...industryBrandRatingOrderInfo,
                        ...filtersPortion,
                    }));
                    replaceParams(prepareParamsForReplacement({
                        ...filters,
                        ...industryBrandRatingOrderInfo,
                        ...filtersPortion,
                    }));
                }}
                isChanging={!isIndustryBrandRatingInfoLoaded}
                isLoading={!_.get(industryInfo, "recommendedFilters")}
            />

            <Box mt={1} mb={2}>
                <GroupedChart
                    countryId={filters.countryId}
                    industryId={filters.industryId}
                    industryDefinition={filters.industryDefinition}
                    metricId={"26"}
                    toggleHelpDrawer={(state) => {
                        metricHelpDrawer.current.toggleHelpDrawer(state);
                    }}
                />
            </Box>

            <Paper className={classes.ratingTablePaper}>
                <Box p={3}>
                    <Typography variant="h6">
                        <FormattedMessage id="BRAND.RATING.TITLE" />
                    </Typography>

                    <Box mt={1}>
                        {industryBrandRatingInfo.reportTimeRange ? (
                            <Box>
                                <Box ml={1} mb={1}>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        {`${formatTimeRangeString(industryBrandRatingInfo.reportTimeRange, true, language)} vs ${formatTimeRangeString(industryBrandRatingInfo.baseTimeRange, true, language)}`}
                                    </Typography>
                                </Box>

                                {brandRatings.length > 0 ? (
                                    <Box>
                                        <Box mb={1}>
                                            <RatingTableSortBlock
                                                orderInfo={industryBrandRatingOrderInfo}
                                                shouldAllowByValueSort={true}
                                                shouldAllowByRawValueSort={true}
                                                onChange={onIndustryBrandRatingTableSortChange}
                                            />
                                        </Box>

                                        <BrandRatingTable
                                            brandRatings={_.take(brandRatings, isIndustryBrandRatingTableExpanded ? brandRatings.length : industryBrandRatingTableCollapsedRowCount)}
                                            shouldShowRankColumn={true}
                                            orderInfo={industryBrandRatingOrderInfo}
                                            onSortChange={onIndustryBrandRatingTableSortChange}
                                        />

                                        {industryBrandRatingTableExpandRowCount > 0 && (
                                            <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
                                                <Button variant="text" color="primary" onClick={() => {
                                                    setIsIndustryBrandRatingTableExpanded(!isIndustryBrandRatingTableExpanded);
                                                }}>
                                                    <Typography>{isIndustryBrandRatingTableExpanded ? <FormattedMessage id="TABLE.SHOW.LESS"/> : <FormattedMessage id="TABLE.SHOW.MORE.TEMPLATE" values={{ count: industryBrandRatingTableExpandRowCount }}/>}</Typography>
                                                </Button>
                                            </Box>
                                        )}
                                    </Box>
                                ) : (
                                    <MessageTitle>
                                        <FormattedMessage id="NOT_ENOUGH_DATA_PROMPT"/>
                                    </MessageTitle>
                                )}
                            </Box>
                        ) : (
                            <Box style={{height: "400px"}}>
                                <MessageTitle>
                                    {!isIndustryBrandRatingInfoLoaded ? <FormattedMessage id="DATA_IS_BEING_LOADED"/> : <FormattedMessage id="NOT_ENOUGH_DATA_PROMPT"/>}
                                </MessageTitle>
                            </Box>
                        )}
                    </Box>

                    <ProgressingOverlay isOpen={!isIndustryBrandRatingInfoLoaded} />
                </Box>
            </Paper>

            <MetricHelpDrawer ref={metricHelpDrawer} />
        </Box>
    );
};

const mapStateToProps = (state) => ({
    user: auth.selectors.getUser(state),
});

export default injectIntl(connect(mapStateToProps, null)(IndustryDetails));