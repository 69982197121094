import React, {useState} from "react";
import { Link } from "react-router-dom";
import MenuItemText from "./MenuItemText";
import MenuSubmenu from "./MenuSubmenu";
import clsx from "clsx";
import {Checkbox, Collapse} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {connect} from "react-redux";
import Tooltip from "../../../app/common/components/tooltip";
import {injectIntl} from "react-intl";

const MenuItem = (props) => {
    const { item, currentUrl, parentItem, layoutConfig, state, intl } = props;

    const isEnabled = (!item.isDisabled || !item.isDisabled(state));
    const isActive = (currentUrl.indexOf(item.page) !== -1);

    const tooltipText = ((item.tooltip && item.tooltip.getText) ? item.tooltip.getText(state, intl) : null);

    const hasSubMenu = (item.submenu && item.submenu.getItems(state).length > 0);
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <li
            className={clsx(
                "kt-menu__item",
                {
                    "kt-menu__item--active kt-menu__item--here": isActive,
                },
                item["custom-class"]
            )}
        >
            <Tooltip arrow title={tooltipText}>
                <Link to={(isEnabled && !isActive) ? item.page : window.location.href.replace(window.location.origin, "")} className="kt-menu__link">
                    <MenuItemText
                        item={item}
                        parentItem={parentItem}
                        currentUrl={currentUrl}
                        layoutConfig={layoutConfig}
                    />

                    {hasSubMenu && (
                        <Checkbox
                            icon={<ExpandLessIcon />}
                            checkedIcon={<ExpandMoreIcon />}
                            checked={!isExpanded}
                            onClick={(event) => {
                                // Use onClick here otherwise it will not work!
                                event.preventDefault();
                                setIsExpanded((prev) => !prev);
                            }}
                        />
                    )}
                </Link>
            </Tooltip>

            {hasSubMenu && (
                <Collapse classes={{wrapper: "kt-menu__submenu"}} in={isExpanded}>
                    <MenuSubmenu
                        item={item}
                        currentUrl={currentUrl}
                        layoutConfig={layoutConfig}
                    />
                </Collapse>
            )}
        </li>
    );
};

const mapStateToProps = (state) => ({
    state: state,
});

export default injectIntl(connect(mapStateToProps, null)(MenuItem));