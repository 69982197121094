import React from "react";
import { Formik } from "formik";
import {connect, useDispatch, useSelector} from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";

import * as alert from "../../store/ducks/alert.duck";

import logoImage from "../../../assets/images/logo.png";
import * as auth from "../../store/ducks/auth.duck";
import { selectors } from "../../../_metronic/ducks/i18n";
import { changePassword } from "../../crud/auth.crud";

import ProgressableButton from "../../common/components/progressable-button";
import GoogleAnalytics from "../../common/components/google-analytics";
import { getLandingUrl } from "../../crud/constants";

const ChangePassword = (props) => {
    const { intl } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const language = useSelector(selectors.getSelectedLanguage);

    return (
        <div className="kt-login__body">
            <div className="kt-login__form">
                <div className="kt-login__logo">
                    <a
                        href={getLandingUrl("HOME", language)}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img alt="Logo" src={logoImage} />
                    </a>
                </div>
                <div className="kt-login__title">
                    <h3>
                        <FormattedMessage id="AUTH.PASSWORD.CHANGE.TITLE" />
                    </h3>
                </div>

                <Formik
                    initialValues={{
                        token: props.match.params.token,
                        password: "",
                        confirmPassword: ""
                    }}
                    validate={(values) => {
                        const errors = {};

                        if (!values.password) {
                            errors.password = intl.formatMessage({
                                id: "VALIDATION.REQUIRED_FIELD",
                            });
                        }

                        if (!values.confirmPassword) {
                            errors.confirmPassword = intl.formatMessage({
                                id: "VALIDATION.REQUIRED_FIELD",
                            });
                        }
                        else if (values.password !== values.confirmPassword) {
                            errors.confirmPassword = intl.formatMessage({
                                id: "VALIDATION.PASSWORDS_SHOULD_MATCH",
                            });
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        GoogleAnalytics.reportEvent({
                            category: 'Auth',
                            action: 'ChangePassword',
                            label: values.email
                        });

                        changePassword(
                            values.token,
                            values.password
                        )
                            .then(({ data: { message } }) => {
                                setSubmitting(false);

                                dispatch(alert.actions.showAlert({
                                    severity: "success",
                                    message: message
                                }));

                                props.verifyUser();
                                history.push("/auth/login");
                            })
                            .catch(() => {
                                setSubmitting(false);
                            });
                    }}
                >
                    {({
                          values,
                          status,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                      }) => (
                        <form onSubmit={handleSubmit} noValidate autoComplete="off">
                            {status && (
                                <div role="alert" className="alert alert-danger">
                                    <div className="alert-text">{status}</div>
                                </div>
                            )}

                            <div className="form-group mb-0">
                                <TextField
                                    type="password"
                                    margin="normal"
                                    label={`${intl.formatMessage({id: "INPUT.PASSWORD"})} *`}
                                    className="kt-width-full"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password}
                                    helperText={touched.password && errors.password}
                                    error={Boolean(touched.password && errors.password)}
                                />
                            </div>

                            <div className="form-group">
                                <TextField
                                    type="password"
                                    margin="normal"
                                    label={`${intl.formatMessage({id: "INPUT.CONFIRM.PASSWORD"})} *`}
                                    className="kt-width-full"
                                    name="confirmPassword"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.confirmPassword}
                                    helperText={touched.confirmPassword && errors.confirmPassword}
                                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                />
                            </div>

                            <div className="kt-login__actions">
                                <Link to="/auth">
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                                    >
                                        <FormattedMessage id="AUTH.GENERAL.BACK_BUTTON" />
                                    </button>
                                </Link>

                                <ProgressableButton
                                    type="submit"
                                    disabled={isSubmitting}
                                    isProgressing={isSubmitting}
                                >
                                    <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
                                </ProgressableButton>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default injectIntl(connect(null, auth.actions)(ChangePassword));