import React from "react";
import {Box, makeStyles, Paper} from "@material-ui/core";
import MetricNakedFilters from "../metric-naked-filters";
import ProgressingOverlay from "../progressing-overlay";
import IndustryDefinitionDropdown from "../industry-definition-dropdown";

const useStyles = makeStyles((theme) => ({
    root: {
        "@supports (position: sticky)": {
            position: "sticky",
            top: "90px",
            zIndex: 1001, // Should be greater than ProgressingOverlay
        },
    },
    portlet: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        "@media (max-width: 1400px)": {
            flexDirection: "column",
        },
    },
    filtersWrapper: {
        padding: "10px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "right",
        flexGrow: 1,
        flexShrink: 0
    }
}));

const IndustryMetricFilters = ({ industryDefinition, locations, periods, months, options, filters, onChange, isChanging, isLoading }) => {
    const classes = useStyles();

    return (
        <Box className={classes.root} mb={2}>
            <Paper className={classes.portlet} elevation={10}>
                {(!isLoading && filters && filters.period) && (
                    <Box className={classes.filtersWrapper}>
                        <Box mr={2}>
                            <IndustryDefinitionDropdown
                                industryDefinition={industryDefinition}
                                onChange={(newIndustryDefinition) => {
                                    onChange({
                                        industryDefinition: newIndustryDefinition
                                    });
                                }}
                            />
                        </Box>

                        <MetricNakedFilters
                            locations={locations}
                            periods={periods}
                            months={months}
                            options={options}
                            filters={filters}
                            onChange={(filtersPortion) => {
                                onChange(filtersPortion);
                            }}
                            isChanging={isChanging}
                        />
                    </Box>
                )}

                <ProgressingOverlay isOpen={isLoading} />
            </Paper>
        </Box>
    );
};

export default IndustryMetricFilters;
