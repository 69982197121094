import moment from "moment";
import _ from "lodash";

export
// FRONT/BACK IMPLEMENTATIONS SHOULD BE THE SAME!
function createTimeRange(startDate, endDate) {
    const timeRange = {
        startDate,
        endDate
    };

    return timeRange;
}

export function parseTimeRange(timeRange) {
    let parsedTimeRange = null;

    if (timeRange) {
        if (typeof(timeRange) === "string") {
            // timeRange could be "undefined"
            try {
                parsedTimeRange = JSON.parse(timeRange);
                if (parsedTimeRange.startDate) {
                    parsedTimeRange.startDate = moment.utc(parsedTimeRange.startDate).toDate();
                }
                if (parsedTimeRange.endDate) {
                    parsedTimeRange.endDate = moment.utc(parsedTimeRange.endDate).toDate();
                }
            }
            catch (error) {

            }
        }
        else {
            parsedTimeRange = timeRange;
            if (parsedTimeRange.startDate) {
                parsedTimeRange.startDate = moment.utc(parsedTimeRange.startDate).toDate();
            }
            if (parsedTimeRange.endDate) {
                parsedTimeRange.endDate = moment.utc(parsedTimeRange.endDate).toDate();
            }
        }
    }

    return parsedTimeRange;
}

export
// FRONT/BACK IMPLEMENTATIONS SHOULD BE THE SAME!
function getTimeRangeLength(timeRange, unit) {
    return moment.utc(timeRange.endDate).diff(moment.utc(timeRange.startDate), unit, true);
}

export
// FRONT/BACK IMPLEMENTATIONS SHOULD BE THE SAME!
function shiftTimeRange(timeRange, offset, unit) {
    return createTimeRange(
        moment.utc(timeRange.startDate).add(offset, unit).toDate(),
        moment.utc(timeRange.endDate).add(offset, unit).toDate()
    );
}

export
// FRONT/BACK IMPLEMENTATIONS SHOULD BE THE SAME!
function createWeekTimeRange(date) {
    return createTimeRange(moment.utc(date).startOf("isoWeek").toDate(), moment.utc(date).endOf("isoWeek").toDate());
}

export
// FRONT/BACK IMPLEMENTATIONS SHOULD BE THE SAME!
function createMonthTimeRange(date) {
    return createTimeRange(moment.utc(date).startOf("month").toDate(), moment.utc(date).endOf("month").toDate());
}

export
// FRONT/BACK IMPLEMENTATIONS SHOULD BE THE SAME!
function createPeriodTimeRange(date, period) {
    let result = null;

    if (period === "week" || period === "isoWeek") {
        result = createWeekTimeRange(date);
    }
    else if (period === "month") {
        result = createMonthTimeRange(date);
    }
    else {
        console.assert(false, "Unsupported period!");
    }

    return result;
}

export
// FRONT/BACK IMPLEMENTATIONS SHOULD BE THE SAME!
function createSeveralMonthTimeRange(months, monthOffset, monthCount, defaultValue) {
    let timeRange = defaultValue;

    if (monthOffset <= months.length - 1) {
        timeRange = createMonthTimeRange(months[monthOffset]);

        if (monthCount > 1) {
            timeRange.endDate = moment.utc(timeRange.startDate).add(monthCount - 1, "month").endOf("month").toDate();

            if (!isTimeRangeWithinMonths(timeRange, months)) {
                timeRange = defaultValue;
            }
        }
    }

    return timeRange;
}

export
// FRONT/BACK IMPLEMENTATIONS SHOULD BE THE SAME!
function isTimeRangeWithinMonths(timeRange, months) {
    let result = true;

    const timeRangeMonths = generateMonthList(timeRange.startDate, timeRange.endDate);
    const allowedTimeRangeMonths = _.filter(timeRangeMonths, (timeRangeMonth) => {
        return _.includes(months, timeRangeMonth);
    });
    if (timeRangeMonths.length !== allowedTimeRangeMonths.length) {
        result = false;
    }

    return result;
}

export const MonthTimeRangeIncludeness = {
    None: "None",
    Full: "Full",
    Partial: "Partial"
};

export function getMonthTimeRangeIncludeness(month, timeRange) {
    let result = MonthTimeRangeIncludeness.None;

    const timeRangeMonths = generateMonthList(timeRange.startDate, timeRange.endDate);
    const monthIndex = _.findIndex(timeRangeMonths, (timeRangeMonth) => timeRangeMonth === month)
    if (monthIndex !== -1) {
        if (monthIndex !== 0 && monthIndex !== (timeRangeMonths.length - 1)) {
            result = MonthTimeRangeIncludeness.Full;
        }
        else {
            const monthTimeRange = createMonthTimeRange(month);

            if (monthIndex === 0) {
                if (timeRange.startDate.getTime() === monthTimeRange.startDate.getTime()) {
                    result = MonthTimeRangeIncludeness.Full;
                }
                else {
                    result = MonthTimeRangeIncludeness.Partial;
                }
            }
            else {
                if (timeRange.endDate.getTime() === monthTimeRange.endDate.getTime()) {
                    result = MonthTimeRangeIncludeness.Full;
                }
                else {
                    result = MonthTimeRangeIncludeness.Partial;
                }
            }
        }
    }

    return result;
}

export function isTimeRangeMonthAligned(timeRange) {
    return (timeRange.startDate.getTime() === createMonthTimeRange(timeRange.startDate).startDate.getTime() && timeRange.endDate.getTime() === createMonthTimeRange(timeRange.endDate).endDate.getTime());
}

export function isTimeRangeWeekAligned(timeRange) {
    return (timeRange.startDate.getTime() === createWeekTimeRange(timeRange.startDate).startDate.getTime() && timeRange.endDate.getTime() === createWeekTimeRange(timeRange.endDate).endDate.getTime());
}

// FRONT/BACK IMPLEMENTATIONS SHOULD BE THE SAME!
const weekStartDay = 1;

export
// FRONT/BACK IMPLEMENTATIONS SHOULD BE THE SAME!
function stringifyDate(date) {
    return moment.utc(date).format("YYYY-MM-DD");
}

// FRONT/BACK IMPLEMENTATIONS SHOULD BE THE SAME!
function generatePeriodDateList(startDate, endDate, period) {
    let dates = [];

    const toDate = moment.utc(endDate).endOf("day");

    let date = null;
    if (period === "week") {
        date = moment.utc(startDate).startOf("day");
        while (date.isoWeekday() !== weekStartDay) {
            date = date.add(1, "day");
        }
    }
    else {
        date = moment.utc(startDate).startOf(period).startOf("day");
    }

    while (!date.isAfter(toDate)) {
        dates.push(stringifyDate(date));
        date = date.add(1, period);
    }

    return dates;
}

export
// FRONT/BACK IMPLEMENTATIONS SHOULD BE THE SAME!
function generateDayList(startDate, endDate) {
    return generatePeriodDateList(startDate, endDate, "day");
}

export
// FRONT/BACK IMPLEMENTATIONS SHOULD BE THE SAME!
function generateWeekList(startDate, endDate) {
    return generatePeriodDateList(startDate, endDate, "week");
}

export
// FRONT/BACK IMPLEMENTATIONS SHOULD BE THE SAME!
function generateMonthList(startDate, endDate) {
    return generatePeriodDateList(startDate, endDate, "month");
}

export function alignTimeRangeToUnit(timeRange, unit) {
    return createTimeRange(moment.utc(timeRange.startDate).startOf(unit).toDate(), moment.utc(timeRange.endDate).endOf(unit).toDate());
}

export function alignTimeRangeToMonth(timeRange) {
    return alignTimeRangeToUnit(timeRange, "month");
}

export function alignTimeRangeToWeek(timeRange) {
    return alignTimeRangeToUnit(timeRange, "isoWeek");
}

export
// FRONT/BACK IMPLEMENTATIONS SHOULD BE THE SAME!
function addToDate(date, amount, unit) {
    return moment.utc(date).add(amount, unit).toDate();
}

export
// FRONT/BACK IMPLEMENTATIONS SHOULD BE THE SAME!
function subtractFromDate(date, amount, unit) {
    return moment.utc(date).subtract(amount, unit).toDate();
}

export function utcDateToLocalDate(date) {
    return moment.utc(date).add(moment(date).utcOffset(), "minute").toDate();
}

export function localDateToUtcDate(date) {
    return moment.utc(date).subtract(moment(date).utcOffset(), "minute").toDate();
}

export class TimeRangeCreator {
    constructor(period, postProcessTimeRange) {
        this.currentMoment = moment.utc();

        if (period === "week") {
            this.period = "isoWeek";
        }
        else if (period === "month") {
            this.period = "month";
        }
        else {
            console.assert(false, "Unsupported period!");
        }

        this.postProcessTimeRange = postProcessTimeRange;
    }

    postProcessTimeRangeIfNeeded(timeRange) {
        let result = timeRange;

        if (this.postProcessTimeRange) {
            result = this.postProcessTimeRange(timeRange);
        }

        return result;
    }

    createTimeRangeCurrentPeriod() {
        const timeRange = createPeriodTimeRange(this.currentMoment, this.period);
        return this.postProcessTimeRangeIfNeeded(timeRange);
    }

    createTimeRangeLastNPeriods(periodCount) {
        const timeRangeStartMoment = this.currentMoment.clone().startOf(this.period).subtract(periodCount, this.period);
        const timeRange = createTimeRange(timeRangeStartMoment.toDate(), timeRangeStartMoment.add(periodCount - 1, this.period).endOf(this.period).toDate());
        return this.postProcessTimeRangeIfNeeded(timeRange);
    }

    createTimeRangeLast1Period() {
        return this.createTimeRangeLastNPeriods(1);
    }

    createTimeRangeLast3Periods() {
        return this.createTimeRangeLastNPeriods(3);
    }

    createTimeRangeLast6Periods() {
        return this.createTimeRangeLastNPeriods(6);
    }

    createTimeRangeLast12Periods() {
        return this.createTimeRangeLastNPeriods(12);
    }

    createTimeRangeCurrentYear() {
        const timeRange = createTimeRange(this.currentMoment.clone().startOf("year").toDate(), this.currentMoment.clone().endOf("year").toDate());
        return this.postProcessTimeRangeIfNeeded(timeRange);
    }

    createTimeRangeLastYear() {
        const timeRange = createTimeRange(this.currentMoment.clone().startOf("year").subtract(1, "year").toDate(), this.currentMoment.clone().subtract(1, "year").endOf("year").toDate());
        return this.postProcessTimeRangeIfNeeded(timeRange);
    }

    createSimilarTimeRangeMinusNYears(timeRange, yearCount, shouldAlignResult) {
        let result = null;

        if (timeRange) {
            result = (this.period === "month" ? shiftTimeRange(timeRange, -1 * yearCount, "year") : shiftTimeRange(timeRange, -52 * yearCount, "week"));
            if (shouldAlignResult) {
                result = alignTimeRangeToUnit(result, this.period);
            }
            result = this.postProcessTimeRangeIfNeeded(result);
        }

        return result;
    }

    createSimilarTimeRangePlusNYears(timeRange, yearCount, shouldAlignResult) {
        let result = null;

        if (timeRange) {
            result = (this.period === "month" ? shiftTimeRange(timeRange, +1 * yearCount, "year") : shiftTimeRange(timeRange, +52 * yearCount, "week"));
            if (shouldAlignResult) {
                result = alignTimeRangeToUnit(result, this.period);
            }
            result = this.postProcessTimeRangeIfNeeded(result);
        }

        return result;
    }

    createPrecedingTimeRange(timeRange) {
        let result = null;

        if (timeRange) {
            if (this.period === "month" && isTimeRangeMonthAligned(timeRange)) {
                const timeRangeMonthLength = Math.ceil(getTimeRangeLength(timeRange, "month"));
                result = alignTimeRangeToMonth(shiftTimeRange(timeRange, -timeRangeMonthLength, "month"));
            }
            else if ((this.period === "week" || this.period === "isoWeek") && isTimeRangeWeekAligned(timeRange)) {
                const timeRangeWeekLength = Math.ceil(getTimeRangeLength(timeRange, "week"));
                result = alignTimeRangeToWeek(shiftTimeRange(timeRange, -timeRangeWeekLength, "week"));
            }
            else {
                const timeRangeDayLength = Math.ceil(getTimeRangeLength(timeRange, "day"));
                result = shiftTimeRange(timeRange, -timeRangeDayLength, "day");
            }

            result = this.postProcessTimeRangeIfNeeded(result);
        }

        return result;
    }
}