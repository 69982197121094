import React from "react";
import {Box} from "@material-ui/core";

export const generateTabProps = (index) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
};

const TabPanel = (props) => {
    const { index, isHidden, children } = props;

    return (
        <Box
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            role="tabpanel"
            hidden={isHidden}
            style={{height: "100%", overflowY: "auto"}}
        >
            {children}
        </Box>
    );
};

export default TabPanel;
