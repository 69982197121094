import React from "react";
import { connect, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { FormattedMessage, injectIntl } from "react-intl";

import { sendTrialRequest } from "../../../crud/contact.crud.js";
import * as auth from "../../../store/ducks/auth.duck";
import * as popups from "../../../store/ducks/popups.duck";
import { Formik } from "formik";

import * as alert from "../../../store/ducks/alert.duck";

import trialRequestImage from "../../../../assets/images/trial_request.png";

import ProgressableButton from "../progressable-button";
import GoogleAnalytics from "../google-analytics";

const accentColor = "#2c77f4";

const useStyles = makeStyles((theme) => ({
  header: {
    background: "#313e5b",
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "white",
    textTransform: "uppercase",

    "@media (max-width: 450px)": {
      textAlign: "center",
    },
  },
  closeButton: {
    color: "#989ead",
  },
  content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  prompt1: {
    fontSize: "17px",
    fontWeight: "500",
    color: "#4a566f",
  },
  list: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#4a566f",
    listStyle: "none",
  },
  listItem: {
    "&::before": {
      content: '"\\2022"',
      fontSize: "18px",
      fontWeight: "bold",
      color: `${accentColor}`,
      display: "inline-block",
      width: "0.7em",
    },
  },
  prompt2: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#313e5b",
  },
  prompt3: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#313e5b",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  buttonContainer: {
    margin: 0,
    paddingBottom: theme.spacing(2),
    justifyContent: "center",
  },
  button: {
    fontSize: "16px",
    fontWeight: "bold",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  infoItem: {
    "@media (max-width: 450px)": {
      width: "100%",
      "& + div": {
        display: "none",
      },
    },
  },
  wrapTextFields: {
    "@media (max-width: 450px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

const TrialRequestPopup = ({ user, popupState, hidePopup, ...props }) => {
  const { intl } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const prompt1 = intl.formatMessage(
    {
      id: "REQUEST.TRIAL.POPUP.PROMPT1",
    },
    {
      SPAN_BEGIN: `<span style='color:${accentColor}; font-weight:bold;'>`,
      SPAN_END: "</span>",
    }
  );

  const listItems = [
    intl.formatMessage({ id: "REQUEST.TRIAL.POPUP.LIST.ITEM1" }),
    intl.formatMessage({ id: "REQUEST.TRIAL.POPUP.LIST.ITEM2" }),
    intl.formatMessage({ id: "REQUEST.TRIAL.POPUP.LIST.ITEM3" }),
    intl.formatMessage({ id: "REQUEST.TRIAL.POPUP.LIST.ITEM4" }),
    intl.formatMessage({ id: "REQUEST.TRIAL.POPUP.LIST.ITEM5" }),
  ].map((item, i) => {
    return (
      <li key={i} className={classes.listItem}>
        {item}
      </li>
    );
  });

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      scroll={"body"}
      open={!!popupState.isOpen}
      onClose={() => hidePopup()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={classes.header}>
        <Box
          position="relative"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box className={classes.title}>
            <FormattedMessage id="REQUEST.TRIAL.POPUP.TITLE" />
          </Box>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => hidePopup()}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Formik
        initialValues={{
          first_name: user.first_name,
          last_name: user.last_name,
          phone: user.phone,
          industry: "",
          company: user.company,
          position: "",
          competitor1: "",
          competitor2: "",
        }}
        validate={(values) => {
          const errors = {};

          const fieldValidators = {
            first_name: "",
            last_name: "",
            phone: "",
            industry: "",
            company: "",
            position: "",
            competitor1: "",
            competitor2: "",
          };

          for (const fieldName of Object.keys(fieldValidators)) {
            if (!values[fieldName]) {
              errors[fieldName] = true;
            }
          }

          return errors;
        }}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          GoogleAnalytics.reportEvent({
            category: "TrialRequestPopup",
            action: "SendTrialRequest",
          });

          sendTrialRequest(values)
            .then(() => {
              setSubmitting(false);
              dispatch(alert.actions.showAlert({
                severity: "success",
                message: intl.formatMessage({id: "SEND.MESSAGE.POPUP.SUBMIT_RESULT"})
              }));
              hidePopup();
            })
            .catch(() => {
              setSubmitting(false);
            });
        }}
      >
        {({
          values,
          status,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            {status && (
              <div role="alert" className="alert alert-danger">
                <div className="alert-text">{status}</div>
              </div>
            )}
            <DialogContent className={classes.content}>
              <Box className={classes.prompt1}>
                <div dangerouslySetInnerHTML={{ __html: prompt1 }} />
              </Box>

              <Box mt={1} className={classes.list}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box className={classes.infoItem} width="60%">
                    {listItems}
                  </Box>
                  <Box width="40%">
                    <img
                      src={trialRequestImage}
                      alt={""}
                      style={{
                        height: "auto",
                        width: "100%",
                      }}
                    ></img>
                  </Box>
                </Box>
              </Box>

              <Box mt={2} className={classes.prompt2}>
                <FormattedMessage id="REQUEST.TRIAL.POPUP.PROMPT2" />
              </Box>

              <Box
                className={classes.wrapTextFields}
                display="flex"
                flexDirection="row"
              >
                <TextField
                  label={`${intl.formatMessage({ id: "INPUT.FIRST.NAME" })} *`}
                  margin="normal"
                  size="small"
                  className={classes.textField}
                  name="first_name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.first_name}
                  error={Boolean(touched.first_name && errors.first_name)}
                />

                <TextField
                  label={`${intl.formatMessage({ id: "INPUT.LAST.NAME" })} *`}
                  margin="normal"
                  size="small"
                  className={classes.textField}
                  name="last_name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.last_name}
                  error={Boolean(touched.last_name && errors.last_name)}
                />

                <TextField
                  label={`${intl.formatMessage({ id: "INPUT.PHONE" })} *`}
                  margin="normal"
                  size="small"
                  className={classes.textField}
                  name="phone"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone}
                  error={Boolean(touched.phone && errors.phone)}
                />
              </Box>

              <Box
                className={classes.wrapTextFields}
                display="flex"
                flexDirection="row"
              >
                <TextField
                  label={`${intl.formatMessage({ id: "INPUT.INDUSTRY" })} *`}
                  margin="normal"
                  size="small"
                  className={classes.textField}
                  name="industry"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.industry}
                  error={Boolean(touched.industry && errors.industry)}
                />

                <TextField
                  label={`${intl.formatMessage({ id: "INPUT.BRAND" })} *`}
                  margin="normal"
                  size="small"
                  className={classes.textField}
                  name="company"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.company}
                  error={Boolean(touched.company && errors.company)}
                />

                <TextField
                  label={`${intl.formatMessage({ id: "INPUT.POSITION" })} *`}
                  margin="normal"
                  size="small"
                  className={classes.textField}
                  name="position"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.position}
                  error={Boolean(touched.position && errors.position)}
                />
              </Box>

              <Box mt={2} className={classes.prompt3}>
                <FormattedMessage id="REQUEST.TRIAL.POPUP.PROMPT3" />
              </Box>

              <Box
                className={classes.wrapTextFields}
                display="flex"
                flexDirection="row"
              >
                <TextField
                  label={`${intl.formatMessage({ id: "INPUT.COMPETITOR" })} *`}
                  margin="normal"
                  size="small"
                  className={classes.textField}
                  name="competitor1"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.competitor1}
                  error={Boolean(touched.competitor1 && errors.competitor1)}
                />

                <TextField
                  label={`${intl.formatMessage({ id: "INPUT.COMPETITOR" })} *`}
                  margin="normal"
                  size="small"
                  className={classes.textField}
                  name="competitor2"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.competitor2}
                  error={Boolean(touched.competitor2 && errors.competitor2)}
                />
              </Box>
            </DialogContent>
            <DialogActions className={classes.buttonContainer}>
              <ProgressableButton
                type="submit"
                disabled={isSubmitting}
                isProgressing={isSubmitting}
              >
                <FormattedMessage id="REQUEST.TRIAL.POPUP.SUBMIT_BUTTON" />
              </ProgressableButton>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  user: auth.selectors.getUser(state),
  popupState: popups.selectors.getTrialRequestPopupState(state),
});

const mapDispatchToProps = (dispatch) => ({
  hidePopup: () => dispatch(popups.actions.hideTrialRequestPopup()),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(TrialRequestPopup)
);
