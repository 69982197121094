import {createAction, handleActions} from "redux-actions";
import { createSelector } from "reselect";
import { createActionName } from "./utils";
import _ from "lodash";
import * as auth from "./auth.duck";

const TAG = "POPUPS";

export const actions = {
    showSendMessagePopup: createAction(createActionName(TAG, "showSendMessagePopup"), (context, title, message) => ({
        context,
        title,
        message
    })),
    hideSendMessagePopup: createAction(createActionName(TAG, "hideSendMessagePopup"), () => ({})),

    showTrialRequestPopup: createAction(createActionName(TAG, "showTrialRequestPopup"), () => ({})),
    hideTrialRequestPopup: createAction(createActionName(TAG, "hideTrialRequestPopup"), () => ({})),

    showNarratorPopup: createAction(createActionName(TAG, "showNarratorPopup"), (context) => ({
        context
    })),
    hideNarratorPopup: createAction(createActionName(TAG, "hideNarratorPopup"), () => ({})),
};

const defaultState = {
    sendMessagePopupState: {},
    trialRequestPopupState: {},
    narratorPopupState: {},
};

const initialState = _.clone(defaultState);

export const reducer = handleActions(
    {
        [actions.showSendMessagePopup]: (state, { payload: { context, title, message } }) => {
            return {
                ...state,
                sendMessagePopupState: {
                    ...state.sendMessagePopupState,
                    isOpen: true,
                    context,
                    title,
                    message,
                }
            };
        },

        [actions.hideSendMessagePopup]: (state) => {
            return {
                ...state,
                sendMessagePopupState: {
                    ...state.sendMessagePopupState,
                    isOpen: false
                }
            };
        },

        [actions.showTrialRequestPopup]: (state) => {
            return {
                ...state,
                trialRequestPopupState: {
                    ...state.trialRequestPopupState,
                    isOpen: true
                }
            };
        },

        [actions.hideTrialRequestPopup]: (state) => {
            return {
                ...state,
                trialRequestPopupState: {
                    ...state.trialRequestPopupState,
                    isOpen: false
                }
            };
        },

        [actions.showNarratorPopup]: (state, { payload: { context } }) => {
            return {
                ...state,
                narratorPopupState: {
                    ...state.narratorPopupState,
                    isOpen: true,
                    context
                }
            };
        },

        [actions.hideNarratorPopup]: (state) => {
            return {
                ...state,
                narratorPopupState: {
                    ...state.narratorPopupState,
                    isOpen: false
                }
            };
        },

        [auth.actionTypes.LogoutUser]: () => {
            console.log(`LogoutUser in ${TAG}`);
            return _.clone(defaultState);
        }
    },
    initialState
);

export function* saga() {

}

const getPopups = (state) => state.popups;

export const selectors = {
    getSendMessagePopupState: createSelector(getPopups, (popups) => popups.sendMessagePopupState),

    getTrialRequestPopupState: createSelector(getPopups, (popups) => popups.trialRequestPopupState),

    getNarratorPopupState: createSelector(getPopups, (popups) => popups.narratorPopupState),
};
