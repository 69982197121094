import React from "react";
import packageJson from "../../../../package.json";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { selectors } from "../../../_metronic/ducks/i18n";
import { Box } from "@material-ui/core";
import Tooltip from "../../common/components/tooltip";
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import { getLandingUrl } from "../../crud/constants";

const Footer = () => {
  const language = useSelector(selectors.getSelectedLanguage);
  const today = new Date().getFullYear();

  return (
    <div
      className={`kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop`}
      id="kt_footer"
    >
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="center"
        width="100%"
      >
        <Box flexGrow={1} className="kt-footer__copyright">
          Copyright &copy; {today.toString()}&nbsp;
          <a
            href={getLandingUrl("HOME", language)}
            target="_blank"
            rel="noopener noreferrer"
            className="kt-link"
          >
            OpenBusiness
          </a>
          &nbsp; ({packageJson.version})
        </Box>
        <div className="kt-footer__menu">
          <Tooltip arrow title={<FormattedMessage id="DATA.CALCULATION.DISCLAIMER" />}>
            <NewReleasesIcon />
          </Tooltip>

          <a
            href={getLandingUrl("TERMS", language)}
            target="_blank"
            rel="noopener noreferrer"
            className="kt-footer__menu-link kt-link"
          >
            <FormattedMessage id="TERMS.CONDITIONS" />
          </a>
          <a
            href={getLandingUrl("ABOUT", language)}
            target="_blank"
            rel="noopener noreferrer"
            className="kt-footer__menu-link kt-link"
          >
            <FormattedMessage id="FOOTER.ABOUT" />
          </a>
          <a
            href={getLandingUrl("CONTACTS", language)}
            target="_blank"
            rel="noopener noreferrer"
            className="kt-footer__menu-link kt-link"
          >
            <FormattedMessage id="FOOTER.CONTACTS" />
          </a>
        </div>
      </Box>
    </div>
  );
};

export default Footer;
