import React from "react";
import { Paper, Box, Typography } from "@material-ui/core";

const Cover = ({ title, subTitle, children }) => {
  return (
    <Paper>
      <Box p={3} display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column">
          <Typography variant="h6">{title}</Typography>
          <Box height="26px">
            {subTitle && (
              <Typography variant="h6" color="textSecondary">
                {subTitle}
              </Typography>
            )}
          </Box>
        </Box>
        <Box>{children}</Box>
      </Box>
    </Paper>
  );
};

export default Cover;
