import {useDispatch} from "react-redux";
import React, {useState} from "react";
import * as comparison from "../../../store/ducks/comparison.duck";
import GoogleAnalytics from "../../../common/components/google-analytics";
import {Box, IconButton, makeStyles, Paper, SvgIcon} from "@material-ui/core";
import BrandTooltip from "../../../common/components/brand-tooltip";
import Tooltip from "../../../common/components/tooltip";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import classnames from "classnames";
import {FormattedMessage} from "react-intl";
import CloseIcon from "@material-ui/icons/Close";
import {Link} from "react-router-dom";
import {generateSalesAnalysisUrl, getLogoImageUrl} from "../../../utils/utils";
import StarIcon from "@material-ui/icons/Star";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const useStyles = makeStyles((theme) => ({
    comparisonBrandCard: {
        width: "90px",
        height: "90px",
        display: "flex",
        justifyContent: "center",
    },
    comparisonBrandCardPaper: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    comparisonBrandCardLogo: {
        width: "60px",
        height: "60px",
    },
    comparisonBrandCardToolbarButton: {
        width: "15px",
        height: "15px",
        padding: 0,

        "&:hover": {
            color: "#2E76F3"
        }
    },
    comparisonBrandCardToolbarButtonIcon: {
        width: "15px",
        height: "15px",
    },
    comparisonBrandCardWarningIcon: {
        width: "15px",
        height: "15px",

        animation: `$comparisonBrandCardWarningIconFadeAnimation 1000ms 3 ${theme.transitions.easing.easeInOut}`
    },
    "@keyframes comparisonBrandCardWarningIconFadeAnimation": {
        "0%": {
            opacity: 0.2,
        },
        "50%": {
            opacity: 1.0,
        },
        "100%": {
            opacity: 0.2,
        }
    },
    comparisonBrandCardWarningIconColor_red: {
        color: "#e00b2b",
    },
    comparisonBrandCardWarningIconColor_yellow: {
        color: "#ecb400",
    },
}));

const ComparisonBrandCardToolbarButton = ({ icon, tooltip, isDisabled, onClick }) => {
    const classes = useStyles();

    const ButtonWrapperComponent = ( { isTooltip, children } ) => {
       return (
           isTooltip ? (
               <Tooltip arrow title={tooltip}>
                   {children}
               </Tooltip>
           ) : (
               <>
                   {children}
               </>
           )
       )
    };

    return (
        <ButtonWrapperComponent isTooltip={!isDisabled}>
            <IconButton
                className={classes.comparisonBrandCardToolbarButton}
                disabled={isDisabled}
                onClick={() => {
                    onClick();
                }}
            >
                <SvgIcon component={icon} className={classes.comparisonBrandCardToolbarButtonIcon} />
            </IconButton>
        </ButtonWrapperComponent>
    );
};

const ComparisonBrandCard = ({ brandInfo, brandInfoIndex, brandInfoCount, warning }) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const isLeftMostCard = (brandInfoIndex === 0);
    const isRightMostCard = (brandInfoIndex === (brandInfoCount - 1));

    const [isFocused, setIsFocused] = useState(false);

    const forceUpdate = () => {
        dispatch(comparison.actions.setComparisonActiveFilters({
            filters: { "forceUpdate": true }
        }));
    };

    const handleRemoveButtonClick = () => {
        GoogleAnalytics.reportEvent({
            category: 'Comparison',
            action: 'RemoveItemFromComparison',
            label: `${brandInfo.id}`
        });

        dispatch(comparison.actions.removeBrandFromComparison({
            id: brandInfo.id
        }));
        forceUpdate();
    };

    const handleMakeFirstButtonClick = () => {
        dispatch(comparison.actions.swapBrandsInComparison({
            index: brandInfoIndex,
            withIndex: 0
        }));
        forceUpdate();
    };

    const handleSwapLeftButtonClick = () => {
        dispatch(comparison.actions.swapBrandsInComparison({
            index: brandInfoIndex,
            withIndex: brandInfoIndex - 1
        }));
        forceUpdate();
    };

    const handleSwapRightButtonClick = () => {
        dispatch(comparison.actions.swapBrandsInComparison({
            index: brandInfoIndex,
            withIndex: brandInfoIndex + 1
        }));
        forceUpdate();
    };

    return (
        <Box p={1}>
            <BrandTooltip
                brandInfo={brandInfo}
                enableSalesAnalysisLink={false}
                useBrandLockStatus={false}
                showAddToCartButton={false}
                showAddToComparisonButton={false}
            >
                <Box className={classes.comparisonBrandCard}
                     onMouseEnter={() => { setIsFocused(true); }}
                     onMouseLeave={() => { setIsFocused(false); }}
                >
                    <Paper elevation={!isFocused ? 2 : 5} className={classes.comparisonBrandCardPaper}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                            {warning && (
                                <Tooltip
                                    arrow
                                    title={<span style={{ whiteSpace: 'pre-line' }}>{warning.text}</span>}
                                >
                                    <WarningRoundedIcon
                                        className={classnames({
                                            [classes.comparisonBrandCardWarningIcon]: true,
                                            [classes[`comparisonBrandCardWarningIconColor_${warning.color}`]]: true,
                                        })}
                                    />
                                </Tooltip>
                            )}

                            <ComparisonBrandCardToolbarButton
                                icon={CloseIcon}
                                tooltip={(<FormattedMessage id="ADD_TO_COMPARISON_BUTTON.REMOVE_FROM_COMPARISON" />)}
                                onClick={handleRemoveButtonClick}
                            />
                        </Box>

                        <Box display="flex" flexDirection="row" justifyContent="center">
                            <Link
                                to={generateSalesAnalysisUrl({
                                    brandId: brandInfo.id
                                })}
                            >
                                <img
                                    src={getLogoImageUrl(brandInfo.logo)}
                                    alt={brandInfo.name}
                                    className={classes.comparisonBrandCardLogo}
                                />
                            </Link>
                        </Box>

                        {brandInfoCount > 1 && (
                            <Box display="flex" flexDirection="row">
                                <ComparisonBrandCardToolbarButton
                                    icon={StarIcon}
                                    tooltip={(<FormattedMessage id="COMPARISON_MAKE_FIRST_PROMPT" />)}
                                    isDisabled={isLeftMostCard}
                                    onClick={handleMakeFirstButtonClick}
                                />

                                <ComparisonBrandCardToolbarButton
                                    icon={ArrowBackIcon}
                                    tooltip={(<FormattedMessage id="COMPARISON_SWAP_LEFT_PROMPT" />)}
                                    isDisabled={isLeftMostCard}
                                    onClick={handleSwapLeftButtonClick}
                                />

                                <ComparisonBrandCardToolbarButton
                                    icon={ArrowForwardIcon}
                                    tooltip={(<FormattedMessage id="COMPARISON_SWAP_RIGHT_PROMPT" />)}
                                    isDisabled={isRightMostCard}
                                    onClick={handleSwapRightButtonClick}
                                />
                            </Box>
                        )}
                    </Paper>
                </Box>
            </BrandTooltip>
        </Box>
    );
};

export default ComparisonBrandCard;