import React, {useState} from "react";
import {
    Box,
    Button,
    ButtonGroup,
    FormControlLabel,
    IconButton,
    InputAdornment,
    makeStyles,
    Popover,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from "@material-ui/core";
import {Dropdown} from "react-bootstrap";
import Tooltip from "../tooltip";
import SearchIcon from "@material-ui/icons/Search";
import {FormattedMessage} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faGlobe,
    faLocationDot,
    faLocationPinLock,
    faStore
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";

const useStyles = makeStyles(() => ({
    popover: {
        maxHeight: "500px",
        overflow: "auto",
    }
}));

export const getSalesChannelIcons = (size, color) => {
    const salesChannelIcons = {
        offline: <FontAwesomeIcon icon={faStore} size={size} color={color} fixedWidth={true} />,
        online: <FontAwesomeIcon icon={faGlobe} size={size} color={color} ixedWidth={true} />,
        all: (
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <FontAwesomeIcon icon={faStore} size={size} color={color} fixedWidth={true} />
                <FontAwesomeIcon icon={faGlobe} size={size} color={color} fixedWidth={true} />
            </Box>
        ),
    };
    return salesChannelIcons;
};

const LocationSelect = ({ salesChannels, locations, salesChannel, locationId, onChange, isEnabled, ...props }) => {
    const { intl } = props;
    const classes = useStyles();

    const [searchQuery, setSearchQuery] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isOpen = !!anchorEl;
    const id = isOpen ? "simple-popover" : undefined;

    const salesChannelIcons = getSalesChannelIcons("lg");
    const salesChannelTitles = {
        offline: intl.formatMessage({ id: "OFFLINE.SALES.CHANNEL.TITLE" }),
        online: intl.formatMessage({ id: "ONLINE.SALES.CHANNEL.TITLE" }),
        all: intl.formatMessage({ id: "ALL.SALES.CHANNEL.TITLE" }),
    };
    const salesChannelTooltips = {
        offline: intl.formatMessage({ id: "OFFLINE.SALES.CHANNEL.TOOLTIP" }),
        online: intl.formatMessage({ id: "ONLINE.SALES.CHANNEL.TOOLTIP" }),
        all: intl.formatMessage({ id: "ALL.SALES.CHANNEL.TOOLTIP" }),
    };
    let filteredSalesChannels = _.keys(salesChannelTitles);
    if (salesChannels && salesChannels.length) {
        filteredSalesChannels = _.filter(filteredSalesChannels, (salesChannel) => {
            return _.includes(salesChannels, salesChannel);
        });
    }

    const salesChannelToLocationsMap = (locations || {});

    const salesChannelLocations = (locations[salesChannel] || []);

    const filteredSalesChannelLocations = [
        { id: "0", name: intl.formatMessage({ id: "LOCATION.ALL_LOCATIONS" }) },
        ...(!!searchQuery.length ? salesChannelLocations.filter(({ name }) => name.toLowerCase().includes(searchQuery.toLowerCase())) : salesChannelLocations),
    ];

    const getTooltipTitle = (locationId) => {
        let result = null;

        if (isEnabled) {
            result = `${intl.formatMessage({ id: "SALES.CHANNEL.PROMPT" })}${salesChannelTitles[salesChannel]}\n`

            if (locationId) {
                const location = salesChannelLocations.find(({ id }) => {
                    return (id === locationId);
                });
                result += `${intl.formatMessage({ id: "LOCATION.TOOLTIP.PROMPT" })}${location.name}`;
            }
            else {
                result += `${intl.formatMessage({ id: "LOCATION.TOOLTIP.PROMPT" })}${intl.formatMessage({ id: "LOCATION.ALL_LOCATIONS" })}`;
            }
        }
        else {
            result = intl.formatMessage({ id: "LOCATION.SELECTION.DISABLED.TOOLTIP" });
        }

        return result;
    };

    return (
        <Box>
            <Tooltip arrow title={<span style={{ whiteSpace: "pre-line"}}>{getTooltipTitle(locationId)}</span>}>
                <span>
                    <Dropdown.Toggle
                        aria-describedby={id}
                        onClick={handleClick}
                        disabled={!isEnabled}
                        style={!isEnabled ? { pointerEvents: 'none' } : {}}
                    >
                        <FontAwesomeIcon icon={locationId ? faLocationPinLock : faLocationDot} size={"lg"} fixedWidth={true} />
                    </Dropdown.Toggle>
                </span>
            </Tooltip>
            <Popover
                id={id}
                open={isOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Box pl={2} pr={2} className={classes.popover}>
                    <Box mt={1} mb={1}>
                        <Typography><FormattedMessage id="SALES.CHANNEL.PROMPT" /></Typography>
                        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                            <ButtonGroup variant="contained">
                                {filteredSalesChannels.map((filteredSalesChannel, index) => (
                                    <Tooltip key={`${index}`} arrow title={<span style={{ whiteSpace: "pre-line"}}>{salesChannelTooltips[filteredSalesChannel]}</span>}>
                                        <Button color={filteredSalesChannel === salesChannel ? "primary" : "default"} onClick={() => {
                                            if (filteredSalesChannel !== salesChannel) {
                                                const newSalesChannelLocations = salesChannelToLocationsMap[filteredSalesChannel];

                                                onChange({
                                                    salesChannel: filteredSalesChannel,
                                                    locationId: (_.find(newSalesChannelLocations, (location) => location.id === locationId) ? locationId : null)
                                                });
                                            }
                                        }}>
                                            {salesChannelIcons[filteredSalesChannel]}
                                        </Button>
                                    </Tooltip>
                                ))}
                            </ButtonGroup>
                        </Box>
                    </Box>

                    <Box mb={2}>
                        <TextField
                            label={<FormattedMessage id="INPUT.LOCATION" />}
                            value={searchQuery}
                            onChange={({ target: { value } }) => setSearchQuery(value)}
                            fullWidth={true}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <IconButton style={{padding: 0}} onClick={() => {
                                        setSearchQuery("");
                                    }}>
                                        <CancelRoundedIcon fontSize="small" />
                                    </IconButton>
                                )
                            }}
                        />
                    </Box>

                    <RadioGroup
                        value={locationId || "0"}
                        onChange={({ target: { value } }) => {
                            onChange({
                                salesChannel: salesChannel,
                                locationId: (value === "0" ? undefined : value)
                            });
                            handleClose();
                        }}
                    >
                        {filteredSalesChannelLocations.map(({ id, name }) => (
                            <FormControlLabel
                                key={`${id}-${name}`}
                                value={id}
                                control={<Radio color="primary" />}
                                label={name}
                            />
                        ))}
                    </RadioGroup>
                </Box>
            </Popover>
        </Box>
    );
};

export default LocationSelect;
