/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {Dropdown} from "react-bootstrap";
import {connect, useDispatch} from "react-redux";
import Tooltip from "../../common/components/tooltip";
import {FormattedMessage} from "react-intl";

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import EmailIcon from '@material-ui/icons/Email';
import StorageIcon from '@material-ui/icons/Storage';
import CachedIcon from '@material-ui/icons/Cached';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import {UserRoles} from "../../crud/constants";
import * as auth from "../../store/ducks/auth.duck";
import * as popups from "../../store/ducks/popups.duck";
import * as admin from "../../store/ducks/admin.duck";
import {toAbsoluteUrl} from "../../utils/utils";

import GoogleAnalytics from "../../common/components/google-analytics";
import {useHistory} from "react-router-dom";

const UserProfile = ({user}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const userFullName = (user.first_name + (user.last_name ? (" " + user.last_name) : "")).trim();

    const handleProfileClick = () => {
        history.push("/profile");
    };

    const handleTrialRequestClick = () => {
        dispatch(popups.actions.showTrialRequestPopup());
    };

    const handleDatabaseStatisticsClick = () => {
        history.push("/database-statistics");
    };

    const handleDataPartnerStatisticsClick = () => {
        history.push("/data-partner-statistics");
    };

    const handleMetricCacheClearClick = () => {
        dispatch(admin.actions.clearCache({
            param: "123"
        }));
    };

    const handleContactUsClick = () => {
        dispatch(popups.actions.showSendMessagePopup("Contact"));
    };

    const handleLogoutClick = () => {
        GoogleAnalytics.reportEvent({
            category: 'Auth',
            action: 'Logout',
            label: user.email
        });

        dispatch(auth.actions.logoutUser());
    };

    return (
        <Dropdown className="kt-header__topbar-item kt-header__topbar-item--langs" drop="down" alignRight>
            <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-user-profile">
                <Tooltip arrow title={userFullName}>
                    <span className="kt-header__topbar-icon">
                        <img src={toAbsoluteUrl("/media/icons/svg/Avatars/default1.svg")} alt="Pic"/>
                    </span>
                </Tooltip>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
                <Dropdown.Item onClick={handleProfileClick}>
                    <span className="kt-nav__link">
                        <span className="kt-nav__link-icon">
                            <AccountCircleIcon color="primary"/>
                        </span>
                        <span className="kt-nav__link-text">
                            <FormattedMessage id="PROFILE.BUTTON"/>
                        </span>
                    </span>
                </Dropdown.Item>
                {user && user.capabilities["trial_request"] && (
                    <Dropdown.Item onClick={handleTrialRequestClick}>
                        <span className="kt-nav__link">
                            <span className="kt-nav__link-icon">
                                <LockIcon color="primary"/>
                            </span>
                            <span className="kt-nav__link-text">
                                <FormattedMessage id="REQUEST.TRIAL.BUTTON.TEXT"/>
                            </span>
                        </span>
                    </Dropdown.Item>
                )}
                {user && user.role === UserRoles.Admin && (
                    <Dropdown.Item onClick={handleDatabaseStatisticsClick}>
                        <span className="kt-nav__link">
                            <span className="kt-nav__link-icon">
                                <StorageIcon color="primary"/>
                            </span>
                            <span className="kt-nav__link-text">
                                <FormattedMessage id="DATABASE.STATISTICS.BUTTON"/>
                            </span>
                        </span>
                    </Dropdown.Item>
                )}
                {user && (user.role === UserRoles.Admin || user.role === UserRoles.DataPartner) && (
                    <Dropdown.Item onClick={handleDataPartnerStatisticsClick}>
                        <span className="kt-nav__link">
                            <span className="kt-nav__link-icon">
                                <StorageIcon color="primary"/>
                            </span>
                            <span className="kt-nav__link-text">
                                <FormattedMessage id="DATA.PARTNER.STATISTICS.BUTTON"/>
                            </span>
                        </span>
                    </Dropdown.Item>
                )}
                {user && (user.role === UserRoles.Admin) && (
                    <Dropdown.Item onClick={handleMetricCacheClearClick}>
                        <span className="kt-nav__link">
                            <span className="kt-nav__link-icon">
                                <CachedIcon color="primary"/>
                            </span>
                            <span className="kt-nav__link-text">
                                <FormattedMessage id="METRIC.CACHE.CLEAR.BUTTON"/>
                            </span>
                        </span>
                    </Dropdown.Item>
                )}
                <Dropdown.Item onClick={handleContactUsClick}>
                    <span className="kt-nav__link">
                        <span className="kt-nav__link-icon">
                            <EmailIcon color="primary"/>
                        </span>
                        <span className="kt-nav__link-text">
                            <FormattedMessage id="CONTACT_US.BUTTON"/>
                        </span>
                    </span>
                </Dropdown.Item>
                <Dropdown.Item onClick={handleLogoutClick}>
                    <span className="kt-nav__link">
                        <span className="kt-nav__link-icon">
                            <ExitToAppIcon color="primary"/>
                        </span>
                        <span className="kt-nav__link-text">
                            <FormattedMessage id="SIGN_OUT.BUTTON"/>
                        </span>
                    </span>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

const mapStateToProps = ({auth: {user}}) => ({
    user,
});

export default connect(mapStateToProps, null)(UserProfile);
