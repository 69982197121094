import React, {forwardRef} from "react";
import {Box, makeStyles, Paper, Tab, Tabs} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import Tooltip from "../tooltip";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import MetricNakedFilters from "../metric-naked-filters";
import ProgressingOverlay from "../progressing-overlay";
import DemoDataWarningBlock from "../demo-data-warning-block";

const useStyles = makeStyles((theme) => ({
    root: {
        "@supports (position: sticky)": {
            position: "sticky",
            top: "90px",
            zIndex: 1001, // Should be greater than ProgressingOverlay
        },
    },
    portlet: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        "@media (max-width: 1400px)": {
            flexDirection: "column",
        },
    },
    portletTabsWrapper: {
        overflow: "hidden",
    },
    filtersWrapper: {
        padding: "10px",
        float: "right",
        flexGrow: 1,
        flexShrink: 0
    },
    releaseNotesBadge: {
        width: "7px",
        height: "7px",
        borderRadius: "50%",
        position: "absolute",
        right: "10px",
        top: "10px",
        backgroundColor: "#f64e60",
    },
}));

const TabComponent = ({ children, isPackagePurchased, areUpdatesAvailable, ...props }) => {
    const classes = useStyles();

    return (
        <Link
            {...props}
            to={isPackagePurchased ? props.to : "#"}
        >
            {children}

            {!isPackagePurchased && (
                <Tooltip arrow title={<FormattedMessage id="SALES_ANALYSIS.NOT.PURCHASED.PACKAGE.PROMPT" />}>
                    <Box ml={1}>
                        <WarningRoundedIcon />
                    </Box>
                </Tooltip>
            )}

            {areUpdatesAvailable && (
                <div className={classes.releaseNotesBadge} />
            )}
        </Link>
    );
};

const MetricPackageTabsAndFilters = ({ tabInfos, activeTabName, filters, months, locations, options, onChange, isChanging, brandInfo, shouldShowDemoDataWarning, isLoading }) => {
    const classes = useStyles();

    const CustomTabComponent = forwardRef((props, ref) => <TabComponent {...props} />);

    return (
        <Box className={classes.root} mb={2}>
            <Paper className={classes.portlet} elevation={10}>
                <Box className={classes.portletTabsWrapper}>
                    <Tabs
                        textColor="primary"
                        indicatorColor="primary"
                        value={activeTabName}
                        variant={"scrollable"}
                        scrollButtons={"on"}
                    >
                        {tabInfos.map(({ name, title, link, isPackagePurchased, areUpdatesAvailable }, index) => (
                            <Tab
                                key={`${index}-tab`}
                                component={CustomTabComponent}
                                value={name}
                                label={title}
                                to={link}
                                isPackagePurchased={isPackagePurchased}
                                areUpdatesAvailable={areUpdatesAvailable}
                            />
                        ))}
                    </Tabs>
                </Box>

                {(!isLoading && filters && filters.period) && (
                    <Box className={classes.filtersWrapper}>
                        <MetricNakedFilters
                            locations={locations}
                            months={months}
                            options={options}
                            filters={filters}
                            onChange={(filtersPortion) => {
                                onChange(filtersPortion);
                            }}
                            isChanging={isChanging}
                        />
                    </Box>
                )}

                <ProgressingOverlay isOpen={isLoading} />
            </Paper>

            {shouldShowDemoDataWarning && (
                <Box mt={1}>
                    <DemoDataWarningBlock brandInfo={brandInfo} />
                </Box>
            )}
        </Box>
    );
};

export default MetricPackageTabsAndFilters;
