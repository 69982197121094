import axios from "axios";

import { API_URL } from "./constants"

export function fetchPurchaseInfos(filters) {
  const params = {
    limit: 18,
    page: filters.page || 1,
    ...filters,
  };
  return axios.get(`${API_URL}/purchase/list`, { params });
}
