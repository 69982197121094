const ServerMode = {
    Prod: "Prod",
    Stage: "Stage",
};
const serverMode = ServerMode.Prod;

const ApiMode = {
    Remote: "Remote",
    Local: "Local",
};
const apiMode = ApiMode.Remote;

export const BASE_URL = (serverMode === ServerMode.Prod ? "https://back.openb24.com" : "https://beta-back.openb24.com");
export const API_URL = (apiMode === ApiMode.Remote ? `${BASE_URL}/api` : "http://localhost:3000");

export const UserRoles = {
    Admin: "admin",
    DataPartner: "data-partner"
};

export const LANDING_URLS = {
    HOME: "https://openb24.com",
    TERMS: "https://openb24.com/terms",
    "DATA.PROCESSING": "https://openb24.com/data",
    ABOUT: "https://openb24.com/about",
    CONTACTS: "https://openb24.com/contacts",
    SUBSCRIPTIONS: "https://openb24.com/subscriptions"
};

export const getLandingUrl = (type, language) => {
    const url = LANDING_URLS[type];
    return `${url}?lang=${language}`;
};

export const IS_CART_ENABLED = false;
export const IS_COUNTRY_BRAND_SEARCH_ENABLED = (serverMode === ServerMode.Stage);

export const GA_TRACKING_CODE = "UA-183669211-1";