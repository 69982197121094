import * as utils from "../../_metronic/utils/utils";

const localStorageLastLocationKey = "lastLocation";

export function saveLastLocation(lastLocation) {
  const localStorateLocations = utils.getStorage(localStorageLastLocationKey);
  let _useLocations = localStorateLocations
    ? JSON.parse(localStorateLocations)
    : [];

  if (lastLocation) {
    _useLocations.push(lastLocation.pathname);
    utils.setStorage(
      localStorageLastLocationKey,
      JSON.stringify(_useLocations),
      120
    );
  }
}

export function forgotLastLocation() {
  utils.removeStorage(localStorageLastLocationKey);
}

export function getLastLocation() {
  const localStorateLocations = utils.getStorage(localStorageLastLocationKey);
  if (!localStorateLocations) {
    return "/";
  }

  const _userLocations = JSON.parse(localStorateLocations);
  const result = _userLocations.length > 0 ? _userLocations.pop() : "/";
  return result;
}
