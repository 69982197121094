import axios from "axios";

import { API_URL } from "./constants"

export const getDatabaseStats = () => {
  return axios.get(`${API_URL}/statistics/database`);
};

export const getDataPartnerStats = (partnerId, month) => {
  return axios.get(`${API_URL}/statistics/data-partner`, {
    params: { partnerId, month },
  });
};

export const requestDataPartnerStatsExport = async (partnerId, month) => {
  return await axios.get(`${API_URL}/statistics/data-partner/export`, {
    params: { partnerId, month },
    responseType: 'blob'
  });
};
