import _ from "lodash";
import {generateSalesAnalysisUrl} from "../utils/utils";
import * as comparison from "../../app/store/ducks/comparison.duck";

const config = {
    header: {
        self: {},
        items: [],
    },
    aside: {
        self: {},
        items: [
            {
                icon: "fas fa-chart-line",
                title: "Industries",
                translate: "INDUSTRIES.CAPTION",
                page: "/industries",
            },
            {
                icon: "fas fa-list-ul",
                title: "Catalog",
                translate: "CATALOG.CAPTION",
                page: "/catalog",
            },
            {
                icon: "fas fa-list-check",
                title: "My companies",
                translate: "MY_COMPANIES.CAPTION",
                page: "/my-companies",
            },
            {
                icon: "fas fa-code-compare",
                title: "Comparison",
                translate: "COMPARISON.SHORT.CAPTION",
                page: "/comparison",
                badge: {
                    getText: (state, intl) => {
                        const count = comparison.selectors.getComparisonBrandInfos(state).length;
                        return (count > 0 ? `${count}` : null);
                    },
                    style: {
                        backgroundColor: "#bb3e53",
                        color: "#ffffff"
                    }
                },
                submenu: {
                    getItems: (state) => {
                        const brandInfos = comparison.selectors.getComparisonBrandInfos(state);
                        return _.map(brandInfos, (brandInfo) => {
                            return {
                                title: brandInfo.name,
                                page: generateSalesAnalysisUrl({
                                    brandId: brandInfo.id
                                })
                            };
                        });
                    }
                }
            },
        ],
    },
};

export default config;