import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles({
    alert: {
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
    },

    alertTitle: {
        margin: 0,
    },
});

const MessageTitle = ({ severity, children }) => {
    const classes = useStyles();

    return (
        <Box width="100%" height="100%" display="flex">
            <Alert severity={severity ? severity : "info"} className={classes.alert}>
                <AlertTitle classes={{ root: classes.alertTitle }}>{children}</AlertTitle>
            </Alert>
        </Box>
    );
};

export default MessageTitle;