import React from "react";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";

import * as auth from "../../store/ducks/auth.duck";
import {Box, Grid} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import { verifyUser } from "../../crud/auth.crud";

import GoogleAnalytics from "../../common/components/google-analytics";

const Verify = (props) => {
    const history = useHistory();

    GoogleAnalytics.reportEvent({
        category: 'Auth',
        action: 'Verify',
        label: props.match.params.token
    });

    verifyUser(props.match.params.token)
        .then(() => {
            props.verifyUser();
            history.push("/auth/login");
        })
        .catch(() => {
            history.push("/auth/login");
        });

    return (
        <Grid container justify="center">
            <Box>
                <FormattedMessage id="AUTH.ACCOUNT_VERIFICATION_STATE" />
            </Box>
        </Grid>
    );
};

export default connect(null, auth.actions)(Verify);