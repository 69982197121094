import React from "react";
import {makeStyles, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    green: {
        width: "auto",
        height: "auto",
        padding: "2px 6px",
        borderRadius: "3px",
        backgroundColor: "#6ca672",
        color: "#ffffff"
    },

    red: {
        width: "auto",
        height: "auto",
        padding: "2px 6px",
        borderRadius: "3px",
        backgroundColor: "#f64e60",
        color: "#ffffff"
    },
}));

const Badge = ({ color, text }) => {
    const classes = useStyles();

    return (
        <Typography className={classes[color]}>{text}</Typography>
    );
};

export default Badge;
