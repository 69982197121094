import React from "react";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { metronic } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import {Box, Typography} from "@material-ui/core";

const languageInfos = [
    {
        lang: "ru",
        name: "Русский",
    },
    {
        lang: "en",
        name: "English",
    },
];

class LanguageSelector extends React.Component {
    render() {
        const { lang, setLanguage } = this.props;
        const currentLanguageInfo = languageInfos.find((languageInfo) => languageInfo.lang === lang);
        return (
            <Dropdown className="kt-header__topbar-item kt-header__topbar-item--langs" drop="down" alignRight>
                <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-lang-selector">
                <span className="kt-header__topbar-icon">
                    <Typography style={{textDecoration: "underline"}} color="primary">{currentLanguageInfo.lang.toUpperCase()}</Typography>
                </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
                    {languageInfos.map((languageInfo) => (
                        <Dropdown.Item
                            key={languageInfo.lang}
                            active={languageInfo.lang === currentLanguageInfo.lang}
                            onClick={() => {
                                setLanguage(languageInfo.lang);
                                setTimeout(() => window.location.reload(), 500);
                            }}
                        >
                        <span className="kt-nav__link">
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <Box mr={1}>
                                    <Typography style={{textDecoration: "underline"}}>{languageInfo.lang.toUpperCase()}</Typography>
                                </Box>
                                <Typography>{languageInfo.name}</Typography>
                            </Box>
                        </span>
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

const mapStateToProps = ({ i18n }) => ({ lang: i18n.lang });

export default connect(
    mapStateToProps,
    metronic.i18n.actions
)(LanguageSelector);
