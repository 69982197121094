import React from "react";
import {
    Tooltip, withStyles
} from "@material-ui/core";

const CustomTooltip = withStyles({
    tooltip: {
        fontSize: "1em",
    }
})(Tooltip);

const Component = ({children, ...props}) => {
    return (
        (props.title ? (
            <CustomTooltip {...props}>
                {children}
            </CustomTooltip>
        ) : (
            <>
                {children}
            </>
        ))
    );
};

export default Component;
