import React, {useEffect, useRef} from "react";
import {Box, Divider, Typography, Paper, Tabs, Tab} from "@material-ui/core";
import HorizontalBar, {enableDataLabels} from "../horizontal-bar";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';

import {
    prepareChartData,
    createChartOptions,
    useChartProperties,
    showTrendLineAnnotations,
    hideTrendLineAnnotations
} from "../chart";
import ChartCard, {ConclusionsTooltipButton} from "../chart-card";
import TabPanel, {generateTabProps} from "../tab-panel";
import MessageTitle from "../../../common/components/message-title";

import { selectors } from "../../../../_metronic/ducks/i18n";
import {Dropdown} from "react-bootstrap";

const MetricDataTypeChartComponent = ({config, intl, language, chartType, ChartComponent, data, scope, isChartLoaded, alertText}) => {
    const options = createChartOptions("bar", config.options, intl, language);
    enableDataLabels(options, intl);

    const chartHeight = (config.height || 300);

    const preparedData = prepareChartData(data, null, scope, options);

    const groupMetricDatas = [];
    if (data && data.groupMetricDatas) {
        for (const groupMetricData of data.groupMetricDatas) {
            const options = createChartOptions(chartType, config.groupOptions, intl, language);

            groupMetricDatas.push({
                options: options,
                preparedData: prepareChartData(groupMetricData, null, scope, options)
            });
        }
    }

    const chartRefs = useRef([].slice(0, 1 + groupMetricDatas.length));

    const [activeTabIndex, setActiveTabIndex] = React.useState(0);

    let conclusions = (preparedData ? preparedData.conclusions : []);
    if (activeTabIndex >= 1 && groupMetricDatas.length > 0) {
        conclusions = (groupMetricDatas[activeTabIndex - 1].preparedData.conclusions || []);
    }

    const handleTabChange = (event, tabIndex) => {
        setActiveTabIndex(tabIndex);
    };

    if (activeTabIndex !== 0 && activeTabIndex > groupMetricDatas.length) {
        setActiveTabIndex(0);
    }

    return (
        <Box style={{display:"flex", flexDirection: "column", height: chartHeight}}>
            {(preparedData && preparedData.datasets && preparedData.datasets.length) ? (
                <>
                    <Paper variant="outlined">
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Box ml={1}>
                                <ConclusionsTooltipButton
                                    conclusions={conclusions}
                                    onTooltipOpen={(conclusions) => {
                                        showTrendLineAnnotations(chartRefs.current[activeTabIndex], conclusions);
                                    }}
                                    onTooltipClose={() => {
                                        hideTrendLineAnnotations(chartRefs.current[activeTabIndex]);
                                    }}
                                />
                            </Box>

                            <Tabs
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="on"
                                value={activeTabIndex}
                                onChange={handleTabChange}
                            >
                                <Tab key={0} icon={<HorizontalSplitIcon />} {...generateTabProps(0)} />

                                {preparedData.labels.map((label, index) => (
                                    <Tab key={index + 1} label={label} {...generateTabProps(index + 1)} />
                                ))}
                            </Tabs>
                        </Box>
                    </Paper>

                    <Box mt={1} style={{flexGrow: 1, overflow: "hidden"}}>
                        <TabPanel index={0} isHidden={activeTabIndex !== 0}>
                            <HorizontalBar
                                ref={el => chartRefs.current[0] = el}
                                data={preparedData}
                                options={options}
                            />
                        </TabPanel>

                        {groupMetricDatas.map((groupMetricData, index) => (
                            <TabPanel key={index + 1} index={index + 1} isHidden={activeTabIndex !== (index + 1)}>
                                {(groupMetricData.preparedData && groupMetricData.preparedData.datasets && groupMetricData.preparedData.datasets.length) ? (
                                    <ChartComponent
                                        ref={el => chartRefs.current[index + 1] = el}
                                        data={groupMetricData.preparedData}
                                        options={groupMetricData.options}
                                    />
                                ) : (
                                    <MessageTitle>
                                        {!isChartLoaded ? (
                                            <FormattedMessage id="DATA_IS_BEING_LOADED"/>
                                        ) : (alertText ? alertText : <FormattedMessage id="NOT_ENOUGH_DATA_PROMPT"/>)}
                                    </MessageTitle>
                                )}
                            </TabPanel>
                        ))}
                    </Box>
                </>
            ) : (
                <MessageTitle>
                    {!isChartLoaded ? (
                        <FormattedMessage id="DATA_IS_BEING_LOADED"/>
                    ) : (alertText ? alertText : <FormattedMessage id="NOT_ENOUGH_DATA_PROMPT"/>)}
                </MessageTitle>
            )}
        </Box>
    );
};

const SocDemDistribution = ({ brandId, metricId, alertText, toggleHelpDrawer, intl }) => {
    const dispatch = useDispatch();

    const language = useSelector(selectors.getSelectedLanguage);

    const {
        metricFilters,
        isChartLoaded,
        scope,
        data,
        dataFeatures,
        title,
        subTitle,
        description,
        releaseNotes,
        chartType,
        ChartComponent,
        setMetricActiveFiltersAction,
    } = useChartProperties(brandId, null, null, null, metricId, 400);

    const config = _.get(data, "metric.config");

    const supportedAudienceGenderFilters = ["any", "male", "female"];
    const audienceGenderFilter = (metricFilters || {}).audienceGender;
    const formattedAudienceGenderFilters = {
        "any": intl.formatMessage({ id: "AUDIENCE.GENDER.ANY" }),
        "male": intl.formatMessage({ id: "AUDIENCE.GENDER.MALE" }),
        "female": intl.formatMessage({ id: "AUDIENCE.GENDER.FEMALE" }),
    };

    const supportedAudienceAgeFilters = ["any", "0_17", "18_24", "25_34", "35_44", "45_54", "55_64", "65_0"];
    const audienceAgeFilter = (metricFilters || {}).audienceAge;
    const formattedAudienceAgeFilters = {
        "any": intl.formatMessage({ id: "AUDIENCE.AGE.ANY" }),
        "0_17": intl.formatMessage({ id: "AUDIENCE.AGE.0_17" }),
        "18_24": intl.formatMessage({ id: "AUDIENCE.AGE.18_24" }),
        "25_34": intl.formatMessage({ id: "AUDIENCE.AGE.25_34" }),
        "35_44": intl.formatMessage({ id: "AUDIENCE.AGE.35_44" }),
        "45_54": intl.formatMessage({ id: "AUDIENCE.AGE.45_54" }),
        "55_64": intl.formatMessage({ id: "AUDIENCE.AGE.55_64" }),
        "65_0": intl.formatMessage({ id: "AUDIENCE.AGE.65_0" }),
    };

    useEffect(() => {
        if (!audienceGenderFilter || !audienceAgeFilter) {
            dispatch(setMetricActiveFiltersAction({
                audienceGender: supportedAudienceGenderFilters[0],
                audienceAge: supportedAudienceAgeFilters[0]
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ChartCard
            title={title}
            subTitle={subTitle}
            description={description}
            releaseNotes={releaseNotes}
            dataFeatures={dataFeatures}
            toggleHelpDrawer={toggleHelpDrawer}
            isLoading={!isChartLoaded}
        >
            {(data && data.metricDataTypeResults) ? (
                <Box>
                    <Box>
                        <Typography variant="button" color="textSecondary">{<FormattedMessage id="METRIC.DATA.TYPE.BY.GENDER" />}</Typography>

                        <Box pl={1} pr={1} pb={1}>
                            <Typography variant="subtitle2">{<FormattedMessage id="AUDIENCE.AGE.DROPDOWN.PROMPT" />}</Typography>

                            <Dropdown>
                                <Dropdown.Toggle>
                                    {formattedAudienceAgeFilters[audienceAgeFilter]}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {supportedAudienceAgeFilters.map((supportedAudienceAgeFilter) => (
                                        <Dropdown.Item
                                            key={supportedAudienceAgeFilter}
                                            active={supportedAudienceAgeFilter === audienceAgeFilter}
                                            onClick={() => {
                                                dispatch(setMetricActiveFiltersAction({
                                                    audienceAge: supportedAudienceAgeFilter
                                                }));
                                            }}>
                                            {formattedAudienceAgeFilters[supportedAudienceAgeFilter]}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Box>

                        <Box mt={1}>
                            <MetricDataTypeChartComponent
                                config={config}
                                intl={intl}
                                language={language}
                                chartType={chartType}
                                ChartComponent={ChartComponent}
                                data={data.metricDataTypeResults["by_gender"]}
                                scope={scope}
                                isChartLoaded={isChartLoaded}
                                alertText={alertText}
                            />
                        </Box>
                    </Box>

                    <Box mt={2} mb={2}>
                        <Divider />
                    </Box>

                    <Box>
                        <Typography variant="button" color="textSecondary">{<FormattedMessage id="METRIC.DATA.TYPE.BY.AGE" />}</Typography>

                        <Box pl={1} pr={1} pb={1}>
                            <Typography variant="subtitle2">{<FormattedMessage id="AUDIENCE.GENDER.DROPDOWN.PROMPT" />}</Typography>

                            <Dropdown>
                                <Dropdown.Toggle>
                                    {formattedAudienceGenderFilters[audienceGenderFilter]}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {supportedAudienceGenderFilters.map((supportedAudienceGenderFilter) => (
                                        <Dropdown.Item
                                            key={supportedAudienceGenderFilter}
                                            active={supportedAudienceGenderFilter === audienceGenderFilter}
                                            onClick={() => {
                                                dispatch(setMetricActiveFiltersAction({
                                                    audienceGender: supportedAudienceGenderFilter
                                                }));
                                            }}>
                                            {formattedAudienceGenderFilters[supportedAudienceGenderFilter]}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Box>

                        <Box mt={1}>
                            <MetricDataTypeChartComponent
                                config={config}
                                intl={intl}
                                language={language}
                                chartType={chartType}
                                ChartComponent={ChartComponent}
                                data={data.metricDataTypeResults["by_age"]}
                                scope={scope}
                                isChartLoaded={isChartLoaded}
                                alertText={alertText}
                            />
                        </Box>
                    </Box>
                </Box>
            ) : (
                <MessageTitle>
                    {!isChartLoaded ? (
                        <FormattedMessage id="DATA_IS_BEING_LOADED"/>
                    ) : (alertText ? alertText : <FormattedMessage id="NOT_ENOUGH_DATA_PROMPT"/>)}
                </MessageTitle>
            )}
        </ChartCard>
    );
};

export default injectIntl(SocDemDistribution);
