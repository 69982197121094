import axios from "axios";

import { API_URL } from "./constants"

export function fetchLocations() {
  return axios.get(`${API_URL}/location/list`);
}

export function fetchIndustries() {
  return axios.get(`${API_URL}/industry/list`);
}

export function fetchBrandInfos(filters) {
  const params = {
    page: filters.page || 1,
    limit: filters.limit || 12,
    order: filters.order || "asc",
    ...filters,
  };
  return axios.get(`${API_URL}/catalog/brands`, { params });
}