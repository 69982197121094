import React from "react";
import {FormattedMessage} from "react-intl";
import Tooltip from "../tooltip";
import {faRefresh} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const RefreshProgressableButton = ({isProgressing, ...props}) => {
  return (
      <Tooltip arrow title={<FormattedMessage id="REFRESH" />}>
          <span>
              <button className="btn btn-primary btn-elevate" {...props}>
                  <FontAwesomeIcon icon={faRefresh} spin={isProgressing} />
              </button>
          </span>
      </Tooltip>
  );
};

export default RefreshProgressableButton;