import {put, takeLatest} from "redux-saga/effects";
import {createAction, handleActions} from "redux-actions";
import { createSelector } from "reselect";
import * as purchasesCrud from "../../crud/purchases.crud";
import { createActionName, wrapSafely } from "./utils";
import _ from "lodash";
import * as auth from "./auth.duck";

const TAG = "PURCHASES";

export const actions = {
    requestPurchaseInfos: createAction(createActionName(TAG, "requestPurchaseInfos")),
    fillPurchaseInfos: createAction(createActionName(TAG, "fillPurchaseInfos")),
};

const defaultState = {
    purchases: [],
    months: [],
    isLoading: false,
    isLoaded: false,
};

const initialState = _.clone(defaultState);

export const reducer = handleActions(
    {
        [actions.requestPurchaseInfos]: (state, { payload }) => {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
            };
        },

        [actions.fillPurchaseInfos]: (state, { payload }) => {
            return {
                ...state,
                ...payload,
                isLoading: false,
                isLoaded: true,
            };
        },

        [auth.actionTypes.LogoutUser]: () => {
            console.log(`LogoutUser in ${TAG}`);
            return _.clone(defaultState);
        }
    },
    initialState
);

export function* saga() {
    yield takeLatest(actions.requestPurchaseInfos, wrapSafely(function*({ payload }) {
        const { data = {} } = yield purchasesCrud.fetchPurchaseInfos(payload);
        yield put(actions.fillPurchaseInfos(data));
    }, TAG, "requestPurchaseInfos"));
}

const getPurchases = (state) => state.purchases;

export const selectors = {
    getPurchaseInfos: createSelector(getPurchases, (purchases) => purchases.purchases),
    getPurchaseInfosLoadState: createSelector(getPurchases, (purchases) => {
        return {
            isLoading: purchases.isLoading,
            isLoaded: purchases.isLoaded
        };
    }),
    getPurchaseTotalPageCount: createSelector(getPurchases, (purchases) => Math.ceil(purchases.total / purchases.limit)),
    getPurchasedMonths: createSelector(getPurchases, (purchases) => purchases.months),
    getPurchasedBrandCount: createSelector(getPurchases, (purchases) => purchases.brandCount),
}