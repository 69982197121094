import React, { useEffect, useState } from "react";
import {Box, Paper, TextField, InputAdornment, IconButton} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import * as catalog from "../../../store/ducks/catalog.duck";
import DropdownAutocomplete from "../../../common/components/dropdown-autocomplete";
import { parseParams } from "../../../utils/utils";

import ProgressableButton from "../../../common/components/progressable-button";

import SearchIcon from "@material-ui/icons/Search";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import SortByAlphaIcon from "@material-ui/icons/SortByAlpha";
import {IS_COUNTRY_BRAND_SEARCH_ENABLED} from "../../../crud/constants";
import _ from "lodash";

const Filters = ({ onSubmit, isLoading }) => {
    const dispatch = useDispatch();

    // TODO: Ahtung - hardcode!
    const supportedCountries = (IS_COUNTRY_BRAND_SEARCH_ENABLED ? [
        {
            id: "1",
            name: "Belarus"
        },
        {
            id: "3",
            name: "Poland"
        }
    ] : []);

    const { industries: supportedIndustries } = useSelector(catalog.selectors.getFilters);
    const brandTotalCount = useSelector(catalog.selectors.getCatalogBrandTotalCount);

    const [selectedFilters, setSelectedFilters] = useState(parseParams());

    if (!selectedFilters.country) {
        selectedFilters.country = (supportedCountries.length > 0 ? _.head(supportedCountries).id : null);
    }

    if (selectedFilters.industry) {
        if (!Array.isArray(selectedFilters.industry)) {
            selectedFilters.industry = [selectedFilters.industry];
        }
    }
    else {
        selectedFilters.industry = [];
    }

    if (!selectedFilters.order) {
        selectedFilters.order = "asc";
    }

    const selectedCountry = _.find(supportedCountries, (supportedCountry) => supportedCountry.id === selectedFilters.country);

    const selectedIndustries = _.filter(supportedIndustries, (supportedIndustry) => {
        return _.includes(selectedFilters.industry, supportedIndustry.id);
    });

    const handleDropdownChange = (type, value) => {
        setSelectedFilters({
            ...selectedFilters,
            [type]: (Array.isArray(value) ? _.map(value, "id") : value.id)
        });
    };

    const handleSearchInputChange = (value) => {
        setSelectedFilters({
            ...selectedFilters,
            "search": value
        });
    };

    useEffect(() => {
        dispatch(catalog.actions.requestFilters());
    }, [dispatch]);

    return (
        <Paper>
            <Box
                p={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
            >
                <Box display="flex" alignItems="center" flexWrap="wrap">
                    {supportedCountries.length > 0 && (
                        <Box mr={2}>
                            <DropdownAutocomplete
                                label={<FormattedMessage id="INPUT.REGION" />}
                                options={supportedCountries}
                                isMultiValue={false}
                                isClearable={false}
                                value={selectedCountry}
                                onChange={(value) => {
                                    handleDropdownChange("country", value);
                                }}
                            />
                        </Box>
                    )}

                    <Box mr={2}>
                        <DropdownAutocomplete
                            label={<FormattedMessage id="SALES_ANALYSIS.INDUSTRY" />}
                            options={supportedIndustries}
                            isMultiValue={true}
                            isClearable={true}
                            value={selectedIndustries}
                            onChange={(value) => {
                                handleDropdownChange("industry", value);
                            }}
                        />
                    </Box>

                    <Box mr={1}>
                        <TextField
                            label={<FormattedMessage id="INPUT.BRAND" />}
                            name="search"
                            value={selectedFilters.search || ""}
                            onChange={(event) => {
                                handleSearchInputChange(event.target.value);
                            }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    handleSearchInputChange(event.target.value);
                                    onSubmit(selectedFilters);
                                }
                            }}
                            variant="outlined"
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <IconButton style={{padding: 0}} onClick={() => {
                                        handleSearchInputChange(null);
                                    }}>
                                        <CancelRoundedIcon fontSize="small" />
                                    </IconButton>
                                )
                            }}
                        />
                    </Box>

                    <Box mr={1}>
                        <IconButton onClick={() => {
                            setSelectedFilters({
                                ...selectedFilters,
                                "order": (selectedFilters.order === "asc" ? "desc" : "asc")
                            });
                        }}>
                            <SortByAlphaIcon style={{ cursor: "pointer", transform: ((selectedFilters.order === "asc") ? "rotate(0)" : "rotate(180deg)"), transition: "all 0.2s linear" }} />
                        </IconButton>
                    </Box>

                    <Box mr={2}>
                        <ProgressableButton
                            onClick={() => {
                                onSubmit(selectedFilters);
                            }}
                            disabled={isLoading}
                            isProgressing={isLoading}
                        >
                            <FormattedMessage id="SEARCH.BUTTON" />
                        </ProgressableButton>
                    </Box>
                </Box>
                <Box>
                    <FormattedMessage id="COMPANIES_FOUND" values={{ total: brandTotalCount }} />
                </Box>
            </Box>
        </Paper>
    );
};
export default Filters;
