import queryString from "query-string";
import _ from "lodash";
import * as metronicUtils from "../../../src/_metronic/utils/utils"
import {BASE_URL} from "../crud/constants";

export const toAbsoluteUrl = (path) => {
    return metronicUtils.toAbsoluteUrl(path);
};

const LOGO_IMAGE_BASE_URL = `${BASE_URL}/storage/bo/logos`;

export const getLogoImageUrl = (logoImagePath) => {
    return (logoImagePath ? `${LOGO_IMAGE_BASE_URL}/${logoImagePath}` : toAbsoluteUrl("/media/logos/logo-placeholder.jpg"));
};

export function parseParams() {
    return queryString.parse(window.location.search);
}

export function replaceParams(params) {
    window.history.replaceState(null, null, window.location.pathname + "?" + queryString.stringify(params));
}

export function createHistoryLocation(pathname, params) {
    params = _.omitBy(params, _.isNil);

    const result = {
        pathname: pathname,
    };

    if (!_.isEmpty(params)) {
        result.search = queryString.stringify(params);
    }

    return result;
}

export const generateSalesAnalysisUrl = (params) => {
    params = _.omitBy(params, _.isNil);

    let url = `/sales-analysis/${params.brandId}`;
    delete params.brandId;

    if (params.tabName) {
        url += `/${params.tabName}`;
        delete params.tabName;
    }

    if (!_.isEmpty(params)) {
        url += "?" + queryString.stringify(params);
    }

    return url;
};

export const generateComparisonUrl = (params) => {
    params = _.omitBy(params, _.isNil);

    let url = `/comparison`;

    if (params.tabName) {
        url += `/${params.tabName}`;
        delete params.tabName;
    }

    if (!_.isEmpty(params)) {
        url += "?" + queryString.stringify(params);
    }

    return url;
};

export const generateCatalogUrl = (params) => {
    params = _.omitBy(params, _.isNil);

    let url = "/catalog";

    if (!_.isEmpty(params)) {
        url += "?" + queryString.stringify(params);
    }

    return url;
};

export const generateIndustryDetailsUrl = (params) => {
    params = _.omitBy(params, _.isNil);

    let url = `/industry`;

    if (!_.isEmpty(params)) {
        url += "?" + queryString.stringify(params);
    }

    return url;
};

export const booleanizeString = (string) => {
    return (string === "true");
};

export * from "./timeUtils"
export * from "./localeUtils"