export const cellStyle = {
    paddingLeft: 7,
    paddingRight: 7,
};

export const rightBorderedCellStyle = {
    ...cellStyle,
    borderRightStyle: "solid",
    borderRightWidth: 1,
    borderRightColor: "#c9c9c9",
};

export const subHeaderCellStyle = {
    top: 52,
    padding: 7,
};

export const rightBorderedSubHeaderCellStyle = {
    ...subHeaderCellStyle,
    ...rightBorderedCellStyle
};