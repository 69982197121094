import React from "react";
import {useDispatch} from "react-redux";
import {Box, makeStyles, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import * as popups from "../../../store/ducks/popups.duck";
import {faUnlock} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const useStyles = makeStyles((theme) => ({
    demoDataWarningBlock: {
        color: "white",
        background: "#f64e60",
        padding: 20,
        borderRadius: "4px",
        animation: `$demoDataWarningBlockFadeAnimation 1000ms 3 ${theme.transitions.easing.easeInOut}`
    },
    "@keyframes demoDataWarningBlockFadeAnimation": {
        "0%": {
            opacity: 0.2,
        },
        "50%": {
            opacity: 1.0,
        },
        "100%": {
            opacity: 0.2,
        },
    },
}));

const DemoDataWarningBlock = ({brandInfo, intl}) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    return (
        <Box className={classes.demoDataWarningBlock}>
            <Box display="flex" flexDirection="row" alignItems="center">
                <Box flexGrow={1}>
                    <Typography variant="button">
                        <FormattedMessage id="SALES_ANALYSIS.DEMO.TEXT" />
                    </Typography>
                </Box>

                <Box ml={1}>
                    <button className="btn btn-primary btn-elevate" onClick={() => {
                        dispatch(popups.actions.showSendMessagePopup(
                            "Brand access request (demo block)",
                            intl.formatMessage({ id: "REQUEST.ACCESS.POPUP.TITLE" }),
                            brandInfo ? intl.formatMessage({ id: "REQUEST.BRAND.ACCESS.MESSAGE.TEMPLATE"}, { brandName: brandInfo.name }) : null
                        ));
                    }}>
                        <FontAwesomeIcon icon={faUnlock} size={"lg"} />
                    </button>
                </Box>
            </Box>
        </Box>
    );
};

export default injectIntl(DemoDataWarningBlock);
