import axios from "axios";

import { API_URL } from "./constants"

export function fetchIndustryRatingInfo(params) {
  return axios.get(`${API_URL}/industry/rating`, { params });
}

export function fetchIndustryInfo(params) {
  return axios.get(`${API_URL}/industry/info`, { params });
}

export function fetchIndustryMetricData(params) {
  return axios.get(`${API_URL}/industry/metric`, { params });
}

export function fetchIndustryBrandRatingInfo(params) {
  return axios.get(`${API_URL}/industry/brandRating`, { params });
}