import React from "react";
import {Box, makeStyles, Paper} from "@material-ui/core";
import { injectIntl, FormattedMessage } from "react-intl";
import _ from "lodash";
import ProgressingOverlay from "../progressing-overlay";
import DropdownAutocomplete from "../dropdown-autocomplete";
import IndustryDefinitionDropdown from "../industry-definition-dropdown";
import MetricNakedFilters from "../metric-naked-filters";

const useStyles = makeStyles(() => ({
    root: {
        "@supports (position: sticky)": {
            position: "sticky",
            top: "90px",
            zIndex: 1001, // Should be greater than ProgressingOverlay
        },
    },
    portlet: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        "@media (max-width: 1400px)": {
            flexDirection: "column",
        },
    },
    filtersWrapper: {
        padding: "10px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "right",
        flexGrow: 1,
        flexShrink: 0
    }
}));

const IndustryRatingFilters = ({ industries, locations, periods, months, lockedMonths, onMonthUnlockRequest, options, filters, onChange, isChanging, isLoading }) => {
    const classes = useStyles();

    const { industryDefinition } = filters;

    return (
        <Box className={classes.root} mb={2}>
            <Paper className={classes.portlet} elevation={10}>
                {(!isLoading && filters && filters.period) && (
                    <Box className={classes.filtersWrapper}>
                        {(industries && industries.length) && (
                            <Box mr={1}>
                                <DropdownAutocomplete
                                    label={<FormattedMessage id="INDUSTRY.DROPDOWN.TITLE" />}
                                    options={industries || []}
                                    isMultiValue={true}
                                    isClearable={true}
                                    value={_.filter(industries || [], (industry) => {
                                        return _.includes(filters.industryIds || [], industry.id);
                                    })}
                                    onChange={(value) => {
                                        onChange({
                                            industryIds: _.map(value, "id")
                                        });
                                    }}
                                />
                            </Box>
                        )}

                        <Box mr={2}>
                            <IndustryDefinitionDropdown
                                industryDefinition={industryDefinition}
                                onChange={(newIndustryDefinition) => {
                                    onChange({
                                        industryDefinition: newIndustryDefinition
                                    });
                                }}
                            />
                        </Box>

                        <MetricNakedFilters
                            locations={locations}
                            periods={periods}
                            months={months}
                            lockedMonths={lockedMonths}
                            onMonthUnlockRequest={onMonthUnlockRequest}
                            options={options}
                            filters={filters}
                            onChange={(filtersPortion) => {
                                onChange(filtersPortion);
                            }}
                            isChanging={isChanging}
                        />
                    </Box>
                )}

                <ProgressingOverlay isOpen={isLoading} />
            </Paper>
        </Box>
    );
};

export default injectIntl(IndustryRatingFilters);
