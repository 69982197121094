import React, {useState} from "react";
import {Paper, Box, Typography, makeStyles, Checkbox, Collapse, IconButton} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import EmojiObjectsOutlinedIcon from "@material-ui/icons/EmojiObjectsOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowsToDot,
    faBrain,
    faCube,
    faCubes,
    faFileArrowDown,
    faGroupArrowsRotate,
    faRepeat,
} from "@fortawesome/free-solid-svg-icons";
import ProgressingOverlay from "../../common/components/progressing-overlay";
import Tooltip from "../../common/components/tooltip";
import {FormattedMessage, injectIntl} from "react-intl";
import {useSelector} from "react-redux";
import { selectors } from "../../../_metronic/ducks/i18n";
import _ from "lodash";
import {convertValueToLocaleString} from "../../utils/utils";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        height: "100%",
    },
    collapseRoot: {
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
    collapse: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column"
    },
    collapseWrapper: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column"
    },
    collapseWrapperInner: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column"
    },
    chartWrapper: {
        flexGrow: 1,
    },
    toolbarButton: {
        width: "30px",
        height: "30px",
        padding: 0,
    },
    toolbarButtonIcon: {
        width: "20px",
        height: "20px",
    },
    toolbarButtonBadge: {
        width: "5px",
        height: "5px",
        position: "absolute",
        right: "4px",
        top: "4px",
        borderRadius: "50%",
        backgroundColor: "#bb3e53",

        animation: `$toolbarButtonBadgeAnimation 1000ms 3 ${theme.transitions.easing.easeInOut}`
    },
    "@keyframes toolbarButtonBadgeAnimation": {
        "0%": {
            opacity: 0.2,
        },
        "50%": {
            opacity: 1.0,
        },
        "100%": {
            opacity: 0.2,
        }
    },
    releaseNotesBadge: {
        width: "auto",
        height: "auto",
        padding: "3px",
        borderRadius: "5px",
        position: "absolute",
        right: 7,
        top: 7,
        backgroundColor: "#f64e60",
        color: "#ffffff"
    },
    dataFeatureBadge: {
        fontSize: "xx-small",
        fontWeight: "500",
        padding: "2px",
        borderWidth: "1px",
        borderRadius: "3px",
        borderStyle: "solid",
        borderColor: "#d0d0d0",
        color: "#8a8a8a",
        backgroundColor: "#fafafa",
        userSelect: "none"
    }
}));

export const ConclusionsTooltipButton = ({ conclusions, onTooltipOpen, onTooltipClose }) => {
    const classes = useStyles();

    const textConclusions = _.map(_.filter(conclusions, (conclusion) => conclusion.type === "text"), "conclusion");

    let isOpened = false;

    return (
        (textConclusions && textConclusions.length > 0) ? (
            <Tooltip
                arrow
                interactive
                title={textConclusions.join("\n")}
                onOpen={() => {
                    if (!isOpened) {
                        isOpened = true;
                        onTooltipOpen(conclusions);
                    }
                }}
                onClose={() => {
                    if (isOpened) {
                        isOpened = false;
                        onTooltipClose(conclusions);
                    }
                }}
            >
                <IconButton className={classes.toolbarButton} style={{cursor: "default"}}>
                    <EmojiObjectsOutlinedIcon className={classes.toolbarButtonIcon} mr={1} color="secondary" />
                    <div className={classes.toolbarButtonBadge} />
                </IconButton>
            </Tooltip>
        ) : (
            <></>
        )
    );
};

const StatValueElement = injectIntl(({type, stat, intl}) => {
    const language = useSelector(selectors.getSelectedLanguage);

    let formattedStatValue = "";
    if (stat.value) {
        formattedStatValue = convertValueToLocaleString(stat.value, intl, language, stat.options);
        if (stat.unit) {
            formattedStatValue += " " + stat.unit;
        }

        if (stat.deltaValue != null) {
            const formattedStatDeltaValue = convertValueToLocaleString(stat.deltaValue, intl, language, {
                style: (stat.options.style === "percent" ? "percentPoint" : "percent"),
                maximumFractionDigits: 1,
                minimumFractionDigits: 1,
                signDisplay: "always"
            });

            formattedStatValue += ` (${formattedStatDeltaValue})`;
        }
    }
    else {
        formattedStatValue = "N/A";
    }

    return (
        <Box display="flex" flexDirection="row" alignItems="center">
            <Box mr={0.5}>
                <span style={{color: stat.options.color}}>
                    <Typography variant="h6" color="inherit">▣</Typography>
                </span>
            </Box>
            <Typography variant="h6" color="textSecondary">{formattedStatValue}</Typography>
        </Box>
    );
});

const generateStatsTable = (type, stats, scope, viewMode, intl) => {
    const typeToTitleMap = {
        "sums": intl.formatMessage({ id: "STATS.TABLE.SUMS.TITLE" }),
        "averages": intl.formatMessage({ id: "STATS.TABLE.AVERAGES.TITLE" }),
    };
    const title = typeToTitleMap[type];

    const setStatDeltaValue = (stat, otherStat) => {
        const statValue = Number(stat.value);
        const otherStatValue = Number(otherStat.value);

        if (stat.options.style === "percent") {
            stat.deltaValue = (statValue - otherStatValue);
        }
        else {
            stat.deltaValue = ((statValue - otherStatValue) / otherStatValue);
        }
    };

    const setStatDeltaValues = (groupedStats) => {
        for (let i = 1; i < groupedStats.length; ++i) {
            if (scope === "brand" || scope === "industry") {
                setStatDeltaValue(groupedStats[0], groupedStats[i]);
            }
            else if (scope === "comparison") {
                setStatDeltaValue(groupedStats[i], groupedStats[0]);
            }
        }
    };

    const groupToStatsMap = _.groupBy(stats, "group");
    _.forEach(groupToStatsMap, (groupStats) => {
        if (groupStats.length >= 2) {
            if (groupStats.length === 2) {
                setStatDeltaValues(groupStats);
            }
            else {
                const groupLabelToStatsMap = _.groupBy(groupStats, "groupLabel");
                _.forEach(groupLabelToStatsMap, (groupLabelStats) => {
                    setStatDeltaValues(groupLabelStats);
                });
            }
        }
    });

    return (
        <Box>
            <Typography variant="h6" color="textSecondary">{title}</Typography>

            <Box ml={1}>
            {
                (viewMode === "auto" && stats.length <= 2) ? (
                    <Box display="flex" flexDirection="row">
                        {stats.map((stat, index) => (
                            <Box mr={4} key={index}>
                                <Typography variant="body1" color="textSecondary">{stat.label}</Typography>
                                <StatValueElement
                                    type={type}
                                    stat={stat}
                                />
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <Box display="flex" flexDirection="row">
                        {_.map(groupToStatsMap, (stats, group) => (
                            <Box display="flex" flexDirection="column" mr={4} key={group}>
                                {_.keys(groupToStatsMap).length > 1 && (
                                    <Typography variant="h6" color="textSecondary">{_.head(stats).groupLabel}</Typography>
                                )}

                                {stats.map((stat, index) => (
                                    <Box key={index}>
                                        <Typography variant="body1" color="textSecondary" style={{display: 'inline-block'}}>{stat.label}:&nbsp;</Typography>
                                        <StatValueElement
                                            type={type}
                                            stat={stat}
                                        />
                                    </Box>
                                ))}
                            </Box>
                        ))}
                    </Box>
                )
            }
            </Box>
        </Box>
    );
};

const ChartCard = ({
    title,
    subTitle,
    description,
    releaseNotes,
    scope,
    statsCardViewMode,
    sums,
    averages,
    buttons,
    conclusions,
    onConclusionsTooltipOpen,
    onConclusionsTooltipClose,
    dataFeatures,
    onExportButtonClickHandler,
    onNarratorGetMetricOpinionButtonClickHandler,
    toggleHelpDrawer,
    isLoading,
    intl,
    children,
}) => {
    const theme = useTheme();
    const classes = useStyles();

    const fasIconColor = theme.palette.primary.light;

    const [isExpanded, setIsExpanded] = useState(true);

    releaseNotes = {
        "new": intl.formatMessage({ id: "METRIC.RELEASE.NOTES.NEW" }),
        "updated": intl.formatMessage({ id: "METRIC.RELEASE.NOTES.UPDATED" })
    }[releaseNotes];

    const dataFeatureToTooltipPropertyMap = {
        "extrapolated_data": {
            abbreviation: "EXR",
            iconId: faCubes,
            textId: "DATA.FEATURES.EXTRAPOLATED.DATA",
        },
        "clean_data": {
            abbreviation: "RAW",
            iconId: faCube,
            textId: "DATA.FEATURES.CLEAN.DATA",
        },
        "absolute_values": {
            abbreviation: "ABS",
            iconId: faArrowsToDot,
            textId: "DATA.FEATURES.ABSOLUTE.VALUES",
        },
        "relative_values": {
            abbreviation: "RLT",
            iconId: faRepeat,
            textId: "DATA.FEATURES.RELATIVE.VALUES",
        },
        "index_values": {
            abbreviation: "IDX",
            iconId: faGroupArrowsRotate,
            textId: "DATA.FEATURES.INDEX.VALUES",
        },
    };

    const dataFeatureBadges = ((dataFeatures && dataFeatures.length > 0) ? (
        <Box display="flex" flexDirection="row" alignItems="center">
            {_.map(dataFeatures, (dataFeature, index) => (
                <Tooltip key={`${index}`} arrow title={
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <FontAwesomeIcon icon={dataFeatureToTooltipPropertyMap[dataFeature].iconId} size={"sm"} />
                        <Box ml={0.5}>
                            <Typography>
                                <FormattedMessage id={dataFeatureToTooltipPropertyMap[dataFeature].textId} />
                            </Typography>
                        </Box>
                    </Box>
                }>
                    <Box ml={index ? 1 : 0} className={classes.dataFeatureBadge}>
                        {dataFeatureToTooltipPropertyMap[dataFeature].abbreviation}
                    </Box>
                </Tooltip>
            ))}
        </Box>
    ) : null);

    return (
        <Box>
            <Paper className={classes.root}>
                {!!releaseNotes && (
                    <div className={classes.releaseNotesBadge}>
                        <Typography variant="body2">{releaseNotes}</Typography>
                    </div>
                )}

                <Box p={3} height="100%" display="flex" flexDirection="column">
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Box display="flex" flexDirection="row" alignItems="center" flexGrow={1}>
                            <Typography variant="h6">{title}</Typography>

                            {dataFeatureBadges && (
                                <Box ml={0.5} mr={0.5}>
                                    {dataFeatureBadges}
                                </Box>
                            )}
                        </Box>

                        {(buttons && buttons.length > 0) && (
                            <Box mr={0.5}>
                                {buttons.map((button, index) => (
                                    <Tooltip key={`${index}`} arrow title={button.tooltip}>
                                        <IconButton className={classes.toolbarButton} onClick={() => {
                                            button.onClick();
                                        }}>
                                            {button.icon}
                                        </IconButton>
                                    </Tooltip>
                                ))}
                            </Box>
                        )}

                        {onNarratorGetMetricOpinionButtonClickHandler && (
                            <Box mr={0.5}>
                                <Tooltip arrow title={<FormattedMessage id="GET.METRIC.OPINION.BUTTON.TOOLTIP" />}>
                                    <IconButton className={classes.toolbarButton} onClick={() => {
                                        onNarratorGetMetricOpinionButtonClickHandler();
                                    }}>
                                        <FontAwesomeIcon icon={faBrain} size={"sm"} color={fasIconColor} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}

                        {(conclusions && conclusions.length > 0) && (
                            <Box mr={0.5}>
                                <ConclusionsTooltipButton
                                    conclusions={conclusions}
                                    onTooltipOpen={onConclusionsTooltipOpen}
                                    onTooltipClose={onConclusionsTooltipClose}
                                />
                            </Box>
                        )}

                        {onExportButtonClickHandler && (
                            <Box mr={0.5}>
                                <Tooltip arrow title={<FormattedMessage id="DATA.EXPORT.BUTTON.TOOLTIP" />}>
                                    <IconButton className={classes.toolbarButton} onClick={() => {
                                        onExportButtonClickHandler();
                                    }}>
                                        <FontAwesomeIcon icon={faFileArrowDown} size={"sm"} color={fasIconColor} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}

                        {description && (
                            <Box mr={0.0}>
                                <Tooltip arrow title={<FormattedMessage id="CHART.INFO.BUTTON.TOOLTIP" />}>
                                    <IconButton className={classes.toolbarButton} onClick={() => {
                                        toggleHelpDrawer({
                                            isOpened: true,
                                            description: description
                                        });
                                    }}>
                                        <InfoOutlinedIcon className={classes.toolbarButtonIcon} color="primary" />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}

                        <Checkbox icon={<ExpandLessIcon />} checkedIcon={<ExpandMoreIcon />} checked={!isExpanded} onChange={() => {
                            setIsExpanded((prev) => !prev);
                        }}>
                        </Checkbox>
                    </Box>

                    <Box ml={1}>
                        <Typography variant="subtitle1" color="textSecondary">
                            {subTitle}
                        </Typography>
                    </Box>

                    <Box className={classes.collapseRoot}>
                        <Collapse className={classes.collapse} classes={{wrapper: classes.collapseWrapper, wrapperInner: classes.collapseWrapperInner}} in={isExpanded}>
                            {statsCardViewMode && (
                                <Box ml={1} mr={1}>
                                    {sums && !!sums.length && (
                                        generateStatsTable("sums", sums, scope, statsCardViewMode, intl)
                                    )}
                                    {averages && !!averages.length && (
                                        generateStatsTable("averages", averages, scope, statsCardViewMode, intl)
                                    )}
                                </Box>
                            )}
                            <Box mt={statsCardViewMode ? 1 : 0} className={classes.chartWrapper}>
                                {children}
                            </Box>
                        </Collapse>
                    </Box>
                </Box>
                <ProgressingOverlay isOpen={isLoading} />
            </Paper>
        </Box>
    );
};

export default injectIntl(ChartCard);
