import React from "react";
import { connect } from "react-redux";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    IconButton,
    Box,
    Typography,
    withStyles, TextField
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { FormattedMessage } from "react-intl";

import * as popups from "../../../store/ducks/popups.duck";
import * as narrator from "../../../store/ducks/narrator.duck";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const DialogActionsPadded = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    }
}))(DialogActions);

const NarratorPopup = ({ popupState, opinionData, opinionDataLoadState, hidePopup }) => {
    return (
        <Dialog
            open={!!popupState.isOpen}
            onClose={() => hidePopup()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6">
                        <FormattedMessage id="NARRATOR.POPUP.TITLE" />
                    </Typography>
                    <IconButton aria-label="close" onClick={() => hidePopup()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>

            <DialogContent dividers>
                {opinionDataLoadState.isLoading ? (
                    <Box display="flex" justifyContent="center">
                        <Typography variant="h6" color={"textSecondary"}>
                            <span style={{marginRight: "8px"}}>
                                <FontAwesomeIcon icon={faSpinner} spin={true} />
                            </span>

                            <FormattedMessage id="NARRATOR.OPINION.LOADING.PROMPT" />
                        </Typography>
                    </Box>
                ) : (
                    <Box>
                        <TextField
                            type="text"
                            name="message"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={10}
                            value={opinionData.opinion}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Box>
                )}
            </DialogContent>

            <DialogActionsPadded>
                <Button
                    onClick={() => hidePopup()}
                    color="primary"
                    variant="contained"
                >
                    OK
                </Button>
            </DialogActionsPadded>
        </Dialog>
    );
};

const mapStateToProps = (state) => ({
    popupState: popups.selectors.getNarratorPopupState(state),
    opinionData: narrator.selectors.getOpinionData(state),
    opinionDataLoadState: narrator.selectors.getOpinionDataLoadState(state),
});

const mapDispatchToProps = (dispatch) => ({
    hidePopup: () => dispatch(popups.actions.hideNarratorPopup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NarratorPopup);
