import React from "react";
import {
    Box,
    Paper,
    Typography,
    Divider,
    makeStyles,
} from "@material-ui/core";
import { getLogoImageUrl } from "../../../utils/utils";
import Tooltip from "../tooltip";

import BusinessIcon from '@material-ui/icons/Business';
import CategoryIcon from '@material-ui/icons/Category';

import {FormattedMessage, injectIntl} from "react-intl";
import _ from "lodash";
import Badge from "../badge";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        alignItems: "inherit",
        textAlign: "left",
        textTransform: "none",
        userSelect: "text",
        position: "relative"
    },

    paper: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },

    logo: {
        width: "100px",
        height: "100px"
    },

    title: {
        fontWeight: "bold",
        fontSize: "18px",
        color: "#262A40",
        textDecoration: "none",
    },

    countryImage: {
        width: "20px"
    },

    text: {
        fontSize: "14px",
        color: "#7e83a0",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },

    icon: {
        width: "15px",
        height: "15px",
    },
}));

export const generateMccInfosTooltipTitle = (mccInfos) => {
    return (
        <span style={{ whiteSpace: "pre-line", display: "inline-block", maxHeight: 300, overflow: "auto" }}>{mccInfos.map((mccInfo) => `${mccInfo.mcc}: ${mccInfo.category}`).join('\n')}</span>
    );
}

const IndustryCard = ({
    industryInfo,
    countryInfo,
    ...props
 }) => {
    const { intl } = props;
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Paper elevation={5} className={classes.paper}>
                <Box display="flex" flexDirection="column" flexGrow={1} height="100%">
                    <Box display="flex" flexDirection="column">
                        <Box p={3}>
                            <Box className={classes.logo}>
                                <img
                                    src={getLogoImageUrl(industryInfo.logo)}
                                    alt={industryInfo.name}
                                    width="100%"
                                />
                            </Box>
                        </Box>

                        <Divider />

                        <Box p={3}>
                            <Typography className={classes.title}>{industryInfo.name}</Typography>
                            <Box mt={1}>
                                <Typography className={classes.text}>{industryInfo.description}</Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box pl={3} pr={3} pb={3} mt={"auto"}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            {industryInfo.isDemo && (
                                <Box mr={1}>
                                    <Badge color={"green"} text={<FormattedMessage id="INDUSTRY.DEMO.BADGE.TEXT" />} />
                                </Box>
                            )}
                        </Box>

                        <Box mt={1}>
                            {!!industryInfo.brandCount && (
                                <Box display="flex" flexDirection="row" alignItems="center" mr={1.5}>
                                    <Box mr={0.5}>
                                        <BusinessIcon className={classes.icon} color="primary" />
                                    </Box>
                                    <Typography className={classes.text}>{intl.formatMessage({ id: "INDUSTRY.BRANDS.PROMPT.TEMPLATE"}, { brandCount: industryInfo.brandCount })}</Typography>
                                </Box>
                            )}

                            {_.get(industryInfo, "mccInfos.length") > 0 && (
                                <Tooltip
                                    arrow
                                    interactive
                                    title={generateMccInfosTooltipTitle(industryInfo.mccInfos)}
                                >
                                    <Box display="flex" flexDirection="row" alignItems="center" mr={1.5}>
                                        <Box mr={0.5}>
                                            <CategoryIcon className={classes.icon} color="primary" />
                                        </Box>
                                        <Typography className={classes.text}>{intl.formatMessage({ id: "INDUSTRY.MCC.COUNT.HINT.TEMPLATE" }, { mccCount: industryInfo.mccInfos.length })}</Typography>
                                    </Box>
                                </Tooltip>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
};

export default injectIntl(IndustryCard);
