import {createAction, handleActions} from "redux-actions";
import { createSelector } from "reselect";
import {put, takeEvery, takeLatest} from "redux-saga/effects";
import * as statsCrud from "../../crud/stats.crud";
import { createActionName, wrapSafely } from "./utils";
import _ from "lodash";
import * as auth from "./auth.duck";

const TAG = "STATS";

export const actions = {
    requestDatabaseStats: createAction(createActionName(TAG, "requestDatabaseStats")),
    fillDatabaseStats: createAction(createActionName(TAG, "fillDatabaseStats")),

    requestDataPartnerStats: createAction(createActionName(TAG, "requestDataPartnerStats"), (partnerId, month) => ({
        partnerId,
        month,
    })),
    fillDataPartnerStats: createAction(createActionName(TAG, "fillDataPartnerStats")),

    requestDataPartnerStatsExport: createAction(createActionName(TAG, "requestDataPartnerStatsExport"), (partnerId, month) => ({
        partnerId,
        month,
    })),
};

const defaultState = {
    databaseStats: undefined,
    isDatabaseStatsLoading: false,
    isDatabaseStatsLoaded: false,

    dataPartnerStats: undefined,
    isDataPartnerStatsLoading: false,
    isDataPartnerStatsLoaded: false,
};

const initialState = _.clone(defaultState);

export const reducer = handleActions(
    {
        [actions.requestDatabaseStats]: (state) => {
            return {
                ...state,
                isDatabaseStatsLoading: true,
                isDatabaseStatsLoaded: false,
            };
        },

        [actions.fillDatabaseStats]: (state, { payload: { databaseStats } }) => {
            return {
                ...state,
                databaseStats: databaseStats,
                isDatabaseStatsLoading: false,
                isDatabaseStatsLoaded: true,
            };
        },

        [actions.requestDataPartnerStats]: (state) => {
            return {
                ...state,
                isDataPartnerStatsLoading: true,
                isDataPartnerStatsLoaded: false,
            };
        },

        [actions.fillDataPartnerStats]: (state, { payload: { dataPartnerStats } }) => {
            return {
                ...state,
                dataPartnerStats: dataPartnerStats,
                isDataPartnerStatsLoading: false,
                isDataPartnerStatsLoaded: true,
            };
        },

        [auth.actionTypes.LogoutUser]: () => {
            console.log(`LogoutUser in ${TAG}`);
            return _.clone(defaultState);
        }
    },
    initialState
);

export function* saga() {
    yield takeLatest(actions.requestDatabaseStats, wrapSafely(function*() {
        const databaseStats = (yield statsCrud.getDatabaseStats()).data;
        yield put(actions.fillDatabaseStats({
            databaseStats
        }));
    }, TAG, "requestDatabaseStats"));

    yield takeLatest(actions.requestDataPartnerStats, wrapSafely(function*({ payload: { partnerId, month } }) {
        const dataPartnerStats = (yield statsCrud.getDataPartnerStats(partnerId, month)).data;
        yield put(actions.fillDataPartnerStats({
            dataPartnerStats
        }));
    }, TAG, "requestDataPartnerStats"));

    yield takeEvery(actions.requestDataPartnerStatsExport, wrapSafely(function*({ payload: { partnerId, month } }) {
        const response = yield statsCrud.requestDataPartnerStatsExport(partnerId, month);

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response.data]));
        link.setAttribute('download', `Report-${month}.xlsx`);
        link.click();
    }, TAG, "requestDataPartnerStatsExport"));
}

const getStats = (state) => state.stats;

export const selectors = {
    getDatabaseStats: createSelector(getStats, (stats) => stats.databaseStats),
    getDatabaseStatsLoadState: createSelector(getStats, (stats) => {
        return {
            isLoading: stats.isDatabaseStatsLoading,
            isLoaded: stats.isDatabaseStatsLoaded
        };
    }),

    getDataPartnerStats: createSelector(getStats, (stats) => stats.dataPartnerStats),
    getDataPartnerStatsLoadState: createSelector(getStats, (stats) => {
        return {
            isLoading: stats.isDataPartnerStatsLoading,
            isLoaded: stats.isDataPartnerStatsLoaded
        };
    }),
};
