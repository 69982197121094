import React, {useEffect} from "react";
import {Box, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {connect, useDispatch, useSelector} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";

import {LayoutSubheader} from "../../../_metronic";

import * as stats from "../../store/ducks/stats.duck";
import RefreshProgressableButton from "../../common/components/refresh-progressable-button";

const DatabaseStatistics = (props) => {
    const { databaseStats = [] } = props;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(stats.actions.requestDatabaseStats());
        // eslint-disable-next-line
    }, []);

    const { isLoading: isDatabaseStatsLoading } = useSelector(stats.selectors.getDatabaseStatsLoadState);

    return (
        <Box className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1">
            <LayoutSubheader
                title={<FormattedMessage id="DATABASE.STATISTICS.CAPTION"/>}
            />
            <Box mt={3} mb={2} flexGrow={1} className="kt-login__wrapper">
                <Box mb={1}>
                    <RefreshProgressableButton
                        disabled={isDatabaseStatsLoading}
                        isProgressing={isDatabaseStatsLoading}
                        onClick={() => {
                            dispatch(stats.actions.requestDatabaseStats());
                        }}
                    >
                    </RefreshProgressableButton>
                </Box>

                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Category</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Value</TableCell>
                            <TableCell>Calculation start</TableCell>
                            <TableCell>Calculation finish</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {databaseStats.map((databaseStat, index) => (
                            <TableRow key={index}>
                                <TableCell>{databaseStat.category}</TableCell>
                                <TableCell>{databaseStat.name}</TableCell>
                                <TableCell><pre style={{margin:"0 0 0 0"}}>{databaseStat.value}</pre></TableCell>
                                <TableCell>{databaseStat.calculation_start_time}</TableCell>
                                <TableCell>{databaseStat.calculation_finish_time}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => ({
    databaseStats: stats.selectors.getDatabaseStats(state),
});

export default injectIntl(connect(mapStateToProps, null)(DatabaseStatistics));
