import React from "react";
import {
    Box,
    Typography,
    Divider,
    makeStyles, Button,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";

import ProgressingOverlay from "../../common/components/progressing-overlay";
import IndustryTooltip from "../../common/components/industry-tooltip";
import { generateCatalogUrl, getLogoImageUrl } from "../../utils/utils";
import InfoIcon from "@material-ui/icons/Info";
import {Link} from "react-router-dom";
import Badge from "../../common/components/badge";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
    },
    wrapBlock: {
        "@media (max-width: 768px)": {
            flexDirection: "column",
            "& > div": {
                "&:first-child": {
                    marginBottom: 8,
                },
            },
        },
    },

    divider: {
        "@media (max-width: 596px)": {
            display: "none",
        },
    },
    info: {
        "@media (max-width: 596px)": {
            marginLeft: 0,
        },
        "@media (max-width: 450px)": {
            justifyContent: "center",
        },
    },
}));

const Header = ({
    industryInfo,
    isIndustryInfoLoaded,
}) => {
    const classes = useStyles();

    return (
        <Box width="100%" className={classes.root}>
            <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
                className={classes.wrapBlock}
            >
                {isIndustryInfoLoaded ? (
                    <>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexWrap="wrap"
                        >
                            {industryInfo.industry.logo && (
                                <Box maxWidth={50} marginRight="20px">
                                    <img
                                        alt={industryInfo.industry.name}
                                        src={getLogoImageUrl(industryInfo.industry.logo)}
                                        style={{ width: "100%" }}
                                    />
                                </Box>
                            )}
                            <Box color="#434349">
                                <Typography variant="h6">{industryInfo.industry.name}</Typography>
                            </Box>

                            <Box p={1} display="flex" alignItems="center">
                                <IndustryTooltip
                                    countryInfo={industryInfo.country}
                                    industryInfo={industryInfo.industry}
                                >
                                    <InfoIcon color="primary" />
                                </IndustryTooltip>
                            </Box>

                            {industryInfo.industry.isDemo && (
                                <Box mr={1}>
                                    <Badge color={"green"} text={<FormattedMessage id="INDUSTRY.DEMO.BADGE.TEXT" />} />
                                </Box>
                            )}

                            <Divider
                                orientation="vertical"
                                flexItem
                                className={classes.divider}
                            />

                            <Box
                                display="flex"
                                flexWrap="wrap"
                                ml={1}
                                p={1}
                                className={classes.info}
                            >
                                {industryInfo.country && (
                                    <Box display="flex" mr={2}>
                                        <Typography color="textSecondary">
                                            <FormattedMessage id="INPUT.REGION" />:
                                        </Typography>
                                        <Box ml={0.5}>
                                            <Link
                                                to={generateCatalogUrl({
                                                    region: industryInfo.country.code
                                                })}
                                            >
                                                <Typography color="primary">{industryInfo.country.code.toUpperCase()}</Typography>
                                            </Link>
                                        </Box>
                                    </Box>
                                )}
                                {industryInfo.industry && (
                                    <Box display="flex">
                                        <Typography color="textSecondary">
                                            <FormattedMessage id="SALES_ANALYSIS.INDUSTRY" />:
                                        </Typography>
                                        <Box ml={0.5}>
                                            <Link
                                                to={generateCatalogUrl({
                                                    industry: industryInfo.industry.id
                                                })}
                                            >
                                                <Typography color="primary">{industryInfo.industry.name}</Typography>
                                            </Link>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                            <Divider
                                orientation="vertical"
                                flexItem
                                className={classes.divider}
                            />
                            <Box ml={1} p={1}>
                                <Link to={generateCatalogUrl()}>
                                    <Button variant="contained" color="primary">
                                        <FormattedMessage id="CATALOG.CAPTION" />
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                    </>
                ) : (
                    <></>
                )}
            </Box>
            <ProgressingOverlay isOpen={!isIndustryInfoLoaded} />
        </Box>
    );
};

export default Header;
