import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import * as brands from "./ducks/brands.duck";
import * as catalog from "./ducks/catalog.duck";
import * as cart from "./ducks/cart.duck";
import * as purchases from "./ducks/purchases.duck";
import * as comparison from "./ducks/comparison.duck";
import * as industries from "./ducks/industries.duck";
import * as stats from "./ducks/stats.duck";
import * as popups from "./ducks/popups.duck";
import * as alert from "./ducks/alert.duck";
import * as admin from "./ducks/admin.duck";
import * as narrator from "./ducks/narrator.duck";

import { metronic } from "../../_metronic";

const appReducer = combineReducers({
  auth: auth.reducer,
  brands: brands.reducer,
  catalog: catalog.reducer,
  cart: cart.reducer,
  purchases: purchases.reducer,
  comparison: comparison.reducer,
  industries: industries.reducer,
  stats: stats.reducer,
  popups: popups.reducer,
  alert: alert.reducer,
  admin: admin.reducer,
  narrator: narrator.reducer,

  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
});

export const rootReducer = (state, action) => {
  if (action.type === auth.actionTypes.LoginUser) {
    console.log("rootReducer - LoginUser");
  }

  if (action.type === auth.actionTypes.LogoutUser) {
    console.log("rootReducer - LogoutUser");
  }

  return appReducer(state, action);
};

export function* rootSaga() {
  yield all([
    auth.saga(),
    brands.saga(),
    catalog.saga(),
    cart.saga(),
    purchases.saga(),
    comparison.saga(),
    industries.saga(),
    stats.saga(),
    popups.saga(),
    alert.saga(),
    admin.saga(),
    narrator.saga(),
  ]);
}
