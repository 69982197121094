import React, {useState} from "react";
import {Box, Checkbox, Collapse, makeStyles, Paper, Typography} from "@material-ui/core";
import ProgressingOverlay from "../progressing-overlay";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {FormattedMessage, injectIntl} from "react-intl";
import {createTimeRange, formatTimeRangeString} from "../../../utils/utils";
import {connect, useSelector} from "react-redux";
import {selectors} from "../../../../_metronic/ducks/i18n";
import _ from "lodash";
import moment from "moment";
import {Link} from "react-router-dom";
import BusinessIcon from "@material-ui/icons/Business";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import StoreIcon from "@material-ui/icons/Store";
import * as auth from "../../../store/ducks/auth.duck";
import MessageTitle from "../message-title";

const useStyles = makeStyles(() => ({
    root: {
        position: "relative",
        height: "100%",
    },
    collapseRoot: {
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
    collapse: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column"
    },
    collapseWrapper: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column"
    },
    collapseWrapperInner: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column"
    },
    icon: {
        width: "15px",
        height: "15px",
    },
}));

const FiltersAdditionalInfo = ({ brandInfo, locations, filters, isLoading, onBrandLinkClick, onLocationLinkClick, onPosLinkClick, user, ...props }) => {
    const classes = useStyles();
    const { intl } = props;
    const language = useSelector(selectors.getSelectedLanguage);

    const [isExpanded, setIsExpanded] = useState(true);

    const { salesChannel, locationId, posId} = filters;

    locations = (locations[salesChannel] || []);
    const location = _.find(locations, (location) => location.id === locationId);

    const poses = ((location || {}).poses || []);
    const pos = poses.find((pos) => pos.id === posId);

    const salesChannelTitles = {
        offline: intl.formatMessage({ id: "OFFLINE.SALES.CHANNEL.TITLE" }),
        online: intl.formatMessage({ id: "ONLINE.SALES.CHANNEL.TITLE" }),
        all: intl.formatMessage({ id: "ALL.SALES.CHANNEL.TITLE" }),
    };

    let title;
    if (location) {
        title = intl.formatMessage({ id: "LOCATION.ADDITIONAL.INFO.TITLE.TEMPLATE" }, { salesChannel: salesChannelTitles[salesChannel] });
    }
    if (pos) {
        title = intl.formatMessage({ id: "POS.ADDITIONAL.INFO.TITLE.TEMPLATE" }, { salesChannel: salesChannelTitles[salesChannel] });
    }

    return (
        (title ? (
            <Box mb={2}>
                <Paper className={classes.root} >
                    <Box p={2}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            {brandInfo && (
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <Box mr={0.5} display="flex" flexDirection="row" alignItems="center">
                                        <BusinessIcon className={classes.icon} color="primary" />
                                    </Box>

                                    <Link to="#" onClick={() => {
                                        onBrandLinkClick();
                                    }}>
                                        <Typography>{brandInfo.name}</Typography>
                                    </Link>
                                </Box>
                            )}

                            {location && (
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <Box ml={1} mr={1}>
                                        <Typography>-></Typography>
                                    </Box>

                                    <Box mr={0.5} display="flex" flexDirection="row" alignItems="center">
                                        <LocationOnIcon className={classes.icon} color="primary" />
                                    </Box>

                                    <Link to="#" onClick={() => {
                                        onLocationLinkClick();
                                    }}>
                                        <Typography>{location.name}</Typography>
                                    </Link>
                                </Box>
                            )}

                            {pos && (
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <Box ml={1} mr={1}>
                                        <Typography>-></Typography>
                                    </Box>

                                    <Box mr={0.5} display="flex" flexDirection="row" alignItems="center">
                                        <StoreIcon className={classes.icon} color="primary" />
                                    </Box>

                                    <Link to="#" onClick={() => {
                                        onPosLinkClick();
                                    }}>
                                        <Typography>{pos.name}</Typography>
                                    </Link>
                                </Box>
                            )}
                        </Box>

                        <Box mt={1}>
                            <MessageTitle severity="warning">
                                <Typography><FormattedMessage id="LOCATION.OR.POS.DATA.DISCLAIMER" /></Typography>
                            </MessageTitle>
                        </Box>

                        {(user && user.capabilities["location_filter"] && user.capabilities["pos_filter"]) && (
                            <Box height="100%" display="flex" flexDirection="column">
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <Box flexGrow={1}>
                                        <Typography variant="h6">{title}</Typography>
                                    </Box>
                                    <Checkbox icon={<ExpandLessIcon />} checkedIcon={<ExpandMoreIcon />} checked={!isExpanded} onChange={() => {
                                        setIsExpanded((prev) => !prev);
                                    }}>
                                    </Checkbox>
                                </Box>
                                <Box className={classes.collapseRoot}>
                                    <Collapse className={classes.collapse} classes={{wrapper: classes.collapseWrapper, wrapperInner: classes.collapseWrapperInner}} in={isExpanded}>
                                        {location && location.poses && !pos && (
                                            <Typography>{`${intl.formatMessage({ id: "LOCATION.ADDITIONAL.INFO.POS.COUNT.PROMPT" })}${location.poses.length}`}</Typography>
                                        )}

                                        {pos && pos.description && (
                                            <Typography>{`${intl.formatMessage({ id: "POS.ADDITIONAL.INFO.DESCRIPTION.PROMPT" })}${pos.description}`}</Typography>
                                        )}

                                        {pos && pos.site && (
                                            <Typography>{`${intl.formatMessage({ id: "POS.ADDITIONAL.INFO.SITE.PROMPT" })}${pos.site}`}</Typography>
                                        )}

                                        {pos && pos.email && (
                                            <Typography>{`${intl.formatMessage({ id: "POS.ADDITIONAL.INFO.EMAIL.PROMPT" })}${pos.email}`}</Typography>
                                        )}

                                        {pos && pos.address && (
                                            <Typography>{`${intl.formatMessage({ id: "POS.ADDITIONAL.INFO.ADDRESS.PROMPT" })}${pos.address}`}</Typography>
                                        )}

                                        {pos && pos.phone && (
                                            <Typography>{`${intl.formatMessage({ id: "POS.ADDITIONAL.INFO.PHONE.PROMPT" })}${pos.phone}`}</Typography>
                                        )}

                                        {pos && pos.first_date_with_metrics && pos.last_date_with_metrics && (
                                            <Typography>{`${intl.formatMessage({ id: "POS.ADDITIONAL.INFO.DATES_WITH_METRICS.PROMPT" })}${formatTimeRangeString(createTimeRange(moment.utc(pos.first_date_with_metrics).toDate(), moment.utc(pos.last_date_with_metrics).toDate()), true, language)}`}</Typography>
                                        )}
                                    </Collapse>
                                </Box>
                            </Box>
                        )}

                        <ProgressingOverlay isOpen={isLoading} />
                    </Box>
                </Paper>
            </Box>
        ) : null)
    );
};

const mapStateToProps = (state) => ({
    user: auth.selectors.getUser(state),
});

export default injectIntl(connect(mapStateToProps, null)(FiltersAdditionalInfo));