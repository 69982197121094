import React, {useEffect} from "react";
import { Box, Paper, IconButton } from "@material-ui/core";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as _ from "lodash";
import { FormattedMessage } from "react-intl";

import * as purchases from "../../../store/ducks/purchases.duck";
import { parseParams, formatMonthYearString } from "../../../utils/utils";
import { selectors } from "../../../../_metronic/ducks/i18n";
import SortByAlphaIcon from "@material-ui/icons/SortByAlpha";
import RefreshProgressableButton from "../../../common/components/refresh-progressable-button";

const formattedMonthOrders = {
    asc: <FormattedMessage id="SORTING.OLD_FIRST" />,
    desc: <FormattedMessage id="SORTING.NEW_FIRST" />,
};

const Filters = ({ onChange, isLoading }) => {
    const params = parseParams();

    const language = useSelector(selectors.getSelectedLanguage);

    const purchasedBrandCount = useSelector(purchases.selectors.getPurchasedBrandCount);
    const months = useSelector(purchases.selectors.getPurchasedMonths);

    const selectedMonth = params.month || "all";
    const selectedMonthOrder = params.monthOrder || "desc";
    const selectedBrandOrder = params.brandOrder || "asc";

    useEffect(() => {
        if (!params.month) {
            const newParams = {
                monthOrder: selectedMonthOrder,
                brandOrder: selectedBrandOrder
            };
            onChange(newParams);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Paper>
            <Box p={2} display="flex" flexWrap="wrap" alignItems="center" justifyContent="space-between">
                <Box display="flex" flexWrap="wrap" alignItems="center">
                    <Box mr={1}>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic">
                                {selectedMonth === "all" ? (<FormattedMessage id="SORTING.ALL_MONTHS" />) : (formatMonthYearString(selectedMonth, language))}
                            </Dropdown.Toggle>

                            <Dropdown.Menu flip={false} style={{ maxHeight: 500, overflow: "auto" }}>
                                <Dropdown.Item
                                    active={selectedMonth === "all"}
                                    onClick={() => onChange({
                                        month: null
                                    })}
                                >
                                    <FormattedMessage id="SORTING.ALL_MONTHS" />
                                </Dropdown.Item>
                                {months.map((item, i) => (
                                    <Dropdown.Item
                                        key={i}
                                        active={item === selectedMonth}
                                        onClick={() => onChange({
                                            month: item
                                        })}
                                    >
                                        {formatMonthYearString(item, language)}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Box>

                    <Box mr={1}>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic">
                                {_.get(formattedMonthOrders, selectedMonthOrder)}
                            </Dropdown.Toggle>

                            <Dropdown.Menu flip={false} style={{ maxHeight: 500, overflow: "auto" }}>
                                {Object.entries(formattedMonthOrders).map(([key, title], i) => (
                                    <Dropdown.Item
                                        key={i}
                                        active={key === selectedMonthOrder}
                                        onClick={() => onChange({
                                            monthOrder: key
                                        })}
                                    >
                                        {title}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Box>

                    <Box mr={1}>
                        <IconButton
                            onClick={() => onChange({
                                brandOrder: (selectedBrandOrder === "asc" ? "desc" : "asc")
                            })}
                        >
                            <SortByAlphaIcon style={{ cursor: "pointer", transform: ((selectedBrandOrder === "asc") ? "rotate(0)" : "rotate(180deg)"), transition: "all 0.2s linear" }} />
                        </IconButton>
                    </Box>

                    <Box>
                        <RefreshProgressableButton
                            disabled={isLoading}
                            isProgressing={isLoading}
                            onClick={() => onChange({
                                forceUpdate: true
                            })}
                        >
                        </RefreshProgressableButton>
                    </Box>
                </Box>

                <Box>
                    <FormattedMessage id="PURCHASED.BRANDS.INFO.TEMPLATE" values={{ brandCount: purchasedBrandCount, monthCount: months.length }} />
                </Box>
            </Box>
        </Paper>
    );
};

export default Filters;
