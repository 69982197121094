export const createActionName = (tag, name) => {
    return `[${tag}::${name}]`;
};

export const wrapSafely = (closure, tag, name) => {
    const actionName = createActionName(tag, name);

    return function*(params) {
        try {
            console.log(new Date() + `: ${actionName} ->`);

            if (params.payload && params.payload.onBegin) {
                params.payload.onBegin();
            }

            yield closure(params);

            if (params.payload && params.payload.onComplete) {
                params.payload.onComplete();
            }

            console.log(new Date() + `: ${actionName} <-`);
        }
        catch (error) {
            console.error(new Date() + `: ${actionName} - `, error);
        }
    }
};

export const getStateHashKey = (filters) => {
    return JSON.stringify(filters);
};