import React, {useRef} from "react";
import _ from "lodash";
import {connect, useDispatch, useSelector} from "react-redux";
import {Box} from "@material-ui/core";
import { Bar, Line } from "react-chartjs-2";
import * as ChartJS from "chart.js";
import {FormattedMessage, injectIntl} from "react-intl";

import * as brands from "../../store/ducks/brands.duck";
import * as comparison from "../../store/ducks/comparison.duck";
import * as industries from "../../store/ducks/industries.duck";
import * as auth from "../../store/ducks/auth.duck";
import * as narrator from "../../store/ducks/narrator.duck";
import ChartCard from "./chart-card";
import MessageTitle from "../../common/components/message-title";

import { selectors } from "../../../_metronic/ducks/i18n";
import {
    formatDateTooltipString,
    formatWeekTooltipString,
    formatMonthYearString,
    convertValueToLocaleString
} from "../../utils/utils";
import {color} from "chart.js/helpers";

const chartComponents = {
    bar: {
        component: Bar
    },
    line: {
        component: Line
    },
    undefined: () => null,
};

export const prepareChartData = (data, unit, scope, options) => {
    let result = null;

    if (data && (data.datasets || data.groupMetricDatas)) {
        const datasets = (data.datasets ? data.datasets.map(
            ({data: datasetData, settings = {}, ...dataset}) => ({
                ...dataset,
                ...settings,
                datasetType: "values",
                data: datasetData.map((item) => _.get(item, "value", null)),
                dates: datasetData.map((item) => _.get(item, "date", null)),
                period: data.period,
                scope,
            })
        ) : null);

        if (_.get(options, "growthBarChartOverlay.display") && (scope === "brand" || scope === "industry")) {
            if (datasets && datasets.length === 2) {
                const growthBarChartOverlayStyle = _.get(options, "growthBarChartOverlay.style", "percent");

                const reportDataset = _.head(datasets);
                const baseDataset = _.last(datasets);

                const data = _.map(reportDataset.data, (reportDatasetItem, index) => {
                    let result = null;

                    let baseDatasetItem = baseDataset.data[index];
                    if (reportDatasetItem !== null && baseDatasetItem !== null) {
                        reportDatasetItem = Number(reportDatasetItem);
                        baseDatasetItem = Number(baseDatasetItem);

                        if (growthBarChartOverlayStyle === "percent") {
                            if (baseDatasetItem) {
                                result = (reportDatasetItem - baseDatasetItem) / baseDatasetItem;
                            }
                        }
                        else if (growthBarChartOverlayStyle === "percentPoint") {
                            result = (reportDatasetItem - baseDatasetItem);
                        }
                        else {
                            console.assert("Unsupported growthBarChartOverlayStyle!");
                        }
                    }

                    return result;
                });

                const growthScaleId = "growthBarChartScale";

                const growthDatasetsLegendItemColors = {
                    positive: "#24693d",
                    negative: "#ff0000",
                    alpha: 0.2,
                };

                datasets.push({
                    ...reportDataset,
                    data,
                    yAxisID: growthScaleId,
                    datasetType: "growthBarChartOverlay",
                    type: "bar",
                    label: options.intl.formatMessage({ id: "GROWTH" }),
                    backgroundColor: (ctx) => {
                        return (data[ctx.dataIndex] >= 0 ? color(growthDatasetsLegendItemColors.positive).alpha(growthDatasetsLegendItemColors.alpha).rgbString() : color(growthDatasetsLegendItemColors.negative).alpha(growthDatasetsLegendItemColors.alpha).rgbString());
                    },
                    borderSkipped: true,
                });

                const maxAbsScaleValue = Math.max(Math.abs(_.minBy(data)), Math.abs(_.maxBy(data)));

                const enableGrowthBarChartScale = (chartOptions, shouldEnable) => {
                    chartOptions.scales[growthScaleId] = {
                        type: "linear",
                        position: "right",
                        min: -1 * maxAbsScaleValue,
                        max: +1 * maxAbsScaleValue,
                        ticks: {
                            callback: (value) => {
                                return convertValueToLocaleString(value, options.intl, options.language, {
                                    style: growthBarChartOverlayStyle,
                                    maximumFractionDigits: 0,
                                    minimumFractionDigits: 0,
                                });
                            }
                        },
                        grid: {
                            drawOnChartArea: false
                        },
                        display: shouldEnable,
                    };

                    const annotations = _.filter(chartOptions.plugins.annotation.annotations, (annotation) => annotation.scaleID !== growthScaleId);
                    if (shouldEnable) {
                        annotations.push(createLineAnnotation(growthScaleId, 0, 1));
                    }
                    chartOptions.plugins.annotation = {
                        ...chartOptions.plugins.annotation,
                        annotations
                    };
                };

                enableGrowthBarChartScale(options, true);

                options.plugins.legend = {
                    onClick: (event, legendItem, legend) => {
                        const dataset = legend.chart.data.datasets[legendItem.datasetIndex];
                        if (dataset.datasetType === "growthBarChartOverlay") {
                            enableGrowthBarChartScale(legend.chart.options, legend.chart.getDatasetMeta(legendItem.datasetIndex).hidden);
                        }

                        ChartJS.Chart.defaults.plugins.legend.onClick(event, legendItem, legend);
                    }
                };

                options.plugins.chartLegendDrawer = {
                    beforeLegendItemDraw: (chart, legendItem) => {
                        const dataset = chart.data.datasets[legendItem.datasetIndex];
                        if (dataset.datasetType === "growthBarChartOverlay") {
                            const context = legendItem.pointStyle.getContext("2d");

                            context.clearRect(0, 0, legendItem.pointStyle.width, legendItem.pointStyle.height);

                            context.fillStyle = color(growthDatasetsLegendItemColors.negative).alpha(0.3).rgbString();
                            context.fillRect(0, 0, legendItem.pointStyle.width / 2, legendItem.pointStyle.height);

                            context.fillStyle = color(growthDatasetsLegendItemColors.positive).alpha(0.3).rgbString();
                            context.fillRect(legendItem.pointStyle.width / 2, 0, legendItem.pointStyle.width / 2, legendItem.pointStyle.height);

                            context.strokeStyle = growthDatasetsLegendItemColors.positive;
                            context.lineWidth = 1;
                            context.strokeRect(0, 0, legendItem.pointStyle.width, legendItem.pointStyle.height);
                        }
                    },
                };
            }
        }

        _.forEach(datasets, (dataset) => {
            dataset.datasets = _.filter(datasets, (dataset) => dataset.datasetType === "values");
        });

        const prepareStats = (stats, options) => {
            return stats.map((stat) => {
                let refDataset = _.find(data.datasets, (dataset) => (dataset.label === stat.groupLabel || dataset.label.includes(stat.label)));
                if (!refDataset) {
                    const groupMetricData = _.find(data.groupMetricDatas, (groupMetricData) => groupMetricData.label === stat.groupLabel);
                    refDataset = _.find(groupMetricData.datasets, (dataset) => (dataset.label === stat.groupLabel || dataset.label.includes(stat.label)));
                }

                return {
                    ...stat,
                    unit,
                    options: {
                        ...options,
                        color: refDataset.settings.backgroundColor
                    }
                };
            });
        };

        let sums = [];
        if (data.sums && options.sums && options.sums.display) {
            sums = prepareStats(data.sums, options.sums);
        }

        let averages = [];
        if (data.averages && options.averages && options.averages.display) {
            averages = prepareStats(data.averages, options.averages);
        }

        let conclusions = [];
        if (data.conclusions && options.conclusions && options.conclusions.display) {
            conclusions = data.conclusions;
        }

        result = {
            scope,
            labels: data.labels,
            datasets,
            sums,
            averages,
            conclusions,
            period: data.period,
            options
        };
    }

    return result;
};

const createLineAnnotation = (scaleId, value, borderWidth) => {
    const annotationColor = "rgba(0, 0, 0, 0.5)";
    return {
        type: "line",
        borderColor: annotationColor,
        borderWidth: borderWidth || 2,
        drawTime: "beforeDatasetsDraw",
        scaleID: scaleId,
        value: value,
    };
};

export const createChartOptions = (chartType, configOptions, intl, language) => {
    const chartOptions = _.clone(configOptions);

    chartOptions.intl = intl;
    chartOptions.locale = language;

    chartOptions.scales = _.mapValues(chartOptions.scales, (scale) => {
        if (scale.ticks && scale.ticks.format) {
            scale.ticks.callback = (value, index, ticks) => {
                return convertValueToLocaleString(value, intl, language, scale.ticks.format);
            };
        }
        return scale;
    });

    if (!chartOptions.plugins) {
        chartOptions.plugins = {};
    }

    const tooltipOptions = chartOptions.tooltips || {};
    chartOptions.plugins.tooltip = {
        options: tooltipOptions,
        language: language,
        mode : "index",
        intersect: !(chartType === "line"),
        filter: (tooltipItem) => {
            const dataset = tooltipItem.dataset;
            return (dataset.datasetType === "values");
        },
        callbacks: {
            title: function (tooltipItems) {
                return null;
            },

            label: function (tooltipItem) {
                let label = null;

                const dataset = tooltipItem.dataset;
                const date = dataset.dates[tooltipItem.dataIndex];

                let dateLabel = null;
                if (date) {
                    if (dataset.period === "day") {
                        dateLabel = formatDateTooltipString(date, language);
                    }
                    else if (dataset.period === "week") {
                        dateLabel = formatWeekTooltipString(date, language);
                    }
                    else if (dataset.period === "month") {
                        dateLabel = formatMonthYearString(date, language);
                    }
                }

                const value = Number(dataset.data[tooltipItem.dataIndex]);
                label = (dateLabel || tooltipItem.label) + ": " + convertValueToLocaleString(value, intl, language, tooltipOptions);

                if (dataset.valueTooltipPrefix) {
                    label = `${dataset.valueTooltipPrefix}${label}`;
                }

                const datasetDeltaFormat = _.get(tooltipOptions, "datasetDeltaFormat", {
                    type: "firstDatasetRelativePercent",
                    style: "percent",
                    maximumFractionDigits: 1,
                    minimumFractionDigits: 1,
                    signDisplay: "always"
                });

                let visibleDatasetCount = 0;
                let otherVisibleDatasetIndex;
                for (let i = 0; i < dataset.datasets.length; ++i) {
                    if (tooltipItem.chart.isDatasetVisible(i)) {
                        ++visibleDatasetCount;

                        if (otherVisibleDatasetIndex === undefined && i !== tooltipItem.datasetIndex) {
                            otherVisibleDatasetIndex = i;
                        }
                    }
                }

                const appendLabelWithRelativePercentage = (otherDataset) => {
                    if (otherDataset.data[tooltipItem.dataIndex]) {
                        const otherValue = Number(otherDataset.data[tooltipItem.dataIndex]);
                        const deltaPercentage = ((value - otherValue) / otherValue);
                        label += ` (${convertValueToLocaleString(deltaPercentage, intl, language, datasetDeltaFormat)})`;
                    }
                };

                const appendLabelWithAbsolutePercentage = (otherDataset) => {
                    if (otherDataset.data[tooltipItem.dataIndex]) {
                        const otherValue = Number(otherDataset.data[tooltipItem.dataIndex]);
                        const deltaValue = value - otherValue;
                        label += ` (${convertValueToLocaleString(deltaValue, intl, language, {
                            ...datasetDeltaFormat,
                            style: "percentPoint"
                        })})`;
                    }
                };

                if (datasetDeltaFormat.type === "firstDatasetRelativePercent") {
                    if (dataset.scope === "brand" || dataset.scope === "industry") {
                        if (tooltipItem.datasetIndex === 0 && visibleDatasetCount === 2) {
                            appendLabelWithRelativePercentage(dataset.datasets[otherVisibleDatasetIndex]);
                        }
                    }
                    else if (dataset.scope === "comparison") {
                        if (tooltipItem.datasetIndex !== 0 && visibleDatasetCount > 1) {
                            appendLabelWithRelativePercentage(dataset.datasets[0]);
                        }
                    }
                }
                else if (datasetDeltaFormat.type === "firstDatasetAbsolutePercent") {
                    if (tooltipItem.datasetIndex === 0 && visibleDatasetCount === 2) {
                        appendLabelWithAbsolutePercentage(dataset.datasets[otherVisibleDatasetIndex]);
                    }
                }
                else if (datasetDeltaFormat.type === "nonFirstDatasetAbsolutePercent") {
                    if (tooltipItem.datasetIndex !== 0 && visibleDatasetCount > 1) {
                        appendLabelWithAbsolutePercentage(dataset.datasets[0]);
                    }
                }

                return label;
            },
        }
    };

    const annotations = [];
    if (_.get(configOptions, "scales.y.highlight")) {
        annotations.push(createLineAnnotation("y", 0));
    }
    if (_.get(configOptions, "scales.x.highlight")) {
        annotations.push(createLineAnnotation("x", 0));
    }
    chartOptions.plugins.annotation = {
        ...chartOptions.plugins.annotation,
        annotations
    };

    return chartOptions;
};

export const showTrendLineAnnotations = (chart, conclusions) => {
    hideTrendLineAnnotations(chart);

    const trendLineConclusions = _.map(_.filter(conclusions, (conclusion) => conclusion.type === "trendLines"), "conclusion");

    const annotations = chart.options.plugins.annotation.annotations;
    _.forEach(trendLineConclusions, (trendLineConclusion) => {
        _.forEach(trendLineConclusion, (trendLine, index) => {
            annotations.push({
                id: "trendLine",
                type: "line",
                borderColor: chart.data.datasets[index].borderColor || "rgb(255, 99, 132)",
                borderWidth: 2,
                borderDash: [5, 5],
                yMin: trendLine.yMin,
                yMax: trendLine.yMax
            });
        });
    });

    chart.options.plugins.annotation = {
        ...chart.options.plugins.annotation,
        annotations
    };

    chart.update();
};

export const hideTrendLineAnnotations = (chart) => {
    chart.options.plugins.annotation.annotations = _.filter(chart.options.plugins.annotation.annotations, (annotation) => {
        return (!annotation.id || !annotation.id.startsWith("trendLine"));
    });

    chart.update();
};

export const createChartOnExportButtonClickHandler = (data, scope, brandId, metricId, user, dispatch) => {
    let result = null;

    const { shouldAllowDataExport } = _.get(data, ["metric", "chart_options"], {});

    if (scope === "brand" && shouldAllowDataExport && (user && user.capabilities["data_export"])) {
        result = () => {
            dispatch(brands.actions.requestBrandMetricDataExport({
                brandId: brandId,
                metricId: metricId,
            }));
        };
    }

    return result;
};

export const useChartProperties = (brandId, countryId, industryId, industryDefinition, metricId, defaultChartHeight) => {
    let getActiveFiltersSelector;
    let setActiveFiltersAction;
    let getMetricActiveFiltersSelector;
    let setMetricActiveFiltersAction;
    let isMetricDataLoadedSelector;
    let getMetricDataSelector;
    if (brandId) {
        getActiveFiltersSelector = brands.selectors.getBrandActiveFilters(brandId);
        setActiveFiltersAction = (filters) => {
            return brands.actions.setBrandActiveFilters({
                brandId: brandId,
                filters: filters
            });
        };
        getMetricActiveFiltersSelector = brands.selectors.getBrandMetricActiveFilters(brandId, metricId);
        setMetricActiveFiltersAction = (filters) => {
            return brands.actions.setBrandMetricActiveFilters({
                brandId: brandId,
                metricId: metricId,
                filters: filters
            });
        };
        isMetricDataLoadedSelector = brands.selectors.getIsBrandMetricDataLoaded(brandId, metricId);
        getMetricDataSelector = brands.selectors.getBrandMetricData(brandId, metricId);
    }
    else if (countryId && industryId && industryDefinition) {
        getActiveFiltersSelector = () => null;
        setActiveFiltersAction = null;
        getMetricActiveFiltersSelector = industries.selectors.getIndustryMetricActiveFilters(countryId, industryId, industryDefinition, metricId);
        setMetricActiveFiltersAction = null;
        isMetricDataLoadedSelector = industries.selectors.getIsIndustryMetricDataLoaded(countryId, industryId, industryDefinition, metricId);
        getMetricDataSelector = industries.selectors.getIndustryMetricData(countryId, industryId, industryDefinition, metricId);
    }
    else {
        getActiveFiltersSelector = comparison.selectors.getComparisonActiveFilters;
        setActiveFiltersAction = (filters) => {
            return comparison.actions.setComparisonActiveFilters({
                filters: filters
            });
        };
        getMetricActiveFiltersSelector = comparison.selectors.getComparisonMetricActiveFilters(metricId);
        setMetricActiveFiltersAction = (filters) => {
            return comparison.actions.setComparisonMetricActiveFilters({
                metricId: metricId,
                filters: filters
            });
        };
        isMetricDataLoadedSelector = comparison.selectors.getIsComparisonMetricDataLoaded(metricId);
        getMetricDataSelector = comparison.selectors.getComparisonMetricData(metricId);
    }

    const filters = useSelector(getActiveFiltersSelector);
    const metricFilters = useSelector(getMetricActiveFiltersSelector);
    const isChartLoaded = useSelector(isMetricDataLoadedSelector);
    const data = useSelector(getMetricDataSelector);
    const unit = _.get(data, "metric.unit");
    const dataFeatures = _.get(data, "dataFeatures", []);
    const scope = _.get(data, "metric.scope");
    const title = _.get(data, "metric.title");
    const subTitle = _.get(data, "metric.subTitle");
    const description = _.get(data, "metric.description");
    const releaseNotes = _.get(data, "metric.config.releaseNotes");
    const chartHeight = _.get(data, "metric.config.height", defaultChartHeight);
    const chartType = _.get(data, "metric.chart_type", undefined);
    const ChartComponent = (chartComponents[chartType] || {}).component;

    return {
        filters,
        metricFilters,
        isChartLoaded,
        scope,
        data,
        unit,
        dataFeatures,
        title,
        subTitle,
        description,
        releaseNotes,
        chartHeight,
        chartType,
        ChartComponent,
        setActiveFiltersAction,
        setMetricActiveFiltersAction,
    };
};

export const calculateStatsCardProperties = (preparedData, scope) => {
    const shouldShowStatsCard = !!((preparedData ? preparedData.sums : []).length || (preparedData ? preparedData.averages : []).length);
    const statsCardViewMode = (shouldShowStatsCard ? ((scope === "brand" || scope === "industry") ? "auto" : "column") : null);

    return {
        shouldShowStatsCard,
        statsCardViewMode
    };
};

const Chart = ({ brandId, countryId, industryId, industryDefinition, metricId, alertText, toggleHelpDrawer, user, intl }) => {
    const dispatch = useDispatch();

    const language = useSelector(selectors.getSelectedLanguage);

    const {
        isChartLoaded,
        scope,
        data,
        unit,
        dataFeatures,
        title,
        subTitle,
        description,
        releaseNotes,
        chartHeight,
        chartType,
        ChartComponent
    } = useChartProperties(brandId, countryId, industryId, industryDefinition, metricId, 300);

    const options = createChartOptions(chartType, _.get(data, "metric.config.options", {}), intl, language);

    const preparedData = prepareChartData(data, unit, scope, options);

    const chartRef = useRef();

    const { statsCardViewMode } = calculateStatsCardProperties(preparedData, scope);

    const onExportButtonClickHandler = createChartOnExportButtonClickHandler(data, scope, brandId, metricId, user, dispatch);

    const onNarratorGetMetricOpinionButtonClickHandler = ((scope === "brand" && user && user.capabilities["metric_narrator"] && (options.narrator || {}).display) ? () => {
        dispatch(narrator.actions.requestBrandMetricOpinionData({
            brandId: brandId,
            metricId: metricId,
        }));
    } : null);

    return (
        <ChartCard
            title={title}
            subTitle={subTitle}
            description={description}
            releaseNotes={releaseNotes}
            scope={scope}
            statsCardViewMode={statsCardViewMode}
            sums={preparedData ? preparedData.sums : []}
            averages={preparedData ? preparedData.averages : []}
            conclusions={preparedData ? preparedData.conclusions : []}
            onConclusionsTooltipOpen={(conclusions) => {
                showTrendLineAnnotations(chartRef.current, conclusions);
            }}
            onConclusionsTooltipClose={() => {
                hideTrendLineAnnotations(chartRef.current);
            }}
            dataFeatures={dataFeatures}
            onExportButtonClickHandler={onExportButtonClickHandler}
            onNarratorGetMetricOpinionButtonClickHandler={onNarratorGetMetricOpinionButtonClickHandler}
            toggleHelpDrawer={toggleHelpDrawer}
            isLoading={!isChartLoaded}
        >
            <Box style={{display:"flex", flexDirection: "column", height: chartHeight}}>
                {(preparedData && preparedData.datasets && preparedData.datasets.length) ? (
                    <ChartComponent
                        ref={chartRef}
                        data={preparedData}
                        options={options}
                    />
                ) : (
                    <MessageTitle>
                        {!isChartLoaded ? (
                            <FormattedMessage id="DATA_IS_BEING_LOADED"/>
                        ) : (alertText ? alertText : <FormattedMessage id="NOT_ENOUGH_DATA_PROMPT"/>)}
                    </MessageTitle>
                )}
            </Box>
        </ChartCard>
    );
};

const mapStateToProps = (state) => ({
    user: auth.selectors.getUser(state),
});

export default injectIntl(connect(mapStateToProps, null)(Chart));
