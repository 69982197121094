import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {createAction} from "redux-actions";
import {put, takeLatest} from "redux-saga/effects";
import {createSelector} from "reselect";
import * as packagesCrud from "../../crud/packages.crud";
import * as cartCrud from "../../crud/cart.crud";
import { createActionName, wrapSafely } from "./utils";
import _ from "lodash";
import * as auth from "./auth.duck";

const TAG = "CART";

export const actions = {
    addItemToCart: createAction(createActionName(TAG, "addItemToCart")),
    removeItemFromCart: createAction(createActionName(TAG, "removeItemFromCart")),
    removeAllItemsFromCart: createAction(createActionName(TAG, "removeAllItemsFromCart")),
    updateItemInCart: createAction(createActionName(TAG, "updateItemInCart")),

    requestSupportedPackages: createAction(createActionName(TAG, "requestSupportedPackages")),
    fillSupportedPackages: createAction(createActionName(TAG, "fillSupportedPackages")),

    setUserData: createAction(createActionName(TAG, "setUserData")),
    createOrder: createAction(createActionName(TAG, "createOrder")),
};

const defaultState = {
    items: [],
    packages: [],
};

const initialState = _.clone(defaultState);

const reducerKey = "cart-key";

export const reducer = persistReducer(
    { storage, key: reducerKey },
    (state = initialState, action) => {
        switch (action.type) {
            case actions.addItemToCart().type: {
                const item = Object.assign({}, action.payload);

                return {
                    ...state,
                    items: [item, ...state.items],
                };
            }

            case actions.removeItemFromCart().type: {
                return {
                    ...state,
                    items: state.items.filter((item) => item.id !== action.payload.id),
                };
            }

            case actions.removeAllItemsFromCart().type: {
                return {
                    ...state,
                    items: [],
                };
            }

            case actions.updateItemInCart().type: {
                const newItems = state.items.map((item) => {
                    if (item.id === action.payload.brandId) {
                        if (action.payload.isChecked) {
                            return {
                                ...item,
                                selectedPackageIds: [
                                    ...(item.selectedPackageIds || []),
                                    action.payload.packageId,
                                ],
                            };
                        } else {
                            return {
                                ...item,
                                selectedPackageIds: item.selectedPackageIds.filter(
                                    (packageId) => packageId !== action.payload.packageId
                                ),
                            };
                        }
                    } else {
                        return item;
                    }
                });

                const uniqueItems = newItems.map((item) => {
                    return {
                        ...item,
                        selectedPackageIds: [...new Set(item.selectedPackageIds)],
                    };
                });

                return {
                    ...state,
                    items: uniqueItems,
                };
            }

            case actions.fillSupportedPackages().type: {
                return {
                    ...state,
                    packages: action.payload,
                };
            }

            case actions.setUserData().type: {
                return {
                    ...state,
                    userData: action.payload,
                };
            }

            case auth.actionTypes.LogoutUser: {
                console.log(`LogoutUser in ${TAG}`);
                storage.removeItem(`persist:${reducerKey}`);
                return _.clone(defaultState);
            }

            default:
                return state;
        }
    }
);

export function* saga() {
    yield takeLatest(actions.requestSupportedPackages, wrapSafely(function*() {
        const packages = yield packagesCrud.getSupportedPackages();
        yield put(actions.fillSupportedPackages(packages.data.items));
    }, TAG, "requestSupportedPackages"));

    yield takeLatest(actions.createOrder, wrapSafely(function*({ payload }) {
        yield cartCrud.createOrder(payload);
        yield put(actions.removeAllItemsFromCart());
    }, TAG, "createOrder"));
}

export const getCart = (state) => state.cart;

export const selectors = {
    getAddedItems: createSelector(getCart, (cart) => cart.items),
    getSupportedPackages: createSelector(getCart, (cart) => cart.packages),
    getUserData: createSelector(getCart, (cart) => cart.userData),
};
