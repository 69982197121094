import React from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { useSelector } from "react-redux";
import * as alert from "../../../store/ducks/alert.duck";
import { Portal, Snackbar } from "@material-ui/core";

const Alert = (props) => {
  const { severity, message } = useSelector(alert.selectors.getAlert);
  return (
    <>
      {message && (
        <Portal>
          <Snackbar open={true}>
            <MuiAlert
              elevation={6}
              variant="filled"
              severity={severity || "error"}
              {...props}
            >
              {message}
            </MuiAlert>
          </Snackbar>
        </Portal>
      )}
    </>
  );
};

export default Alert;
