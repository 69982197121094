import React, {useEffect} from "react";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Box,
    Typography,
} from "@material-ui/core";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";

import ChartCard from "../chart-card";
import MessageTitle from "../../../common/components/message-title";
import {Dropdown} from "react-bootstrap";
import {useChartProperties} from "../chart";
import {convertValueToLocaleString} from "../../../utils/utils";
import {selectors} from "../../../../_metronic/ducks/i18n";

const getDataCellStyle = (value) => {
    let style = {};

    if (value !== undefined) {
        const styles = [
            {
                backgroundColor: "#E3F2FD"
            },
            {
                backgroundColor: "#BBDEFB"
            },
            {
                backgroundColor: "#90CAF9"
            },
            {
                backgroundColor: "#64B5F6"
            },
            {
                backgroundColor: "#42A5F5"
            },
            {
                backgroundColor: "#2196F3"
            },
            {
                backgroundColor: "#1E88E5"
            },
            {
                backgroundColor: "#1976D2"
            },
            {
                backgroundColor: "#1565C0"
            },
            {
                backgroundColor: "#0D47A1"
            }
        ];

        const styleIndex = Math.min(Math.max(Math.floor(value * 100 / styles.length), 0), styles.length - 1);
        style = styles[styleIndex];
    }

    return style;
};

const CustomerRetention = ({ brandId, metricId, alertText, toggleHelpDrawer, intl }) => {
    const dispatch = useDispatch();

    const language = useSelector(selectors.getSelectedLanguage);

    const {
        metricFilters,
        isChartLoaded,
        data,
        dataFeatures,
        title,
        subTitle,
        description,
        releaseNotes,
        chartHeight,
        setMetricActiveFiltersAction,
    } = useChartProperties(brandId, null, null, null, metricId, 400);

    const preparedData = _.get(data, "table", {});

    const supportedPeriodFilters = ["week", "month"];
    const periodFilter = (metricFilters || {}).period;

    const formattedPeriodFilters = {
        week: intl.formatMessage({ id: "PERIOD.WEEK" }),
        month: intl.formatMessage({ id: "PERIOD.MONTH" }),
    };

    useEffect(() => {
        if (!periodFilter) {
            dispatch(setMetricActiveFiltersAction({
                period: supportedPeriodFilters[0]
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ChartCard
            title={title}
            subTitle={subTitle}
            description={description}
            releaseNotes={releaseNotes}
            dataFeatures={dataFeatures}
            toggleHelpDrawer={toggleHelpDrawer}
            isLoading={!isChartLoaded}
        >
            <Box style={{display:"flex", flexDirection: "column", height: chartHeight}}>
                <Box p={1}>
                    <Typography variant="subtitle2">{<FormattedMessage id="PERIOD.DROPDOWN.PROMPT" />}</Typography>

                    <Dropdown>
                        <Dropdown.Toggle>
                            {formattedPeriodFilters[periodFilter]}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {supportedPeriodFilters.map((supportedPeriodFilter) => (
                                <Dropdown.Item
                                    key={supportedPeriodFilter}
                                    active={supportedPeriodFilter === periodFilter}
                                    onClick={() => {
                                    dispatch(setMetricActiveFiltersAction({
                                        period: supportedPeriodFilter
                                    }));
                                }}>
                                    {formattedPeriodFilters[supportedPeriodFilter]}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </Box>

                <Box style={{flexGrow: 1, overflow: 'auto'}}>
                    {(preparedData && preparedData.headers && preparedData.headers.length) ? (
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {[<FormattedMessage id="PERIOD"/>].concat(preparedData.headers).map((item, index) => (
                                        <TableCell key={item} align={index !== 0 ? "center" : "left"}>
                                            {item}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(preparedData.rows || []).map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Box display="flex" flexDirection="column">
                                                <Box clone fontSize="14px" color="#000">
                                                    <Typography>{row.title}</Typography>
                                                </Box>
                                            </Box>
                                        </TableCell>

                                        {preparedData.headers.map((item, index) => (
                                            <TableCell align="center" key={`${item}-${index}`} style={getDataCellStyle(row.data[index])}>
                                                {row.data[index] ? convertValueToLocaleString(Number(row.data[index]), intl, language, {
                                                    style: "percent",
                                                    maximumFractionDigits: 0,
                                                    minimumFractionDigits: 0,
                                                }) : ''}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <MessageTitle>
                            {!isChartLoaded ? (
                                <FormattedMessage id="DATA_IS_BEING_LOADED"/>
                            ) : (alertText ? alertText : <FormattedMessage id="NOT_ENOUGH_DATA_PROMPT"/>)}
                        </MessageTitle>
                    )}
                </Box>
            </Box>
        </ChartCard>
    );
};

export default injectIntl(CustomerRetention);
