import axios from "axios";

import { API_URL } from "./constants"

export const getPurchaseCurrentMonth = () => {
  return axios.get(`${API_URL}/purchase/currentMonth`);
}

export const createOrder = (order) => {
  return axios.post(`${API_URL}/purchase`, {order});
}