import React, { useState } from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {
  Box,
  Divider,
  Typography,
  Button,
  Portal,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { selectors } from "../../../../_metronic/ducks/i18n";
import { FormattedMessage } from "react-intl";

import * as cart from "../../../store/ducks/cart.duck";
import { getItemPrice, getDiscountPercent, getDiscountPrice, getTotalPrice, formatPrice } from "../services-list";
import Cover from "../cover";
import moment from "moment";

import GoogleAnalytics from "../../../common/components/google-analytics";

const OrderSummary = ({
  items,
  packages,
  userOrderData,
}) => {
  const dispatch = useDispatch();

  const [openSucces, setOpenSucces] = useState(false);
  const [openPackagesError, setOpenPackagesError] = useState(false);

  const language = useSelector(selectors.getSelectedLanguage);

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const handleCloseSucces = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSucces(false);
  };

  const handleClosePackagesError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenPackagesError(false);
  };

  const getSelectedPackagesCount = (packageId) => {
    let count = 0;
    items.map(
      (item) => (item.selectedPackageIds || []).includes(packageId) && count++
    );

    return count;
  };

  const getPurchases = () =>
    items.reduce((acc, item) => {
      if ((item.selectedPackageIds || []).length) {
        return [
          ...acc,
          {
            brand_id: item.id,
            packages: [...item.selectedPackageIds],
            estimatedPrice: getItemPrice(item, packages),
          },
        ];
      }
      return acc;
    }, []);

  const checkOut = () => {
    const purchases = getPurchases();
    if (!purchases.length) {
      setOpenPackagesError(true);
    }
    else {
      if (!Object.values(userOrderData).includes("")) {
        const order = {
          user: userOrderData,
          purchases: getPurchases(),
          additionalInfo: {
            localTime: moment().format()
          },
        };

        GoogleAnalytics.reportEvent({
          category: 'Cart',
          action: 'CheckOut'
        });

        dispatch(cart.actions.createOrder(order));
        setOpenSucces(true);
      }
    }
  };

  const discountPercent = getDiscountPercent(items, packages);

  return (
    <Cover title={<FormattedMessage id="CART.ORDER_SUMMARY" />}>
      <Box marginBottom="20px">
        {packages.map((packageItem) => (
          <Box
            key={packageItem.id}
            display="flex"
            justifyContent="space-between"
            marginBottom="10px"
          >
            <Typography color="textSecondary">
              {packageItem.title}
            </Typography>
            <Typography>{getSelectedPackagesCount(packageItem.id)}</Typography>
          </Box>
        ))}
      </Box>
      <Divider />
      <Box>
        <Box margin="20px 0">
          <Typography color="textSecondary">
            <FormattedMessage id="CART.DISCOUNT_TITLE" />
          </Typography>
        </Box>
        <Box marginBottom="20px">
          <Typography>
            <FormattedMessage id="CART.DISCOUNT_TEXT" />
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" marginBottom="20px">
          <Typography>
            <FormattedMessage
              id="CART.DISCOUNT_TERMS"
              values={{ count: "3+" }}
            />
          </Typography>
          <Typography>
            <FormattedMessage
              id="CART.DISCOUNT_PERCENT"
              values={{ discount: "15" }}
            />
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" marginBottom="40px">
          <Typography>
            <FormattedMessage
              id="CART.DISCOUNT_TERMS"
              values={{ count: "5+" }}
            />
          </Typography>
          <Typography>
            <FormattedMessage
              id="CART.DISCOUNT_PERCENT"
              values={{ discount: "30" }}
            />
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box margin="30px 0">
        <Box display="flex" justifyContent="space-between" marginBottom="15px">
          <Typography color="textSecondary">
            <FormattedMessage id="CART.PRICE" />
          </Typography>
          <Typography>
            {formatPrice(getTotalPrice(items, packages), language)}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          marginBottom="15px"
        >
          <Typography color="textSecondary">
            <FormattedMessage id="CART.DISCOUNT_TITLE" />
          </Typography>
          <Typography>
            {formatPrice(getDiscountPrice(items, packages, discountPercent), language) + " (" + (discountPercent * 100).toFixed(0) + "%)"}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography color="textSecondary">
            <FormattedMessage id="CART.TOTAL" />
          </Typography>
          <Typography>
            {formatPrice(getTotalPrice(items, packages, discountPercent), language)}
          </Typography>
        </Box>
      </Box>
      <Button onClick={checkOut} variant="contained" color="primary" fullWidth>
        <FormattedMessage id="CART.CHECKOUT" />
      </Button>
      <Portal>
        <Snackbar
          open={openSucces}
          autoHideDuration={6000}
          onClose={handleCloseSucces}
        >
          <Alert onClose={handleCloseSucces} severity="success">
            <FormattedMessage id="CART.ORDER_SUCCES" />
          </Alert>
        </Snackbar>
        <Snackbar
          open={openPackagesError}
          autoHideDuration={3000}
          onClose={handleClosePackagesError}
        >
          <Alert onClose={handleClosePackagesError} severity="error">
            <FormattedMessage id="CART.ORDER_ERROR" />
          </Alert>
        </Snackbar>
      </Portal>
    </Cover>
  );
};

const mapStateToProps = (state) => ({
  items: cart.selectors.getAddedItems(state),
  packages: cart.selectors.getSupportedPackages(state),
  userOrderData: cart.selectors.getUserData(state)
});

export default connect(mapStateToProps, null)(OrderSummary);
