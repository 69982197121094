import React from "react";
import { makeStyles } from "@material-ui/core";
import { Dropdown } from "react-bootstrap";
import { injectIntl } from "react-intl";

import Tooltip from "../tooltip";
import "react-calendar/dist/Calendar.css";

import _ from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCity,
    faMountainCity
} from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles(() => ({
    dropdownMenu: {
        maxHeight: "500px",
        overflow: "auto",
    },
}));

const IndustryDefinitionDropdown = ({ industryDefinition, onChange, ...props }) => {
    const { intl } = props;
    const classes = useStyles();

    const formattedIndustryDefinitions = {
        brands_only: intl.formatMessage({ id: "INDUSTRY.DEFINITION.BRANDS.ONLY" }),
        brands_and_mcc: intl.formatMessage({ id: "INDUSTRY.DEFINITION.BRANDS.AND.MCC" }),
    };
    const industryDefinitionIcons = {
        brands_only: faCity,
        brands_and_mcc: faMountainCity,
    };
    const filteredIndustryDefinitions = _.keys(formattedIndustryDefinitions);

    return (
        <Dropdown>
            <Tooltip arrow title={`${intl.formatMessage({id: "INDUSTRY.DEFINITION.TOOLTIP.PROMPT"})}${formattedIndustryDefinitions[industryDefinition]}`}>
                <Dropdown.Toggle>
                    <FontAwesomeIcon icon={industryDefinitionIcons[industryDefinition]} size={"lg"} fixedWidth={true} />
                    &nbsp;
                    {formattedIndustryDefinitions[industryDefinition]}
                </Dropdown.Toggle>
            </Tooltip>
            <Dropdown.Menu flip={false} className={classes.dropdownMenu}>
                {filteredIndustryDefinitions.map((filteredIndustryDefinition) => (
                    <Dropdown.Item
                        key={filteredIndustryDefinition}
                        active={industryDefinition === filteredIndustryDefinition}
                        onClick={() => onChange(filteredIndustryDefinition)}
                    >
                        {formattedIndustryDefinitions[filteredIndustryDefinition]}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default injectIntl(IndustryDefinitionDropdown);
