import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Box, Button, Paper, Table, TableHead, TableCell, TableBody, TableRow, Typography, makeStyles} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import { LayoutSubheader } from "../../../_metronic";
import {
    parseParams,
    replaceParams,
    generateCatalogUrl,
    generateIndustryDetailsUrl,
    getLogoImageUrl,
    parseTimeRange,
    formatTimeRangeString,
} from "../../utils/utils";
import * as industries from "../../store/ducks/industries.duck";
import * as popups from "../../store/ducks/popups.duck";
import {useDispatch, useSelector} from "react-redux";
import ProgressingOverlay from "../../common/components/progressing-overlay";
import MessageTitle from "../../common/components/message-title";
import {selectors} from "../../../_metronic/ducks/i18n";
import {rightBorderedCellStyle} from "../../utils/tableStyles";
import css from "./styles.module.scss";
import _ from "lodash";
import IndustryRatingFilters from "../../common/components/industry-rating-filters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLock,
    faUnlock,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "../../common/components/tooltip";
import {generateMccInfosTooltipTitle} from "../../common/components/industry-card";
import {useTheme} from "@material-ui/styles";
import Badge from "../../common/components/badge";
import {
    createRatingPropertyValueElement,
    RatingTableSortBlock,
    RatingTableSortLabel
} from "../../common/components/brand-rating-table";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    ratingTablePaper: {
        position: "relative",
        height: "100%",
    },
    ratingTableContent: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    ratingTableContainer: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
    },
    ratingTableWrapper: {
        flexGrow: 1,
    },
}));

export const assignTimeRanges = (object, reportTimeRange, baseTimeRange) => {
    object.reportTimeRange = reportTimeRange;
    object.baseTimeRange = baseTimeRange;
    object.timeRanges = [reportTimeRange, baseTimeRange];
};

export const prepareParamsForDispatch = (params) => {
    const preparedParams = _.clone(params);
    assignTimeRanges(preparedParams, preparedParams.reportTimeRange, preparedParams.baseTimeRange);
    return preparedParams;
};

export const prepareParamsForReplacement = (params) => {
    const preparedParams = _.clone(params);
    assignTimeRanges(preparedParams, JSON.stringify(preparedParams.reportTimeRange), JSON.stringify(preparedParams.baseTimeRange));
    return preparedParams;
};

const Industries = ({intl}) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const classes = useStyles();

    const language = useSelector(selectors.getSelectedLanguage);

    const industryRatingInfo = useSelector(industries.selectors.getIndustryRatingInfo);
    const { isLoading } = useSelector(industries.selectors.getIndustryRatingInfoLoadState);

    const industryRatings = (industryRatingInfo.industryRatings || []);

    const ratingTableCollapsedRowCount = 5;
    const ratingTableExpandRowCount = (industryRatings.length - ratingTableCollapsedRowCount);
    const [isRatingTableExpanded, setIsRatingTableExpanded] = useState(false);

    const getFilters = () => {
        const params = {
            ...industryRatingInfo.recommendedFilters,
            ...parseParams()
        };

        const { countryId = "1", industryDefinition = "brands_only", salesChannel, locationId, reportTimeRange, baseTimeRange, period } = params;

        let { industryIds } = params;
        if (industryIds) {
            if (!Array.isArray(industryIds)) {
                industryIds = [industryIds];
            }
        }
        else {
            industryIds = [];
        }

        const result = {
            countryId,
            industryIds,
            industryDefinition,
            salesChannel,
            locationId,
            period
        };

        if (reportTimeRange && baseTimeRange) {
            assignTimeRanges(result, parseTimeRange(reportTimeRange), parseTimeRange(baseTimeRange));
        }

        return result;
    };
    const filters = getFilters();

    const getIndustryRatingOrderInfo = () => {
        const { sortKey, sortProperty, sortOrder } = parseParams();
        return {
            sortKey,
            sortProperty,
            sortOrder
        };
    };
    const industryRatingOrderInfo = getIndustryRatingOrderInfo();

    const requestFilteredIndustryRatingInfo = (filters) => {
        if (filters) {
            dispatch(industries.actions.requestIndustryRatingInfo({
                ...filters,
            }));
        }
    };

    const onIndustryRatingTableSortChange = (newOrderInfo) => {
        const newParams = {
            ...filters,
            ...industryRatingOrderInfo,
            ...newOrderInfo,
        };

        requestFilteredIndustryRatingInfo(prepareParamsForDispatch(newParams));
        replaceParams(prepareParamsForReplacement(newParams));
    };

    const onUnlockRequest = () => {
        dispatch(popups.actions.showSendMessagePopup(
            "Industry access request (industries page)",
            intl.formatMessage({ id: "REQUEST.ACCESS.POPUP.TITLE" }),
            intl.formatMessage({ id: "REQUEST.INDUSTRIES.HISTORICAL.DATA.ACCESS.MESSAGE" }),
        ));
    };

    useEffect(() => {
        if (industryRatings.length === 0) {
            requestFilteredIndustryRatingInfo({
                ...filters,
                ...industryRatingOrderInfo,
            });
        }
        else {
            if (!industryRatingOrderInfo.sortKey) {
                onIndustryRatingTableSortChange({
                    sortKey: _.head(_.head(industryRatings).properties).key,
                    sortProperty: "rawValue",
                    sortOrder: "desc",
                });
            }
        }
        // eslint-disable-next-line
    }, [industryRatings.length]);

    return (
        <Box className={classes.root}>
            <LayoutSubheader
                title={<FormattedMessage id="INDUSTRIES.CAPTION" />}
                component={
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Link to={generateCatalogUrl()}>
                            <Button variant="contained" color="primary">
                                <FormattedMessage id="CATALOG.CAPTION" />
                            </Button>
                        </Link>

                        {_.get(industryRatingInfo, "availableDates.locked") && (
                            <Box ml={2}>
                                <Button variant="contained" color="secondary" startIcon={<FontAwesomeIcon icon={faUnlock} size={"lg"} />} onClick={onUnlockRequest}>
                                    <FormattedMessage id="ACCESS.REQUEST.BUTTON" />
                                </Button>
                            </Box>
                        )}
                    </Box>
                }
            />

            <IndustryRatingFilters
                industries={_.get(industryRatingInfo, "availableIndustries")}
                locations={_.get(industryRatingInfo, "availableLocations")}
                periods={["month", "week"]}
                months={_.get(industryRatingInfo, "availableMonths")}
                lockedMonths={_.get(industryRatingInfo, "lockedMonths")}
                onMonthUnlockRequest={onUnlockRequest}
                options={{
                    locationFilter: {
                        visible: true,
                    },
                    posFilter: {
                        visible: false,
                    },
                    periodFilter: {
                        visible: true,
                    },
                    timeRanges: {
                        reportTimeRange: {
                            visible: true,
                            presetListVisible: true,
                            customizable: true,
                            monthListVisible: true,
                        },
                        baseTimeRange: {
                            visible: true,
                            presetListVisible: true,
                            customizable: true,
                            monthListVisible: true,
                        },
                        swappable: true,
                    },
                }}
                filters={filters}
                onChange={(filtersPortion) => {
                    const newParams = {
                        ...filters,
                        ...industryRatingOrderInfo,
                        ...filtersPortion,
                    };

                    requestFilteredIndustryRatingInfo(prepareParamsForDispatch(newParams));
                    replaceParams(prepareParamsForReplacement(newParams));
                }}
                isChanging={isLoading}
                isLoading={!_.get(industryRatingInfo, "availableMonths")}
            />

            <Paper className={classes.ratingTablePaper}>
                <Box className={classes.ratingTableContent} p={3}>
                    <Typography variant="h6">
                        <FormattedMessage id="INDUSTRY.RATING.TITLE" />
                    </Typography>

                    {industryRatingInfo.reportTimeRange ? (
                        <Box className={classes.ratingTableContainer}>
                            <Box ml={1} mb={1}>
                                <Typography variant="subtitle1" color="textSecondary">
                                    {`${formatTimeRangeString(industryRatingInfo.reportTimeRange, true, language)} vs ${formatTimeRangeString(industryRatingInfo.baseTimeRange, true, language)}`}
                                </Typography>
                            </Box>

                            {industryRatings.length > 0 ? (
                                <Box>
                                    <Box mb={1}>
                                        <RatingTableSortBlock
                                            orderInfo={industryRatingOrderInfo}
                                            shouldAllowByValueSort={true}
                                            shouldAllowByRawValueSort={true}
                                            onChange={onIndustryRatingTableSortChange}
                                        />
                                    </Box>

                                    <Box className={classes.ratingTableWrapper}>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center" style={rightBorderedCellStyle}>
                                                        <span className={css.columnTitle}>
                                                            <FormattedMessage id="INDUSTRY.RATING.RANK.COLUMN.TITLE" />
                                                        </span>
                                                    </TableCell>

                                                    <TableCell align="center" style={rightBorderedCellStyle}>
                                                        <span className={css.columnTitle}>
                                                            <FormattedMessage id="INDUSTRY.RATING.NAME.COLUMN.TITLE" />
                                                        </span>
                                                    </TableCell>

                                                    {industryRatings[0].properties.map((property, index) => (
                                                        <TableCell key={index} align="center" style={index < (industryRatings[0].properties.length - 1) ? rightBorderedCellStyle : {}}>
                                                            <span className={css.columnTitle}>
                                                                {property.title}
                                                            </span>
                                                            <RatingTableSortLabel
                                                                sortKey={property.key}
                                                                orderInfo={industryRatingOrderInfo}
                                                                onSortChange={onIndustryRatingTableSortChange}
                                                                options={{
                                                                    defaultSortOrder: "desc"
                                                                }}
                                                            />
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {(_.take(industryRatings, isRatingTableExpanded ? industryRatings.length : ratingTableCollapsedRowCount)).map((industryRating, rowIndex) => (
                                                    <TableRow key={rowIndex}>
                                                        <TableCell align="center" style={rightBorderedCellStyle}>
                                                            <Typography className={css.industryRank}>
                                                                {_.get(industryRating, "rank", "")}
                                                            </Typography>
                                                        </TableCell>

                                                        {industryRating.industry ? (
                                                            <TableCell style={rightBorderedCellStyle}>
                                                                <Box className={css.industryWrapper}>
                                                                    <img
                                                                        className={css.industryImage}
                                                                        src={getLogoImageUrl(_.get(industryRating, "industry.logo", ""))}
                                                                        alt={_.get(industryRating, "industry.name", "")}
                                                                    />
                                                                    <Box>
                                                                        <Link
                                                                            to={!_.get(industryRating, "industry.isLocked") ? generateIndustryDetailsUrl({
                                                                                countryId: _.get(industryRating, "country.id", ""),
                                                                                industryId: _.get(industryRating, "industry.id", ""),
                                                                                industryDefinition: filters.industryDefinition,
                                                                                locationId: filters.locationId,
                                                                                reportTimeRange: (filters.reportTimeRange ? JSON.stringify(filters.reportTimeRange) : null),
                                                                                baseTimeRange: (filters.baseTimeRange ? JSON.stringify(filters.baseTimeRange) : null),
                                                                                period: filters.period,
                                                                            }) : window.location.href.replace(window.location.origin, "")}
                                                                            onClick={(event) => {
                                                                                if (_.get(industryRating, "industry.isLocked")) {
                                                                                    event.preventDefault();
                                                                                    dispatch(popups.actions.showSendMessagePopup(
                                                                                        "Industry access request (industry rating)",
                                                                                        intl.formatMessage({ id: "REQUEST.ACCESS.POPUP.TITLE" }),
                                                                                        intl.formatMessage({ id: "REQUEST.INDUSTRY.ACCESS.MESSAGE.TEMPLATE"}, { industryName: _.get(industryRating, "industry.name", "") }),
                                                                                    ));
                                                                                }
                                                                            }}
                                                                        >
                                                                            <Box display="flex" flexDirection="row" alignItems="center">
                                                                                <Typography className={css.industryName}>
                                                                                    {_.get(industryRating, "industry.name", "")}

                                                                                    {_.get(industryRating, "industry.isLocked") && (
                                                                                        <span style={{marginLeft: "8px"}}>
                                                                                            <FontAwesomeIcon icon={faLock} color={theme.palette.primary.dark} />
                                                                                        </span>
                                                                                    )}
                                                                                </Typography>

                                                                                {_.get(industryRating, "industry.isDemo") && (
                                                                                    <Box ml={1}>
                                                                                        <Badge color={"green"} text={<FormattedMessage id="BRAND.DEMO.BADGE.TEXT" />} />
                                                                                    </Box>
                                                                                )}
                                                                            </Box>
                                                                        </Link>

                                                                        <Link
                                                                            to={generateCatalogUrl({
                                                                                industry: _.get(industryRating, "industry.id", "")
                                                                            })}
                                                                        >
                                                                            <p className={css.catalogLink}>
                                                                                <FormattedMessage id="INDUSTRY.BRAND.COUNT.HINT.TEMPLATE" values={{ brandCount: _.get(industryRating, "industry.brandCount", "") }} />
                                                                            </p>
                                                                        </Link>

                                                                        {_.get(industryRating, "industry.mccInfos.length") > 0 && (
                                                                            <Box style={{width: "min-content", whiteSpace: "nowrap"}}>
                                                                                <Tooltip
                                                                                    arrow
                                                                                    interactive
                                                                                    title={generateMccInfosTooltipTitle(industryRating.industry.mccInfos)}
                                                                                >
                                                                                    <Typography variant="subtitle2" color="textSecondary">
                                                                                        <FormattedMessage id="INDUSTRY.MCC.COUNT.HINT.TEMPLATE" values={{ mccCount: industryRating.industry.mccInfos.length }} />
                                                                                    </Typography>
                                                                                </Tooltip>
                                                                            </Box>
                                                                        )}
                                                                    </Box>
                                                                </Box>
                                                            </TableCell>
                                                        ) : (
                                                            <TableCell style={rightBorderedCellStyle}>
                                                                <Box className={css.industryWrapper} display="flex" justifyContent="center" alignItems="center">
                                                                    <Typography className={css.industryName}>
                                                                        <FormattedMessage id="TOTAL.VALUES.PROMPT"/>
                                                                    </Typography>
                                                                </Box>
                                                            </TableCell>
                                                        )}

                                                        {industryRating.properties.map((property, index) => (
                                                            <TableCell key={index} style={index < (industryRating.properties.length - 1) ? rightBorderedCellStyle : {}}>
                                                                {createRatingPropertyValueElement(property, industryRatings[0].properties[index].settings, intl, language)}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>

                                        {ratingTableExpandRowCount > 0 && (
                                            <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
                                                <Button variant="text" color="primary" onClick={() => {
                                                    setIsRatingTableExpanded(!isRatingTableExpanded);
                                                }}>
                                                    <Typography>{isRatingTableExpanded ? <FormattedMessage id="TABLE.SHOW.LESS"/> : <FormattedMessage id="TABLE.SHOW.MORE.TEMPLATE" values={{ count: ratingTableExpandRowCount }}/>}</Typography>
                                                </Button>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            ) : (
                                <MessageTitle>
                                    <FormattedMessage id="NOT_ENOUGH_DATA_PROMPT"/>
                                </MessageTitle>
                            )}
                        </Box>
                    ) : (
                        <MessageTitle>
                            {isLoading ? <FormattedMessage id="DATA_IS_BEING_LOADED"/> : <FormattedMessage id="NOT_ENOUGH_DATA_PROMPT"/>}
                        </MessageTitle>
                    )}

                    <ProgressingOverlay isOpen={isLoading} />
                </Box>
            </Paper>
        </Box>
    );
};

export default injectIntl(Industries);