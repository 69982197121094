import React from "react";
import {
    Tooltip, withStyles
} from "@material-ui/core";
import BrandCard from "../brand-card";

const CustomTooltip = withStyles({
    tooltip: {
        backgroundColor: "transparent"
    }
})(Tooltip);

const BrandTooltip = ({
    brandInfo,
    enableSalesAnalysisLink,
    useBrandLockStatus,
    showAddToCartButton,
    showAddToComparisonButton,
    children
}) => {
    return (brandInfo ? (
        <CustomTooltip title={
            <BrandCard
                brandInfo={brandInfo}
                enableSalesAnalysisLink={enableSalesAnalysisLink}
                useBrandLockStatus={useBrandLockStatus}
                showAddToCartButton={showAddToCartButton}
                showAddToComparisonButton={showAddToComparisonButton}
            />
        }
           arrow
           interactive
        >
            {children}
        </CustomTooltip>
    ) : (
        <>
            {children}
        </>
    ));
};

export default BrandTooltip;
