import React from "react";
import {
    Tooltip, withStyles
} from "@material-ui/core";
import IndustryCard from "../industry-card";

const CustomTooltip = withStyles({
    tooltip: {
        backgroundColor: "transparent"
    }
})(Tooltip);

const IndustryTooltip = ({
    countryInfo,
    industryInfo,
    children
}) => {
    return (industryInfo ? (
        <CustomTooltip title={
            <IndustryCard
                countryInfo={countryInfo}
                industryInfo={industryInfo}
            />
        }
           arrow
           interactive
        >
            {children}
        </CustomTooltip>
    ) : (
        <>
            {children}
        </>
    ));
};

export default IndustryTooltip;
