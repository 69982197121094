import React, {useRef} from "react";
import {Box, Paper, Tabs, Tab} from "@material-ui/core";
import _ from "lodash";
import {connect, useSelector} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";

import {
    prepareChartData,
    createChartOptions,
    useChartProperties,
    calculateStatsCardProperties,
    showTrendLineAnnotations,
    hideTrendLineAnnotations
} from "../chart";
import ChartCard, {ConclusionsTooltipButton} from "../chart-card";
import TabPanel, {generateTabProps} from "../tab-panel";
import MessageTitle from "../../../common/components/message-title";

import { selectors } from "../../../../_metronic/ducks/i18n";
import * as auth from "../../../store/ducks/auth.duck";

const GroupedChart = ({ brandId, countryId, industryId, industryDefinition, metricId, alertText, toggleHelpDrawer, user, intl }) => {
    const language = useSelector(selectors.getSelectedLanguage);

    const {
        isChartLoaded,
        scope,
        data,
        unit,
        dataFeatures,
        title,
        subTitle,
        description,
        releaseNotes,
        chartHeight,
        chartType,
        ChartComponent
    } = useChartProperties(brandId, countryId, industryId, industryDefinition, metricId, 350);

    const options = createChartOptions(chartType, _.get(data, "metric.config.options", {}), intl, language);

    const preparedData = prepareChartData(data, unit, scope, options);

    const groupMetricDatas = [];
    if (data && data.groupMetricDatas) {
        for (const groupMetricData of data.groupMetricDatas) {
            const options = createChartOptions(chartType, _.get(data, "metric.config.options", {}), intl, language);

            groupMetricDatas.push({
                label: groupMetricData.label,
                options: options,
                preparedData: prepareChartData(groupMetricData, null, scope, options)
            });
        }
    }

    const chartRefs = useRef([].slice(0, groupMetricDatas.length));

    const { statsCardViewMode } = calculateStatsCardProperties(preparedData, scope);

    const [activeTabIndex, setActiveTabIndex] = React.useState(-1);

    if (activeTabIndex === -1 && groupMetricDatas.length > 0) {
        const defaultGroupIndex = _.findIndex(groupMetricDatas, (groupMetricData) => {
            return (groupMetricData.preparedData.datasets[0].default);
        });
        setActiveTabIndex(defaultGroupIndex >= 0 ? defaultGroupIndex : 0);
    }

    let conclusions = (preparedData ? preparedData.conclusions : []);
    if (activeTabIndex >= 0 && groupMetricDatas.length > 0) {
        conclusions = (groupMetricDatas[activeTabIndex].preparedData.conclusions || []);
    }

    const handleTabChange = (event, tabIndex) => {
        setActiveTabIndex(tabIndex);
    };

    return (
        <ChartCard
            title={title}
            subTitle={subTitle}
            description={description}
            releaseNotes={releaseNotes}
            scope={scope}
            statsCardViewMode={statsCardViewMode}
            sums={preparedData ? preparedData.sums : []}
            averages={preparedData ? preparedData.averages : []}
            dataFeatures={dataFeatures}
            toggleHelpDrawer={toggleHelpDrawer}
            isLoading={!isChartLoaded}
        >
            <Box style={{display:"flex", flexDirection: "column", height: chartHeight}}>
                {(groupMetricDatas.length) ? (
                    <>
                        <Paper variant="outlined">
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <Box ml={2}>
                                    <ConclusionsTooltipButton
                                        conclusions={conclusions}
                                        onTooltipOpen={(conclusions) => {
                                            showTrendLineAnnotations(chartRefs.current[activeTabIndex], conclusions);
                                        }}
                                        onTooltipClose={() => {
                                            hideTrendLineAnnotations(chartRefs.current[activeTabIndex]);
                                        }}
                                    />
                                </Box>

                                <Tabs
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="on"
                                    value={activeTabIndex}
                                    onChange={handleTabChange}
                                >
                                    {groupMetricDatas.map((groupMetricData, index) => (
                                        <Tab key={index} label={groupMetricData.label} {...generateTabProps(index)} />
                                    ))}
                                </Tabs>
                            </Box>
                        </Paper>

                        <Box mt={1} style={{flexGrow: 1, overflow: "hidden"}}>
                            {groupMetricDatas.map((groupMetricData, index) => (
                                <TabPanel key={index} index={index} isHidden={activeTabIndex !== index}>
                                    {(groupMetricData.preparedData && groupMetricData.preparedData.datasets && groupMetricData.preparedData.datasets.length) ? (
                                        <ChartComponent
                                            ref={el => chartRefs.current[index] = el}
                                            data={groupMetricData.preparedData}
                                            options={groupMetricData.options}
                                        />
                                    ) : (
                                        <MessageTitle>
                                            {!isChartLoaded ? (
                                                <FormattedMessage id="DATA_IS_BEING_LOADED"/>
                                            ) : (alertText ? alertText : <FormattedMessage id="NOT_ENOUGH_DATA_PROMPT"/>)}
                                        </MessageTitle>
                                    )}
                                </TabPanel>
                            ))}
                        </Box>
                    </>
                ) : (
                    <MessageTitle>
                        {!isChartLoaded ? (
                            <FormattedMessage id="DATA_IS_BEING_LOADED"/>
                        ) : (alertText ? alertText : <FormattedMessage id="NOT_ENOUGH_DATA_PROMPT"/>)}
                    </MessageTitle>
                )}
            </Box>
        </ChartCard>
    );
};

const mapStateToProps = (state) => ({
    user: auth.selectors.getUser(state),
});

export default injectIntl(connect(mapStateToProps, null)(GroupedChart));