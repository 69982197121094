import React, {useEffect, useRef} from "react";
import { useHistory } from "react-router-dom";
import { Box, Paper, makeStyles, Typography, Button } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

import { LayoutSubheader } from "../../../_metronic";
import ImageBlock from "./image-block";
import Filters from "./filters";
import BrandCard from "../../common/components/brand-card";
import Pagination from "../../common/components/pagination";
import { connect, useDispatch, useSelector } from "react-redux";
import { parseParams, createHistoryLocation, generateCatalogUrl } from "../../utils/utils";
import * as auth from "../../store/ducks/auth.duck";
import * as catalog from "../../store/ducks/catalog.duck";
import * as popups from "../../store/ducks/popups.duck";
import GoogleAnalytics from "../../common/components/google-analytics";

const useStyles = makeStyles((theme) => ({
    companyCardGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
        gridGap: "1rem",
        "@media (max-width: 370px)": {
            gridTemplateColumns: "repeat(auto-fill, minmax(285px, 1fr))",
        },
    },
    companyCardEmptyGrid: {
        textAlign: "center"
    },
    wrapPagination: {
        "@media (max-width: 370px)": {
            paddingLeft: 3,
            paddingRight: 3,
        },
    },
}));

const Catalog = ({ user, userLoginTime }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const params = parseParams();
    const { isLoading } = useSelector(catalog.selectors.getCatalogLoadState);
    const brandInfos = useSelector(catalog.selectors.getCatalogBrandInfos);
    const totalPageCount = useSelector(catalog.selectors.getCatalogBrandTotalPageCount);

    const getGridProperties = (grid) => {
        const gridComputedStyle = window.getComputedStyle(grid);

        const columnCount = gridComputedStyle.getPropertyValue("grid-template-columns").split(" ").length;
        const rowCount = 3;

        return {
            columnCount,
            rowCount,
            maxItemCount: columnCount * rowCount
        };
    };

    const companyCardGrid = useRef(null);

    const handleSubmitFilters = (selectedFilters) => {
        const newParams = {
            ...selectedFilters,
            page: 1,
            limit: getGridProperties(companyCardGrid.current).maxItemCount,
        };

        GoogleAnalytics.reportEvent({
            category: 'Catalog',
            action: 'Search',
            label: `${newParams.region}/${newParams.industry}/${newParams.search}`
        });

        history.push(createHistoryLocation(generateCatalogUrl(), newParams));
        dispatch(catalog.actions.requestBrandInfos(newParams));
    };

    const handleNextPage = (page = 1) => {
        const newParams = {
            ...params,
            page,
            limit: getGridProperties(companyCardGrid.current).maxItemCount,
        };

        GoogleAnalytics.reportEvent({
            category: 'Catalog',
            action: 'NewPage',
            label: `${newParams.region}/${newParams.industry}/${newParams.search}`
        });

        history.push(createHistoryLocation(generateCatalogUrl(), newParams));
        dispatch(catalog.actions.requestBrandInfos(newParams));
    };

    useEffect(() => {
        // This timer is required to allow DOM calculate grid's properties properly!
        const nextPageTimer = setTimeout(() => {
            handleNextPage(1);
        }, 1 * 1000);

        const trialPopupTimer = setTimeout(() => {
            if (userLoginTime) {
                const currentTime = new Date();
                if (currentTime.getTime() - userLoginTime.getTime() <= 10 * 1000) {
                    if (user && user.capabilities["trial_request"]) {
                        dispatch(popups.actions.showTrialRequestPopup());
                    }
                }
            }
        }, 3 * 1000);

        return () => {
            clearTimeout(nextPageTimer);
            clearTimeout(trialPopupTimer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box>
            <LayoutSubheader
                title={<FormattedMessage id="CATALOG.CAPTION" />}
                component={
                    user && user.capabilities["trial_request"] && (
                        <Box>
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{fontSize: "1em"}}
                                onClick={() => {
                                    dispatch(popups.actions.showTrialRequestPopup());
                                }}
                            >
                                <FormattedMessage id="REQUEST.TRIAL.BUTTON.TEXT" />
                            </Button>
                        </Box>
                    )
                }
                imageComponent={<ImageBlock />}
            />

            <Filters onSubmit={handleSubmitFilters} isLoading={isLoading} />

            <Box mt={3} mb={2}>
                <Box className={classes.companyCardGrid} ref={companyCardGrid}>
                    {
                        brandInfos.length > 0 ? brandInfos.map((brandInfo) => (
                                <Box key={brandInfo.id}>
                                    <BrandCard
                                        brandInfo={brandInfo}
                                        enableSalesAnalysisLink={true}
                                        useBrandLockStatus={true}
                                        showAddToCartButton={true}
                                        showAddToComparisonButton={true}
                                    />
                                </Box>
                            )) :
                            // This empty box (simulates fake BrandCard) is required to allow DOM calculate grid's properties properly!
                            <Box style={{width: "100%"}} />
                    }
                </Box>

                {brandInfos.length === 0 && (
                    <Box className={classes.companyCardEmptyGrid}>
                        <Typography>
                            <FormattedMessage id="CAN_NOT_FIND_A_COMPANY_QUESTION" />
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                                dispatch(popups.actions.showSendMessagePopup("Cant' find a company!"))
                            }
                        >
                            <FormattedMessage id="CONTACT_US" />
                        </Button>
                    </Box>
                )}
            </Box>
            <Box>
                {!isNaN(totalPageCount) && totalPageCount > 0 && (
                    <Paper>
                        <Box className={classes.wrapPagination} p={2} pb={"3px"}>
                            <Pagination
                                totalPages={totalPageCount}
                                currentPage={Number(params.page) || 1}
                                onChange={handleNextPage}
                            />
                        </Box>
                    </Paper>
                )}
            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => ({
    user: auth.selectors.getUser(state),
    userLoginTime: auth.selectors.getUserLoginTime(state),
});

export default connect(mapStateToProps)(Catalog);
