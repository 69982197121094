import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import {connect, useDispatch, useSelector} from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextField, IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

import {
    getLastLocation,
    forgotLastLocation,
} from "../../router/RouterHelpers";
import logoImage from "../../../assets/images/logo.png";
import * as auth from "../../store/ducks/auth.duck";
import { selectors } from "../../../_metronic/ducks/i18n";
import { loginUser } from "../../crud/auth.crud";

import ProgressableButton from "../../common/components/progressable-button";
import GoogleAnalytics from "../../common/components/google-analytics";
import { getLandingUrl } from "../../crud/constants";
import * as alert from "../../store/ducks/alert.duck";

function Login({ isUserRegistered, isUserVerified, ...props }) {
    const { intl } = props;
    const dispatch = useDispatch();
    const language = useSelector(selectors.getSelectedLanguage);

    useEffect(() => {
        if (isUserRegistered) {
            setTimeout(() => props.setIsUserRegistered(false), 5000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUserRegistered]);

    useEffect(() => {
        if (getLastLocation().includes("/auth/verify")) {
            dispatch(alert.actions.showAlert({
                severity: (isUserVerified ? "success" : "error"),
                message: intl.formatMessage({id: (isUserVerified ? "AUTH.ACCOUNT_VERIFICATION_SUCCESS" : "AUTH.ACCOUNT_VERIFICATION_ERROR")})
            }));
            forgotLastLocation();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="kt-login__body">
                <div className="kt-login__form">
                    <div className="kt-login__logo">
                        <a
                            href={getLandingUrl("HOME", language)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img alt="Logo" src={logoImage} />
                        </a>
                    </div>
                    <div className="kt-login__title">
                        <h3>
                            <FormattedMessage id="AUTH.LOGIN.TITLE" />
                        </h3>
                    </div>

                    <Formik
                        initialValues={{
                            email: "",
                            password: "",
                        }}
                        validate={(values) => {
                            const errors = {};

                            if (!values.email) {
                                errors.email = intl.formatMessage({
                                    id: "VALIDATION.REQUIRED_FIELD",
                                });
                            }
                            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                                errors.email = intl.formatMessage({
                                    id: "VALIDATION.INVALID_FIELD",
                                });
                            }

                            if (!values.password) {
                                errors.password = intl.formatMessage({
                                    id: "VALIDATION.REQUIRED_FIELD",
                                });
                            }

                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            GoogleAnalytics.reportEvent({
                                category: 'Auth',
                                action: 'Login',
                                label: values.email
                            });

                            loginUser(values.email, values.password)
                                .then(({ data: { token } }) => {
                                    setSubmitting(false);

                                    props.loginUser(token);
                                })
                                .catch(() => {
                                    setSubmitting(false);
                                });
                        }}
                    >
                        {({
                              values,
                              status,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                          }) => (
                            <form
                                noValidate={true}
                                autoComplete="off"
                                className="kt-form"
                                onSubmit={handleSubmit}
                            >
                                {status && (
                                    <div role="alert" className="alert alert-danger">
                                        <div className="alert-text">{status}</div>
                                    </div>
                                )}

                                {isUserRegistered && (
                                    <div className="alert isUserRegistered">
                                        <div className="alert-text">
                                            <FormattedMessage id="AUTH.REGISTER.THANKS" />
                                        </div>
                                        <IconButton
                                            color="inherit"
                                            onClick={() => props.setIsUserRegistered(false)}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </div>
                                )}

                                <div className="form-group">
                                    <TextField
                                        type="email"
                                        label={<FormattedMessage id="INPUT.EMAIL" />}
                                        margin="normal"
                                        className="kt-width-full"
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.email}
                                        helperText={touched.email && errors.email}
                                        error={Boolean(touched.email && errors.email)}
                                    />
                                </div>

                                <div className="form-group">
                                    <TextField
                                        type="password"
                                        margin="normal"
                                        label={<FormattedMessage id="INPUT.PASSWORD" />}
                                        className="kt-width-full"
                                        name="password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.password}
                                        helperText={touched.password && errors.password}
                                        error={Boolean(touched.password && errors.password)}
                                    />
                                </div>

                                <div className="kt-login__head kt-login__actions">
                                    <Link
                                        to="/auth/password/recover"
                                        className="kt-link kt-login__link-forgot"
                                    >
                                        <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                                    </Link>

                                    <Link
                                        to="/auth/registration"
                                        className="kt-link kt-login__signup-link"
                                    >
                                        <FormattedMessage id="AUTH.REGISTER.TITLE" />
                                    </Link>

                                    <ProgressableButton
                                        type="submit"
                                        disabled={isSubmitting}
                                        isProgressing={isSubmitting}
                                    >
                                        <FormattedMessage id="AUTH.LOGIN.BUTTON" />
                                    </ProgressableButton>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    isUserRegistered: auth.selectors.getIsUserRegistered(state),
    isUserVerified: auth.selectors.getIsUserVerified(state),
});

export default injectIntl(connect(mapStateToProps, auth.actions)(Login));
