import React, {useEffect, createRef} from "react";
import {Box, Button, makeStyles} from "@material-ui/core";
import {connect, useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import { LayoutSubheader } from "../../../_metronic";

import {
    Portlet,
} from "../../partials/content/Portlet";
import ProgressingOverlay from "../../common/components/progressing-overlay";
import ComparisonBrandCard from "./comparison-brand-card";
import MetricPackageTabsAndFilters from "../../common/components/metric-package-tabs-and-filters";
import PackageTab from "../../partials/package-tab";
import MetricHelpDrawer from "../../common/components/metric-help-drawer";
import {generateComparisonUrl} from "../../utils/utils";
import * as auth from "../../store/ducks/auth.duck";
import * as comparison from "../../store/ducks/comparison.duck";
import * as brands from "../../store/ducks/brands.duck";
import _ from "lodash";
import {selectors} from "../../../_metronic/ducks/i18n";

const useStyles = makeStyles((theme) => ({
    portlet: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    comparisonBrandCardRemoveAllButton: {
        padding: "3px",
        fontSize: "0.8em"
    },
}));

const Comparison = ({
    match: { params: { requestedTabName } = {} } = {},
    user,
    ...props
}) => {
    const { intl } = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();

    const language = useSelector(selectors.getSelectedLanguage);

    const comparisonBrandInfos = useSelector(comparison.selectors.getComparisonBrandInfos);
    const areAllBrandInfosLoaded = useSelector(comparison.selectors.getAreAllBrandInfosLoaded);
    const brandDatas = useSelector(brands.selectors.getBrandDatas(comparisonBrandInfos.map((comparisonBrandInfo) => comparisonBrandInfo.id)));
    const areAllBrandInfosNotPurchased = brands.functions.areAllBrandInfosNotPurchased(brandDatas);
    const areAllBrandInfosNotDemo = brands.functions.areAllBrandInfosNotDemo(brandDatas);
    const supportedPackages = comparison.functions.getComparisonBrandSupportedPackages(brandDatas);
    const purchasedPackages = comparison.functions.getComparisonBrandPackages(brandDatas);
    const activePackageId = useSelector(comparison.selectors.getComparisonActivePackageId);

    const tabInfos = supportedPackages.map((supportedPackage) => {
        const purchasedPackage = purchasedPackages.find((purchasedPackage) => purchasedPackage.package.id === supportedPackage.id);
        return {
            id: supportedPackage.id,
            name: supportedPackage.name,
            title: supportedPackage.title,
            link: generateComparisonUrl({
                tabName: supportedPackage.name
            }),
            isPackagePurchased: !!purchasedPackage,
            areUpdatesAvailable: !!_.find((supportedPackage || {}).metrics || [], (metric) => {
                return !!_.get(metric, "config.releaseNotes");
            }),
        };
    });

    const { months, locations } = comparison.functions.getAvailableMonthsAndLocationsForComparison(brandDatas, activePackageId, language);

    const filters = useSelector(comparison.selectors.getComparisonActiveFilters);

    const metricHelpDrawer = createRef();

    if (!requestedTabName) {
        for (const tabInfo of tabInfos) {
            if (tabInfo.isPackagePurchased) {
                requestedTabName = tabInfo.name;
                break;
            }
        }
    }

    const { id: requestedPackageId } = tabInfos.find(({ name }) => name === requestedTabName) || {};
    const { name: activeTabName } = tabInfos.find(({ id }) => id === activePackageId) || {};

    const comparisonBrandCardWarnings = {};

    if (areAllBrandInfosLoaded) {
        for (const brandId of _.keys(brandDatas)) {
            const brandData = brandDatas[brandId];

            let warning = null;

            const brandInfo = brands.functions.getBrandInfo(brandData);
            if (!brandInfo.isPurchased && !brandInfo.isDemo) {
                warning = {
                    text: intl.formatMessage({id: "COMPARISON.WARNING.BRAND_IS_NOT_PURCHASED"}),
                    color: "red"
                };
            }
            else {
                if (filters && filters.period) {
                    const warningTexts = [];

                    if (filters.locationId) {
                        const brandLocations = brands.functions.getBrandLocations(brandData);
                        if (!brandLocations[filters.salesChannel].find(({ id }) => (id === filters.locationId))) {
                            warningTexts.push(intl.formatMessage({id: "COMPARISON.WARNING.LOCATION_IS_NOT_AVAILABLE_FOR_BRAND"}));
                        }
                    }

                    if (warningTexts.length) {
                        warning = {
                            text: warningTexts.map((warningText) => {
                                return (" * " + warningText);
                            }).join("\n"),
                            color: "yellow"
                        };
                    }
                }
                else {
                    warning = {
                        text: intl.formatMessage({id: "COMPARISON.WARNING.COMPARISON_IS_NOT_POSSIBLE"}),
                        color: "red"
                    };
                }
            }

            comparisonBrandCardWarnings[brandId] = warning;
        }
    }

    useEffect(() => {
        dispatch(comparison.actions.setComparisonActivePackageId({
            packageId: requestedPackageId,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestedPackageId]);

    return (
        <Box>
            <LayoutSubheader
                title={<FormattedMessage id="COMPARISON.LONG.CAPTION" />}
            />

            <Portlet className={classes.portlet}>
                {comparisonBrandInfos.length > 0 ? (
                    <>
                        {areAllBrandInfosLoaded ? (
                            <Box>
                                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" style={{overflow: "auto"}}>
                                    {comparisonBrandInfos.map((brandInfo, index) => (
                                        <ComparisonBrandCard
                                            key={index}
                                            brandInfo={brandInfo}
                                            brandInfoIndex={index}
                                            brandInfoCount={comparisonBrandInfos.length}
                                            warning={comparisonBrandCardWarnings[brandInfo.id]}
                                        />
                                    ))}
                                </Box>
                                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" style={{width: "100%"}}>
                                    <Button variant="text" color="primary" className={classes.comparisonBrandCardRemoveAllButton} onClick={() => {
                                        dispatch(comparison.actions.removeAllBrandsFromComparison());
                                    }}
                                    >
                                        <FormattedMessage id="COMPARISON.CLEAR_COMPARISON" />
                                    </Button>
                                </Box>
                            </Box>
                        ) : (
                            <Box className={classes.comparisonBrandCard}></Box>
                        )}
                        <ProgressingOverlay isOpen={!areAllBrandInfosLoaded} />
                    </>
                ) : (
                    <>
                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={2} style={{width: "100%"}}>
                            <Box>
                                <FormattedMessage id="COMPARISON_IS_EMPTY_PROMPT" />
                            </Box>
                            <Box mt={1}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        history.push({
                                            pathname: "/my-companies"
                                        });
                                    }}
                                >
                                    <FormattedMessage id="MY_COMPANIES.CAPTION" />
                                </Button>
                            </Box>
                            <Box mt={1}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        history.push({
                                            pathname: "/catalog"
                                        });
                                    }}
                                >
                                    <FormattedMessage id="CATALOG.CAPTION" />
                                </Button>
                            </Box>
                        </Box>
                    </>
                )}
            </Portlet>

            {comparisonBrandInfos.length > 0 && (
                <Box>
                    {areAllBrandInfosLoaded && filters && filters.period && (
                        <MetricPackageTabsAndFilters
                            tabInfos={tabInfos}
                            activeTabName={activeTabName}
                            locations={locations}
                            months={months}
                            options={{
                                posFilter: {
                                    visible: false,
                                },
                                periodFilter: {
                                    visible: true,
                                },
                                timeRanges: {
                                    reportTimeRange: {
                                        visible: true,
                                        presetListVisible: true,
                                        customizable: true,
                                        monthListVisible: true,
                                    },
                                    baseTimeRange: {
                                        visible: true,
                                        presetListVisible: true,
                                        customizable: true,
                                        clearable: true,
                                        monthListVisible: true,
                                    },
                                    swappable: true,
                                },
                                switches: {
                                    visible: (user && (user.capabilities["raw_data_mode"] || user.capabilities["index_data_mode"])),
                                    rawDataSwitch: {
                                        visible: (user && user.capabilities["raw_data_mode"]),
                                    },
                                    indexDataSwitch: {
                                        visible: (user && user.capabilities["index_data_mode"]),
                                    },
                                },
                            }}
                            filters={filters}
                            onChange={(filtersPortion) => {
                                dispatch(
                                    comparison.actions.setComparisonActiveFilters({
                                        filters: {
                                            ...filtersPortion
                                        },
                                    })
                                );
                            }}
                            shouldShowDemoDataWarning={areAllBrandInfosLoaded && areAllBrandInfosNotPurchased && areAllBrandInfosNotDemo}
                            isLoading={!areAllBrandInfosLoaded}
                        />
                    )}

                    {areAllBrandInfosLoaded && filters && filters.period && (
                        tabInfos.map(({ id, name }, index) =>
                            activeTabName === name && (
                                <React.Fragment key={`${index}-tab-panel`}>
                                    <PackageTab packageId={id} toggleHelpDrawer={(state) => {
                                        metricHelpDrawer.current.toggleHelpDrawer(state);
                                    }} />
                                </React.Fragment>
                            )
                        )
                    )}
                </Box>
            )}

            <MetricHelpDrawer ref={metricHelpDrawer} />
        </Box>
    );
};

const mapStateToProps = (state) => ({
    user: auth.selectors.getUser(state),
});

export default injectIntl(connect(mapStateToProps, null)(Comparison));
