import axios from "axios";

import { API_URL } from "./constants"

export const REGISTER_USER_URL = `${API_URL}/auth/register`;
export const VERIFY_USER_URL = `${API_URL}/auth/verify`;
export const LOGIN_USER_URL = `${API_URL}/auth/login`;
export const RECOVER_PASSWORD_URL = `${API_URL}/auth/password/recover`;
export const CHANGE_PASSWORD_URL = `${API_URL}/auth/password/change`;

export const USER_URL = `${API_URL}/user`;

export const registerUser = (email, password, first_name, last_name, phone, company, region) => {
  return axios.post(REGISTER_USER_URL, { email, password, first_name, last_name, phone, company, region });
}

export const verifyUser = (token) => {
  return axios.get(`${VERIFY_USER_URL}/${token}`);
}

export const loginUser = (email, password) => {
  return axios.post(LOGIN_USER_URL, { email, password });
}

export const recoverPassword = (email) => {
  return axios.post(RECOVER_PASSWORD_URL, { email });
}

export const changePassword = (token, password, confirmPassword) => {
  return axios.post(CHANGE_PASSWORD_URL, { token, password, confirmPassword });
}

export const getUser = () => {
  return axios.get(USER_URL);
}

export const updateUser = (password, first_name, last_name, phone, company, region) => {
  return axios.put(USER_URL, { password, first_name, last_name, phone, company, region });
}