import React, {useEffect, useState} from "react";
import {Box, IconButton, InputAdornment, TextField} from "@material-ui/core";
import {connect, useDispatch, useSelector} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";

import {LayoutSubheader} from "../../../_metronic";
import PhoneMaskedInput from "../../common/components/phone-masked-input";
import Tooltip from "../../common/components/tooltip";

import * as auth from "../../store/ducks/auth.duck";
import InfoIcon from "@material-ui/icons/Info";
import {Formik} from "formik";
import { updateUser } from "../../crud/auth.crud";
import * as alert from "../../store/ducks/alert.duck";

import ProgressableButton from "../../common/components/progressable-button";
import GoogleAnalytics from "../../common/components/google-analytics";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const Profile = (props) => {
    const { user, intl } = props;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(auth.actions.requestUser());
        // eslint-disable-next-line
    }, []);

    const { isLoading: isUserLoading } = useSelector(auth.selectors.getUserLoadState);

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const handleClickShowPassword = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
    const handleClickShowConfirmPassword = () => {
        setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
    };

    return (
        <Box className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1">
            <LayoutSubheader
                title={<FormattedMessage id="PROFILE.CAPTION"/>}
            />
            <Box mt={3} mb={2} flexGrow={1} className="kt-login__wrapper">
                <div className="kt-login__body">
                    <div className="kt-login__form">
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                first_name: user.first_name,
                                last_name: user.last_name,
                                phone: user.phone,
                                email: user.email,
                                company: user.company,
                                region: user.region,
                                password: "",
                                confirmPassword: ""
                            }}
                            validate={(values) => {
                                const errors = {};

                                if (
                                    values.first_name
                                        ? !/^[-a-zA-Zа-яА-ЯёЁ\s]+$/i.test(values.first_name)
                                        : false
                                ) {
                                    errors.first_name = intl.formatMessage({
                                        id: "VALIDATION.INVALID_FIELD",
                                    });
                                }
                                else if (!values.first_name) {
                                    errors.first_name = intl.formatMessage({
                                        id: "VALIDATION.REQUIRED_FIELD",
                                    });
                                }

                                if (
                                    values.last_name
                                        ? !/^[-a-zA-Zа-яА-ЯёЁ\s]+$/i.test(values.last_name)
                                        : false
                                ) {
                                    errors.last_name = intl.formatMessage({
                                        id: "VALIDATION.INVALID_FIELD",
                                    });
                                }

                                if (!values.password && values.confirmPassword) {
                                    errors.password = intl.formatMessage({
                                        id: "VALIDATION.REQUIRED_FIELD",
                                    });
                                }

                                if (values.password && !values.confirmPassword) {
                                    errors.confirmPassword = intl.formatMessage({
                                        id: "VALIDATION.REQUIRED_FIELD",
                                    });
                                }

                                if (values.password !== values.confirmPassword) {
                                    errors.confirmPassword = intl.formatMessage({
                                        id: "VALIDATION.PASSWORDS_SHOULD_MATCH",
                                    });
                                }

                                return errors;
                            }}
                            onSubmit={(values, { setStatus, setSubmitting }) => {
                                GoogleAnalytics.reportEvent({
                                    category: 'Profile',
                                    action: 'Update',
                                    label: values.email
                                });

                                updateUser(
                                    values.password,
                                    values.first_name,
                                    values.last_name,
                                    values.phone,
                                    values.company,
                                    values.region
                                )
                                    .then(({ data: { message } }) => {
                                        setSubmitting(false);

                                        dispatch(alert.actions.showAlert({
                                            severity: "success",
                                            message: message
                                        }));

                                        dispatch(auth.actions.requestUser());
                                    })
                                    .catch(() => {
                                        setSubmitting(false);
                                    });
                            }}
                        >
                            {({
                                  values,
                                  status,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                              }) => (
                                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                    {status && (
                                        <div role="alert" className="alert alert-danger">
                                            <div className="alert-text">{status}</div>
                                        </div>
                                    )}

                                    <div className="form-group mb-0">
                                        <TextField
                                            margin="normal"
                                            label={`${intl.formatMessage({id: "INPUT.FIRST.NAME"})} *`}
                                            className="kt-width-full"
                                            name="first_name"
                                            disabled={isUserLoading}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.first_name}
                                            helperText={touched.first_name && errors.first_name}
                                            error={Boolean(touched.first_name && errors.first_name)}
                                        />
                                    </div>

                                    <div className="form-group mb-0">
                                        <TextField
                                            margin="normal"
                                            label={<FormattedMessage id="INPUT.LAST.NAME" />}
                                            className="kt-width-full"
                                            name="last_name"
                                            disabled={isUserLoading}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.last_name}
                                            helperText={touched.last_name && errors.last_name}
                                            error={Boolean(touched.last_name && errors.last_name)}
                                        />
                                    </div>

                                    <div className="form-group mb-0">
                                        <TextField
                                            margin="normal"
                                            label={<FormattedMessage id="INPUT.PHONE" />}
                                            className="kt-width-full"
                                            name="phone"
                                            disabled={isUserLoading}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.phone}
                                            helperText={touched.phone && errors.phone}
                                            error={Boolean(touched.phone && errors.phone)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Tooltip
                                                            arrow
                                                            title={<FormattedMessage id="INPUT.PHONE.TOOLTIP" />}
                                                            placement="right"
                                                        >
                                                            <InfoIcon color="primary" />
                                                        </Tooltip>
                                                    </InputAdornment>
                                                ),
                                                inputComponent: PhoneMaskedInput,
                                            }}
                                        />
                                    </div>

                                    <div className="form-group mb-0">
                                        <TextField
                                            label={`${intl.formatMessage({id: "INPUT.EMAIL"})} *`}
                                            margin="normal"
                                            className="kt-width-full"
                                            name="email"
                                            disabled={true}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.email}
                                            helperText={touched.email && errors.email}
                                            error={Boolean(touched.email && errors.email)}
                                        />
                                    </div>

                                    <div className="form-group mb-0">
                                        <TextField
                                            margin="normal"
                                            label={<FormattedMessage id="INPUT.BRAND" />}
                                            className="kt-width-full"
                                            name="company"
                                            disabled={isUserLoading}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.company}
                                        />
                                    </div>

                                    <div className="form-group mb-0">
                                        <TextField
                                            margin="normal"
                                            label={<FormattedMessage id="INPUT.REGION" />}
                                            className="kt-width-full"
                                            name="region"
                                            disabled={isUserLoading}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.region}
                                        />
                                    </div>

                                    <div className="form-group mb-0">
                                        <TextField
                                            type={isPasswordVisible ? 'text' : 'password'}
                                            margin="normal"
                                            label={`${intl.formatMessage({id: "INPUT.PASSWORD.CHANGE"})}`}
                                            className="kt-width-full"
                                            name="password"
                                            disabled={isUserLoading}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.password}
                                            helperText={touched.password && errors.password}
                                            error={Boolean(touched.password && errors.password)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                        >
                                                            {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <TextField
                                            type={isConfirmPasswordVisible ? 'text' : 'password'}
                                            margin="normal"
                                            label={`${intl.formatMessage({id: "INPUT.CONFIRM.PASSWORD"})}`}
                                            className="kt-width-full"
                                            name="confirmPassword"
                                            disabled={isUserLoading}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.confirmPassword}
                                            helperText={touched.confirmPassword && errors.confirmPassword}
                                            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowConfirmPassword}
                                                        >
                                                            {isConfirmPasswordVisible ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>

                                    <ProgressableButton
                                        type="submit"
                                        disabled={isUserLoading || isSubmitting}
                                        isProgressing={isUserLoading || isSubmitting}
                                    >
                                        <FormattedMessage id="PROFILE.SAVE_BUTTON" />
                                    </ProgressableButton>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => ({
    user: auth.selectors.getUser(state),
});

export default injectIntl(connect(mapStateToProps, null)(Profile));
