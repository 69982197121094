import {all, put, select, takeEvery} from "redux-saga/effects";
import {createAction, handleActions} from "redux-actions";
import { createSelector } from "reselect";
import * as packagesCrud from "../../crud/packages.crud";
import * as brandsCrud from "../../crud/brands.crud";
import * as metricsCrud from "../../crud/metrics.crud";
import {createActionName, getStateHashKey, wrapSafely} from "./utils";
import {createMonthTimeRange, createSeveralMonthTimeRange, parseTimeRange} from "../../utils/utils";
import _ from "lodash";
import * as auth from "./auth.duck";

const TAG = "BRANDS";

export const actions = {
    requestBrandInfo: createAction(createActionName(TAG, "requestBrandInfo")),
    onBrandInfoRequested: createAction(createActionName(TAG, "onBrandInfoRequested")),
    fillBrandInfo: createAction(createActionName(TAG, "fillBrandInfo")),

    setBrandActivePackageId: createAction(createActionName(TAG, "setBrandActivePackageId")),
    fillBrandActivePackageId: createAction(createActionName(TAG, "fillBrandActivePackageId")),

    setBrandActiveFilters: createAction(createActionName(TAG, "setBrandActiveFilters")),
    setBrandMetricActiveFilters: createAction(createActionName(TAG, "setBrandMetricActiveFilters")),

    requestBrandMetricData: createAction(createActionName(TAG, "requestBrandMetricData")),
    fillBrandMetricData: createAction(createActionName(TAG, "fillBrandMetricData")),

    requestBrandMetricDataExport: createAction(createActionName(TAG, "requestBrandMetricDataExport")),
};

const defaultState = {
    brandDatas: {},
    brandMetricDatas: {},
};

const initialState = _.clone(defaultState);

export const reducer = handleActions(
    {
        [actions.requestBrandInfo]: (state, { payload: { brandId } }) => {
            state.brandDatas = {
                ...state.brandDatas,
                [brandId]: {
                    ...state.brandDatas[brandId],
                    isLoading: true,
                    // Reuse isLoaded from previous state to not reload brand info every time when package changes.
                }
            };

            return { ...state };
        },

        [actions.fillBrandInfo]: (state, { payload: { brandId, data } }) => {
            state.brandDatas = {
                ...state.brandDatas,
                [brandId]: {
                    ...state.brandDatas[brandId],
                    item: data.brand,
                    supportedPackages: data.supportedPackages,
                    packages: data.packages,
                    activePackageId: undefined,
                    locations: data.locations,
                    filters: {},
                    metricFilters: {},
                    isLoading: false,
                    isLoaded: true,
                }
            };

            return { ...state };
        },

        [actions.fillBrandActivePackageId]: (state, { payload: { brandId, packageId } }) => {
            state.brandDatas = {
                ...state.brandDatas,
                [brandId]: {
                    ...state.brandDatas[brandId],
                    activePackageId: packageId,
                }
            };

            return { ...state };
        },

        [actions.setBrandActiveFilters]: (state, { payload: { brandId, filters } }) => {
            if (filters) {
                filters = {
                    ...state.brandDatas[brandId].filters,
                    ...filters,
                };
            }

            state.brandDatas = {
                ...state.brandDatas,
                [brandId]: {
                    ...state.brandDatas[brandId],
                    filters
                }
            };

            return { ...state };
        },

        [actions.setBrandMetricActiveFilters]: (state, { payload: { brandId, metricId, filters } }) => {
            if (filters) {
                filters = {
                    ...state.brandDatas[brandId].metricFilters[metricId],
                    ...filters,
                };
            }

            state.brandDatas = {
                ...state.brandDatas,
                [brandId]: {
                    ...state.brandDatas[brandId],
                    metricFilters: {
                        ...state.brandDatas[brandId].metricFilters,
                        [metricId]: {
                            ...state.brandDatas[brandId].metricFilters[metricId],
                            ...filters,
                        }
                    }
                }
            };

            return { ...state };
        },

        [actions.requestBrandMetricData]: (state, { payload: { brandId, metricId, filters, metricFilters } }) => {
            const key = getStateHashKey({brandId, metricId, filters, metricFilters});

            state.brandMetricDatas = {
                ...state.brandMetricDatas,
                [key]: {
                    ...state.brandMetricDatas[key],
                    isLoading: true,
                    isLoaded: false,
                }
            };

            return { ...state };
        },

        [actions.fillBrandMetricData]: (state, { payload: { brandId, metricId, filters, metricFilters, data } }) => {
            const key = getStateHashKey({brandId, metricId, filters, metricFilters});

            state.brandMetricDatas = {
                ...state.brandMetricDatas,
                [key]: {
                    ...state.brandMetricDatas[key],
                    ...data,
                    isLoading: false,
                    isLoaded: true,
                }
            };

            return { ...state };
        },

        [auth.actionTypes.LogoutUser]: () => {
            console.log(`LogoutUser in ${TAG}`);
            return _.clone(defaultState);
        }
    },
    initialState
);

function *fetchAndFillMetricData(brandId, metricId, filters) {
    const metricFilters = yield select(selectors.getBrandMetricActiveFilters(brandId, metricId));

    yield put(
        actions.requestBrandMetricData({
            brandId,
            metricId,
            filters,
            metricFilters
        })
    );

    const data = yield metricsCrud.fetchBrandMetricData(
        brandId,
        metricId,
        filters.period,
        [
            filters.reportTimeRange,
            filters.baseTimeRange
        ],
        filters.salesChannel,
        filters.locationId,
        filters.posId,
        metricFilters,
        filters.isRawDataMode,
        filters.isIndexDataMode
    );

    yield put(
        actions.fillBrandMetricData({
            brandId,
            metricId,
            filters,
            metricFilters,
            data
        })
    );
}

export function* saga() {
    yield takeEvery(actions.requestBrandInfo, wrapSafely(function*({ payload: { brandId, packageId, month, scope } }) {
        const { isLoaded: isBrandInfoLoaded } = yield select(selectors.getBrandInfoLoadState(brandId));
        if (!isBrandInfoLoaded) {
            const supportedPackagesData = yield packagesCrud.getSupportedPackages();
            const supportedPackages = (supportedPackagesData.data.items || []).map((item) => ({
                ...item
            }));

            const brandPackagesData = yield brandsCrud.getBrandPackages(brandId);

            const data = {
                supportedPackages: supportedPackages,
                ...brandPackagesData.data,
                packages: brandPackagesData.data.packages || [],
            };

            _.forEach(data.packages, (packageInfo) => {
                if (packageInfo.recommendedFilters) {
                    packageInfo.recommendedFilters.reportTimeRange = parseTimeRange(packageInfo.recommendedFilters.reportTimeRange);
                    packageInfo.recommendedFilters.baseTimeRange = parseTimeRange(packageInfo.recommendedFilters.baseTimeRange);
                }
            });

            yield put(
                actions.fillBrandInfo({
                    brandId, data
                })
            );
        }

        if (packageId) {
            yield put(
                actions.fillBrandActivePackageId({
                    brandId,
                    packageId,
                })
            );

            let filters = yield select(selectors.getBrandActiveFilters(brandId));
            if (!filters || !filters.period) {
                filters = yield select(selectors.getBrandPackageRecommendedFilters(brandId, packageId));
            }

            if (month) {
                const packageMonths = yield select(selectors.getBrandPackageMonths(brandId, packageId));
                const monthIndex = _.indexOf(packageMonths, month);
                if (monthIndex >= 0) {
                    filters = {
                        salesChannel: "all",
                        period: "week",
                        reportTimeRange: createMonthTimeRange(month),
                        baseTimeRange: undefined
                    };

                    if (monthIndex >= 1) {
                        filters.baseTimeRange = createSeveralMonthTimeRange(packageMonths, monthIndex - 1, 1, undefined);
                    }
                }
            }

            yield put(
                actions.setBrandActiveFilters({
                    brandId,
                    filters,
                })
            );
        }

        yield put(
            actions.onBrandInfoRequested({
                brandId,
                packageId,
                month,
                scope
            })
        );
    }, TAG, "requestBrandInfo"));

    yield takeEvery(actions.setBrandActivePackageId, wrapSafely(function*({ payload: { brandId, packageId, month } }) {
        const activePackageId = yield select(selectors.getBrandActivePackageId(brandId));
        if (!activePackageId || activePackageId !== packageId) {
            yield put(
                actions.fillBrandActivePackageId({
                    brandId,
                    packageId,
                })
            );
        }

        yield put(
            actions.requestBrandInfo({
                brandId,
                packageId,
                month,
                scope: "brand",
            })
        );
    }, TAG, "setBrandActivePackageId"));

    yield takeEvery(actions.setBrandActiveFilters, wrapSafely(function*({ payload: { brandId } }) {
        const filters = yield select(selectors.getBrandActiveFilters(brandId));
        if (filters && filters.period) {
            const supportedPackages = yield select(selectors.getBrandSupportedPackages(brandId));
            const activePackageId = yield select(selectors.getBrandActivePackageId(brandId));
            const activePackage = supportedPackages.find((item) => item.id === activePackageId) || {};

            const metricIds = _.map(_.filter(activePackage.metrics, (metric) => _.includes(metric.scopes, "brand")), "id");
            yield all(metricIds.map((metricId) => fetchAndFillMetricData(brandId, metricId, filters)));
        }
    }, TAG, "setBrandActiveFilters"));

    yield takeEvery(actions.setBrandMetricActiveFilters, wrapSafely(function*({ payload: { brandId, metricId } }) {
        const filters = yield select(selectors.getBrandActiveFilters(brandId));
        if (filters && filters.period) {
            yield fetchAndFillMetricData(brandId, metricId, filters);
        }
    }, TAG, "setBrandMetricActiveFilters"));

    yield takeEvery(actions.requestBrandMetricDataExport, wrapSafely(function*({ payload: { brandId, metricId } }) {
        const brand = yield select(selectors.getBrandInfo(brandId));
        const filters = yield select(selectors.getBrandActiveFilters(brandId));
        const metricFilters = yield select(selectors.getBrandMetricActiveFilters(brandId, metricId));

        const supportedPackages = yield select(selectors.getBrandSupportedPackages(brandId));
        const activePackageId = yield select(selectors.getBrandActivePackageId(brandId));
        const activePackage = supportedPackages.find((item) => item.id === activePackageId) || {};
        const metric = activePackage.metrics.find((item) => item.id === metricId) || {};

        const response = yield metricsCrud.requestBrandMetricDataExport(
            brandId,
            metricId,
            filters.period,
            [
                filters.reportTimeRange,
                filters.baseTimeRange
            ],
            filters.salesChannel,
            filters.locationId,
            filters.posId,
            metricFilters,
            filters.isRawDataMode,
            filters.isIndexDataMode
        );

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response.data]));
        link.setAttribute('download', `${brand.name}-${metric.title}.xlsx`);
        link.click();
    }, TAG, "requestBrandMetricDataExport"));
}

const getBrands = (state) => state.brands;

export const functions = {
    getBrandData: (brands, brandId) => {
        return (brands.brandDatas[brandId] || {});
    },

    getBrandInfo: (brandData) => {
        return (brandData.item || {});
    },

    getBrandInfoLoadState: (brandData) => {
        return {
            isLoading: !!brandData.isLoading,
            isLoaded: !!brandData.isLoaded
        };
    },

    getBrandInfosLoadStates: (brands) => {
        const result = {};

        for (const brandId of _.keys(brands.brandDatas)) {
            const brandData = functions.getBrandData(brands, brandId);
            result[brandId] = functions.getBrandInfoLoadState(brandData);
        }

        return result;
    },

    areAllBrandInfosNotPurchased: (brandDatas) => {
        brandDatas = _.values(brandDatas);
        return !_.find(brandDatas, (brandData) => functions.getBrandInfo(brandData).isPurchased);
    },

    areAllBrandInfosNotDemo: (brandDatas) => {
        brandDatas = _.values(brandDatas);
        return !_.find(brandDatas, (brandData) => functions.getBrandInfo(brandData).isDemo);
    },

    getBrandSupportedPackages: (brandData) => {
        return (brandData.supportedPackages || []);
    },

    getBrandPackages: (brandData) => {
        return (brandData.packages || []);
    },

    getBrandPackage: (brandData, packageId) => {
        return (functions.getBrandPackages(brandData).find(
            (p) => `${p.package.id}` === `${packageId}`
        ) || {});
    },

    getBrandPackageMonths: (brandData, packageId) => {
        const metricPackage = functions.getBrandPackage(brandData, packageId);
        return (metricPackage.months || []);
    },

    getBrandLocations: (brandData) => {
        return (brandData.locations || []);
    },

    getBrandDatas: (brands, brandIds) => {
        const result = {};

        for (const brandId of brandIds) {
            result[brandId] = functions.getBrandData(brands, brandId);
        }

        return result;
    },

    getBrandMetricData: (brands, brandId, metricId) => {
        const brandData = functions.getBrandData(brands, brandId);
        const filters = brandData.filters;
        const metricFilters = brandData.metricFilters[metricId];
        return _.get(brands.brandMetricDatas, getStateHashKey({brandId, metricId, filters, metricFilters}), {});
    },
};

const getBrandData = (brandId) => createSelector(getBrands, (brands) => {
    return functions.getBrandData(brands, brandId);
});

export const selectors = {
    getBrands: createSelector(getBrands, (brands) => brands),

    getBrandInfo: (brandId) => createSelector(getBrandData(brandId), (brandData) => brandData.item),

    getBrandInfoLoadState: (brandId) => createSelector(getBrandData(brandId), (brandData) => {
        return functions.getBrandInfoLoadState(brandData);
    }),

    getBrandInfosLoadStates: createSelector(getBrands, (brands) => {
        return functions.getBrandInfosLoadStates(brands);
    }),

    getBrandDatas: (brandIds) => createSelector(getBrands, (brands) => {
        return functions.getBrandDatas(brands, brandIds);
    }),

    getBrandSupportedPackages: (brandId) => createSelector(getBrandData(brandId), (brandData) => {
        return functions.getBrandSupportedPackages(brandData);
    }),

    getBrandPackages: (brandId) => createSelector(getBrandData(brandId), (brandData) => {
        return functions.getBrandPackages(brandData);
    }),

    getBrandActivePackageId: (brandId) => createSelector(getBrandData(brandId), (brandData) => {
        return brandData.activePackageId;
    }),

    getBrandPackage: (brandId, packageId) => createSelector(getBrandData(brandId), (brandData) => {
        return functions.getBrandPackage(brandData, packageId);
    }),

    getBrandPackageMonths: (brandId, packageId) => createSelector(getBrandData(brandId), (brandData) => {
        return _.orderBy(functions.getBrandPackageMonths(brandData, packageId), null, ["asc"]);
    }),

    getBrandPackageRecommendedFilters: (brandId, packageId) => createSelector(getBrandData(brandId), (brandData) => {
        const packageInfo = functions.getBrandPackage(brandData, packageId);
        return packageInfo.recommendedFilters;
    }),

    getBrandLocations: (brandId) => createSelector(getBrandData(brandId), (brandData) => {
        return functions.getBrandLocations(brandData);
    }),

    getBrandActiveFilters: (brandId) => createSelector(getBrandData(brandId), (brandData) => {
        return brandData.filters;
    }),

    getBrandMetricActiveFilters: (brandId, metricId) => createSelector(getBrandData(brandId), (brandData) => {
        return brandData.metricFilters[metricId];
    }),

    getBrandMetricData: (brandId, metricId) => createSelector(getBrands, (brands) => {
        return functions.getBrandMetricData(brands, brandId, metricId);
    }),

    getIsBrandMetricDataLoaded: (brandId, metricId) => createSelector(getBrands, (brands) => {
        return functions.getBrandMetricData(brands, brandId, metricId).isLoaded;
    }),
};
