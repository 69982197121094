import React, {useRef, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {
    Box,
    Typography,
    Divider,
    Button,
    makeStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    withStyles,
    Checkbox,
    FormControlLabel,
} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";

import * as auth from "../../store/ducks/auth.duck";
import * as popups from "../../store/ducks/popups.duck";
import ProgressingOverlay from "../../common/components/progressing-overlay";
import BrandTooltip from "../../common/components/brand-tooltip";
import AddToCartButton from "../../common/components/add-to-cart-button";
import AddToComparisonButton from "../../common/components/add-to-comparison-button";
import {generateCatalogUrl, getLogoImageUrl} from "../../utils/utils";
import InfoIcon from "@material-ui/icons/Info";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import CloseIcon from "@material-ui/icons/Close";
import {Link} from "react-router-dom";
import GoogleMapReact from "google-map-react";
import { IS_CART_ENABLED } from "../../crud/constants";
import _ from "lodash";
import {selectors} from "../../../_metronic/ducks/i18n";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUnlock} from "@fortawesome/free-solid-svg-icons";
import Badge from "../../common/components/badge";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
    },
    wrapBlock: {
        "@media (max-width: 768px)": {
            flexDirection: "column",
            "& > div": {
                "&:first-child": {
                    marginBottom: 8,
                },
            },
        },
    },

    divider: {
        "@media (max-width: 596px)": {
            display: "none",
        },
    },
    info: {
        "@media (max-width: 596px)": {
            marginLeft: 0,
        },
        "@media (max-width: 450px)": {
            justifyContent: "center",
        },
    },
}));

const DialogActionsPadded = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    }
}))(DialogActions);

const BrandLocationsMapPopup = ({ isOpen, onClose, brandInfo, locations, onLocationSelected, locale, user, ...props }) => {
    const { intl } = props;

    const [mapState, setMapState] = useState({
        mapsApi: null,
        mapInstance: null,
        markers: [],

        shouldShowLocations: true,
        shouldShowPoses: true,
    });

    const mapContainer = useRef();

    const isObjectGeoValid = (object) => {
        return (object.geo_coordinates && object.geo_coordinates.latitude && object.geo_coordinates.longitude);
    };

    const locationCount = _.reduce(locations, (acc, location) => {
        return (acc + (isObjectGeoValid(location) ? 1 : 0));
    }, 0);

    const calculateGeoValidPosCount = (location) => {
        return _.reduce(location.poses, (acc, pos) => {
            return (acc + (isObjectGeoValid(pos) ? 1 : 0));
        }, 0);
    };

    const posCount = _.reduce(locations, (acc, location) => {
        return (acc + calculateGeoValidPosCount(location));
    }, 0);

    const loadMap = (mapsApi, mapInstance) => {
        const mapBounds = new mapsApi.LatLngBounds();

        const infoWindow = new mapsApi.InfoWindow();

        const addMarker = (type, data) => {
            const colors = {
                "location": "blue",
                "pos": "red"
            };

            const marker = new mapsApi.Marker({
                map: mapInstance,
                object: {
                    type: type,
                    data: data
                },
                icon: {
                    url: `http://maps.google.com/mapfiles/ms/icons/${colors[type]}-dot.png`
                },
                position: {
                    lat: Number(data.geo_coordinates.latitude),
                    lng: Number(data.geo_coordinates.longitude)
                },
            });

            mapState.markers.push(marker);

            marker.addListener("click", () => {
                let content = "";

                let location;
                let pos;
                if (marker.object.type === "location") {
                    location = marker.object.data;
                    const locationPosMapCount = calculateGeoValidPosCount(location);

                    content += `<p><b>${intl.formatMessage({id: "LOCATION.TOOLTIP.PROMPT"})}${location.name}</b></p>`;
                    content += `<p>${intl.formatMessage({id: "LOCATION.POS.COUNT.TOOLTIP.TEMPLATE"}, {totalCount: location.poses.length, mapCount: locationPosMapCount})}</p>`;

                    content += `<p><a id="markerInfoWindowLocationSelectButton" href="#">${intl.formatMessage({id: "LOCATION.SELECT.PROMPT"})}</a></p>`;
                    if (locationPosMapCount > 0) {
                        content += `<p><a id="markerInfoWindowLocationPosesShowButton" href="#">${intl.formatMessage({id: "LOCATION.SHOW.POSES.PROMPT.TEMPLATE"}, {count: locationPosMapCount})}</a></p>`;
                    }
                }
                else if (marker.object.type === "pos") {
                    pos = marker.object.data;
                    location = pos.location;
                    const locationPosMapCount = calculateGeoValidPosCount(location);

                    content += `<p><b>${intl.formatMessage({id: "POS.TOOLTIP.PROMPT"})}${pos.address}</b></p>`;
                    content += `<p>${intl.formatMessage({id: "LOCATION.TOOLTIP.PROMPT"})}${location.name}</p>`;

                    content += `<p><a id="markerInfoWindowLocationSelectButton" href="#">${intl.formatMessage({id: "LOCATION.SELECT.PROMPT"})}</a></p>`;
                    content += `<p><a id="markerInfoWindowPosSelectButton" href="#">${intl.formatMessage({id: "POS.SELECT.PROMPT"})}</a></p>`;
                    if (locationPosMapCount > 0) {
                        content += `<p><a id="markerInfoWindowLocationPosesShowButton" href="#">${intl.formatMessage({id: "LOCATION.SHOW.POSES.PROMPT.TEMPLATE"}, {count: locationPosMapCount})}</a></p>`;
                    }
                }
                else {
                    console.assert(false);
                }

                mapsApi.event.addListener(infoWindow, "domready", () => {
                    let button;

                    button = document.getElementById("markerInfoWindowLocationSelectButton");
                    if (button) {
                        button.addEventListener("click", () => {
                            onLocationSelected({
                                locationId: location.id,
                                posId: null
                            });
                            infoWindow.close();
                        });
                    }

                    button = document.getElementById("markerInfoWindowPosSelectButton");
                    if (button) {
                        button.addEventListener("click", () => {
                            onLocationSelected({
                                locationId: location.id,
                                posId: pos.id
                            });
                            infoWindow.close();
                        });
                    }

                    button = document.getElementById("markerInfoWindowLocationPosesShowButton");
                    if (button) {
                        button.addEventListener("click", () => {
                            for (const marker of mapState.markers) {
                                if (marker.object.type === "location") {
                                    marker.setMap(null);
                                }
                                else if (marker.object.type === "pos") {
                                    marker.setMap(marker.object.data.location.id === location.id ? mapInstance : null);
                                }
                                else {
                                    console.assert(false);
                                }
                            }
                            infoWindow.close();
                            setMapState({
                                ...mapState,
                                mapInstance: mapInstance,
                                shouldShowLocations: false,
                                shouldShowPoses: false,
                            });
                        });
                    }
                });

                infoWindow.setContent(content);

                infoWindow.open({
                    anchor: marker,
                    map: mapInstance,
                });
            });

            mapBounds.extend(marker.position);
        };

        _.forEach(locations, (location) => {
            if (user && user.capabilities["location_filter"]) {
                if (isObjectGeoValid(location)) {
                    addMarker("location", location);
                }
            }

            if (user && user.capabilities["pos_filter"]) {
                _.forEach(location.poses, (pos) => {
                    if (isObjectGeoValid(pos)) {
                        addMarker("pos", {
                            ...pos,
                            location
                        });
                    }
                });
            }
        });

        mapInstance.fitBounds(mapBounds);
    };

    return (
        <Dialog
            PaperProps={
                {
                    style: {
                        minHeight: "90%",
                        maxHeight: "90%",
                    }
                }
            }
            maxWidth={"md"}
            fullWidth={true}
            TransitionProps={
                {
                    onEntered: () => {
                        mapContainer.current.style.height = mapContainer.current.getBoundingClientRect().height + "px";
                    }
                }
            }
            open={!!isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box display={"flex"} flexDirection={"row"}>
                        <Box mr={1}>
                            <LocationOnIcon color="primary"/>
                        </Box>
                        <Typography variant="h6">
                            <FormattedMessage id="BRAND.LOCATIONS.MAP.POPUP.TITLE" />
                        </Typography>
                    </Box>
                    <IconButton aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>

            <DialogContent dividers style={{
                display: "flex",
                flexDirection: "column"
            }}>
                <Box flexGrow={1} display="flex" flexDirection="column">
                    <Box display="flex" flexDirection="row">
                        <FormControlLabel
                            control={
                                <Checkbox color="primary" checked={mapState.shouldShowLocations} onChange={() => {
                                    const shouldShowLocations = !mapState.shouldShowLocations;
                                    setMapState({
                                        ...mapState,
                                        shouldShowLocations: shouldShowLocations,
                                    });
                                    for (const marker of mapState.markers) {
                                        if (marker.object.type === "location") {
                                            marker.setMap(shouldShowLocations ? mapState.mapInstance : null);
                                        }
                                    }
                                }} />
                            }
                            label={intl.formatMessage({id: "LOCATIONS.CHECKBOX.LABEL.TEMPLATE"}, {count: locationCount})}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox color="primary" style={{ marginLeft: "20px" }} checked={mapState.shouldShowPoses} onChange={() => {
                                    const shouldShowPoses = !mapState.shouldShowPoses;
                                    setMapState({
                                        ...mapState,
                                        shouldShowPoses: shouldShowPoses,
                                    });
                                    for (const marker of mapState.markers) {
                                        if (marker.object.type === "pos") {
                                            marker.setMap(shouldShowPoses ? mapState.mapInstance : null);
                                        }
                                    }
                                }} />
                            }
                            label={intl.formatMessage({id: "POSES.CHECKBOX.LABEL.TEMPLATE"}, {count: posCount})}
                        />
                    </Box>

                    <Box flexGrow={1} ref={mapContainer}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "AIzaSyDVfG8H-SY9aKD2zJn0D5TRHMhhp3l3DR4" }}
                            defaultCenter={{
                                lat: 0,
                                lng: 0
                            }}
                            defaultZoom={1}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => {
                                setMapState({
                                    ...mapState,
                                    mapApi: maps,
                                    mapInstance: map,
                                });
                                loadMap(maps, map);
                            }}
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActionsPadded>
                <Button color="primary" variant="contained" onClick={onClose}>
                    <FormattedMessage id="CLOSE_BUTTON_TITLE" />
                </Button>
            </DialogActionsPadded>
        </Dialog>
    );
};

const Header = ({
    brandInfo,
    locations,
    onLocationSelected,
    isBrandInfoLoaded,
    onUnlockRequest,
    user,
    ...props
}) => {
    const { intl } = props;
    const dispatch = useDispatch();
    const classes = useStyles();

    const language = useSelector(selectors.getSelectedLanguage);

    const [brandLocationsMapPopupState, setBrandLocationsMapPopupState] = useState({
        isOpen: false,
    });

    return (
        <Box width="100%" className={classes.root}>
            <BrandLocationsMapPopup
                isOpen={brandLocationsMapPopupState.isOpen}
                onClose={() => {
                    setBrandLocationsMapPopupState({
                        ...brandLocationsMapPopupState,
                        isOpen: false
                    });
                }}
                brandInfo={brandInfo}
                locations={locations["offline"]}
                onLocationSelected={onLocationSelected}
                locale={language}
                intl={intl}
                user={user}
            />

            <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
                className={classes.wrapBlock}
            >
                {isBrandInfoLoaded ? (
                    <>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexWrap="wrap"
                        >
                            {brandInfo.logo && (
                                <Box maxWidth={50} marginRight="20px">
                                    <img
                                        alt={brandInfo.name}
                                        src={getLogoImageUrl(brandInfo.logo)}
                                        style={{ width: "100%" }}
                                    />
                                </Box>
                            )}
                            <Box color="#434349">
                                <Typography variant="h6">{brandInfo.name}</Typography>
                            </Box>

                            <Box p={1} display="flex" alignItems="center">
                                <BrandTooltip
                                    brandInfo={brandInfo}
                                    enableSalesAnalysisLink={false}
                                    useBrandLockStatus={false}
                                    showAddToCartButton={false}
                                    showAddToComparisonButton={false}
                                >
                                    <InfoIcon color="primary" />
                                </BrandTooltip>
                            </Box>

                            {brandInfo.isDemo && (
                                <Box mr={1}>
                                    <Badge color={"green"} text={<FormattedMessage id="BRAND.DEMO.BADGE.TEXT" />} />
                                </Box>
                            )}

                            <Divider
                                orientation="vertical"
                                flexItem
                                className={classes.divider}
                            />

                            <Box
                                display="flex"
                                flexWrap="wrap"
                                ml={1}
                                p={1}
                                className={classes.info}
                            >
                                {brandInfo.country && (
                                    <Box display="flex" mr={2}>
                                        <Typography color="textSecondary">
                                            <FormattedMessage id="INPUT.REGION" />:
                                        </Typography>
                                        <Box ml={0.5}>
                                            <Link
                                                to={generateCatalogUrl({
                                                    region: brandInfo.country.code
                                                })}
                                            >
                                                <Typography color="primary">{brandInfo.country.code.toUpperCase()}</Typography>
                                            </Link>
                                        </Box>
                                    </Box>
                                )}
                                {brandInfo.industry && (
                                    <Box display="flex">
                                        <Typography color="textSecondary">
                                            <FormattedMessage id="SALES_ANALYSIS.INDUSTRY" />:
                                        </Typography>
                                        <Box ml={0.5}>
                                            <Link
                                                to={generateCatalogUrl({
                                                    industry: brandInfo.industry.id
                                                })}
                                            >
                                                <Typography color="primary">{brandInfo.industry.name}</Typography>
                                            </Link>
                                        </Box>
                                    </Box>
                                )}
                            </Box>

                            {(user && user.capabilities["brand_presence_map"] && (user.capabilities["location_filter"] || user.capabilities["pos_filter"])) && (
                                <Box ml={1}>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<LocationOnIcon />}
                                        onClick={() => {
                                            setBrandLocationsMapPopupState({
                                                isOpen: true
                                            });
                                        }}
                                    >
                                        <FormattedMessage id="MAP.BUTTON.TITLE" />
                                    </Button>
                                </Box>
                            )}

                            {!brandInfo.isPurchased && !brandInfo.isDemo && (
                                <Box ml={1}>
                                    <Button variant="contained" color="secondary" startIcon={<FontAwesomeIcon icon={faUnlock} size={"lg"} />} onClick={onUnlockRequest}>
                                        <FormattedMessage id="ACCESS.REQUEST.BUTTON" />
                                    </Button>
                                </Box>
                            )}
                        </Box>

                        <Box display="flex" flexDirection="row" alignItems="center">
                            {!brandInfo.isPurchased && !brandInfo.isDemo && IS_CART_ENABLED && (
                                <Box ml={1}>
                                    <AddToCartButton
                                        brandInfo={brandInfo}
                                        text={<FormattedMessage id="ADD_TO_CART_BUTTON.ADD_TO_CART" />}
                                    />
                                </Box>
                            )}

                            <Box ml={1}>
                                <AddToComparisonButton brandInfo={brandInfo} />
                            </Box>

                            {user && user.capabilities["trial_request"] && (
                                <Box ml={1}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        style={{fontSize: "1em"}}
                                        onClick={() => {
                                            dispatch(popups.actions.showTrialRequestPopup());
                                        }}
                                    >
                                        <FormattedMessage id="REQUEST.TRIAL.BUTTON.TEXT" />
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </>
                ) : (
                    <></>
                )}
            </Box>
            <ProgressingOverlay isOpen={!isBrandInfoLoaded} />
        </Box>
    );
};

const mapStateToProps = (state) => ({
    user: auth.selectors.getUser(state)
});

export default injectIntl(connect(mapStateToProps)(Header));
