import React from "react";
import clsx from "clsx";

const ProgressableButton = ({isProgressing, children, ...props}) => {
  return (
      <button
          className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
              {
                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": isProgressing,
              }
          )}`}
          {...props}
      >
        {children}
      </button>
  );
};

export default ProgressableButton;