import React, { useEffect } from "react";
import {connect, useDispatch} from "react-redux";
import {
  Grid,
  TextField,
  FormControl,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";

import * as auth from "../../../store/ducks/auth.duck";
import * as cart from "../../../store/ducks/cart.duck";
import Cover from "../cover";

const useFormControlStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const useHelperTextStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "-20px",
    right: 0,
    color: "red",
  },
}));

const schema = Yup.object().shape({
  first_name: Yup.string().required(
    <FormattedMessage id="VALIDATION.REQUIRED_FIELD" />
  ),
  last_name: Yup.string().required(
    <FormattedMessage id="VALIDATION.REQUIRED_FIELD" />
  ),
  phone: Yup.string().required(
    <FormattedMessage id="VALIDATION.REQUIRED_FIELD" />
  ),
  company: Yup.string().required(
    <FormattedMessage id="VALIDATION.REQUIRED_FIELD" />
  ),
  email: Yup.string()
    .email(<FormattedMessage id="VALIDATION.INVALID_FIELD" />)
    .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
  region: Yup.string().required(
    <FormattedMessage id="VALIDATION.REQUIRED_FIELD" />
  ),
});

const CustomerDataForm = ({ user }) => {
  const dispatch = useDispatch();

  const classFormControl = useFormControlStyles();
  const classHelperText = useHelperTextStyles();

  const ChangeUserData = () => {
    const { values } = useFormikContext();
    useEffect(() => {
      dispatch(cart.actions.setUserData({ ...values, language: user.language }));
    }, [values]);

    return null;
  };

  return (
    <Cover title={<FormattedMessage id="CART.CUSTOMER_DATA" />}>
      <Formik
        initialValues={{
          first_name: user.first_name || "",
          last_name: user.last_name || "",
          phone: user.phone || "",
          company: user.company || "",
          email: user.email || "",
          region: user.region || "",
        }}
        validationSchema={schema}
        validateOnMount
      >
        {({ handleChange, values, errors }) => (
          <form noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item md={4} sm={6} xs={12}>
                <FormControl className={classFormControl.root}>
                  <TextField
                    id="first_name"
                    label={<FormattedMessage id="INPUT.FIRST.NAME" />}
                    variant="outlined"
                    onChange={handleChange}
                    defaultValue={values.first_name}
                  />
                  <FormHelperText className={classHelperText.root}>
                    {errors.first_name}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <FormControl className={classFormControl.root}>
                  <TextField
                    id="last_name"
                    label={<FormattedMessage id="INPUT.LAST.NAME" />}
                    variant="outlined"
                    onChange={handleChange}
                    defaultValue={values.last_name}
                  />
                  <FormHelperText className={classHelperText.root}>
                    {errors.last_name}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <FormControl className={classFormControl.root}>
                  <TextField
                    id="phone"
                    label={<FormattedMessage id="INPUT.PHONE" />}
                    variant="outlined"
                    defaultValue={values.phone}
                    onChange={handleChange}
                  />
                  <FormHelperText className={classHelperText.root}>
                    {errors.phone}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <FormControl className={classFormControl.root}>
                  <TextField
                    id="company"
                    label={<FormattedMessage id="INPUT.BRAND" />}
                    variant="outlined"
                    defaultValue={values.company}
                    onChange={handleChange}
                  />
                  <FormHelperText className={classHelperText.root}>
                    {errors.company}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <FormControl className={classFormControl.root}>
                  <TextField
                    id="email"
                    label={<FormattedMessage id="INPUT.EMAIL" />}
                    variant="outlined"
                    defaultValue={values.email}
                    onChange={handleChange}
                  />
                  <FormHelperText className={classHelperText.root}>
                    {errors.email}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <FormControl className={classFormControl.root}>
                  <TextField
                    id="region"
                    label={<FormattedMessage id="INPUT.REGION" />}
                    variant="outlined"
                    defaultValue={values.region}
                    onChange={handleChange}
                  />
                  <FormHelperText className={classHelperText.root}>
                    {errors.region}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <ChangeUserData />
          </form>
        )}
      </Formik>
    </Cover>
  );
};

const mapStateToProps = (state) => ({
  user: auth.selectors.getUser(state),
});

export default connect(mapStateToProps, null)(CustomerDataForm);
