import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { createSelector } from "reselect";

import { getUser } from "../../crud/auth.crud";
import * as routerHelpers from "../../router/RouterHelpers";
import { createActionName, wrapSafely } from "./utils";
import _ from "lodash";

const TAG = "AUTH";

export const actionTypes = {
    RegisterUser: createActionName(TAG, "RegisterUser"),
    VerifyUser: createActionName(TAG, "VerifyUser"),

    SetIsUserRegistered: createActionName(TAG, "SetIsUserRegistered"),
    SetIsUserVerified: createActionName(TAG, "SetIsUserVerified"),

    LoginUser: createActionName(TAG, "LoginUser"),
    LogoutUser: createActionName(TAG, "LogoutUser"),

    RequestUser: createActionName(TAG, "RequestUser"),
    FillUser: createActionName(TAG, "FillUser"),
};

export const actions = {
    registerUser: (authToken) => ({
        type: actionTypes.RegisterUser,
        payload: { authToken },
    }),

    verifyUser: () => ({
        type: actionTypes.VerifyUser
    }),

    setIsUserRegistered: (isUserRegistered) => ({
        type: actionTypes.SetIsUserRegistered,
        payload: { isUserRegistered },
    }),

    setIsVerified: (isUserVerified) => ({
        type: actionTypes.SetIsUserVerified,
        payload: { isUserVerified },
    }),

    loginUser: (authToken) => ({
        type: actionTypes.LoginUser,
        payload: { authToken }
    }),

    logoutUser: () => ({
        type: actionTypes.LogoutUser
    }),

    requestUser: (user) => ({
        type: actionTypes.RequestUser,
        payload: { user },
    }),

    fillUser: (user) => ({
        type: actionTypes.FillUser,
        payload: { user }
    }),
};

const defaultState = {
    user: undefined,
    isUserLoading: false,
    isUserLoaded: false,
    userLoginTime: undefined,
    authToken: undefined,
    isUserRegistered: false,
    isUserVerified: false,
};

const initialState = _.clone(defaultState);

const reducerKey = "auth-key";

export const reducer = persistReducer(
    { storage, key: reducerKey, whitelist: ["user", "authToken"] },
    (state = initialState, action) => {
        switch (action.type) {
            case actionTypes.RegisterUser: {
                const { authToken } = action.payload;
                return { authToken, user: undefined };
            }

            case actionTypes.VerifyUser: {
                return { ...state };
            }

            case actionTypes.SetIsUserRegistered: {
                const { isUserRegistered } = action.payload;
                return {
                    ...state,
                    isUserRegistered: isUserRegistered,
                };
            }

            case actionTypes.SetIsUserVerified: {
                const { isUserVerified } = action.payload;
                return {
                    ...state,
                    isUserVerified: isUserVerified,
                };
            }

            case actionTypes.LoginUser: {
                const { authToken } = action.payload;
                return {
                    authToken,
                    user: undefined,
                    userLoginTime: new Date()
                };
            }

            case actionTypes.LogoutUser: {
                console.log(`LogoutUser in ${TAG}`);
                routerHelpers.forgotLastLocation();
                storage.removeItem(`persist:${reducerKey}`);
                return _.clone(defaultState);
            }

            case actionTypes.RequestUser: {
                return {
                    ...state,
                    isUserLoading: true,
                    isUserLoaded: false,
                };
            }

            case actionTypes.FillUser: {
                const { user } = action.payload;
                return {
                    ...state,
                    user: user,
                    isUserLoading: false,
                    isUserLoaded: true,
                };
            }

            default:
                return state;
        }
    }
);

export function* saga() {
    yield takeLatest(actionTypes.RegisterUser, wrapSafely(function*() {
        yield put(actions.setIsUserRegistered(true));
    }, TAG, "registerUser"));

    yield takeLatest(actionTypes.VerifyUser, wrapSafely(function*() {
        yield put(actions.setIsVerified(true));
    }, TAG, "verifyUser"));

    yield takeLatest(actionTypes.LoginUser, wrapSafely(function*() {
        yield put(actions.requestUser());
    }, TAG, "loginUser"));

    yield takeLatest(actionTypes.RequestUser, wrapSafely(function*() {
        const { data: { user } = {} } = yield getUser();
        yield put(actions.fillUser(user));
    }, TAG, "requestUser"));
}

const getAuth = (state) => state.auth;

export const selectors = {
    getUser: createSelector(getAuth, (auth) => auth.user),
    getUserLoadState: createSelector(getAuth, (auth) => {
        return {
            isLoading: auth.isUserLoading,
            isLoaded: auth.isUserLoaded
        };
    }),
    getUserLoginTime: createSelector(getAuth, (auth) => auth.userLoginTime),
    getIsUserRegistered: createSelector(getAuth, (auth) => auth.isUserRegistered),
    getIsUserVerified: createSelector(getAuth, (auth) => auth.isUserVerified),
};
