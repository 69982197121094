import React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import Topbar from "./Topbar";
import { LayoutContextConsumer } from "../LayoutContext";
import AnimateLoading from "../../../app/partials/layout/AnimateLoading";
import KTHeader from "../../_assets/js/header";
import * as builder from "../../ducks/builder";
import { Box, Divider, Typography, withStyles } from "@material-ui/core";

const styles = () => ({
  wrapHeader: {
    "@media (max-width: 1040px)": {
      padding: 16,
    },
  },
});

class Header extends React.Component {
  headerCommonRef = React.createRef();

  componentDidMount() {
    let options = {};
    if (
      this.headerCommonRef.current.getAttribute("data-ktheader-minimize") ===
      "1"
    ) {
      options["minimize.desktop.on"] = "kt-header--minimize";
      options["offset.desktop"] = 130;
    }

    // eslint-disable-next-line no-undef
    new KTHeader(this.headerCommonRef.current, options);
  }

  render() {
    const { headerAttributes, headerClasses, classes } = this.props;

    return (
      <div
        className={`kt-header kt-grid__item ${headerClasses}`}
        id="kt_header"
        ref={this.headerCommonRef}
        {...headerAttributes}
      >
        <AnimateLoading />
        <Box
          p={1}
          pl={3}
          className={classes.wrapHeader}
          display="flex"
          alignItems="center"
          width="100%"
        >
          <LayoutContextConsumer>
            {({ subheader: { title, breadcrumb, component } }) => (
              <>
                {title && (
                  <Box color="#434349" whiteSpace="nowrap">
                    <Typography variant="h6">{title}</Typography>
                  </Box>
                )}
                {component ? (
                  <Box
                    display="flex"
                    marginLeft={title ? "20px" : "0"}
                    width="100%"
                  >
                    {title && <Divider orientation="vertical" flexItem />}
                    <Box
                      marginLeft={title ? "20px" : "0"}
                      width="100%"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {component}
                    </Box>
                  </Box>
                ) : null}
              </>
            )}
          </LayoutContextConsumer>
        </Box>

        <Topbar />
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  headerAttributes: builder.selectors.getAttributes(store, { path: "header" }),
  headerClasses: builder.selectors.getClasses(store, {
    path: "header",
    toString: true,
  }),
  menuHeaderDisplay: objectPath.get(
    store.builder.layoutConfig,
    "header.menu.self.display"
  ),
  fluid:
    objectPath.get(store.builder.layoutConfig, "header.self.width") === "fluid",
});

export default connect(mapStateToProps)(withStyles(styles)(Header));
