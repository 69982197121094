import axios from "axios";
import {API_URL} from "./constants"

export const fetchBrandMetricData = async (brandId, metricId, period, timeRanges, salesChannel, locationId, posId, metricFilters, isRawDataMode, isIndexDataMode) => {
    const { data } = await axios.get(
        `${API_URL}/purchase/brand/${brandId}/metric/${metricId}`,
        {
            params: {
                period,
                timeRanges,
                salesChannel,
                locationId,
                posId,
                metricFilters,
                isRawDataMode,
                isIndexDataMode,
            },
        }
    );
    return data;
};

export const fetchComparisonMetricData = async (brandIds, metricId, period, timeRanges, salesChannel, locationId, metricFilters, isRawDataMode, isIndexDataMode) => {
    const { data } = await axios.get(
        `${API_URL}/purchase/brands/${brandIds}/metric/${metricId}`,
        {
            params: {
                period,
                timeRanges,
                salesChannel,
                locationId,
                metricFilters,
                isRawDataMode,
                isIndexDataMode,
            },
        }
    );
    return data;
};

export const requestBrandMetricDataExport = async (brandId, metricId, period, timeRanges, salesChannel, locationId, posId, metricFilters, isRawDataMode, isIndexDataMode) => {
    return await axios.get(
        `${API_URL}/purchase/brand/${brandId}/metric/${metricId}/export`,
        {
            params: {
                period,
                timeRanges,
                salesChannel,
                locationId,
                posId,
                metricFilters,
                isRawDataMode,
                isIndexDataMode,
            },
            responseType: 'blob'
        }
    );
};