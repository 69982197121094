import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {all, put, select, takeEvery} from "redux-saga/effects";
import {createAction} from "redux-actions";
import { createSelector } from "reselect";
import {createActionName, getStateHashKey, wrapSafely} from "./utils";
import {createMonthTimeRange, isTimeRangeWithinMonths} from "../../utils/utils";
import * as brands from "./brands.duck";
import * as metricsCrud from "../../crud/metrics.crud";
import _ from "lodash";
import * as auth from "./auth.duck";

export const comparisonBrandMaxCount = 5;

const TAG = "COMPARISON";

export const actions = {
    addBrandToComparison: createAction(createActionName(TAG, "addBrandToComparison")),
    removeBrandFromComparison: createAction(createActionName(TAG, "removeBrandFromComparison")),
    removeAllBrandsFromComparison: createAction(createActionName(TAG, "removeAllBrandsFromComparison")),

    swapBrandsInComparison: createAction(createActionName(TAG, "swapBrandsInComparison")),

    updateBrandInfo: createAction(createActionName(TAG, "updateBrandInfo")),

    requestBrandInfos: createAction(createActionName(TAG, "requestBrandInfos")),

    setComparisonActivePackageId: createAction(createActionName(TAG, "setComparisonActivePackageId")),
    fillComparisonActivePackageId: createAction(createActionName(TAG, "fillComparisonActivePackageId")),

    setComparisonActiveFilters: createAction(createActionName(TAG, "setComparisonActiveFilters")),
    setComparisonMetricActiveFilters: createAction(createActionName(TAG, "setComparisonMetricActiveFilters")),

    requestComparisonMetricData: createAction(createActionName(TAG, "requestComparisonMetricData")),
    fillComparisonMetricData: createAction(createActionName(TAG, "fillComparisonMetricData")),
};

const defaultState = {
    brandInfos: [],
    activePackageId: undefined,
    filters: {},
    metricFilters: {},
    brandMetricDatas: {}
};

const initialState = _.clone(defaultState);

const reducerKey = "comparison-key";

export const reducer = persistReducer(
    { storage, key: reducerKey, whitelist: ["brandInfos"] },
    (state = initialState, action) => {
        switch (action.type) {
            case actions.addBrandToComparison().type: {
                const { brandInfo } = action.payload;
                const existingBrandInfo = _.find(state.brandInfos, (el) => {
                    return (el.id === brandInfo.id);
                });
                if (!existingBrandInfo) {
                    state.brandInfos.push(brandInfo);
                }

                return {
                    ...state,
                    brandInfos: [...state.brandInfos]
                };
            }

            case actions.removeBrandFromComparison().type: {
                const { id } = action.payload;
                _.remove(state.brandInfos, (el) => {
                    return (el.id === id);
                });

                return {
                    ...state,
                    brandInfos: [...state.brandInfos]
                };
            }

            case actions.removeAllBrandsFromComparison().type: {
                state.brandInfos = [];

                return {
                    ...state
                };
            }

            case actions.swapBrandsInComparison().type: {
                const { index, withIndex } = action.payload;

                const temp = state.brandInfos[index];
                state.brandInfos[index] = state.brandInfos[withIndex];
                state.brandInfos[withIndex] = temp;

                return {
                    ...state,
                    brandInfos: [...state.brandInfos]
                };
            }

            case actions.updateBrandInfo().type: {
                const { brandInfo } = action.payload;
                const existingBrandInfoIndex = _.findIndex(state.brandInfos, (el) => {
                    return (el.id === brandInfo.id);
                });
                if (existingBrandInfoIndex) {
                    state.brandInfos[existingBrandInfoIndex] = brandInfo;
                }

                return {
                    ...state,
                    brandInfos: [...state.brandInfos]
                };
            }

            case actions.fillComparisonActivePackageId().type: {
                const { packageId } = action.payload;

                return {
                    ...state,
                    activePackageId: packageId
                };
            }

            case actions.setComparisonActiveFilters().type: {
                let { filters } = action.payload;
                if (filters) {
                    filters = {
                        ...state.filters,
                        ...filters,
                    };
                }

                return {
                    ...state,
                    filters
                };
            }

            case actions.setComparisonMetricActiveFilters().type: {
                let { metricId, filters } = action.payload;
                if (filters) {
                    filters = {
                        ...state.metricFilters[metricId],
                        ...filters,
                    };
                }

                state.metricFilters[metricId] = {
                    ...state.metricFilters[metricId],
                    ...filters,
                };

                return { ...state };
            }

            case actions.requestComparisonMetricData().type: {
                const { brandIds, metricId, filters, metricFilters } = action.payload;
                
                const key = getStateHashKey({brandIds, metricId, filters, metricFilters});

                state.brandMetricDatas = {
                    ...state.brandMetricDatas,
                    [key]: {
                        ...state.brandMetricDatas[key],
                        isLoading: true,
                        isLoaded: false,
                    }
                };

                return { ...state };
            }

            case actions.fillComparisonMetricData().type: {
                const { brandIds, metricId, filters, metricFilters, data } = action.payload;

                const key = getStateHashKey({brandIds, metricId, filters, metricFilters});

                state.brandMetricDatas = {
                    ...state.brandMetricDatas,
                    [key]: {
                        ...state.brandMetricDatas[key],
                        ...data,
                        isLoading: false,
                        isLoaded: true,
                    }
                };

                return { ...state };
            }

            case auth.actionTypes.LogoutUser: {
                console.log(`LogoutUser in ${TAG}`);
                storage.removeItem(`persist:${reducerKey}`);
                return _.clone(defaultState);
            }

            default:
                return state;
        }
    }
);

function *rebuildComparisonActiveFiltersIfNeeded() {
    let result = false;

    const currentFilters = yield select(selectors.getComparisonActiveFilters);
    let updatedFilters = undefined;

    const comparisonBrandInfos = yield select(selectors.getComparisonBrandInfos);
    if (comparisonBrandInfos.length > 0) {
        const areAllBrandInfosLoaded = yield select(selectors.getAreAllBrandInfosLoaded);
        if (areAllBrandInfosLoaded) {
            const brandDatas = yield select(brands.selectors.getBrandDatas(_.map(comparisonBrandInfos, "id")));
            const activePackageId = yield select(selectors.getComparisonActivePackageId);
            const { months, locations } = functions.getAvailableMonthsAndLocationsForComparison(brandDatas, activePackageId);
            if (months.length > 0) {
                const areFiltersInvalid = (filters) => {
                    return (
                        (!filters || !filters.period) ||
                        (!filters.reportTimeRange || !isTimeRangeWithinMonths(filters.reportTimeRange, months)) ||
                        (!filters.baseTimeRange || !isTimeRangeWithinMonths(filters.baseTimeRange, months)) ||
                        (filters.location && !_.find(locations, ({ id }) => (id === filters.location)))
                    );
                };
                if (areFiltersInvalid(currentFilters)) {
                    // Try to use the first brand's recommended filters.
                    updatedFilters = yield select(brands.selectors.getBrandPackageRecommendedFilters(comparisonBrandInfos[0].id, activePackageId));
                    if (areFiltersInvalid(updatedFilters)) {
                        updatedFilters.reportTimeRange = createMonthTimeRange(_.nth(months, -1));
                        if (months.length >= 2) {
                            updatedFilters.baseTimeRange = createMonthTimeRange(_.nth(months, -2));
                        }
                        updatedFilters.location = null;
                    }
                }
            }
            else {
                updatedFilters = null;
            }
        }
    }
    else {
        updatedFilters = null;
    }

    if (updatedFilters !== undefined && !_.isEqual(currentFilters, updatedFilters)) {
        yield put(
            actions.setComparisonActiveFilters({filters: updatedFilters})
        );
        result = true;
    }

    return result;
}

function *fetchComparisonMetricDatasIfPossible(metricIds) {
    const comparisonBrandInfos = yield select(selectors.getComparisonBrandInfos);
    if (comparisonBrandInfos.length > 0) {
        const areAllBrandInfosLoaded = yield select(selectors.getAreAllBrandInfosLoaded);
        if (areAllBrandInfosLoaded) {
            const filters = yield select(selectors.getComparisonActiveFilters);
            if (filters && filters.period) {
                const comparisonBrandIds = comparisonBrandInfos.map((comparisonBrandInfo) => comparisonBrandInfo.id);
                const brandDatas = yield select(brands.selectors.getBrandDatas(comparisonBrandIds));
                const brandIds = functions.getComparableBrandIds(brandDatas, comparisonBrandIds);

                if (brandIds.length > 0) {
                    if (!metricIds) {
                        const supportedPackages = functions.getComparisonBrandSupportedPackages(brandDatas);
                        const activePackageId = yield select(selectors.getComparisonActivePackageId);
                        const activePackage = _.find(supportedPackages, (supportedPackage) => supportedPackage.id === activePackageId);
                        metricIds = _.map(_.filter(activePackage.metrics, (metric) => _.includes(metric.scopes, "comparison")), "id");
                    }

                    function *fetchAndFillMetricData(metricId) {
                        const metricFilters = yield select(selectors.getComparisonMetricActiveFilters(metricId));

                        yield put(
                            actions.requestComparisonMetricData({
                                brandIds, 
                                metricId, 
                                filters, 
                                metricFilters
                            })
                        );
                        
                        const data = yield metricsCrud.fetchComparisonMetricData(
                            brandIds,
                            metricId,
                            filters.period,
                            [
                                filters.reportTimeRange,
                                filters.baseTimeRange
                            ],
                            filters.salesChannel,
                            filters.locationId,
                            metricFilters,
                            filters.isRawDataMode,
                            filters.isIndexDataMode
                        );

                        yield put(
                            actions.fillComparisonMetricData({
                                brandIds,
                                metricId,
                                filters,
                                metricFilters,
                                data
                            })
                        );
                    }

                    yield all(metricIds.map((metricId) => fetchAndFillMetricData(metricId)));
                }
            }
        }
    }
}

export function* saga() {
    yield takeEvery(actions.addBrandToComparison, wrapSafely(function*() {
        yield rebuildComparisonActiveFiltersIfNeeded();
    }, TAG, "addBrandToComparison"));

    yield takeEvery(actions.removeBrandFromComparison, wrapSafely(function*() {
        yield rebuildComparisonActiveFiltersIfNeeded();
    }, TAG, "removeBrandFromComparison"));

    yield takeEvery(actions.requestBrandInfos, wrapSafely(function*({ payload: { packageId } }) {
        const comparisonBrandInfos = yield select(selectors.getComparisonBrandInfos);
        for (const comparisonBrandInfo of comparisonBrandInfos) {
            comparisonBrandInfo.brandInfoRequestCount = (comparisonBrandInfo.brandInfoRequestCount || 0) + 1;
            yield put(
                actions.updateBrandInfo({
                    brandInfo: comparisonBrandInfo
                })
            );

            yield put(
                brands.actions.requestBrandInfo({
                    brandId: comparisonBrandInfo.id,
                    packageId: packageId,
                    scope: "comparison",
                })
            );
        }
    }, TAG, "requestBrandInfos"));

    yield takeEvery(brands.actions.onBrandInfoRequested, wrapSafely(function*({ payload: { brandId, scope } }) {
        if (scope === "comparison") {
            const comparisonBrandInfos = yield select(selectors.getComparisonBrandInfos);
            if (comparisonBrandInfos.length > 0) {
                const comparisonBrandInfo = _.find(comparisonBrandInfos, (comparisonBrandInfo) => comparisonBrandInfo.id === brandId);
                if (comparisonBrandInfo) {
                    console.assert(comparisonBrandInfo.brandInfoRequestCount > 0);
                    --comparisonBrandInfo.brandInfoRequestCount;

                    yield put(
                        actions.updateBrandInfo({
                            brandInfo: comparisonBrandInfo
                        })
                    );

                    const brandInfoRequestCount = _.reduce(comparisonBrandInfos, (acc, comparisonBrandInfo) => {
                        return (acc + comparisonBrandInfo.brandInfoRequestCount);
                    }, 0);
                    if (brandInfoRequestCount === 0) {
                        const result = yield rebuildComparisonActiveFiltersIfNeeded();
                        if (!result) {
                            yield fetchComparisonMetricDatasIfPossible();
                        }
                    }
                }
            }
        }
    }, TAG, "onBrandInfoRequested"));

    yield takeEvery(actions.setComparisonActivePackageId, wrapSafely(function*({ payload: { packageId } }) {
        const activePackageId = yield select(selectors.getComparisonActivePackageId);
        if (!activePackageId || activePackageId !== packageId) {
            yield put(
                actions.fillComparisonActivePackageId({
                    packageId,
                })
            );
        }

        yield put(
            actions.requestBrandInfos({
                packageId,
            })
        );
    }, TAG, "setComparisonActivePackageId"));

    yield takeEvery(actions.setComparisonActiveFilters, wrapSafely(function*() {
        yield fetchComparisonMetricDatasIfPossible();
    }, TAG, "setComparisonActiveFilters"));

    yield takeEvery(actions.setComparisonMetricActiveFilters, wrapSafely(function*({ payload: { metricId } }) {
        yield fetchComparisonMetricDatasIfPossible([metricId]);
    }, TAG, "setComparisonMetricActiveFilters"));
}

export const functions = {
    getComparisonBrandSupportedPackages: (brandDatas) => {
        let supportedPackages = [];

        supportedPackages = {};
        _.forEach(brandDatas, (brandData) => {
            const brandSupportedPackages = brands.functions.getBrandSupportedPackages(brandData);
            _.forEach(brandSupportedPackages, (brandSupportedPackage) => {
                supportedPackages[brandSupportedPackage.id] = brandSupportedPackage;
            });
        });

        supportedPackages = _.values(supportedPackages);
        supportedPackages = _.filter(supportedPackages, (supportedPackage) => {
            return !!_.find(supportedPackage.metrics, (metric) => _.includes(metric.scopes, "comparison"));
        });
        supportedPackages = _.orderBy(supportedPackages, "id", ["asc"]);

        return supportedPackages;
    },

    getComparisonBrandPackages: (brandDatas) => {
        let packages = [];

        packages = {};
        _.forEach(brandDatas, (brandData) => {
            const brandPackages = brands.functions.getBrandPackages(brandData);
            _.forEach(brandPackages, (brandPackage) => {
                packages[brandPackage.package.id] = brandPackage;
            });
        });

        packages = _.values(packages);
        packages = _.orderBy(packages, "package.id", ["asc"]);

        return packages;
    },

    getComparableBrandIds: (brandDatas, brandIds) => {
        const comparableBrandIds = [];

        const areAllBrandInfosNotPurchased = brands.functions.areAllBrandInfosNotPurchased(brandDatas);

        for (const brandId of brandIds) {
            const brandInfo = brands.functions.getBrandInfo(brandDatas[brandId]);
            if (areAllBrandInfosNotPurchased || (brandInfo.isPurchased || brandInfo.isDemo)) {
                comparableBrandIds.push(brandId);
            }
        }

        return comparableBrandIds;
    },

    getAvailableMonthsAndLocationsForComparison: (brandDatas, packageId, language) => {
        let months = [];
        const locations = {};

        const brandMonthsArray = [];
        for (const brandId of _.keys(brandDatas)) {
            const brandData = brandDatas[brandId];
            const brandMonths = brands.functions.getBrandPackageMonths(brandData, packageId);
            brandMonthsArray.push(brandMonths);

            const brandLocations = brands.functions.getBrandLocations(brandData);
            _.forEach(brandLocations, (brandSalesChannelLocations, salesChannel) => {
                locations[salesChannel] = _.concat(locations[salesChannel] || [], brandSalesChannelLocations);
            });
        }

        months = _.intersection(...brandMonthsArray);
        months = _.orderBy(months, null, ["asc"]);

        _.forEach(locations, (salesChannelLocations, salesChannel) => {
            locations[salesChannel] = _.uniqBy(salesChannelLocations, "id");
            locations[salesChannel].sort((a, b) => {
                return a.name.localeCompare(b.name, language);
            });
        });

        return {
            months,
            locations
        }
    },

    getComparisonMetricData: (comparisonState, brandsState, metricId) => {
        const comparisonBrandIds = comparisonState.brandInfos.map((comparisonBrandInfo) => comparisonBrandInfo.id);
        const brandDatas = brands.functions.getBrandDatas(brandsState, comparisonBrandIds);
        const brandIds = functions.getComparableBrandIds(brandDatas, comparisonBrandIds);
        const filters = comparisonState.filters;
        const metricFilters = comparisonState.metricFilters[metricId];
        return _.get(comparisonState.brandMetricDatas, getStateHashKey({brandIds, metricId, filters, metricFilters}), {});
    },
};

const getComparison = (state) => state.comparison;

export const selectors = {
    getComparisonBrandInfos: createSelector(getComparison, (comparison) => comparison.brandInfos),

    getAreAllBrandInfosLoaded: createSelector(getComparison, brands.selectors.getBrandInfosLoadStates, (comparison, brandInfosLoadStates) => {
        let result = true;

        const brandIds = comparison.brandInfos.map((brandInfo) => {
            return brandInfo.id;
        });
        for (const brandId of brandIds) {
            if (!brandInfosLoadStates[brandId] || !brandInfosLoadStates[brandId].isLoaded) {
                result = false;
                break;
            }
        }

        return result;
    }),

    getComparisonActivePackageId: createSelector(getComparison, (comparison) => comparison.activePackageId),

    getComparisonActiveFilters: createSelector(getComparison, (comparison) => comparison.filters),

    getComparisonMetricActiveFilters: (metricId) => createSelector(getComparison, (comparison) => {
        return comparison.metricFilters[metricId];
    }),

    getComparisonMetricData: (metricId) => createSelector(getComparison, brands.selectors.getBrands, (comparison, brands) => {
        return functions.getComparisonMetricData(comparison, brands, metricId);
    }),

    getIsComparisonMetricDataLoaded: (metricId) => createSelector(getComparison, brands.selectors.getBrands, (comparison, brands) => {
        return functions.getComparisonMetricData(comparison, brands, metricId).isLoaded;
    }),
};
