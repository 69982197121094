import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import objectPath from "object-path";
import { Box } from "@material-ui/core";

import * as builder from "../../ducks/builder";
import KTToggle from "../../_assets/js/toggle";
import { toAbsoluteUrl } from "../../utils/utils";
import MyCart from "../../../app/partials/layout/MyCart";
import LanguageSelector from "../../../app/partials/layout/LanguageSelector";
import UserProfile from "../../../app/partials/layout/UserProfile";

import { IS_CART_ENABLED } from "../../../app/crud/constants";

class HeaderMobile extends React.Component {
  toggleButtonRef = React.createRef();
  headerMobileCssClasses = "";
  layoutConfig = this.props.layoutConfig;
  componentDidMount() {
    new KTToggle(this.toggleButtonRef.current, this.props.toggleOptions);
  }

  render() {
    const {
      asideDisplay,
      headerMobileCssClasses,
      headerMobileAttributes,
    } = this.props;
    return (
      <div
        id="kt_header_mobile"
        className={`kt-header-mobile ${headerMobileCssClasses}`}
        {...headerMobileAttributes}
      >
        <div className="kt-header-mobile__logo">
          <Link to="/">
            <img
              alt="logo"
              src={toAbsoluteUrl("/media/logos/ob-aside-logo-light.svg")}
            />
          </Link>
        </div>
        <div className="kt-header-mobile__toolbar">
          <Box display="flex">
            {IS_CART_ENABLED && (
              <MyCart
                  iconType=""
                  skin="light"
                  icon="flaticon2-shopping-cart-1"
                  bgImage={toAbsoluteUrl("/media/misc/bg-1.jpg")}
              />
            )}
            <LanguageSelector />
            <UserProfile showBadge={true} />
          </Box>

          {asideDisplay && (
            <button
              className="kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left"
              id="kt_aside_mobile_toggler"
            >
              <span />
            </button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  headerMobileCssClasses: builder.selectors.getClasses(store, {
    path: "header_mobile",
    toString: true,
  }),
  headerMobileAttributes: builder.selectors.getAttributes(store, {
    path: "header_mobile",
  }),
  asideDisplay: objectPath.get(
    store.builder.layoutConfig,
    "aside.self.display"
  ),
  toggleOptions: {
    target: "body",
    targetState: "kt-header__topbar--mobile-on",
    togglerState: "kt-header-mobile__toolbar-topbar-toggler--active",
  },
});

export default connect(mapStateToProps)(HeaderMobile);
