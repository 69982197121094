import {createAction, handleActions} from "redux-actions";
import {all, call, put, takeLatest} from "redux-saga/effects";
import {createSelector} from "reselect";
import * as catalogCrud from "../../crud/catalog.crud";
import { createActionName, wrapSafely } from "./utils";
import _ from "lodash";
import * as auth from "./auth.duck";

const TAG = "CATALOG";

export const actions = {
    requestFilters: createAction(createActionName(TAG, "requestFilters")),
    fillFilters: createAction(createActionName(TAG, "fillFilters")),

    requestBrandInfos: createAction(createActionName(TAG, "requestBrandInfos")),
    fillBrandInfos: createAction(createActionName(TAG, "fillBrandInfos")),
};

const defaultState = {
    locations: [],
    industries: [],
    brands: [],
    isLoading: false,
    isLoaded: false,
};

const initialState = _.clone(defaultState);

export const reducer = handleActions(
    {
        [actions.requestFilters]: (state) => {
            return {
                ...state,
            };
        },

        [actions.fillFilters]: (state, { payload: { locations, industries } }) => {
            return {
                ...state,
                locations: locations,
                industries: industries,
            };
        },

        [actions.requestBrandInfos]: (state) => {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
            };
        },

        [actions.fillBrandInfos]: (state, { payload }) => {
            return {
                ...state,
                ...payload,
                isLoading: false,
                isLoaded: true,
            };
        },

        [auth.actionTypes.LogoutUser]: () => {
            console.log(`LogoutUser in ${TAG}`);
            return _.clone(defaultState);
        }
    },
    initialState
);

export function* saga() {
    yield takeLatest(actions.requestFilters, wrapSafely(function*() {
        const [locations, industries] = yield all([
            call(catalogCrud.fetchLocations),
            call(catalogCrud.fetchIndustries),
        ]);

        yield put(
            actions.fillFilters({
                locations: locations.data.items,
                industries: industries.data.items,
            })
        );
    }, TAG, "requestFilters"));

    yield takeLatest(actions.requestBrandInfos, wrapSafely(function*({ payload }) {
        const { data = {} } = yield catalogCrud.fetchBrandInfos(payload);
        yield put(actions.fillBrandInfos(data));
    }, TAG, "requestBrandInfos"));
}

export const getCatalog = (state) => state.catalog;

export const selectors = {
    getFilters: createSelector(getCatalog, (catalog) => {
        return {
            locations: catalog.locations,
            industries: catalog.industries
        };
    }),
    getCatalogBrandInfos: createSelector(getCatalog, (catalog) => catalog.brands),
    getCatalogLoadState: createSelector(getCatalog, (catalog) => {
        return {
            isLoading: catalog.isLoading,
            isLoaded: catalog.isLoaded
        };
    }),
    getCatalogBrandTotalCount: createSelector(getCatalog, (catalog) => catalog.total),
    getCatalogBrandTotalPageCount: createSelector(getCatalog, (catalog) => Math.ceil(catalog.total / catalog.limit)),
};
