import ReactGA from 'react-ga';
import { GA_TRACKING_CODE } from "../../../crud/constants";

ReactGA.initialize(GA_TRACKING_CODE, {
  redactEmail: false,
  debug: false
});

const GoogleAnalytics = {
  reportPageView: (location, history) => {
    if (history.action === 'PUSH') {
      const pageUrl = location.pathname + location.search;
      //console.log(`reportPageView - ${pageUrl}`);
      ReactGA.pageview(pageUrl);
    }
  },

  /*
  {
    category: 'Editing',
    action: 'Deleted Component',
    label: 'Game Widget',
    value: 123,
    nonInteraction: true
  }
  */
  reportEvent: (event) => {
    //console.log(`reportEvent - ${JSON.stringify(event, null, 2)}`);
    ReactGA.event({
      ...event,
      nonInteraction: true
    });
  }
};

export default GoogleAnalytics;