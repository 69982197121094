import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createSelector } from "reselect";
import { supportedLanguages } from "../i18n/I18nProvider";

const getDefaultLanguage = () => {
  let result = null;

  const requiredLanguage = (new URLSearchParams(window.location.search).get("lang") || navigator.language || navigator.userLanguage);

  for (const supportedLanguage of supportedLanguages) {
    if (requiredLanguage.startsWith(supportedLanguage)) {
      result = supportedLanguage;
      break;
    }
  }

  if (!result) {
    result = supportedLanguages[0];
  }

  return result;
}

const actionTypes = {
  SetLanguage: "i18n/SET_LANGUAGE",
};

const initialState = {
  lang: getDefaultLanguage(),
};

export const reducer = persistReducer(
  { storage, key: "i18n" },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SetLanguage:
        return { ...state, lang: action.payload.lang };

      default:
        return state;
    }
  }
);

export const actions = {
  setLanguage: (lang) => ({ type: actionTypes.SetLanguage, payload: { lang } }),
};

const getI18n = (state) => state.i18n;

export const selectors = {
  getSelectedLanguage: createSelector(getI18n, (i18n) => i18n.lang),
};
