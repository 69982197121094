import {createAction, handleActions} from "redux-actions";
import {takeLatest} from "redux-saga/effects";
import * as adminCrud from "../../crud/admin.crud";
import { createActionName, wrapSafely } from "./utils";
import _ from "lodash";
import * as auth from "./auth.duck";

const TAG = "ADMIN";

export const actions = {
    clearCache: createAction(createActionName(TAG, "clearCache"), (options) => ({
        options,
    })),
};

const defaultState = {

};

const initialState = _.clone(defaultState);

export const reducer = handleActions(
    {
        [actions.clearCache]: (state) => {
            return {
                ...state,
            };
        },

        [auth.actionTypes.LogoutUser]: () => {
            console.log(`LogoutUser in ${TAG}`);
            return _.clone(defaultState);
        }
    },
    initialState
);

export function* saga() {
    yield takeLatest(actions.clearCache, wrapSafely(function*({ payload: { options } }) {
        yield adminCrud.clearCache(options);
    }, TAG, "clearCache"));
}