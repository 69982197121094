import React, {useState, useEffect} from "react";
import {
    Box,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography, Button, makeStyles
} from "@material-ui/core";
import { Dropdown } from "react-bootstrap";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";

import {
    generateSalesAnalysisUrl,
    generateCatalogUrl,
    formatMonthYearString,
    getLogoImageUrl,
    convertValueToLocaleString
} from "../../../utils/utils";
import * as brands from "../../../store/ducks/brands.duck";
import BrandTooltip from "../../../common/components/brand-tooltip";
import ChartCard from "../chart-card";
import MessageTitle from "../../../common/components/message-title";

import { selectors } from "../../../../_metronic/ducks/i18n";

import css from "./styles.module.scss";
import {Link} from "react-router-dom";
import {subHeaderCellStyle, rightBorderedCellStyle, rightBorderedSubHeaderCellStyle, cellStyle} from "../../../utils/tableStyles";
import {useChartProperties} from "../chart";
import Tooltip from "../../../common/components/tooltip";
import * as popups from "../../../store/ducks/popups.duck";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import {useTheme} from "@material-ui/styles";
import Badge from "../../../common/components/badge";

export const createSharePercentageValueElement = (valueInfo, intl, language) => {
    let value = valueInfo.value;

    let valueSpanElement = null;
    if (!value || !isNaN(Number(value))) {
        value = Number(value);

        let formattedValue = ((value ? "" : "< ") + convertValueToLocaleString(value || 0.001, intl, language, {
            style: "percent",
            maximumFractionDigits: 1,
            minimumFractionDigits: 1,
        }));
        let valueClassName = css.value;
        let valueTooltipTitle;

        if (valueInfo.valuePolicy) {
            if (valueInfo.valuePolicy === "blur") {
                formattedValue = formattedValue.replace(/\d/g, "*");
                valueClassName = css.valueBlur;
                valueTooltipTitle = intl.formatMessage({ id: "BLURRY.VALUE.TOOLTIP" });
            }
            else {
                console.assert(false, "Unsupported valuePolicy!");
            }
        }

        valueSpanElement = (
            <Tooltip arrow title={valueTooltipTitle}>
                <span className={valueClassName}>
                    {formattedValue}
                </span>
            </Tooltip>
        );
    }
    else {
        valueSpanElement = (
            <span className={css.value}>
                {value}
            </span>
        );
    }

    return (
        <Box style={{whiteSpace: "nowrap"}}>
            {valueSpanElement}
        </Box>
    );
};

const useStyles = makeStyles(() => ({
    dropdownMenuToggle: {
        display: "flex",
        maxWidth: "150px",
    },
    dropdownMenu: {
        maxHeight: "500px",
        overflow: "auto",
    },
}));

const BrandIntersection = ({ brandId, metricId, supportedTimeIntervals, shouldSelectBrandIndustryFilter, alertText, toggleHelpDrawer, intl }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const classes = useStyles();

    const language = useSelector(selectors.getSelectedLanguage);

    const brandInfo = useSelector(brands.selectors.getBrandInfo(brandId));

    const {
        filters,
        metricFilters,
        isChartLoaded,
        data,
        dataFeatures,
        title,
        subTitle,
        description,
        releaseNotes,
        chartHeight,
        setMetricActiveFiltersAction,
    } = useChartProperties(brandId, null, null, null, metricId, 400);

    const preparedData = data;

    const isPreparedDataNonEmpty = !!(preparedData && preparedData.intersectionsTable && preparedData.intersectionsTable.brandsAndPoses && preparedData.intersectionsTable.brandsAndPoses.length);

    const supportedTimeIntervalFilters = _.filter(["hour_3", "hour_24", "hour_72", "month_1"], (supportedTimeIntervalFilter) => {
        return _.includes(supportedTimeIntervals, supportedTimeIntervalFilter);
    });
    const timeIntervalFilter = (metricFilters || {}).timeInterval;
    const formattedTimeIntervalFilters = {
        hour_3: intl.formatMessage({ id: "TIME.INTERVAL.FILTER.HOUR_3" }),
        hour_24: intl.formatMessage({ id: "TIME.INTERVAL.FILTER.HOUR_24" }),
        hour_72: intl.formatMessage({ id: "TIME.INTERVAL.FILTER.HOUR_72" }),
        month_1: intl.formatMessage({ id: "TIME.INTERVAL.FILTER.MONTH_1" }),
    };

    const industryFilterInitialValue = "industryFilterInitialValue";
    const [industryFilter, setIndustryFilter] = useState(industryFilterInitialValue);

    const supportedIntersectionTypeFilters = ["all_brands", "other_brands", "this_brand"];
    const intersectionTypeFilter = (metricFilters || {}).intersectionType;
    const formattedIntersectionTypeFilters = {
        all_brands: intl.formatMessage({ id: "BRAND.INTERSECTION.TYPE.FILTER.ALL_BRANDS" }),
        other_brands: intl.formatMessage({ id: "BRAND.INTERSECTION.TYPE.FILTER.OTHER_BRANDS" }),
        this_brand: intl.formatMessage({ id: "BRAND.INTERSECTION.TYPE.FILTER.THIS_BRAND" }),
    };
    const intersectionTypeFilterEnableStates = {
        all_brands: true,
        other_brands: true,
        this_brand: !!filters.posId
    };
    const formattedIntersectionTypeTooltips = {
        this_brand: intl.formatMessage({ id: "BRAND.INTERSECTION.TYPE.FILTER.THIS_BRAND.TOOLTIP" }),
    };

    let supportedIndustryFilters = (isPreparedDataNonEmpty ? _.map(preparedData.intersectionsTable.brandsAndPoses, ({brand}) => {
        return _.get(brand, ["industry", "name"], "");
    }) : []);
    supportedIndustryFilters = _.uniq(supportedIndustryFilters);
    supportedIndustryFilters = _.sortBy(supportedIndustryFilters);
    supportedIndustryFilters.splice(0, 0, "");

    const industryFilteredRowIndexex = [];
    if (isPreparedDataNonEmpty) {
        _.forEach(preparedData.intersectionsTable.brandsAndPoses, ({brand}, index) => {
            if (!industryFilter || _.get(brand, ["industry", "name"], "") === industryFilter) {
                industryFilteredRowIndexex.push(index);
            }
        });
    }

    if (industryFilter === industryFilterInitialValue && isPreparedDataNonEmpty) {
        if (shouldSelectBrandIndustryFilter && brandInfo.industry.name && _.includes(supportedIndustryFilters, brandInfo.industry.name)) {
            setIndustryFilter(brandInfo.industry.name);
        }
        else {
            setIndustryFilter(supportedIndustryFilters[0]);
        }
    }

    const intersectionsTableCollapsedRowCount = 3;
    const intersectionsTableExpandRowCount = (industryFilteredRowIndexex.length - intersectionsTableCollapsedRowCount);
    const [isIntersectionsTableExpanded, setIsIntersectionsTableExpanded] = useState(false);

    useEffect(() => {
        if (!timeIntervalFilter) {
            dispatch(setMetricActiveFiltersAction({
                timeInterval: supportedTimeIntervalFilters[0]
            }));
        }

        if (!intersectionTypeFilter) {
            dispatch(setMetricActiveFiltersAction({
                intersectionType: supportedIntersectionTypeFilters[0]
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ChartCard
            title={title}
            subTitle={subTitle}
            description={description}
            releaseNotes={releaseNotes}
            dataFeatures={dataFeatures}
            toggleHelpDrawer={toggleHelpDrawer}
            isLoading={!isChartLoaded}
        >
            <Box style={{display:"flex", flexDirection: "column", height: chartHeight}}>
                <Box display="flex" flexDirection="row">
                    {isPreparedDataNonEmpty && (
                        <Box p={1}>
                            <Typography variant="subtitle2">{<FormattedMessage id="MONTH.PROMPT" />}</Typography>
                            <Typography variant="subtitle1">{formatMonthYearString(preparedData.intersectionsTable.month, language)}</Typography>
                        </Box>
                    )}

                    {supportedTimeIntervalFilters.length > 1 && (
                        <Box p={1}>
                            <Typography variant="subtitle2">{<FormattedMessage id="TIME.INTERVAL.DROPDOWN.PROMPT" />}</Typography>

                            <Dropdown>
                                <Tooltip arrow title={formattedTimeIntervalFilters[timeIntervalFilter]}>
                                    <Dropdown.Toggle className={classes.dropdownMenuToggle}>
                                        <span className="text-truncate">
                                            {formattedTimeIntervalFilters[timeIntervalFilter]}
                                        </span>
                                    </Dropdown.Toggle>
                                </Tooltip>

                                <Dropdown.Menu flip={false} className={classes.dropdownMenu}>
                                    {supportedTimeIntervalFilters.map((supportedTimeIntervalFilter) => (
                                        <Dropdown.Item
                                            key={supportedTimeIntervalFilter}
                                            active={supportedTimeIntervalFilter === timeIntervalFilter}
                                            onClick={() => {
                                                dispatch(setMetricActiveFiltersAction({
                                                    timeInterval: supportedTimeIntervalFilter
                                                }));
                                            }}>
                                            {formattedTimeIntervalFilters[supportedTimeIntervalFilter]}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Box>
                    )}

                    {isPreparedDataNonEmpty && (
                        <Box p={1}>
                            <Typography variant="subtitle2">{<FormattedMessage id="INDUSTRY.DROPDOWN.PROMPT" />}</Typography>

                            <Dropdown>
                                <Tooltip arrow title={industryFilter ? industryFilter : <FormattedMessage id="NOT_SELECTED" />}>
                                    <Dropdown.Toggle className={classes.dropdownMenuToggle}>
                                        <span className="text-truncate">
                                            {industryFilter ? industryFilter : <FormattedMessage id="NOT_SELECTED" />}
                                        </span>
                                    </Dropdown.Toggle>
                                </Tooltip>

                                <Dropdown.Menu flip={false} className={classes.dropdownMenu}>
                                    {supportedIndustryFilters.map((supportedIndustryFilter) => (
                                        <Dropdown.Item
                                            key={supportedIndustryFilter}
                                            active={supportedIndustryFilter === industryFilter}
                                            onClick={() => setIndustryFilter(supportedIndustryFilter)}>
                                            {supportedIndustryFilter ? supportedIndustryFilter : <FormattedMessage id="NOT_SELECTED" />}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Box>
                    )}

                    <Box p={1}>
                        <Typography variant="subtitle2">{<FormattedMessage id="BRAND.INTERSECTION.TYPE.DROPDOWN.PROMPT" />}</Typography>

                        <Dropdown>
                            <Tooltip arrow title={formattedIntersectionTypeFilters[intersectionTypeFilter]}>
                                <Dropdown.Toggle className={classes.dropdownMenuToggle}>
                                    <span className="text-truncate">
                                        {formattedIntersectionTypeFilters[intersectionTypeFilter]}
                                    </span>
                                </Dropdown.Toggle>
                            </Tooltip>

                            <Dropdown.Menu flip={false} className={classes.dropdownMenu}>
                                {supportedIntersectionTypeFilters.map((supportedIntersectionTypeFilter) => (
                                    <Tooltip
                                        arrow
                                        title={formattedIntersectionTypeTooltips[supportedIntersectionTypeFilter]}
                                        placement="right"
                                        key={supportedIntersectionTypeFilter}
                                    >
                                        <Dropdown.Item
                                            active={supportedIntersectionTypeFilter === intersectionTypeFilter}
                                            disabled={!intersectionTypeFilterEnableStates[supportedIntersectionTypeFilter]}
                                            style={{pointerEvents: "all"}}
                                            onClick={() => {
                                                dispatch(setMetricActiveFiltersAction({
                                                    intersectionType: supportedIntersectionTypeFilter
                                                }));
                                            }}>
                                            {formattedIntersectionTypeFilters[supportedIntersectionTypeFilter]}
                                        </Dropdown.Item>
                                    </Tooltip>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Box>
                </Box>

                <Box style={{flexGrow: 1, overflow: 'auto'}}>
                    {isPreparedDataNonEmpty ? (
                        <Box>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" style={rightBorderedCellStyle}>
                                            <FormattedMessage id="COMPANY" />
                                        </TableCell>

                                        {preparedData.intersectionsTable.columnDatas.map((columnData, index) => (
                                            <TableCell key={index} align="center" colSpan={columnData.subColumnDatas.length} style={index < (preparedData.intersectionsTable.columnDatas.length - 1) ? rightBorderedCellStyle : {}}>
                                                {columnData.title}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" style={rightBorderedSubHeaderCellStyle}>
                                        </TableCell>

                                        {preparedData.intersectionsTable.columnDatas.map((columnData, index) => {
                                            return columnData.subColumnDatas.map((subColumnData, subColumnDataIndex) => (
                                                <TableCell key={subColumnDataIndex} align={(subColumnDataIndex === (columnData.subColumnDatas.length - 1)) ? "left" : "right"} style={(index < (preparedData.intersectionsTable.columnDatas.length - 1) && subColumnDataIndex === (columnData.subColumnDatas.length - 1)) ? rightBorderedSubHeaderCellStyle : subHeaderCellStyle}>
                                                    <Typography variant="subtitle2" color="textSecondary" noWrap={true}>{subColumnData.title}</Typography>
                                                </TableCell>
                                            ));
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {_.take(industryFilteredRowIndexex, isIntersectionsTableExpanded ? industryFilteredRowIndexex.length : intersectionsTableCollapsedRowCount).map((rowIndex) => {
                                        const brandAndPos = preparedData.intersectionsTable.brandsAndPoses[rowIndex];
                                        const brand = brandAndPos.brand;
                                        const pos = brandAndPos.pos;

                                        return (
                                            <TableRow key={rowIndex}>
                                                <BrandTooltip
                                                    key={rowIndex}
                                                    brandInfo={brand}
                                                    enableSalesAnalysisLink={true}
                                                    useBrandLockStatus={false}
                                                    showAddToCartButton={false}
                                                    showAddToComparisonButton={true}
                                                >
                                                    <TableCell style={rightBorderedCellStyle}>
                                                        <div className={css.companyWrapper}>
                                                            {brand ? (
                                                                <>
                                                                    <img
                                                                        src={getLogoImageUrl(_.get(brand, "logo", ""))}
                                                                        alt={_.get(brand, ["name"], "")}
                                                                        className={css.companyImage}
                                                                    />
                                                                    <Box>
                                                                        <Link
                                                                            to={!_.get(brand, "isLocked") ? generateSalesAnalysisUrl({
                                                                                brandId: _.get(brand, ["id"], "")
                                                                            }) : window.location.href.replace(window.location.origin, "")}
                                                                            onClick={(event) => {
                                                                                if (_.get(brand, "isLocked")) {
                                                                                    event.preventDefault();
                                                                                    dispatch(popups.actions.showSendMessagePopup(
                                                                                        "Brand access request (brand intersection)",
                                                                                        intl.formatMessage({ id: "REQUEST.ACCESS.POPUP.TITLE" }),
                                                                                        intl.formatMessage({ id: "REQUEST.BRAND.ACCESS.MESSAGE.TEMPLATE"}, { brandName: _.get(brand, "name", "") }),
                                                                                    ));
                                                                                }
                                                                            }}
                                                                        >
                                                                            <Box display="flex" flexDirection="row" alignItems="center">
                                                                                <Typography className={css.companyName}>
                                                                                    {_.get(pos ? pos : brand, ["name"], "")}

                                                                                    {_.get(brand, "isLocked") && (
                                                                                        <span style={{marginLeft: "8px"}}>
                                                                                            <FontAwesomeIcon icon={faLock} color={theme.palette.primary.dark} />
                                                                                        </span>
                                                                                    )}
                                                                                </Typography>

                                                                                {_.get(brand, "isDemo") && (
                                                                                    <Box ml={1}>
                                                                                        <Badge color={"green"} text={<FormattedMessage id="BRAND.DEMO.BADGE.TEXT" />} />
                                                                                    </Box>
                                                                                )}
                                                                            </Box>
                                                                        </Link>

                                                                        <Link
                                                                            to={generateCatalogUrl({
                                                                                industry: _.get(brand, ["industry", "id"], "")
                                                                            })}
                                                                        >
                                                                            <p className={css.catalogLink}>
                                                                                {_.get(brand, ["industry", "name"], "")}
                                                                            </p>
                                                                        </Link>
                                                                    </Box>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <img src={getLogoImageUrl(null)} alt="" className={css.companyImage}/>
                                                                    <Box>
                                                                        <Link
                                                                            to={generateCatalogUrl()}
                                                                        >
                                                                            <p className={css.catalogLink}>
                                                                                <FormattedMessage id="OTHER_COMPANY" />
                                                                            </p>
                                                                        </Link>
                                                                    </Box>
                                                                </>
                                                            )}
                                                        </div>
                                                    </TableCell>
                                                </BrandTooltip>

                                                {preparedData.intersectionsTable.columnDatas.map((columnData, index) => {
                                                    return columnData.subColumnDatas.map((subColumnData, subColumnDataIndex) => (
                                                        <TableCell key={subColumnDataIndex} align={(subColumnDataIndex === (columnData.subColumnDatas.length - 1)) ? "left" : "right"} style={(index < (preparedData.intersectionsTable.columnDatas.length - 1) && subColumnDataIndex === (columnData.subColumnDatas.length - 1)) ? rightBorderedCellStyle : cellStyle}>
                                                            {createSharePercentageValueElement(subColumnData.values[rowIndex], intl, language)}
                                                        </TableCell>
                                                    ));
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>

                            {intersectionsTableExpandRowCount > 0 && (
                                <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
                                    <Button variant="text" color="primary" onClick={() => {
                                        setIsIntersectionsTableExpanded(!isIntersectionsTableExpanded);
                                    }}>
                                        <Typography>{isIntersectionsTableExpanded ? <FormattedMessage id="TABLE.SHOW.LESS"/> : <FormattedMessage id="TABLE.SHOW.MORE.TEMPLATE" values={{ count: intersectionsTableExpandRowCount }}/>}</Typography>
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    ) : (
                        <MessageTitle>
                            {!isChartLoaded ? (
                                <FormattedMessage id="DATA_IS_BEING_LOADED"/>
                            ) : (alertText ? alertText : <FormattedMessage id="NOT_ENOUGH_DATA_PROMPT"/>)}
                        </MessageTitle>
                    )}
                </Box>
            </Box>
        </ChartCard>
    );
};

export default injectIntl(BrandIntersection);
