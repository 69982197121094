import axios from "axios";
import {API_URL} from "./constants"

export const clearCache = async (options) => {
    const {data} = await axios.get(
        `${API_URL}/admin/cache/clear`,
        {
            params: {
                options: options
            },
        }
    );

    return data;
};