import React, {forwardRef, useImperativeHandle, useState} from "react";
import {Box, Drawer, Typography} from "@material-ui/core";
import KTUtil from "../../../../_metronic/_assets/js/util";
import ProgressingOverlay from "../progressing-overlay";

const MetricHelpDrawer = forwardRef((props, ref) => {
  const [helpDrawerState, setHelpDrawerState] = useState({
    anchor: KTUtil.isDesktopDevice() ? "right" : "bottom",
    width: KTUtil.isDesktopDevice() ? window.innerWidth * 0.35 : "100%",
    height: KTUtil.isDesktopDevice() ? "100%" : window.innerHeight * 0.35,
    isOpened: false
  });

  const toggleHelpDrawer = (state) => {
    const newHelpDrawerState = {
      ...helpDrawerState,
      ...state,
      isContentLoaded: false
    };

    newHelpDrawerState.description = (newHelpDrawerState.description || "");

    if (newHelpDrawerState.description) {
      if ((newHelpDrawerState.description.startsWith("http://") || newHelpDrawerState.description.startsWith("https://"))) {
        newHelpDrawerState.isDescriptionUrl = true;
        newHelpDrawerState.isContentLoaded = false;
      }
      else {
        newHelpDrawerState.isDescriptionUrl = false;
        newHelpDrawerState.isContentLoaded = true;
      }
    }

    setHelpDrawerState(newHelpDrawerState);
  };

  useImperativeHandle(ref, () => ({
    toggleHelpDrawer
  }));

  return (
      <Drawer
          anchor={helpDrawerState.anchor}
          open={helpDrawerState.isOpened}
          onClose={() => {
            toggleHelpDrawer({
              isOpened: false
            });
          }}
      >
        <Box style={{width: helpDrawerState.width, height: helpDrawerState.height}} p={1} display="flex" flexDirection="column">
          <Box flexGrow={1}>
            {helpDrawerState.isDescriptionUrl ? (
                <iframe title={"Metric description"} src={helpDrawerState.description} style={{width: "100%", height: "100%"}} frameBorder="0" onLoad={() => {
                  setHelpDrawerState({
                    ...helpDrawerState,
                    isContentLoaded: true
                  });
                }} />
            ) : (
                <Typography>{helpDrawerState.description}</Typography>
            )}
          </Box>
        </Box>
        <ProgressingOverlay isOpen={!helpDrawerState.isContentLoaded} />
      </Drawer>
  );
});

export default MetricHelpDrawer;
