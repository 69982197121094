import React from "react";
import PaginationInternal from "./pagination-internal";

const Pagination = ({ currentPage, totalPages, onChange }) => {
  const onClick = (event) => {
    const a = event.target;
    const pageNumber = a.dataset.value
      ? parseInt(a.dataset.value, 10)
      : parseInt(a.parentNode.dataset.value, 10);

    if (typeof onChange === "function") {
      onChange(pageNumber);
    }
  };
  return (
    <PaginationInternal
      currentPage={currentPage}
      totalPages={totalPages}
      onClick={onClick}
    />
  );
};

export default Pagination;
