/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { setupAxios } from "./_metronic";
import store, { persistor } from "./app/store/store";
import App from "./App";
import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
import * as auth from "./app/store/ducks/auth.duck";
import * as alert from "./app/store/ducks/alert.duck";
import {
    Box,
} from "@material-ui/core";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios, store);

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        let alertMessage;
        let shouldProposePageReload = false;
        if (error.response) {
            if (error.response.status === 401) {
                store.dispatch(auth.actions.logoutUser());
            }

            if (error.response.data.message) {
                alertMessage = error.response.data.message;
            }
            else if (error.response.data.error) {
                if (typeof(error.response.data.error) === "object") {
                    const key = Object.keys(error.response.data.error)[0];

                    const errorText = error.response.data.error[key];
                    const errorCategory = key.charAt(0).toUpperCase() + key.slice(1);

                    alertMessage = `${errorCategory}: ${errorText}`;
                }
                else {
                    alertMessage = error.response.data.error;
                }
            }
            else {
                alertMessage = `Error: ${error.response.statusText} (${error.response.status})`;
                shouldProposePageReload = true;
            }
        }
        else {
            alertMessage = `${error}`;
            shouldProposePageReload = true;
        }

        if (shouldProposePageReload) {
            alertMessage = (
                <Box>
                    <Box>{alertMessage}</Box>
                    <Box>Please refresh the page and try again.</Box>
                </Box>
            );
        }

        store.dispatch(alert.actions.showAlert({
            severity: "error",
            message: alertMessage,
            timeout: (shouldProposePageReload ? 10 : 5) * 1000
        }));

        return Promise.reject(error);
    }
);

ReactDOM.render(
    <App store={store} persistor={persistor} basename={PUBLIC_URL} />,
    document.getElementById("root")
);
