import React from "react";
import MyCart from "../../../app/partials/layout/MyCart";
import LanguageSelector from "../../../app/partials/layout/LanguageSelector";
import UserProfile from "../../../app/partials/layout/UserProfile";
import {toAbsoluteUrl} from "../../utils/utils";

import {IS_CART_ENABLED} from "../../../app/crud/constants";

export default class Topbar extends React.Component {
    render() {
        return (
            <div className="kt-header__topbar">
                {IS_CART_ENABLED && (
                    <MyCart
                        iconType=""
                        skin="light"
                        icon="flaticon2-shopping-cart-1"
                        bgImage={toAbsoluteUrl("/media/misc/bg-1.jpg")}
                    />
                )}
                <LanguageSelector/>
                <UserProfile showBadge={true}/>
            </div>
        );
    }
}
