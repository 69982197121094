import React, {useState} from "react";
import {Box} from "@material-ui/core";
import { Bubble } from "react-chartjs-2";
import _ from "lodash";
import {connect, useDispatch, useSelector} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";

import * as auth from "../../../store/ducks/auth.duck";
import ChartCard from "../chart-card";
import MessageTitle from "../../../common/components/message-title";
import Tooltip from "../../../common/components/tooltip";

import { selectors } from "../../../../_metronic/ducks/i18n";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import {useChartProperties} from "../chart";
import {convertValueToLocaleString} from "../../../utils/utils";
import {Chart, Scale, LinearScale} from "chart.js";

const prepareChartData = (data, options) => {
    let result = null;

    if (data && data.datasets) {
        const pointStyles = [
            "circle",
            "rect",
            "rectRot",
            "triangle",
            "rectRounded",
        ];

        const datasets = data.datasets.map((dataset, index) => ({
            ...dataset,
            ...(dataset.settings || {}),
            data: dataset.data.map((item) => {
                return {
                    x: item.metricValue || 0,
                    y: item.growthValue || 0,
                    r: (dataset.settings || {}).circleRadius
                };
            }),
            pointStyle: pointStyles[index % pointStyles.length],
            dataSources: dataset.data.map((item) => {
                return item;
            }),
            period: data.period
        }));

        result = {
            labels: data.labels,
            datasets: datasets,
            period: data.period,
            options
        };
    }

    return result;
};

const createChartOptions = (chartType, options, filters, intl, language, onTooltipActionButtonClick) => {
    const chartOptions = _.clone(options);

    chartOptions.intl = intl;
    chartOptions.locale = language;

    chartOptions.scales = _.mapValues(chartOptions.scales, (scale) => {
        if (scale.ticks && scale.ticks.format) {
            scale.ticks.callback = (value, index, ticks) => {
                return convertValueToLocaleString(value, intl, language, scale.ticks.format);
            };
        }
        return scale;
    });

    if (!chartOptions.plugins) {
        chartOptions.plugins = {};
    }

    const loadTooltipElement = (chart) => {
        return chart.canvas.parentNode.querySelector("div");
    };

    const saveTooltipElement = (chart, tooltipElement) => {
        chart.canvas.parentNode.appendChild(tooltipElement);
    };

    const createElement = (tagName) => {
        const element = document.createElement(tagName);
        if (!element.style) {
            element.style = {};
        }
        element.style.pointerEvents = "none";
        return element;
    };

    const createTooltipElementIfNeeded = (chart) => {
        let tooltipElement = loadTooltipElement(chart);
        if (!tooltipElement) {
            const tooltipElementId = "tooltipElementId";
            tooltipElement = createElement("div");
            tooltipElement.id = tooltipElementId;
            tooltipElement.style.padding = "5px";
            tooltipElement.style.background = "rgba(0, 0, 0, 0.7)";
            tooltipElement.style.borderRadius = "3px";
            tooltipElement.style.color = "white";
            tooltipElement.style.opacity = 0;
            tooltipElement.style.position = "absolute";
            tooltipElement.style.transition = "all 0.2s ease";
            tooltipElement.style["max-height"] = "150px";
            tooltipElement.style["overflow-y"] = "auto";

            const tooltipScrollBarStyleElementId = "tooltipScrollBarStyleElementId";
            if (!document.getElementById(tooltipScrollBarStyleElementId)) {
                const styleElement = document.createElement("style");
                styleElement.id = tooltipScrollBarStyleElementId
                styleElement.appendChild(document.createTextNode(`#${tooltipElementId}::-webkit-scrollbar {-webkit-appearance: none; width: 7px;} #${tooltipElementId}::-webkit-scrollbar-thumb {border-radius: 4px; background-color: rgba(255, 255, 255, 0.7);`));
                _.head(document.getElementsByTagName("head")).appendChild(styleElement);
            }

            const rowElement = createElement("div");
            rowElement.style.display = "flex";
            rowElement.style["flex-direction"] = "row";
            tooltipElement.appendChild(rowElement);

            const titleTableElement = createElement("table");
            titleTableElement.style["flex-grow"] = "1";
            rowElement.appendChild(titleTableElement);
            tooltipElement.titleTableElement = titleTableElement;

            const closeButton = createElement("span");
            closeButton.innerHTML = "&#10006;";
            closeButton.style.cursor = "pointer";
            closeButton.onclick = () => {
                hideTooltipElement(chart);
            };
            rowElement.appendChild(closeButton);
            tooltipElement.closeButton = closeButton;

            const bodyTableElement = createElement("table");
            bodyTableElement.style.width = "100%";
            tooltipElement.appendChild(bodyTableElement);
            tooltipElement.bodyTableElement = bodyTableElement;

            saveTooltipElement(chart, tooltipElement);
        }
    };

    const hideTooltipElement = (chart) => {
        const tooltipElement = loadTooltipElement(chart);
        if (tooltipElement) {
            tooltipElement.style.opacity = 0;
            tooltipElement.style.pointerEvents = "none";
            tooltipElement.closeButton.style.pointerEvents = "none";
            _.forEach(tooltipElement.getElementsByTagName("button"), (button) => {
                button.style.pointerEvents = "none";
            });
        }
    };

    const fillTooltipElement = (tooltip, chart) => {
        if (tooltip.body) {
            const tooltipElement = loadTooltipElement(chart);

            while (tooltipElement.titleTableElement.firstChild) {
                tooltipElement.titleTableElement.firstChild.remove();
            }
            const tableHead = createElement("thead");
            _.forEach(tooltip.title || [], (title) => {
                const tr = createElement("tr");

                const th = createElement("th");
                const text = document.createTextNode(title);
                th.appendChild(text);

                tr.appendChild(th);

                tableHead.appendChild(tr);
            });
            tooltipElement.titleTableElement.appendChild(tableHead);

            while (tooltipElement.bodyTableElement.firstChild) {
                tooltipElement.bodyTableElement.firstChild.remove();
            }
            const tableBody = createElement("tbody");
            _.forEach(tooltip.body, (bodyInfo, i) => {
                const colors = tooltip.labelColors[i];

                const tr = createElement("tr");

                const td = createElement("td");
                td.style["white-space"] = "pre";

                _.forEach(bodyInfo.lines, (bodyLine, i) => {
                    if (i === 0) {
                        const text = createElement("span");
                        text.innerHTML = bodyLine.bold();
                        td.appendChild(text);
                    }
                    else {
                        const colorSpan = createElement("span");
                        colorSpan.style.background = colors.backgroundColor;
                        colorSpan.style.borderColor = colors.borderColor;
                        colorSpan.style.borderWidth = "2px";
                        colorSpan.style.marginRight = "5px";
                        colorSpan.style.height = "10px";
                        colorSpan.style.width = "10px";
                        colorSpan.style.display = "inline-block";
                        td.appendChild(colorSpan);

                        const text = document.createTextNode(bodyLine);
                        td.appendChild(text);
                    }

                    if (i !== (bodyInfo.lines.length - 1)) {
                        td.appendChild(createElement("br"));
                    }
                });
                tr.appendChild(td);

                tableBody.appendChild(tr);

                if (onTooltipActionButtonClick) {
                    const dataPoint = tooltip.dataPoints[i];
                    const dataset = dataPoint.dataset;
                    const dataSource = dataset.dataSources[dataPoint.dataIndex];

                    let buttonTitle = null;
                    if (dataSource.pos && !(filters.posId && dataSource.pos && filters.posId === dataSource.pos.id)) {
                        buttonTitle = intl.formatMessage({id: "POS.SELECT.PROMPT"});
                    }
                    else if (dataSource.location && !(filters.locationId && dataSource.location && filters.locationId === dataSource.location.id)) {
                        buttonTitle = intl.formatMessage({id: "LOCATION.SELECT.PROMPT"});
                    }

                    if (buttonTitle) {
                        const actionButton = createElement("button");
                        actionButton.innerText = "{ACTION}";
                        actionButton.style.width = "100%";
                        actionButton.style.cursor = "pointer";

                        const actionButtonTr = createElement("tr");
                        const actionButtonTd = createElement("td");
                        actionButtonTd.appendChild(actionButton);
                        actionButtonTr.appendChild(actionButtonTd);
                        tableBody.appendChild(actionButtonTr);

                        actionButton.innerText = buttonTitle;
                        actionButton.onclick = () => {
                            onTooltipActionButtonClick(dataSource);
                            hideTooltipElement(chart);
                        };
                    }
                }

                if (i !== (tooltip.body.length - 1)) {
                    const emptyTr = createElement("tr");
                    const emptyTd = createElement("td");
                    emptyTd.appendChild(createElement("br"));
                    emptyTr.appendChild(emptyTd);
                    tableBody.appendChild(emptyTr);
                }
            });
            tooltipElement.bodyTableElement.appendChild(tableBody);
        }
    };

    const moveAndEnableTooltipElement = (tooltip, chart) => {
        const tooltipElement = loadTooltipElement(chart);

        const canvasLeft = chart.canvas.offsetLeft;
        const canvasRight = chart.canvas.offsetLeft + chart.canvas.offsetWidth;
        const canvasTop = chart.canvas.offsetTop;

        const tooltipElementRect = tooltipElement.getBoundingClientRect();

        let tooltipElementLeft = chart.canvas.offsetLeft + tooltip.caretX - tooltipElementRect.width / 2;
        const tooltipElementRight = tooltipElementLeft + tooltipElementRect.width;
        if (tooltipElementLeft < canvasLeft) {
            tooltipElementLeft = canvasLeft;
        }
        else if (tooltipElementRight > canvasRight) {
            tooltipElementLeft = tooltipElementLeft - (tooltipElementRight - canvasRight);
        }

        let tooltipElementTop = chart.canvas.offsetTop + tooltip.caretY - tooltipElementRect.height;
        if (tooltipElementTop < canvasTop) {
            tooltipElementTop = tooltipElementTop + tooltipElementRect.height;
        }

        tooltipElement.style.left = tooltipElementLeft + "px";
        tooltipElement.style.top = tooltipElementTop + "px";

        tooltipElement.style.opacity = 1;
        tooltipElement.style.pointerEvents = "all";
        tooltipElement.closeButton.style.pointerEvents = "all";
        _.forEach(tooltipElement.getElementsByTagName("button"), (button) => {
            button.style.pointerEvents = "all";
        });
    };

    chartOptions.onClick = (event, args, chart) => {
        hideTooltipElement(chart);
    };

    const tooltipOptions = chartOptions.tooltips || {};
    chartOptions.plugins.tooltip = {
        chartOptions: tooltipOptions,
        language: language,
        mode: "point",
        callbacks: {
            title: function (tooltipItems) {
                let title = null;

                const tooltipItem = _.head(tooltipItems);
                const dataset = tooltipItem.dataset;
                const dataSource = dataset.dataSources[tooltipItem.dataIndex];

                if (dataSource.pos) {
                    title = intl.formatMessage({id: "POS.MORE.INFO.PROMPT"});
                }
                else {
                    title = intl.formatMessage({id: "LOCATION.MORE.INFO.PROMPT"});
                }

                return title;
            },

            label: function (tooltipItem) {
                let label = null;

                const dataset = tooltipItem.dataset;
                const dataSource = dataset.dataSources[tooltipItem.dataIndex];

                let tooltipItemTitle = dataSource.location.name;
                if (dataSource.pos) {
                    tooltipItemTitle += ` (${dataSource.pos.name})`;
                }
                else {
                    if (dataset.brandName) {
                        tooltipItemTitle += `, ${dataset.brandName}`;
                    }
                }

                const metricValue = Number(dataSource.metricValue);
                label = dataSource.metricLabel + ": " + convertValueToLocaleString(metricValue, intl, language, tooltipOptions);

                if (dataset.valueTooltipPrefix) {
                    label = `${dataset.valueTooltipPrefix}${label}`;
                }

                const growthValue = Number(dataSource.growthValue);
                if (!isNaN(growthValue)) {
                    label += ` (${convertValueToLocaleString(growthValue, intl, language, {
                        style: "percent",
                        maximumFractionDigits: 1,
                        minimumFractionDigits: 1,
                        signDisplay: "exceptZero"
                    })})`;
                }

                label = [tooltipItemTitle, label];

                if (dataSource.baseMetricLabel) {
                    const baseMetricValue = Number(dataSource.baseMetricValue);
                    let baseLabel = dataSource.baseMetricLabel + ": " + convertValueToLocaleString(baseMetricValue, intl, language, tooltipOptions);
                    if (dataset.valueTooltipPrefix) {
                        baseLabel = `${dataset.valueTooltipPrefix}${baseLabel}`;
                    }
                    label.push(baseLabel);
                }

                return label;
            },
        },
    };

    chartOptions.plugins.tooltip = {
        ...chartOptions.plugins.tooltip,
        enabled: false,
        external: (context) => {
            const { tooltip, chart } = context;

            createTooltipElementIfNeeded(chart);
            fillTooltipElement(tooltip, chart);
            moveAndEnableTooltipElement(tooltip, chart);
        },
    };

    chartOptions.plugins.eventHook = {
        beforeUpdate: (chart) => {
            hideTooltipElement(chart);
        },
    };

    return chartOptions;
};

const ChartToolbar = ({initialValue, onChange}) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        onChange(newValue);
    };

    return (
        <ToggleButtonGroup
            color="primary"
            size="small"
            orientation="vertical"
            value={value}
            exclusive={false}
            onChange={handleChange}
        >
            <ToggleButton value="avg" style={{padding: 0}}>
                <Tooltip arrow title={<FormattedMessage id="ANNOTATION.LINE.AVERAGE.BUTTON.TOOLTIP" />}>
                    <Box p={1}>avg</Box>
                </Tooltip>
            </ToggleButton>

            <ToggleButton value="med" style={{padding: 0}}>
                <Tooltip arrow title={<FormattedMessage id="ANNOTATION.LINE.MEDIAN.BUTTON.TOOLTIP" />}>
                    <Box p={1}>med</Box>
                </Tooltip>
            </ToggleButton>

            <ToggleButton value="logX" style={{padding: 0}}>
                <Tooltip arrow title={<FormattedMessage id="SCALE.X.LOG.BUTTON.TOOLTIP" />}>
                    <Box p={1}>log X</Box>
                </Tooltip>
            </ToggleButton>

            <ToggleButton value="logY" style={{padding: 0}}>
                <Tooltip arrow title={<FormattedMessage id="SCALE.Y.LOG.BUTTON.TOOLTIP" />}>
                    <Box p={1}>log Y</Box>
                </Tooltip>
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

class LogAxis extends Scale {
    constructor(cfg) {
        super(cfg);
        this._startValue = undefined;
        this._valueRange = 0;
    }

    init(options) {
        super.init(options);

        this._logBase = (this.axis === "x" ? 10 : 2);
        this._logFunction = (value) => {
            return (Math.log(value) / Math.log(this._logBase));
        };
        this._valueTickMultiplier = (this.axis === "x" ? 1 : 100);
    }

    parse(raw, index) {
        const value = LinearScale.prototype.parse.apply(this, [raw, index]);
        return (isFinite(value) ? value : null);
    }

    determineDataLimits() {
        const { min, max } = this.getMinMax(true);
        this.min = (isFinite(min) ? min : null);
        this.max = (isFinite(max) ? max : null);
    }

    buildTicks() {
        const ticks = [];

        const absMin = (Math.abs(this.min) * this._valueTickMultiplier);
        const absMax = (Math.abs(this.max) * this._valueTickMultiplier);

        if (absMin <= this._logBase) {
            ticks.push({
                value: this._logBase * Math.sign(this.min)
            });
        }

        let power = Math.floor((absMin > this._logBase ? Math.sign(this.min) : 1) * this._logFunction(absMin > this._logBase ? absMin : 1));
        const maxPower = Math.ceil((absMax > this._logBase ? Math.sign(this.max) : 1) * this._logFunction(absMax > this._logBase ? absMax : 1));
        while (power <= maxPower) {
            ticks.push({
                value: Math.sign(power) * Math.pow(this._logBase, Math.abs(power))
            });
            ++power;
        }

        if (absMax <= this._logBase) {
            ticks.push({
                value: this._logBase * Math.sign(this.max)
            });
        }

        _.forEach(ticks, (tick) => {
            tick.value /= this._valueTickMultiplier;
        });

        const values = _.map(ticks, "value");
        this.min = Math.min(...values);
        this.max = Math.max(...values);

        return ticks;
    }

    getLogValue(value) {
        const absValue = Math.abs(value);
        return (absValue > this._logBase ? (Math.sign(value) * this._logFunction(absValue)) : (value / this._logBase));
    }

    /* @protected*/
    configure() {
        const start = this.min;

        super.configure();

        this._startValue = this.getLogValue(start);
        this._valueRange = (this.getLogValue(this.max) - this.getLogValue(start));
    }

    getPixelForValue(value){
        if (value === undefined) {
            value = this.min;
        }

        return this.getPixelForDecimal((this.getLogValue(value) - this._startValue) / this._valueRange);
    }

    getValueForPixel(pixel){
        const decimal = this.getLogValue(this.getDecimalForPixel(pixel));
        return Math.pow(2, this._startValue + decimal * this._valueRange);
    }
}
LogAxis.id = "matrixLogAxis";
LogAxis.defaults = {};
Chart.register(LogAxis);

const BostonMatrix = ({brandId, metricId, alertText, toggleHelpDrawer, user, intl}) => {
    const dispatch = useDispatch();

    const language = useSelector(selectors.getSelectedLanguage);

    const {
        filters,
        isChartLoaded,
        scope,
        data,
        dataFeatures,
        title,
        subTitle,
        description,
        releaseNotes,
        chartHeight,
        setActiveFiltersAction,
    } = useChartProperties(brandId, null, null, null, metricId, 300);

    const options = createChartOptions("matrix", _.get(data, "metric.config.options", {}), filters, intl, language, (scope === "brand" || !filters.locationId) ? ((dataSource) => {
        const filters = {};
        if (dataSource.location) {
            filters.locationId = dataSource.location.id;
        }
        if (dataSource.pos) {
            filters.posId = dataSource.pos.id;
        }

        dispatch(setActiveFiltersAction(filters));
    }) : null);

    const [chartToolbarState, setChartToolbarState] = useState(["med", "logX", "logY"]);

    const preparedData = prepareChartData(data, options);

    if (preparedData && preparedData.datasets && preparedData.datasets.length) {
        const dataItems = preparedData.datasets[0].data;
        if (dataItems.length) {
            const annotations = [];

            const createLineAnnotation = (scaleId, value, rotation, content) => {
                const annotationColor = "rgba(0, 0, 0, 0.5)";
                return {
                    type: "line",
                    borderColor: annotationColor,
                    borderWidth: 2,
                    drawTime: "beforeDatasetsDraw",
                    scaleID: scaleId,
                    value: value,
                    label: {
                        enabled: true,
                        borderColor: annotationColor,
                        backgroundColor: annotationColor,
                        borderRadius: 3,
                        position: "end",
                        rotation: rotation,
                        content: content,
                    },
                };
            };

            if (chartToolbarState.includes("avg")) {
                let averageXValue = 0;
                let averageYValue = 0;
                _.forEach(dataItems, (dataItem) => {
                    averageXValue += dataItem.x;
                    averageYValue += dataItem.y;
                });

                averageXValue /= dataItems.length;
                averageYValue /= dataItems.length;

                annotations.push(
                    createLineAnnotation("x", averageXValue, 0, intl.formatMessage({ id: "ANNOTATION.LINE.AVERAGE.TITLE" })),
                    createLineAnnotation("y", averageYValue, "auto", intl.formatMessage({ id: "ANNOTATION.LINE.AVERAGE.TITLE" }))
                );
            }

            if (chartToolbarState.includes("med")) {
                const calculateMedianValue = (values) => {
                    let result;

                    values = _.sortBy(values);

                    const middleIndex = Math.floor(values.length / 2);

                    if (values.length % 2) {
                        result = values[middleIndex];
                    }
                    else {
                        result = (values[middleIndex - 1] + values[middleIndex]) / 2.0;
                    }

                    return result;
                }

                const medianXValue = calculateMedianValue(_.map(dataItems, (dataItem) => dataItem.x));
                const medianYValue = calculateMedianValue(_.map(dataItems, (dataItem) => dataItem.y));

                annotations.push(
                    createLineAnnotation("x", medianXValue, 0, intl.formatMessage({ id: "ANNOTATION.LINE.MEDIAN.TITLE" })),
                    createLineAnnotation("y", medianYValue, "auto", intl.formatMessage({ id: "ANNOTATION.LINE.MEDIAN.TITLE" }))
                );
            }

            options.plugins = {
                ...options.plugins,
                annotation: {
                    annotations: annotations
                }
            };
        }
    }

    _.set(options, "scales.x.type", chartToolbarState.includes("logX") ? "matrixLogAxis" : "linear");
    _.set(options, "scales.y.type", chartToolbarState.includes("logY") ? "matrixLogAxis" : "linear");

    return (
        <ChartCard
            title={title}
            subTitle={subTitle}
            description={description}
            releaseNotes={releaseNotes}
            dataFeatures={dataFeatures}
            toggleHelpDrawer={toggleHelpDrawer}
            isLoading={!isChartLoaded}
        >
            <Box style={{display:"flex", flexDirection: "column", height: chartHeight}}>
                {(preparedData && preparedData.datasets && preparedData.datasets.length) ? (
                    <Box style={{display:"flex", flexDirection: "row", alignItems: "center", height: chartHeight}}>
                        <Box style={{flexGrow: 1, flexShrink: 1, minWidth: 0, height: chartHeight}}>
                            <Bubble
                                data={preparedData}
                                options={options}
                            />
                        </Box>
                        <ChartToolbar initialValue={chartToolbarState} onChange={(value) => {
                            setChartToolbarState(value);
                        }} />
                    </Box>
                ) : (
                    <MessageTitle>
                        {!isChartLoaded ? (
                            <FormattedMessage id="DATA_IS_BEING_LOADED"/>
                        ) : (alertText ? alertText : <FormattedMessage id="NOT_ENOUGH_DATA_PROMPT"/>)}
                    </MessageTitle>
                )}
            </Box>
        </ChartCard>
    );
};

const mapStateToProps = (state) => ({
    user: auth.selectors.getUser(state),
});

export default injectIntl(connect(mapStateToProps, null)(BostonMatrix));