import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";

import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import LanguageSelector from "../../../app/partials/layout/LanguageSelector";
import Footer from "../../partials/layout/Footer";

import backgroundImage from "../../../assets/images/login.jpg";

import Register from "./Register";
import Login from "./Login";
import Verify from "./Verify";
import RecoverPassword from "./RecoverPassword";
import ChangePassword from "./ChangePassword";
import {Box} from "@material-ui/core";

import { selectors } from "../../../_metronic/ducks/i18n";
import { getLandingUrl } from "../../crud/constants";
import {useSelector} from "react-redux";

const AuthPage = (props) => {
  const { intl } = props;
  const language = useSelector(selectors.getSelectedLanguage);

  const sloganSubDescription = intl.formatMessage({
    id: "SLOGAN.DESCRIPTION.SUB",
  }, {
    LINK_BEGIN: `<a href="${getLandingUrl("SUBSCRIPTIONS", language)}" target="_blank" rel="noopener noreferrer">`,
    LINK_END: "</a>"
  });

  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root" style={{backgroundColor:"white"}}>
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div
              className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
              style={{
                background: `#4b2772 url(${backgroundImage})`,
                backgroundBlendMode: "multiply",
                backgroundSize: "cover",
              }}
            >
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                <div className="kt-grid__item kt-grid__item--middle">
                  <div className="kt-login__title">
                    <FormattedMessage id="SLOGAN.TITLE" />
                  </div>
                  <div className="kt-login__subtitle">
                    <FormattedMessage id="SLOGAN.DESCRIPTION.MAIN" />
                  </div>
                  <div className="kt-login__subtitle" style={{
                    fontWeight: "bold"
                  }}>
                    <div dangerouslySetInnerHTML={{__html: sloganSubDescription }} />
                  </div>
                </div>
              </div>
            </div>

            <Box display="flex" flexDirection="column" className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1">
              <Box flexGrow={1} className="kt-login__wrapper">
                <Switch>
                  <Redirect from="/auth/registration" to="/auth/register" />
                  <Route path="/auth/register" component={Register} />
                  <Route path="/auth/login" component={Login} />
                  <Route path="/auth/verify/:token" component={Verify} />
                  <Route path="/auth/password/recover" component={RecoverPassword} />
                  <Route path="/auth/password/change/:token" component={ChangePassword} />
                  <Redirect to="/auth/login" />
                </Switch>
              </Box>
              <Box display="flex" flexDirection="row" width="100%">
                <Box flexGrow={1}>
                  <Footer/>
                </Box>
                <LanguageSelector />
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default injectIntl(AuthPage);
