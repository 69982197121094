import React, {useEffect, useState} from "react";
import {
    Box,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography, Button, makeStyles
} from "@material-ui/core";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";

import {
    generateSalesAnalysisUrl,
    generateCatalogUrl,
    getLogoImageUrl
} from "../../../utils/utils";
import BrandTooltip from "../../../common/components/brand-tooltip";
import ChartCard from "../chart-card";
import MessageTitle from "../../../common/components/message-title";

import css from "../brand-intersection/styles.module.scss";
import {Link} from "react-router-dom";
import {Dropdown} from "react-bootstrap";
import {subHeaderCellStyle, rightBorderedCellStyle, rightBorderedSubHeaderCellStyle, cellStyle} from "../../../utils/tableStyles";
import {useChartProperties} from "../chart";
import {selectors} from "../../../../_metronic/ducks/i18n";
import Tooltip from "../../../common/components/tooltip";
import * as popups from "../../../store/ducks/popups.duck";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import {useTheme} from "@material-ui/styles";
import Badge from "../../../common/components/badge";
import {createSharePercentageValueElement} from "../brand-intersection";

const useStyles = makeStyles(() => ({
    dropdownMenuToggle: {
        display: "flex",
        maxWidth: "150px",
    },
    dropdownMenu: {
        maxHeight: "500px",
        overflow: "auto",
    },
}));

const BrandShareDistribution = ({ brandId, metricId, alertText, toggleHelpDrawer, intl }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const classes = useStyles();

    const language = useSelector(selectors.getSelectedLanguage);

    const {
        metricFilters,
        isChartLoaded,
        data,
        dataFeatures,
        title,
        subTitle,
        description,
        releaseNotes,
        chartHeight,
        setMetricActiveFiltersAction,
    } = useChartProperties(brandId, null, null, null, metricId, 400);

    const preparedData = data;

    const isPreparedDataNonEmpty = !!(preparedData && preparedData.sharesTable && preparedData.sharesTable.brands && preparedData.sharesTable.brands.length);

    const supportedShareFilters = ["shareRegion", "shareBrand"];
    const shareFilter = (metricFilters || {}).share;
    const formattedShareFilters = {
        shareRegion: intl.formatMessage({ id: "SHARE.FILTER.COUNTRY" }),
        shareBrand: intl.formatMessage({ id: "SHARE.FILTER.BRAND" }),
    };

    const formattedAudienceFilters = {};
    _.forEach((preparedData && preparedData.sharesTable && preparedData.sharesTable.audiences) ? preparedData.sharesTable.audiences : [], (audience) => {
        formattedAudienceFilters[audience.group] = audience.title;
    });
    const supportedAudienceFilters = _.keys(formattedAudienceFilters);
    const audienceFilter = (metricFilters || {}).audience || (supportedAudienceFilters.length > 0 ? supportedAudienceFilters[0] : null);

    const sharesTableCollapsedRowCount = 3;
    const sharesTableExpandRowCount = ((_.get(preparedData, "sharesTable.brands") || []).length - sharesTableCollapsedRowCount);
    const [isSharesTableExpanded, setIsSharesTableExpanded] = useState(false);

    useEffect(() => {
        if (!shareFilter) {
            dispatch(setMetricActiveFiltersAction({
                share: supportedShareFilters[0]
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ChartCard
            title={title}
            subTitle={subTitle}
            description={description}
            releaseNotes={releaseNotes}
            dataFeatures={dataFeatures}
            toggleHelpDrawer={toggleHelpDrawer}
            isLoading={!isChartLoaded}
        >
            <Box style={{display:"flex", flexDirection: "column", height: chartHeight}}>
                <Box display="flex" flexDirection="row">
                    <Box p={1}>
                        <Typography variant="subtitle2">{<FormattedMessage id="SHARE.DROPDOWN.PROMPT" />}</Typography>

                        <Dropdown>
                            <Tooltip arrow title={formattedShareFilters[shareFilter]}>
                                <Dropdown.Toggle className={classes.dropdownMenuToggle}>
                                    <span className="text-truncate">
                                        {formattedShareFilters[shareFilter]}
                                    </span>
                                </Dropdown.Toggle>
                            </Tooltip>

                            <Dropdown.Menu>
                                {supportedShareFilters.map((supportedShareFilter) => (
                                    <Dropdown.Item
                                        key={supportedShareFilter}
                                        active={supportedShareFilter === shareFilter}
                                        onClick={() => {
                                            dispatch(setMetricActiveFiltersAction({
                                                share: supportedShareFilter
                                            }));
                                        }}>
                                        {formattedShareFilters[supportedShareFilter]}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Box>

                    {supportedAudienceFilters.length > 0 && (
                        <Box p={1}>
                            <Typography variant="subtitle2">{<FormattedMessage id="AUDIENCE.DROPDOWN.PROMPT" />}</Typography>

                            <Dropdown>
                                <Tooltip arrow title={formattedAudienceFilters[audienceFilter]}>
                                    <Dropdown.Toggle className={classes.dropdownMenuToggle}>
                                        <span className="text-truncate">
                                            {formattedAudienceFilters[audienceFilter]}
                                        </span>
                                    </Dropdown.Toggle>
                                </Tooltip>

                                <Dropdown.Menu>
                                    {supportedAudienceFilters.map((supportedAudienceFilter) => (
                                        <Dropdown.Item
                                            key={supportedAudienceFilter}
                                            active={supportedAudienceFilter === audienceFilter}
                                            onClick={() => {
                                                dispatch(setMetricActiveFiltersAction({
                                                    audience: supportedAudienceFilter
                                                }));
                                            }}>
                                            {formattedAudienceFilters[supportedAudienceFilter]}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Box>
                    )}
                </Box>

                <Box style={{flexGrow: 1, overflow: 'auto'}}>
                    {isPreparedDataNonEmpty ? (
                        <Box>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" style={rightBorderedCellStyle}>
                                            <FormattedMessage id="COMPANY" />
                                        </TableCell>

                                        {preparedData.sharesTable.columnDatas.map((columnData, index) => (
                                            <TableCell key={index} align="center" colSpan={columnData.subColumnDatas.length} style={index < (preparedData.sharesTable.columnDatas.length - 1) ? rightBorderedCellStyle : {}}>
                                                {columnData.title}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" style={rightBorderedSubHeaderCellStyle}>
                                        </TableCell>

                                        {preparedData.sharesTable.columnDatas.map((columnData, index) => {
                                            return columnData.subColumnDatas.map((subColumnData, subColumnDataIndex) => (
                                                <TableCell key={subColumnDataIndex} align={(subColumnDataIndex === (columnData.subColumnDatas.length - 1)) ? "left" : "right"} style={(index < (preparedData.sharesTable.columnDatas.length - 1) && subColumnDataIndex === (columnData.subColumnDatas.length - 1)) ? rightBorderedSubHeaderCellStyle : subHeaderCellStyle}>
                                                    <Typography variant="subtitle2" color="textSecondary" noWrap={true}>{subColumnData.title}</Typography>
                                                </TableCell>
                                            ));
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {_.take(preparedData.sharesTable.brands, isSharesTableExpanded ? preparedData.sharesTable.brands.length : sharesTableCollapsedRowCount).map((brand, rowIndex) => (
                                        <TableRow key={rowIndex}>
                                            <BrandTooltip
                                                key={rowIndex}
                                                brandInfo={brand}
                                                enableSalesAnalysisLink={true}
                                                useBrandLockStatus={false}
                                                showAddToCartButton={false}
                                                showAddToComparisonButton={true}
                                            >
                                                <TableCell style={rightBorderedCellStyle}>
                                                    <div className={css.companyWrapper}>
                                                        {brand ? (
                                                            <>
                                                                <img
                                                                    src={getLogoImageUrl(_.get(brand, "logo", ""))}
                                                                    alt={_.get(brand, ["name"], "")}
                                                                    className={css.companyImage}
                                                                />
                                                                <Box>
                                                                    <Link
                                                                        to={!_.get(brand, "isLocked") ? generateSalesAnalysisUrl({
                                                                            brandId: _.get(brand, ["id"], brandId)
                                                                        }) : window.location.href.replace(window.location.origin, "")}
                                                                        onClick={(event) => {
                                                                            if (_.get(brand, "isLocked")) {
                                                                                event.preventDefault();
                                                                                dispatch(popups.actions.showSendMessagePopup(
                                                                                    "Brand access request (brand share distribution)",
                                                                                    intl.formatMessage({ id: "REQUEST.ACCESS.POPUP.TITLE" }),
                                                                                    intl.formatMessage({ id: "REQUEST.BRAND.ACCESS.MESSAGE.TEMPLATE"}, { brandName: _.get(brand, "name", "") }),
                                                                                ));
                                                                            }
                                                                        }}
                                                                    >
                                                                        <Box display="flex" flexDirection="row" alignItems="center">
                                                                            <Typography className={css.companyName}>
                                                                                {_.get(brand, ["name"], "")}

                                                                                {_.get(brand, "isLocked") && (
                                                                                    <span style={{marginLeft: "8px"}}>
                                                                                        <FontAwesomeIcon icon={faLock} color={theme.palette.primary.dark} />
                                                                                    </span>
                                                                                )}
                                                                            </Typography>

                                                                            {_.get(brand, "isDemo") && (
                                                                                <Box ml={1}>
                                                                                    <Badge color={"green"} text={<FormattedMessage id="BRAND.DEMO.BADGE.TEXT" />} />
                                                                                </Box>
                                                                            )}
                                                                        </Box>
                                                                    </Link>

                                                                    <Link
                                                                        to={generateCatalogUrl({
                                                                            industry: _.get(brand, ["industry", "id"], "")
                                                                        })}
                                                                    >
                                                                        <p className={css.catalogLink}>
                                                                            {_.get(brand, ["industry", "name"], "")}
                                                                        </p>
                                                                    </Link>
                                                                </Box>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <img src={getLogoImageUrl(null)} alt="" className={css.companyImage}/>
                                                                <Box>
                                                                    <Link
                                                                        to={generateCatalogUrl()}
                                                                    >
                                                                        <p className={css.catalogLink}>
                                                                            <FormattedMessage id="OTHER_COMPANY" />
                                                                        </p>
                                                                    </Link>
                                                                </Box>
                                                            </>
                                                        )}
                                                    </div>
                                                </TableCell>
                                            </BrandTooltip>

                                            {preparedData.sharesTable.columnDatas.map((columnData, index) => {
                                                return columnData.subColumnDatas.map((subColumnData, subColumnDataIndex) => (
                                                    <TableCell key={subColumnDataIndex} align={(subColumnDataIndex === (columnData.subColumnDatas.length - 1)) ? "left" : "right"} style={(index < (preparedData.sharesTable.columnDatas.length - 1) && subColumnDataIndex === (columnData.subColumnDatas.length - 1)) ? rightBorderedCellStyle : cellStyle}>
                                                        {createSharePercentageValueElement(subColumnData.values[rowIndex], intl, language)}
                                                    </TableCell>
                                                ));
                                            })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                            {sharesTableExpandRowCount > 0 && (
                                <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
                                    <Button variant="text" color="primary" onClick={() => {
                                        setIsSharesTableExpanded(!isSharesTableExpanded);
                                    }}>
                                        <Typography>{isSharesTableExpanded ? <FormattedMessage id="TABLE.SHOW.LESS"/> : <FormattedMessage id="TABLE.SHOW.MORE.TEMPLATE" values={{ count: sharesTableExpandRowCount }}/>}</Typography>
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    ) : (
                        <MessageTitle>
                            {!isChartLoaded ? (
                                <FormattedMessage id="DATA_IS_BEING_LOADED"/>
                            ) : (alertText ? alertText : <FormattedMessage id="NOT_ENOUGH_DATA_PROMPT"/>)}
                        </MessageTitle>
                    )}
                </Box>
            </Box>
        </ChartCard>
    );
};

export default injectIntl(BrandShareDistribution);
