import moment from "moment";
// It should be imported to be loaded.
/* eslint-disable no-unused-vars */
import localizationRu from "moment/locale/ru";
import {createMonthTimeRange} from "./timeUtils";
/* eslint-enable no-unused-vars */

export function formatDateString(date, locale) {
    return moment.utc(date).locale(locale).format("L");
}

export function formatDateTooltipString(date, locale) {
    return moment.utc(date).locale(locale).format("dd DD MMM YYYY");
}

export function formatWeekTooltipString(date, locale) {
    return (moment.utc(date).startOf("day").locale(locale).format("dd DD MMM YYYY") + " - " + moment.utc(date).add(6, "day").endOf("day").locale(locale).format("dd DD MMM YYYY"));
}

export
// FRONT/BACK IMPLEMENTATIONS SHOULD BE THE SAME!
function formatMonthYearString(date, locale) {
    let result = moment.utc(date).locale(locale).format("MMMM YYYY");
    result = result.charAt(0).toUpperCase() + result.slice(1);
    return result;
}

export
// FRONT/BACK IMPLEMENTATIONS SHOULD BE THE SAME!
function formatTimeRangeString(timeRange, isSmartMode, locale) {
    let result = "";

    const TimeRangePeriodLabelType = {
        Month: "Month",
        Date: "Date"
    };

    let timeRangeStartPeriodLabelType = TimeRangePeriodLabelType.Date;
    let timeRangeEndPeriodLabelType = TimeRangePeriodLabelType.Date;

    if (isSmartMode) {
        timeRangeStartPeriodLabelType = (timeRange.startDate.getTime() === createMonthTimeRange(timeRange.startDate).startDate.getTime() ? TimeRangePeriodLabelType.Month : TimeRangePeriodLabelType.Date);
        timeRangeEndPeriodLabelType = (timeRange.endDate.getTime() === createMonthTimeRange(timeRange.endDate).endDate.getTime() ? TimeRangePeriodLabelType.Month : TimeRangePeriodLabelType.Date);

        if (timeRangeStartPeriodLabelType !== timeRangeEndPeriodLabelType) {
            timeRangeStartPeriodLabelType = TimeRangePeriodLabelType.Date;
            timeRangeEndPeriodLabelType = TimeRangePeriodLabelType.Date;
        }
    }

    const timeRangeStartPeriodLabel = (timeRangeStartPeriodLabelType === TimeRangePeriodLabelType.Month ? formatMonthYearString(timeRange.startDate, locale) : formatDateString(timeRange.startDate, locale));
    const timeRangeEndPeriodLabel = (timeRangeEndPeriodLabelType === TimeRangePeriodLabelType.Month ? formatMonthYearString(timeRange.endDate, locale) : formatDateString(timeRange.endDate, locale));

    if (timeRangeStartPeriodLabel === timeRangeEndPeriodLabel) {
        result = timeRangeStartPeriodLabel;
    }
    else {
        result = timeRangeStartPeriodLabel + " - " + timeRangeEndPeriodLabel;
    }

    return result;
}

export const convertValueToLocaleString = (value, intl, locale, format) => {
    let result;

    if (format.style === "percentPoint" || format.styleOverride === "percentPoint") {
        result = `${(value * 100).toLocaleString(locale, {
            ...format,
            style: "decimal"
        })} ${intl.formatMessage({ id: "PERCENTAGE.POINT.ABBREVIATION" })}`;
    }
    else {
        result = value.toLocaleString(locale, format);
    }

    if (format.style === "percent") {
        result = result.replaceAll(/\s+%/g, "%");
    }

    return result;
};