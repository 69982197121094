import React from "react";
import MaskedInput from "react-text-mask";

const PhoneMaskedInput = ({ inputRef, ...other }) => {
  return (
      <MaskedInput
          {...other}
          ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
          }}
          mask={[
            "+",
            "3",
            "7",
            "5",
            " ",
            "(",
            /\d/,
            /\d/,
            ")",
            " ",
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
          ]}
          placeholderChar={"\u2000"}
      />
  );
};

export default PhoneMaskedInput;
