import axios from "axios";

import { API_URL } from "./constants"

export const fetchBrandMetricOpinionData = async (brandId, metricId, period, timeRanges, locationId, posId, metricFilters, isRawDataMode, isIndexDataMode) => {
  const { data } = await axios.get(
      `${API_URL}/narrator/brand/${brandId}/metric/${metricId}/opinion`,
      {
        params: {
          period,
          timeRanges,
          locationId,
          posId,
          metricFilters,
          isRawDataMode,
          isIndexDataMode,
        },
      }
  );
  return data;
};
