import {put, takeEvery} from "redux-saga/effects";
import {createAction, handleActions} from "redux-actions";
import { createSelector } from "reselect";
import * as industriesCrud from "../../crud/industries.crud";
import {createActionName, getStateHashKey, wrapSafely} from "./utils";
import _ from "lodash";
import * as auth from "./auth.duck";
import {parseTimeRange} from "../../utils/timeUtils";

const TAG = "INDUSTRIES";

export const actions = {
    requestIndustryRatingInfo: createAction(createActionName(TAG, "requestIndustryRatingInfo")),
    fillIndustryRatingInfo: createAction(createActionName(TAG, "fillIndustryRatingInfo")),

    requestIndustryInfo: createAction(createActionName(TAG, "requestIndustryInfo")),
    fillIndustryInfo: createAction(createActionName(TAG, "fillIndustryInfo")),

    requestIndustryMetricData: createAction(createActionName(TAG, "requestIndustryMetricData")),
    fillIndustryMetricData: createAction(createActionName(TAG, "fillIndustryMetricData")),

    requestIndustryBrandRatingInfo: createAction(createActionName(TAG, "requestIndustryBrandRatingInfo")),
    fillIndustryBrandRatingInfo: createAction(createActionName(TAG, "fillIndustryBrandRatingInfo")),
};

const defaultState = {
    industryRatingInfo: {},
    industryInfos: {},
    industryMetricDatas: {},
    industryBrandRatingInfos: {}
};

const initialState = _.clone(defaultState);

export const reducer = handleActions(
    {
        [actions.requestIndustryRatingInfo]: (state) => {
            state.industryRatingInfo = {
                ...state.industryRatingInfo,
                isLoading: true,
                isLoaded: false,
            };

            return { ...state };
        },

        [actions.fillIndustryRatingInfo]: (state, { payload: { data } }) => {
            state.industryRatingInfo = {
                ...state.industryRatingInfo,
                ...data,
                isLoading: false,
                isLoaded: true,
            };

            return { ...state };
        },

        [actions.requestIndustryInfo]: (state, { payload: { countryId, industryId, industryDefinition } }) => {
            const key = getStateHashKey({countryId, industryId, industryDefinition});

            state.industryInfos = {
                ...state.industryInfos,
                [key]: {
                    ...state.industryInfos[key],
                    isLoading: true,
                    isLoaded: false,
                }
            };

            return { ...state };
        },

        [actions.fillIndustryInfo]: (state, { payload: { countryId, industryId, industryDefinition, data } }) => {
            const key = getStateHashKey({countryId, industryId, industryDefinition});

            state.industryInfos = {
                ...state.industryInfos,
                [key]: {
                    ...state.industryInfos[key],
                    ...data,
                    isLoading: false,
                    isLoaded: true,
                }
            };

            return { ...state };
        },

        [actions.requestIndustryMetricData]: (state, { payload: { countryId, industryId, industryDefinition, metricId } }) => {
            const key = getStateHashKey({countryId, industryId, industryDefinition, metricId});

            state.industryMetricDatas = {
                ...state.industryMetricDatas,
                [key]: {
                    ...state.industryMetricDatas[key],
                    isLoading: true,
                    isLoaded: false,
                }
            };

            return { ...state };
        },

        [actions.fillIndustryMetricData]: (state, { payload: { countryId, industryId, industryDefinition, metricId, data } }) => {
            const key = getStateHashKey({countryId, industryId, industryDefinition, metricId});

            state.industryMetricDatas = {
                ...state.industryMetricDatas,
                [key]: {
                    ...state.industryMetricDatas[key],
                    ...data,
                    isLoading: false,
                    isLoaded: true,
                }
            };

            return { ...state };
        },

        [actions.requestIndustryBrandRatingInfo]: (state, { payload: { countryId, industryId, industryDefinition, timeRanges, period } }) => {
            const key = getStateHashKey({countryId, industryId, industryDefinition, timeRanges, period});

            state.industryBrandRatingInfos = {
                ...state.industryBrandRatingInfos,
                [key]: {
                    ...state.industryBrandRatingInfos[key],
                    isLoading: true,
                    isLoaded: false,
                }
            };

            return { ...state };
        },

        [actions.fillIndustryBrandRatingInfo]: (state, { payload: { countryId, industryId, industryDefinition, timeRanges, period, data } }) => {
            const key = getStateHashKey({countryId, industryId, industryDefinition, timeRanges, period});

            state.industryBrandRatingInfos = {
                ...state.industryBrandRatingInfos,
                [key]: {
                    ...state.industryBrandRatingInfos[key],
                    ...data,
                    isLoading: false,
                    isLoaded: true,
                }
            };

            return { ...state };
        },

        [auth.actionTypes.LogoutUser]: () => {
            console.log(`LogoutUser in ${TAG}`);
            return _.clone(defaultState);
        }
    },
    initialState
);

export function* saga() {
    yield takeEvery(actions.requestIndustryRatingInfo, wrapSafely(function*({ payload }) {
        const { data = {} } = yield industriesCrud.fetchIndustryRatingInfo(payload);

        if (data.reportTimeRange) {
            data.reportTimeRange = parseTimeRange(data.reportTimeRange);
        }
        if (data.baseTimeRange) {
            data.baseTimeRange = parseTimeRange(data.baseTimeRange);
        }

        yield put(actions.fillIndustryRatingInfo({
            ...payload,
            data
        }));
    }, TAG, "requestIndustryRatingInfo"));

    yield takeEvery(actions.requestIndustryInfo, wrapSafely(function*({ payload }) {
        const { data = {} } = yield industriesCrud.fetchIndustryInfo(payload);
        yield put(actions.fillIndustryInfo({
            ...payload,
            data
        }));
    }, TAG, "requestIndustryInfo"));

    yield takeEvery(actions.requestIndustryMetricData, wrapSafely(function*({ payload }) {
        const { data = {} } = yield industriesCrud.fetchIndustryMetricData(payload);
        yield put(actions.fillIndustryMetricData({
            ...payload,
            data
        }));
    }, TAG, "requestIndustryMetricData"));

    yield takeEvery(actions.requestIndustryBrandRatingInfo, wrapSafely(function*({ payload }) {
        const { data = {} } = yield industriesCrud.fetchIndustryBrandRatingInfo(payload);

        if (data.reportTimeRange) {
            data.reportTimeRange = parseTimeRange(data.reportTimeRange);
        }
        if (data.baseTimeRange) {
            data.baseTimeRange = parseTimeRange(data.baseTimeRange);
        }

        yield put(actions.fillIndustryBrandRatingInfo({
            ...payload,
            data
        }));
    }, TAG, "requestIndustryBrandRatingInfo"));
}

const getIndustries = (state) => state.industries;

const functions = {
    getIndustryInfo: (industryInfos, filters) => {
        return _.get(industryInfos, getStateHashKey(filters), {});
    },
    getIndustryMetricData: (industryMetricDatas, filters) => {
        return _.get(industryMetricDatas, getStateHashKey(filters), {});
    },
    getIndustryBrandRatingInfo: (industryBrandRatingInfos, filters) => {
        return _.get(industryBrandRatingInfos, getStateHashKey(filters), {});
    }
};

export const selectors = {
    getIndustryRatingInfo: createSelector(getIndustries, ({ industryRatingInfo }) => industryRatingInfo),
    getIndustryRatingInfoLoadState: createSelector(getIndustries, ({ industryRatingInfo }) => {
        return {
            isLoading: !!industryRatingInfo.isLoading,
            isLoaded: !!industryRatingInfo.isLoaded
        };
    }),
    getIndustryInfo: (countryId, industryId, industryDefinition) => createSelector(getIndustries, ({ industryInfos }) => {
        return functions.getIndustryInfo(industryInfos, {countryId, industryId, industryDefinition});
    }),
    getIndustryInfoLoadState: (countryId, industryId, industryDefinition) => createSelector(getIndustries, ({ industryInfos }) => {
        const industryInfo = functions.getIndustryInfo(industryInfos, {countryId, industryId, industryDefinition});
        return {
            isLoading: !!industryInfo.isLoading,
            isLoaded: !!industryInfo.isLoaded
        };
    }),
    getIndustryMetricActiveFilters: (countryId, industryId, industryDefinition, metricId) => createSelector(getIndustries, ({ industryInfos }) => {
        // No metric filters are supported for now.
        return {};
    }),
    getIndustryMetricData: (countryId, industryId, industryDefinition, metricId) => createSelector(getIndustries, ({ industryMetricDatas }) => {
        // For now the only one metric is loaded for industry - dynamics (26);
        console.assert(metricId === "26");

        const industryMetricData = functions.getIndustryMetricData(industryMetricDatas, {countryId, industryId, industryDefinition, metricId});
        return industryMetricData;
    }),
    getIsIndustryMetricDataLoaded: (countryId, industryId, industryDefinition, metricId) => createSelector(getIndustries, ({ industryMetricDatas }) => {
        // For now the only one metric is loaded for industry - dynamics (26);
        console.assert(metricId === "26");

        const industryMetricData = functions.getIndustryMetricData(industryMetricDatas, {countryId, industryId, industryDefinition, metricId});
        return industryMetricData.isLoaded;
    }),
    getIndustryBrandRatingInfo: (countryId, industryId, industryDefinition, timeRanges, period) => createSelector(getIndustries, ({ industryBrandRatingInfos }) => {
        return functions.getIndustryBrandRatingInfo(industryBrandRatingInfos, {countryId, industryId, industryDefinition, timeRanges, period});
    }),
    getIndustryBrandRatingInfoLoadState: (countryId, industryId, industryDefinition, timeRanges, period) => createSelector(getIndustries, ({ industryBrandRatingInfos }) => {
        const industryBrandRatingInfo = functions.getIndustryBrandRatingInfo(industryBrandRatingInfos, {countryId, industryId, industryDefinition, timeRanges, period});
        return {
            isLoading: !!industryBrandRatingInfo.isLoading,
            isLoaded: !!industryBrandRatingInfo.isLoaded
        };
    }),
}