import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import * as cart from "../../store/ducks/cart.duck";

const MyCart = ({ icon, items }) => {
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Link to="/cart" className="kt-header__topbar-item">
      <div className="kt-header__topbar-icon">
        <i className={icon} />
        <div
          style={{
            position: "absolute",
            backgroundColor: "#bb3e53",
            color: "#fab9c5",
            fontSize: "11px",
            width: "22px",
            height: "22px",
            borderRadius: "50%",
            textAlign: "center",
            bottom: "0",
            left: "20px",
          }}
        >
          <span style={{ verticalAlign: "sub" }}>{items.length}</span>
        </div>
      </div>
    </Link>
  );
};

const mapStateToProps = (state) => ({
  items: cart.selectors.getAddedItems(state),
});

export default connect(mapStateToProps, null)(MyCart);
