import React, {useEffect} from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    withStyles
} from "@material-ui/core";
import {connect, useDispatch, useSelector} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";

import {LayoutSubheader} from "../../../_metronic";

import * as auth from "../../store/ducks/auth.duck";
import * as stats from "../../store/ducks/stats.duck";
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';

import { selectors } from "../../../_metronic/ducks/i18n";
import {
    parseParams,
    createHistoryLocation,
    formatDateString,
    formatMonthYearString,
    generateMonthList, subtractFromDate
} from "../../utils/utils";
import {UserRoles} from "../../crud/constants";
import moment from "moment";
import {useHistory} from "react-router-dom";
import * as _ from "lodash";
import {Dropdown} from "react-bootstrap";
import RefreshProgressableButton from "../../common/components/refresh-progressable-button";

const generateTableDataForInvoices = (invoices) => {
    let invoiceColumn = [];
    let purchasedItemColumn = [];
    let packageColumn = [];

    const purchasedItemColors = [
        "#ffffff",
        "#f5f5f5"
    ];

    let purchasedItemIndex = 0;
    for (const invoice of invoices) {
        invoiceColumn.push(invoice);

        const oldPurchasedItemColumnLength = purchasedItemColumn.length;

        for (const purchasedItem of invoice.purchasedItems) {
            purchasedItemColumn.push(purchasedItem);
            purchasedItem.color = purchasedItemColors[purchasedItemIndex % purchasedItemColors.length];
            if (purchasedItem.packages.length > 1) {
                purchasedItem.rowSpan = purchasedItem.packages.length;
                purchasedItemColumn = purchasedItemColumn.concat(Array(purchasedItem.packages.length - 1).fill(null));
            }

            for (const purchasedItemPackage of purchasedItem.packages) {
                purchasedItemPackage.color = purchasedItem.color;
                packageColumn.push(purchasedItemPackage);
            }
            ++purchasedItemIndex;
        }

        const newPurchasedItemColumnLength = purchasedItemColumn.length;
        const purchasedItemColumnLengthDelta = newPurchasedItemColumnLength - oldPurchasedItemColumnLength;
        if (purchasedItemColumnLengthDelta > 1) {
            invoice.rowSpan = purchasedItemColumnLengthDelta;
            invoiceColumn = invoiceColumn.concat(Array(purchasedItemColumnLengthDelta - 1).fill(null));
        }
    }

    return {
        invoiceColumn,
        purchasedItemColumn,
        packageColumn
    };
};

const formatMoneyValue = (value, currency, language) => {
    let result = "";

    let formatter = null;
    if (currency) {
        formatter = new Intl.NumberFormat(language, {
            style: 'currency',
            useGrouping: false,
            currency: currency,
        });
    }
    else {
        formatter = new Intl.NumberFormat(language, {
            style: 'decimal',
            useGrouping: false,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }
    result = formatter.format(value);

    return result;
};

const formatPercentValue = (value, language) => {
    const formatter = new Intl.NumberFormat(language, {
        style: 'percent',
        useGrouping: false,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    return formatter.format(value);
};

const formatMonthsPeriod = (months, language) => {
    return months.map((month) => {
        return formatMonthYearString(month, language)
    }).join("-");
};

const formatRevenueAmountSums = (revenueAmountSums, language) => {
    let sums = [];
    for (const [currency, revenueAmountSum] of Object.entries(revenueAmountSums)) {
        sums.push(formatMoneyValue(revenueAmountSum, currency, language));
    }

    return sums.join("; ");
};

const generateMonthFilterList = () => {
    let months = [];

    const currentMoment = moment.utc();
    months = _.reverse(generateMonthList(subtractFromDate(currentMoment, 3, "month"), currentMoment));

    return months;
};

const BorderedTable = withStyles({
    root: {
        border: 1,
        borderStyle: "solid",
        borderColor: "lightgray",
    },
})(Table);

const BorderedTableCell = withStyles({
    root: {
        border: 1,
        borderStyle: "solid",
        borderColor: "inherit"
    },
})(TableCell);

const DataPartnerStatistics = (props) => {
    const { user, dataPartnerStats = {}, intl } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const language = useSelector(selectors.getSelectedLanguage);

    const months = generateMonthFilterList();

    const params = parseParams();
    const month = params.month || months[1];
    const partnerId = ((user && user.role === UserRoles.Admin) ? params.partnerId : null);

    const changePartnerFilter = (partnerId) => {
        const newParams = {
            month,
            partnerId
        };

        if (!_.isEqual(newParams, params)) {
            history.push(createHistoryLocation(window.location.path, newParams));
            dispatch(stats.actions.requestDataPartnerStats(partnerId, month));
        }
    };

    const changeMonthFilter = (month) => {
        const newParams = {
            month,
            partnerId
        };

        if (!_.isEqual(newParams, params)) {
            history.push(createHistoryLocation(window.location.path, newParams));
            dispatch(stats.actions.requestDataPartnerStats(partnerId, month));
        }
    };

    useEffect(() => {
        dispatch(stats.actions.requestDataPartnerStats(partnerId, month));
        // eslint-disable-next-line
    }, []);

    const { isLoading: isDataPartnerStatsLoading } = useSelector(stats.selectors.getDataPartnerStatsLoadState);

    const invoiceTableData = generateTableDataForInvoices(dataPartnerStats.invoices || []);

    const onExportButtonClickHandler = () => {
        dispatch(stats.actions.requestDataPartnerStatsExport(partnerId, month));
    };

    return (
        <Box className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1">
            <LayoutSubheader
                title={<FormattedMessage id="DATA.PARTNER.STATISTICS.CAPTION"/>}
            />
            <Box mt={3} mb={2} flexGrow={1} className="kt-login__wrapper">
                <Box mb={1} display="flex" flexDirection="row" alignItems="center">
                    <Box mr={1}>
                        <Typography variant="h6" color={"textSecondary"}>
                            <FormattedMessage id="DATA.PARTNER.STATS.PARTNER.NAME.PROMPT" />
                        </Typography>
                    </Box>
                    {user && user.role === UserRoles.Admin && dataPartnerStats.partners && (
                        <Dropdown>
                            <Dropdown.Toggle>
                                {partnerId ? _.find(dataPartnerStats.partners, (el) => {
                                    return (el.id === partnerId);
                                }).name : ""}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {dataPartnerStats.partners.map((item, i) => (
                                    <Dropdown.Item
                                        key={i}
                                        onClick={() => changePartnerFilter(item.id)}
                                    >
                                        {`${item.name} (${item.id})`}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                    {user && user.role === UserRoles.DataPartner && dataPartnerStats.partner && (
                        <Typography variant="h6" color={"textSecondary"}>
                            {dataPartnerStats.partner.name}
                        </Typography>
                    )}
                </Box>

                <Box display="flex" flexWrap="wrap" mb={1}>
                    <Box mr={1}>
                        <Dropdown>
                            <Dropdown.Toggle>
                                {formatMonthYearString(month, language)}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {months.map((item, i) => (
                                    <Dropdown.Item
                                        key={i}
                                        onClick={() => changeMonthFilter(item)}
                                    >
                                        {formatMonthYearString(item, language)}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Box>

                    <Box mr={1}>
                        <RefreshProgressableButton
                            disabled={isDataPartnerStatsLoading}
                            isProgressing={isDataPartnerStatsLoading}
                            onClick={() => {
                                dispatch(stats.actions.requestDataPartnerStats(partnerId, month));
                            }}
                        >
                        </RefreshProgressableButton>
                    </Box>

                    {dataPartnerStats.invoices && (
                        <Box>
                            <button
                                className="btn btn-secondary btn-elevate"
                                color="primary"
                                onClick={onExportButtonClickHandler}
                            >
                                <SaveAltOutlinedIcon />
                            </button>
                        </Box>
                    )}
                </Box>

                <Box style={{height: 500, overflow: 'auto'}}>
                    <BorderedTable stickyHeader>
                        <TableHead>
                            <TableRow>
                                <BorderedTableCell><FormattedMessage id="DATA.PARTNER.STATS.TABLE.COLUMN.INVOICE.DATE.TITLE" /></BorderedTableCell>
                                <BorderedTableCell><FormattedMessage id="DATA.PARTNER.STATS.TABLE.COLUMN.COUNTRY.NAME.TITLE" /></BorderedTableCell>
                                <BorderedTableCell><FormattedMessage id="DATA.PARTNER.STATS.TABLE.COLUMN.INDUSTRY.NAME.TITLE" /></BorderedTableCell>
                                <BorderedTableCell><FormattedMessage id="DATA.PARTNER.STATS.TABLE.COLUMN.BRAND.NAME.TITLE" /></BorderedTableCell>
                                <BorderedTableCell><FormattedMessage id="DATA.PARTNER.STATS.TABLE.COLUMN.PACKAGE.NAME.TITLE" /></BorderedTableCell>
                                <BorderedTableCell><FormattedMessage id="DATA.PARTNER.STATS.TABLE.COLUMN.PACKAGE.CURRENCY.TITLE" /></BorderedTableCell>
                                <BorderedTableCell><FormattedMessage id="DATA.PARTNER.STATS.TABLE.COLUMN.PACKAGE.BASE.PRICE.TITLE" /></BorderedTableCell>
                                <BorderedTableCell><FormattedMessage id="DATA.PARTNER.STATS.TABLE.COLUMN.PACKAGE.PRICE.DISCOUNT.TITLE" /></BorderedTableCell>
                                <BorderedTableCell><FormattedMessage id="DATA.PARTNER.STATS.TABLE.COLUMN.PACKAGE.FINAL.PRICE.TITLE" /></BorderedTableCell>
                                <BorderedTableCell><FormattedMessage id="DATA.PARTNER.STATS.TABLE.COLUMN.TOTAL.TRANSACTION.COUNT.TITLE" /></BorderedTableCell>
                                <BorderedTableCell><FormattedMessage id="DATA.PARTNER.STATS.TABLE.COLUMN.PARTNER.TRANSACTION.COUNT.TITLE" /></BorderedTableCell>
                                <BorderedTableCell><FormattedMessage id="DATA.PARTNER.STATS.TABLE.COLUMN.PARTNER.TRANSACTION.SHARE.TITLE" /></BorderedTableCell>
                                <BorderedTableCell><FormattedMessage id="DATA.PARTNER.STATS.TABLE.COLUMN.PARTNER.REVENUE.SHARE.TITLE" /></BorderedTableCell>
                                <BorderedTableCell><FormattedMessage id="DATA.PARTNER.STATS.TABLE.COLUMN.PARTNER.REVENUE.AMOUNT.TITLE" /></BorderedTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {invoiceTableData.invoiceColumn.map((invoiceObject, rowIndex) => {
                                const purchasedItemObject = invoiceTableData.purchasedItemColumn[rowIndex];
                                const packageObject = invoiceTableData.packageColumn[rowIndex];

                                return (
                                    <TableRow key={`invoice-${rowIndex}`}>
                                        {invoiceObject && (
                                            <BorderedTableCell style={{verticalAlign: "top"}} rowSpan={invoiceObject.rowSpan}>{formatDateString(invoiceObject.date, language)}</BorderedTableCell>
                                        )}

                                        {purchasedItemObject && (
                                            <>
                                                <BorderedTableCell rowSpan={purchasedItemObject.rowSpan} style={{backgroundColor: purchasedItemObject.color}}>{purchasedItemObject.country.name}</BorderedTableCell>
                                                <BorderedTableCell rowSpan={purchasedItemObject.rowSpan} style={{backgroundColor: purchasedItemObject.color}}>{purchasedItemObject.industry.name}</BorderedTableCell>
                                                <BorderedTableCell rowSpan={purchasedItemObject.rowSpan} style={{backgroundColor: purchasedItemObject.color, fontWeight: "bold"}}>{purchasedItemObject.brand ? purchasedItemObject.brand.name : intl.formatMessage({id: "DATA.PARTNER.STATS.TABLE.COLUMN.BRAND.NAME.ALL.BRANDS"})}</BorderedTableCell>
                                            </>
                                        )}

                                        {packageObject && (
                                            <>
                                                <BorderedTableCell style={{backgroundColor: packageObject.color}}>{`${packageObject.title} (${formatMonthsPeriod(packageObject.months, language)})`}</BorderedTableCell>
                                                <BorderedTableCell style={{backgroundColor: packageObject.color}}>{packageObject.currency}</BorderedTableCell>
                                                <BorderedTableCell style={{backgroundColor: packageObject.color}}>{formatMoneyValue(packageObject.basePrice, null, language)}</BorderedTableCell>
                                                <BorderedTableCell style={{backgroundColor: packageObject.color}}>{formatPercentValue(packageObject.priceDiscountPercentage, language)}</BorderedTableCell>
                                                <BorderedTableCell style={{backgroundColor: packageObject.color}}>{formatMoneyValue(packageObject.finalPrice, null, language)}</BorderedTableCell>
                                                <BorderedTableCell style={{backgroundColor: packageObject.color}}>{packageObject.totalTransactonCount}</BorderedTableCell>
                                                <BorderedTableCell style={{backgroundColor: packageObject.color}}>{packageObject.partnerTransactonCount}</BorderedTableCell>
                                                <BorderedTableCell style={{backgroundColor: packageObject.color}}>{formatPercentValue(packageObject.partnerTransactonPercentage, language)}</BorderedTableCell>
                                                <BorderedTableCell style={{backgroundColor: packageObject.color}}>{formatPercentValue(packageObject.revenueSharePercentage, language)}</BorderedTableCell>
                                                <BorderedTableCell style={{backgroundColor: packageObject.color}}>{formatMoneyValue(packageObject.revenueAmount, null, language)}</BorderedTableCell>
                                            </>
                                        )}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </BorderedTable>

                    {dataPartnerStats.revenueAmountSums && (
                        <Box>
                            <Typography variant="h6" color={"textSecondary"} align={"right"}>
                                <FormattedMessage id="DATA.PARTNER.STATS.TOTAL.REVENUE.PROMPT" />{formatRevenueAmountSums(dataPartnerStats.revenueAmountSums, language)}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => ({
    user: auth.selectors.getUser(state),
    dataPartnerStats: stats.selectors.getDataPartnerStats(state),
});

export default injectIntl(connect(mapStateToProps, null)(DataPartnerStatistics));
