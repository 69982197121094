import React from "react";
import { Box, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

import { toAbsoluteUrl } from "../../utils/utils";

const ImageBlock = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${toAbsoluteUrl("/media/bg/catalog.jpg")}`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="250px"
        color="#fff"
      >
        <Box pl={3} pr={3}>
          <Typography align="center" variant="h5">
            <FormattedMessage id="CATALOG.TITLE" />
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default ImageBlock;
