import React, {createRef} from "react";
import {connect, useDispatch} from "react-redux";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Box,
    Typography, TextField, makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {FormattedMessage, injectIntl} from "react-intl";

import { sendMessage } from "../../../crud/contact.crud.js";
import * as popups from "../../../store/ducks/popups.duck";
import {Formik} from "formik";

import * as alert from "../../../store/ducks/alert.duck";

import ProgressableButton from "../progressable-button";
import GoogleAnalytics from "../google-analytics";
import * as auth from "../../../store/ducks/auth.duck";

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        margin: 0,
        padding: theme.spacing(2),
    }
}));

const SendMessagePopup = ({ popupState, user, hidePopup, ...props }) => {
    const { intl } = props;
    const dispatch = useDispatch();
    const classes = useStyles();

    const messageTextField = createRef();

    const caretPositionToken = "[CARET]";
    const messageTextFieldCaretPosition = (popupState.message ? popupState.message.indexOf(caretPositionToken) : -1);
    if (messageTextFieldCaretPosition >= 0) {
        popupState.message = popupState.message.replace(caretPositionToken, "");
    }

    return (
        <Dialog
            scroll={"body"}
            open={!!popupState.isOpen}
            onClose={() => hidePopup()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            TransitionProps={{
                onEntered: (event) => {
                    if (messageTextFieldCaretPosition >= 0) {
                        messageTextField.current.focus();
                        messageTextField.current.selectionStart = messageTextFieldCaretPosition;
                    }
                }
            }}
        >
            <DialogTitle id="alert-dialog-title">
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6">
                        {popupState.title ? popupState.title : <FormattedMessage id="SEND.MESSAGE.POPUP.TITLE" />}
                    </Typography>
                    <IconButton aria-label="close" onClick={() => hidePopup()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <Formik
                initialValues={{
                    phone: user.phone,
                    message: popupState.message,
                }}
                validate={(values) => {
                    const errors = {};

                    if (!values.phone) {
                        errors.phone = true;
                    }

                    if (!values.message) {
                        errors.message = true;
                    }

                    return errors;
                }}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    GoogleAnalytics.reportEvent({
                        category: 'SendMessagePopup',
                        action: 'SendMessage'
                    });

                    sendMessage(popupState.context || "No context", values.phone, values.message)
                        .then(() => {
                            setSubmitting(false);
                            dispatch(alert.actions.showAlert({
                                severity: "success",
                                message: intl.formatMessage({id: "SEND.MESSAGE.POPUP.SUBMIT_RESULT"})
                            }));
                            hidePopup();
                        })
                        .catch(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {({
                      values,
                      status,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit} noValidate autoComplete="off">
                        {status && (
                            <div role="alert" className="alert alert-danger">
                                <div className="alert-text">{status}</div>
                            </div>
                        )}

                        <DialogContent dividers>
                            <DialogContentText id="alert-dialog-description">
                                <FormattedMessage id="SEND.MESSAGE.POPUP.PROMPT" />
                            </DialogContentText>

                            <Box mb={1}>
                                <TextField
                                    type="tel"
                                    name="phone"
                                    label={<FormattedMessage id="INPUT.PHONE" />}
                                    variant="outlined"
                                    fullWidth
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.phone}
                                    error={Boolean(touched.phone && errors.phone)}
                                />
                            </Box>

                            <Box mb={0}>
                                <TextField
                                    type="text"
                                    name="message"
                                    label={<FormattedMessage id="INPUT.MESSAGE" />}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={5}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputRef={messageTextField}
                                    value={values.message}
                                    error={Boolean(touched.message && errors.message)}
                                />
                            </Box>
                        </DialogContent>

                        <DialogActions className={classes.buttonContainer}>
                            <ProgressableButton
                                type="submit"
                                disabled={isSubmitting}
                                isProgressing={isSubmitting}
                            >
                                <FormattedMessage id="SEND.MESSAGE.POPUP.SUBMIT_BUTTON" />
                            </ProgressableButton>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </Dialog>
    );
};

const mapStateToProps = (state) => ({
    popupState: popups.selectors.getSendMessagePopupState(state),
    user: auth.selectors.getUser(state),
});

const mapDispatchToProps = (dispatch) => ({
    hidePopup: () => dispatch(popups.actions.hideSendMessagePopup()),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SendMessagePopup));
