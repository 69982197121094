import React, {createRef, useEffect} from "react";
import { Box } from "@material-ui/core";
import {connect, useDispatch, useSelector} from "react-redux";
import { LayoutSubheader } from "../../../_metronic";
import Header from "./header";
import MetricPackageTabsAndFilters from "../../common/components/metric-package-tabs-and-filters";
import FiltersAdditionalInfo from "../../common/components/filters-additional-info";
import PackageTab from "../../partials/package-tab";
import MetricHelpDrawer from "../../common/components/metric-help-drawer";
import { parseParams, generateSalesAnalysisUrl } from "../../utils/utils";
import * as brands from "../../store/ducks/brands.duck";
import _ from "lodash";
import * as auth from "../../store/ducks/auth.duck";
import * as popups from "../../store/ducks/popups.duck";
import {injectIntl} from "react-intl";

const SalesAnalysis = ({
    match: { params: { brandId, requestedTabName } = {} } = {},
    user,
    intl
}) => {
    const dispatch = useDispatch();

    const params = parseParams();

    const brandInfo = useSelector(brands.selectors.getBrandInfo(brandId));
    const { isLoaded: isBrandInfoLoaded } = useSelector(brands.selectors.getBrandInfoLoadState(brandId));
    const supportedPackages = useSelector(brands.selectors.getBrandSupportedPackages(brandId));
    const purchasedPackages = useSelector(brands.selectors.getBrandPackages(brandId));
    const activePackageId = useSelector(brands.selectors.getBrandActivePackageId(brandId));

    const tabInfos = supportedPackages.map((supportedPackage) => {
        const purchasedPackage = purchasedPackages.find((purchasedPackage) => purchasedPackage.package.id === supportedPackage.id);
        return {
            id: supportedPackage.id,
            name: supportedPackage.name,
            title: supportedPackage.title,
            link: generateSalesAnalysisUrl({
                brandId,
                tabName: supportedPackage.name
            }),
            isPackagePurchased: !!purchasedPackage,
            areUpdatesAvailable: !!_.find((supportedPackage || {}).metrics || [], (metric) => {
                return !!_.get(metric, "config.releaseNotes");
            }),
        };
    });

    const months = useSelector(brands.selectors.getBrandPackageMonths(brandId, activePackageId));
    const locations = useSelector(brands.selectors.getBrandLocations(brandId));

    const filters = useSelector(brands.selectors.getBrandActiveFilters(brandId));

    const metricHelpDrawer = createRef();

    if (!requestedTabName) {
        for (const tabInfo of tabInfos) {
            if (tabInfo.isPackagePurchased) {
                requestedTabName = tabInfo.name;
                break;
            }
        }
    }

    const { id: requestedPackageId } = tabInfos.find((tabInfo) => (tabInfo.name === requestedTabName)) || {};
    const { name: activeTabName } = tabInfos.find((tabInfo) => (tabInfo.id === activePackageId)) || {};

    useEffect(() => {
        dispatch(brands.actions.setBrandActivePackageId({
            brandId: brandId,
            packageId: requestedPackageId,
            month: params.month
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandId, requestedPackageId]);

    return (
        <Box>
            <LayoutSubheader
                component={
                    <Header
                        brandInfo={brandInfo}
                        locations={locations}
                        onLocationSelected={({ locationId, posId }) => {
                            dispatch(
                                brands.actions.setBrandActiveFilters({
                                    brandId: brandId,
                                    filters: {
                                        salesChannel: "offline",
                                        locationId,
                                        posId
                                    },
                                })
                            );
                        }}
                        isBrandInfoLoaded={isBrandInfoLoaded}
                        onUnlockRequest={() => {
                            dispatch(popups.actions.showSendMessagePopup(
                                "Brand access request (brand page)",
                                intl.formatMessage({ id: "REQUEST.ACCESS.POPUP.TITLE" }),
                                intl.formatMessage({ id: "REQUEST.BRAND.ACCESS.MESSAGE.TEMPLATE"}, { brandName: brandInfo.name }),
                            ));
                        }}
                    />
                }
            />

            <MetricPackageTabsAndFilters
                tabInfos={tabInfos}
                activeTabName={activeTabName}
                locations={locations}
                months={months}
                options={{
                    posFilter: {
                        visible: true,
                    },
                    periodFilter: {
                        visible: true,
                    },
                    timeRanges: {
                        reportTimeRange: {
                            visible: true,
                            presetListVisible: true,
                            customizable: true,
                            monthListVisible: true,
                        },
                        baseTimeRange: {
                            visible: true,
                            presetListVisible: true,
                            customizable: true,
                            clearable: true,
                            monthListVisible: true,
                        },
                        swappable: true,
                    },
                    switches: {
                        visible: (user && (user.capabilities["raw_data_mode"] || user.capabilities["index_data_mode"])),
                        rawDataSwitch: {
                            visible: (user && user.capabilities["raw_data_mode"]),
                        },
                        indexDataSwitch: {
                            visible: (user && user.capabilities["index_data_mode"]),
                        },
                    },
                }}
                filters={filters}
                onChange={(filtersPortion) => {
                    dispatch(
                        brands.actions.setBrandActiveFilters({
                            brandId: brandId,
                            filters: {
                                ...filtersPortion
                            },
                        })
                    );
                }}
                brandInfo={brandInfo}
                shouldShowDemoDataWarning={isBrandInfoLoaded && !brandInfo.isPurchased && !brandInfo.isDemo}
                isLoading={!isBrandInfoLoaded}
            />

            {filters && filters.period && (
                <FiltersAdditionalInfo
                    brandInfo={brandInfo}
                    locations={locations}
                    filters={filters}
                    isLoading={!isBrandInfoLoaded}
                    onBrandLinkClick={() => {
                        dispatch(
                            brands.actions.setBrandActiveFilters({
                                brandId: brandId,
                                filters: {
                                    locationId: null,
                                    posId: null,
                                },
                            })
                        );
                    }}
                    onLocationLinkClick={() => {
                        dispatch(
                            brands.actions.setBrandActiveFilters({
                                brandId: brandId,
                                filters: {
                                    posId: null,
                                },
                            })
                        );
                    }}
                    onPosLinkClick={() => {
                        // Do nothing!
                    }}
                />
            )}

            {tabInfos.map(({ id, name }, index) =>
                activeTabName === name && (
                    <React.Fragment key={`${index}-tab-panel`}>
                        <PackageTab
                            brandId={brandId}
                            packageId={id}
                            toggleHelpDrawer={(state) => {
                                metricHelpDrawer.current.toggleHelpDrawer(state);
                            }}
                        />
                    </React.Fragment>
                )
            )}

            <MetricHelpDrawer ref={metricHelpDrawer} />
        </Box>
    );
};

const mapStateToProps = (state) => ({
    user: auth.selectors.getUser(state),
});

export default injectIntl(connect(mapStateToProps, null)(SalesAnalysis));